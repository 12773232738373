import { entity } from "simpler-state";



// ORDERS RELATED
export const ordersShowDeleted = entity(false);
export const packageDeleting = entity(false);
export const packageRestoring = entity(false);

// SERVICES RELATED

export const servicesImportProgress = entity(null);
export const servicesImportIncrement = entity(null);