import {
    Box,
    Container,
    Divider,
    Grid,
    Typography
} from '@mui/material'
import React, {useEffect, useState} from 'react'

import {
    openCatalogPartDrawer,
} from "Context/DrawerStates";
import {styled} from '@mui/material/styles'

export const CatalogConfigurationPartsCards = (props) => {
    const [catalogItems, setCatalogItems] = useState(props.parts);
    useEffect(()=> {
        setCatalogItems(props.parts)
    }, [props.parts])
    const Grid_ = styled(Grid)`
      margin-left: 40px;
      max-width: 180px !important;
      margin-bottom: 20px;
      border: 1px solid lightgray;
      border-radius: 10px;
      padding: 10px !important;
      margin-top: 40px;
    `;
    const IMG_ = styled('img')`
      max-width: 80px;
      height: auto;
      display: flex;
      align-self: center;
      justify-content: center;
      align-content: center;
    `;

    return (
        <>
          <Divider sx={styles.margin_bottom}><Typography color="secondary">Items</Typography></Divider>
          <Grid container spacing={5}>
            {catalogItems?.map((item) => {
              return (
                <Grid_
                  key={item.id}
                  item
                  xs={6}
                  sm={4}
                  md={3}
                  lg={2}
                >
                  <Container>
                  <Box
                    alignSelf="center"
                    key={item.id}
                    onClick={() => {
                      const itemForDrawer = catalogItems?.find(
                        (propItem) => propItem.id === item.id
                      );
    
                      props.setSelectedPart(itemForDrawer);
                      openCatalogPartDrawer();
                      
                    }}
                  >
                    {/* RENDER A CARD */}
                    <Box
                      sx={styles.box[1]}
                    >
                      <Box
                        sx={styles.box[3]}
                      >
                        <IMG_
                          src={item.iconImage}
                          alt=""
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={styles.box[2]}
                    >
                      <Typography variant="h6" sx={styles.typography}>
                        {item.name}
                      </Typography>
                    </Box>
                  </Box>
                  </Container>
                </Grid_>
              );
            })}
          </Grid>
          </>
      );
}

const styles = {
    box: {
      1: {
        display: "flex",
        flexDirection: "row",
        // justifyContent: "space-between",
        justifyContent: "center",
        alignItems: "center",
      },
      2: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      },
      3: {
        height: 80,
        width: 80,
        display: "flex",
        justifyContent: "center",
      }
    },
    typography: { fontWeight: "bold" }
  };