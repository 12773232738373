import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Checkbox,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from 'react';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Loading from './Loading';
import {styled} from "@mui/material/styles";

const SpecificationsContainer = (props) =>{

    const groupBy =  (xs, key) => {
      return xs?.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    const {specifications, serviceParts} = props;
    const [orderedSet, setOrderedSet] = useState((props.specifications !== undefined) ? groupBy(props.specifications?.data?.filter((ele, ind) => ind === specifications.data.findIndex(elem => elem.Name === ele.Name  && elem.Group === ele.Group)), 'Type') : []);
    var checkedState = useRef([])
    const [expanded, setExpanded] = React.useState(false);
    const [selectedSpecifications, setSelectedSpecifications] = useState({specs: []});

    console.log("Orderedset: ", orderedSet)
    useEffect(() =>{
      if(Array.isArray(specifications.data)){
        let pp = specifications.data?.filter((ele, ind) => ind === specifications.data.findIndex(elem => elem.Name === ele.Name && elem.Group === ele.Group))
        let newArray = []
        
        
        setOrderedSet(groupBy(pp, 'Type'))
        Object.keys(orderedSet).map((group) =>{
            return newArray.push(new Array(orderedSet[group].length).fill(false))
        })

        checkedState.current = newArray
        checkedState.current = checkedState.current
      }

      if(orderedSet !== undefined){
        Object.keys(orderedSet).map((group) =>{
        orderedSet[group].map((spec, index) => {
          console.log("Spec: ", spec);
          console.log("Index: ", index);
          if(spec?.isApp){
            console.log("IsApp Spec: ", spec);
            checkSpecification(index, group) 
          }
        })
      })
      }
      
        
              
    }, [props.specifications, specifications])

    useEffect(() => {
      
    }, [orderedSet, checkedState])

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    const checkSpecification = (position, group) => {
      const index = Object.keys(orderedSet).indexOf(group);
      if(checkedState.current[index] !== undefined){
        const updatedCheckedState = checkedState.current[index].map((item, idx) =>
          idx === position ? !item : item
        );
        checkedState.current[index] = updatedCheckedState
        checkedState.current = checkedState.current
        if(updatedCheckedState.at(position))
          addSelectedSpecification(position, group);
        else
          removeSelectedSpecification(position, group); 
      }      
      
    };

    const removeSelectedSpecification = (position, group) =>{
      let connTypes = selectedSpecifications.specs
      let typeToRemove = connTypes.indexOf(orderedSet[group].at(position))
      connTypes.splice(typeToRemove, 1);
      setSelectedSpecifications({specs: Array.from(new Set(connTypes))})
      props.setSelectedSpecificationsMain({specs: Array.from(new Set(connTypes))})
    }

    const addSelectedSpecification = (position, group) =>{
      let connTypes = selectedSpecifications.specs;
      if(connTypes.indexOf(JSON.parse(JSON.stringify(orderedSet[group].at(position)))) < 0){
        connTypes.push(orderedSet[group].at(position))
      }
      setSelectedSpecifications({specs: Array.from(new Set(connTypes))})
      props.setSelectedSpecificationsMain({specs: Array.from(new Set(connTypes))})
    }

    const getNameByType = (type) =>{
      if(Number(type) === 0 || Number(type) === 1){
        return "Specs"
      }else if(Number(type) === 9){
        return "Insulation and Liner"
      }
    }

    return(
            <Box >
                {
                  !specifications.isLoading  && specifications.data !== [] && (orderedSet !== undefined) ? 
                  Object.keys(orderedSet).map((group, index) => (
                    <Box key={index} ref={React.createRef()} sx={{marginLeft: "1rem !important"}}>
                      <Accordion expanded={expanded === index} onChange={handleChange(index)} elevation={false}>
                        <AccordionSummary
                          ref={React.createRef()}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography_ 
                            ref={React.createRef()} 
                          >
                            {getNameByType(group)}
                          </Typography_>
                        </AccordionSummary>
                        <AccordionDetails >
                          <List>
                            {
                              checkedState.current !== [] &&
                              orderedSet[group].map((type, idx) => {
                                const index = Object.keys(orderedSet).indexOf(group);
                                if(checkedState.current[index] && Array.isArray(checkedState.current[index])){
                                return (
                                  <ListItem key={idx}>
                                      <ListItemText key={idx} primary={type.Name} />
                                        <ListItemIcon>
                                          < Checkbox 
                                            checked={checkedState.current[index][idx]}
                                            onChange={() => checkSpecification(idx, group)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                          />
                                        </ListItemIcon>
                                    </ListItem>
                                )
                                }
                                
                              })
                            }
                          </List>
                          
                        </AccordionDetails>
                      </Accordion>
                    </Box>)) : <Loading msg="Loading Specifications"></Loading>
                }    
          </Box>
    )
};

const styles = {
  typography: { marginLeft: "1rem !important"},
  card: {
    width: 310,
    height: "auto", 
    overflowY: 'auto', 
  }
};

const Typography_ = styled(Typography)`
  //width: 80% !important; 
  flex-shrink: 0 !important;
`;


export default SpecificationsContainer;