import { Auth, onAuthUIStateChange } from "aws-amplify";
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    Divider,
    Grid,
    TextField,
    Typography
} from "@mui/material"

import { AmplifyButton } from "@aws-amplify/ui-react";
import { pilotApply } from "data/queries/queryAPI";
import { styled } from "@mui/material/styles";

/** Pilot Program Application-Form Modal
     * 
     * @param {*} props 
     * @returns 
     */
    const ExpireDialog = props => {
    
        const Title = `Pilot Program Expiration`;
        const Caption = `Your Field Orderz Pilot Trial has expired and you will now be logged out`;

        const handleLogout = () => {
            props.setAccount(null);
            try {
              Auth.signOut({global: false});
            } catch (error) {
              console.log('error signing out: ', error);
            }
            window.location.assign("/");//.reload();
          };
    
        return(
            <Dialog
                open={ props.isOpen }
                onClose={ () => props.setIsOpen(false) }
            >
                <DialogTitle> {Title} </DialogTitle>
    
                <Box sx={{ paddingLeft: 3, paddingRight: 3, paddingBottom: 3 }} >
    
    
                    <Box sx={{ textAlign: 'justify', width: '75%' }} >
                        <Typography variant='caption'> {Caption} </Typography>
                    </Box>   
                    <Divider/>
                    <Grid spacing={1} container direction='column' sx={{marginTop: 1}}>
    
                        <Grid item>
                            <Typography>Please contact STRATUS services to either extend your license or your company will be deleted within 7 days.</Typography>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="secondary" onClick={handleLogout}>Okay</Button>
                        </Grid>      
                    </Grid>
                </Box>
            </Dialog>
        )
    }
    
    const styles = {
    
        signUpbtn: {
    
            backgroundColor:'#FF9900', 
            padding: 12, 
            color: '#fff', 
            width: 153,
            maxWidth: 153,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: '1rem',
            marginTop: '.5rem',
            textAlign: 'center'
        }
    }
    
    
    
    export default ExpireDialog;
    