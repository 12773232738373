import {
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    CircularProgress,
    Container,
    Divider,
    Drawer,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material"
import React, {useEffect, useState} from 'react';
import {closeServicesConfigDrawer, servicesConfigDrawerOpen} from "Context/DrawerStates"
import {
    convertServiceToCatalog,
    getFabGeometry,
    postFabGeometry,
    updateServiceAccessory,
    updateServiceItems
} from "../../../../data/queries/queryAPI";
import {useMutation, useQueryClient} from "react-query"

import CancelIcon from "@mui/icons-material/Cancel";
import ErrorDialog from "components/Utilities/ErrorDialog";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import {ServiceConfigurationDrawerNavBar} from "./ServiceConfiguratonDrawerNavBar"
import {ServiceConfigurationPartCard} from "./ServiceConfigurationPartCard"
import {ServiceConfigurationPartConnectors} from "./ServiceConfigurationPartConnectors"
import {ServiceConfigurationPartDimensions} from './ServiceConfigurationPartDimensions'
import {ServiceConfigurationPartMain} from "./ServiceConfigurationPartMain"
import {ServiceConfigurationPartOptions} from "./ServiceConfigurationPartOptions";
import { ServiceConfigurationPartPreviewer } from "./ServiceConfigurationPartPreviewer";
import clone from 'just-clone'
import {styled} from "@mui/material/styles";

export const ServiceConfigurationDrawer = (props) => {
    const [selectedPart, setSelectedPart] = useState(props.selectedPart);
    const [selectedService, setSelectedService] = useState(props.selectedService);
    const [selectedConnectionTypes, setSelectedConnectionTypes] = useState(props.selectedConnectionTypes);
    const [companyId, setCompanyId] = useState(props.companyId);
    const [selectedTab, setSelectedTab] = useState("Main");
    const [propertiesChanged, setPropertiesChanged] = useState(false);
    const ductServiceTypeFabIds = [1, 2, 3, 30, 32, 33, 34, 35, 36, 56];
    const drawerWidth = 400;
    const [isUpdating, setIsUpdating] = useState(false);
    const [meshData, setMeshData] = useState([]);
    const [dimensions, setDimensions] = useState(undefined)
    const queryClient = useQueryClient()
    const [dialogOpen, setDialogOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };
    

    useEffect(() => {
        setSelectedPart(props.selectedPart)
        setSelectedTab("Main");
        setPropertiesChanged(false);
    }, [props.selectedPart])

    useEffect(() => {
        setSelectedService(props.selectedService)
        setSelectedConnectionTypes(props.selectedService.connectionTypes?.filter(i => {return i?.isApp === true;}))
    }, [props.selectedService])

    useEffect(() => {
        setCompanyId(props.companyId)
    }, [props.companyId])


    const { mutate: convertService } = useMutation(convertServiceToCatalog, {
        onSettled: (data) => {
            queryClient.refetchQueries(['allOnlyCatalogs']).then(()=> {
                setIsUpdating(false);
                setPropertiesChanged(false);
            })
        },
        onError: (error) => {
            setIsUpdating(false);
            setErrorMessage(error.message || 'An error occurred');
            setDialogOpen(true);
        },
    });

    const { mutate: updateServiceParts } = useMutation(updateServiceItems, {
        onSettled: (data) => {
          console.log("Service items have been updated");
        },
        onError: (error) => {
            setIsUpdating(false);
            setErrorMessage(error.message || 'An error occurred');
            setDialogOpen(true);
        },
    });

    const { mutate: updateServiceGroups } = useMutation(updateServiceAccessory, {
        onSettled: (data) => {
          console.log("Service groups have been updated");
        },
        onError: (error) => {
            setIsUpdating(false);
            setErrorMessage(error.message || 'An error occurred');
            setDialogOpen(true);
        },
    });

    const { mutate: postMeshData } = useMutation(postFabGeometry, {
        onSettled: (data) => {
            console.log("Mesh data has been posted");
            let payload = {
                id: selectedService.id,
                meshId: data
            }
            getMeshData(payload)
        },
        onError: (error) => {
            setIsUpdating(false);
            setErrorMessage(error.message || 'An error occurred');
            setDialogOpen(true);
        },
    });

    const { mutate: getMeshData } = useMutation(getFabGeometry, {
        onSettled: (data) => {
          console.log("Mesh data has been got");
          setMeshData({data: data.data})
        },
        onError: (error) => {
            setIsUpdating(false);
            setErrorMessage(error.message || 'An error occurred');
            setDialogOpen(true);
          },
    })

    async function updateServiceItemData(groupPayload,payload) {
        setIsUpdating(true);
        updateServiceGroups(groupPayload);
        updateServiceParts(payload);
    }
    const updatePartData = () => {
        if (selectedService !== undefined &&  selectedPart !== undefined) {
            let updateObject = clone(selectedService)
            updateObject.items.connectors[updateObject.items.connectors.findIndex((connector) => connector.partNumber === selectedPart.partNumber)] = selectedPart;
            let selectedGroup = undefined
            selectedService.groups.find((group) => {
                group.items.forEach((item) => {
                    if(item.id === selectedPart.partNumber){
                        selectedGroup = group;
                    }
                })
            })
            let groupPayload = {}
            let payload = {}

            groupPayload['companyId'] = companyId;
            groupPayload['serviceId'] = selectedService.id;
            groupPayload['groupId'] = selectedGroup.id;
            groupPayload['data'] = {}
            groupPayload.data['menuIcon'] = selectedPart.materialSprite;
            groupPayload.data['supported'] = selectedPart.supported;
            groupPayload.data['isExcluded'] = selectedPart.isExcluded;
            groupPayload.data['item'] = selectedPart;

            payload['companyId'] = companyId;
            payload['id'] = selectedService.id;
            payload['items'] = selectedPart;
            payload['connectionTypes'] = selectedConnectionTypes;
            updateServiceItemData(groupPayload, payload).then(() => {
                convertService(selectedService.id)
                props.setSelectedService(updateObject)
            })
        }
    }

    const GenerateMesh = () => {
        let selectedPartMeshData = {
            Name: selectedPart.description,
            PatternNumber: selectedPart.cid,
            DatabaseId: selectedPart.databaseId,
            ItemPath: selectedPart.itemPath,
            Dimensions: selectedPart.properties,
            Connectors: selectedPart.connectors,
            Seams: selectedPart.seams,
            InletConnector: selectedPart.inletIndex,
            OutletConnector: selectedPart.outletIndex,
            InsulationSpecFabId: selectedPart.InsulationSpecFabId,
            MaterialFabId: selectedPart.MaterialFabId,
            MaterialGaugeFabId: selectedPart.MaterialGaugeFabId,
            ServiceTypeFabId: selectedPart.ServiceTypeFabId,
            SpecFabId: selectedPart.SpecFabId,
            Options: selectedPart.Options,
            Order: "",
            ItemNumber: "",
            Notes: "",
            Quantity: 1
        }
        let payload = {
            id: selectedService.id,
            data: {selectedItemMeshData: selectedPartMeshData},
        }
        postMeshData(payload)
        return
    }

    return(
        <Drawer
            anchor="right"
            open={servicesConfigDrawerOpen.use()}
            onClose={() => {
                closeServicesConfigDrawer()
            }}
            variant="temporary"
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                  width: drawerWidth,
                  boxSizing: "border-box",
                },
              }}
            BackdropProps={{sx: {background: "rgba(0,0,0,0)"}}}
        >
            <DrawerHeader
                sx={styles.drawer.drawerHeader}
            >
                <Typography variant="h6"  component="div">
                    Part Configuration
                </Typography>
                <IconButton
                    sx={styles.iconButton}
                    onClick={() => {
                        closeServicesConfigDrawer();
                    }}
                >
                    <CancelIcon sx={styles.cancelIcon}/>
                </IconButton>
            </DrawerHeader>
            <ServiceConfigurationDrawerNavBar itemOptions={selectedPart?.Options} productLists={selectedPart?.ProductLists} serviceTypeFabId={selectedPart?.ServiceTypeFabId} ductServiceTypeFabIds={ductServiceTypeFabIds} selectedTab={selectedTab} setSelectedTab={setSelectedTab}/>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
            >
                <ServiceConfigurationPartCard selectedPart={selectedPart} setSelectedPart={setSelectedPart} setPropertiesChanged={setPropertiesChanged}/>
                {
                    selectedTab === 'Main' ? (
                        <ServiceConfigurationPartMain selectedPart={selectedPart} setSelectedPart={setSelectedPart} selectedService={selectedService} setPropertiesChanged={setPropertiesChanged}/>
                    ):null
                }
                {
                    selectedTab === 'Dimensions' ? (
                        <ServiceConfigurationPartDimensions properties={selectedPart?.properties} selectedPart={selectedPart} setSelectedPart={setSelectedPart} dimensions={dimensions} setPropertiesChanged={setPropertiesChanged}/>
                    ): null
                }
                {
                    selectedTab === 'Connectors' ? (
                        <ServiceConfigurationPartConnectors selectedPart={selectedPart} setSelectedPart={setSelectedPart} selectedConnectionTypes={selectedConnectionTypes} setPropertiesChanged={setPropertiesChanged}/>
                    ): null
                }
                {
                    selectedTab === 'Options' ? (
                        <ServiceConfigurationPartOptions selectedPart={selectedPart} setSelectedPart={setSelectedPart} itemOptions={selectedPart?.Options} setPropertiesChanged={setPropertiesChanged} />
                    ): null
                }
                {
                    selectedTab === 'Dimensions' || selectedTab === 'Connectors' ? (
                        <ServiceConfigurationPartPreviewer
                            selectedPart={selectedPart}
                            setDimensions={setDimensions}
                            meshData={meshData}
                            buttons={
                                <Box
                                  sx={{display: "flex", justifyContent: "center"}}
                                >
                                  <GenerateMesh_btn variant="contained" color="secondary" onClick={GenerateMesh}> Preview </GenerateMesh_btn>
                                </Box>
                            }
                        />
                    ): null
                }
                {
                    propertiesChanged ? (
                        <Button variant="contained" color="secondary" onClick={updatePartData}>{isUpdating ? <CircularProgress color="white"/> : "Update Part"}</Button>
                    ): null
                }

            </Grid>
            <ErrorDialog open={dialogOpen} handleClose={handleCloseDialog} errorMessage={errorMessage} />
        </Drawer>
    )
}

const styles = {
    box: { paddingTop: "1rem" },
    drawer: {
        drawerHeader: {
          backgroundColor: "#000",
          color: "#fff",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }
      },
    iconButton: { color: "#fff", textAnchor: "right" },
    btn: { paddingLeft: "2rem", paddingRight: "2rem" },
    cancelIcon: { color: "#fff", textAlign: "right" },
    row: {display: "flex", flexDirection: "row"},
    tenrem: { width: "64px", height: "64px"},
};

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
}));

const GenerateMesh_btn = styled(Button)`
  margin-top: .25rem !important;
`;