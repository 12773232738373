import {
    Box,
    Button,
    Divider,
    MenuItem,
    Select,
    Switch,
    Typography
} from '@mui/material'
import React, {useEffect, useState} from 'react'

import CircularProgress from '@mui/material/CircularProgress'
import clone from 'just-clone'
import { setAttachmentDownloadSetting } from 'Context/currentUser'
import {styled} from '@mui/styles'
import {updateCompanySettings} from 'data/queries/queryAPI'
import {useMutation} from 'react-query'

const ToggleSwitch = styled(Switch)( ({theme}) => ({

    '& .MuiSwitch-switchBase':{
      marginTop: '9px !important',
      marginLeft: '9px !important',
      transition: 'translateX(10px) !important',
      transitionDuration: '300 ms !important',

      '& .MuiSwitch-thumb':{
        color: 'black !important',
        transitionDuration: '300 ms !important',

      },
      '&.Mui-checked': {
        '& .MuiSwitch-thumb' : {
          color: '#F15A29 !important'
        },
        '& ~ .MuiSwitch-track':{
          backgroundColor: '#F15A29 !important',

        }
      },
      '& ~ .MuiSwitch-track':{
        backgroundColor: 'black !important',

      }
    },
}));
export const SettingsList = (props) => {
    const [settings, setSettings] = useState(props.settings !== undefined ? props.settings : 
      {
        attachmentDownloads: true,
        oletEOP: false,
        autoNumbering: false,
        autoNumberingFormat: "{Project}-{Order}-{Area}",
      }
    )

    const [attachmentDownloads, setAttachmentDownloads] = useState(settings?.attachmentDownloads !== undefined ? settings.attachmentDownloads : true);
    const [oletEOP, setOletEOP] = useState(settings?.oletEOP !== undefined ? settings.oletEOP : false);
    const [autoNumbering, setAutoNumbering] = useState(settings?.autoNumbering !== undefined ? settings.autoNumbering : false);
    const [autoNumberingFormat, setAutoNumberingFormat] = useState(settings?.autoNumberingFormat !== undefined ? settings.autoNumberingFormat : "{Project}-{Order}-{Area}");
    const [updating, setUpdating] = useState(false);

    useEffect(() => {
      if(props.settings !== undefined){
        setSettings(props.settings)
      }
    }, [props.settings])

    useEffect(() => {
      setAttachmentDownloads(settings?.attachmentDownloads !== undefined ? settings.attachmentDownloads : true)
      setOletEOP(settings?.oletEOP !== undefined ? settings.oletEOP : false)
      setAutoNumbering(settings?.autoNumbering !== undefined ? settings.autoNumbering : false)
      setAutoNumberingFormat(settings?.autoNumberingFormat !== undefined ? settings.autoNumberingFormat : "{Project}-{Order}-{Area}")
      setVariables(parseString(settings?.autoNumberingFormat !== undefined ? settings.autoNumberingFormat : "{Project}-{Order}-{Area}"))
    }, [settings])
    const { mutate: writeSettings } = useMutation(updateCompanySettings, {
      onSettled: () => {
        setUpdating(false);
      },
      onError: (error) => {
        console.error("[Error] Update company settings failed", error);
        alert("An error occurred while updating company settings: ", error);
      },
  });
    const options = [
        "Division",
        "Project",
        "Order",
        "Area",
        "Date",
        "Incrementor",
        "Abbreviation",
    ]

    const parseString = (str) => {
      const regex = /{([^{}]+)}/g;
      const matches = str.match(regex);
      if (matches) {
        return matches.map(match => match.slice(1, -1));
      }
      return [];
    }
    const [variables, setVariables] = useState(parseString(autoNumberingFormat));
    const setVariable = (index, event) => {
      const newVariables = [...variables];
      newVariables[index] = event.target.value;
      setVariables(newVariables);
    
      // If you need to update individual state variables as well, do it here:
      if (index === 0) {
        setVariable1(event);
      } else if (index === 1) {
        setVariable2(event);
      } else if (index === 2) {
        setVariable3(event);
      }
    };
    const setVariable1 = (e) =>{
      let newValue = e.target.value;
      let temp = clone(variables)
      temp[0] = String(newValue)
      setVariables(temp);
      setAutoNumberingFormat("{" + temp[0] + "}-{" + temp[1] + "}-{" + temp[2] + "}")
    }
    const setVariable2 = (e) =>{
      let newValue = e.target.value;
      let temp = clone(variables)
      temp[1] = String(newValue)
      setVariables(temp);
      setAutoNumberingFormat("{" + temp[0] + "}-{" + temp[1] + "}-{" + temp[2] + "}")
    }
    const setVariable3 = (e) =>{
      let newValue = e.target.value;
      let temp = clone(variables)
      temp[2] = String(newValue)
      setVariables(temp);
      setAutoNumberingFormat("{" + temp[0] + "}-{" + temp[1] + "}-{" + temp[2] + "}")
    }
    const handleSave = () => {
      setUpdating(true);
      setAttachmentDownloads(attachmentDownloads);
      setAttachmentDownloadSetting(attachmentDownloads)
      setOletEOP(oletEOP);
      setAutoNumbering(autoNumbering);
      setVariables(variables);
      setAutoNumberingFormat("{" + variables[0] + "}-{" + variables[1] + "}-{" + variables[2] + "}")
      let payload = {};
      payload.companyId = props.companyId;
      payload.attachmentDownloads = attachmentDownloads;
      payload.oletEOP = oletEOP;
      payload.autoNumbering = autoNumbering;
      payload.autoNumberingFormat = autoNumberingFormat;
      writeSettings(payload);
    }
    const ToggleItem = ({ label, checked, onChange }) => (
      <Box display="flex" alignItems="center" justifyContent="space-between" width="20%">
        <Typography variant="h6">{label}</Typography>
        <ToggleSwitch checked={checked} onChange={onChange} />
      </Box>
    );
    return (
        <>
            <Box sx={{paddingLeft: "2rem", paddingBottom: '1rem'}}>
                <Typography variant="h5" > Settings </Typography>
            </Box>

            <Divider/>
            {
              settings !== undefined ? (<Box>
                <Box px={2} py={1}>
                  <ToggleItem
                    label="Attachment Downloads"
                    checked={attachmentDownloads}
                    onChange={() => setAttachmentDownloads(!attachmentDownloads)}
                  />
                </Box>
                <Box px={2} py={1}>
                  <ToggleItem
                    label="Dimension Olet EOP"
                    checked={oletEOP}
                    onChange={() => setOletEOP(!oletEOP)}
                  />
                </Box>
                <Box px={2} py={1}>
                  <ToggleItem
                    label="Auto Numbering"
                    checked={autoNumbering}
                    onChange={() => setAutoNumbering(!autoNumbering)}
                  />
                </Box>
                <Box px={2} py={1} display="flex" justifyContent="space-between" width="50%">
                  <Box pt={0.5}>
                    <Typography variant="h6">Auto Numbering Format</Typography>
                  </Box>
                  <Box display="flex">
                    {variables.map((variable, index) => (
                      <React.Fragment key={index}>
                        <Select
                          sx={{
                            width: "100%",
                            minWidth: "10rem",
                            maxWidth: "10rem",
                            marginBottom: "1rem",
                            height: "45px",
                            paddingTop: "5px"
                          }}
                          value={variable}
                          onChange={(e) => setVariable(index, e)}
                        >
                          {options.map((option) => (
                            <MenuItem
                              key={option}
                              sx={{
                                display: "flex !important",
                                flexDirection: "column !important"
                              }}
                              value={option}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                        {index < variables.length - 1 && (
                          <Box pt={0.75} px={0.5}>
                            <Typography>-</Typography>
                          </Box>
                        )}
                      </React.Fragment>
                    ))}
                    <Box minWidth="100%" pt={0.75} px={0.5}>
                      <Typography>- "P" - &#123;IncrementorX&#125;</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box px={2}>
                  <Button color="secondary" variant="contained" onClick={handleSave}>
                    {updating ? (
                      <CircularProgress color="white" size={24} />
                    ) : (
                      "Save"
                    )}
                  </Button>
                </Box>
              </Box>) : (
                <Box sx={styles.color.gray} open={true}>
                  <Box sx={styles.box[3]}>
                    <Typography variant="h3">Loading Settings...</Typography>
                    <CircularProgress color="inherit" />
                  </Box>
                </Box>
              )
            }
            
        </>
    )
}
const styles = {
  color: {
    gray: { color: "gray" },
    orange: {color: "#F15A29"},
    white: {color: "#fff"}
  },
  box: {
    1: {
      paddingTop: "1rem",
      paddingLeft: "2rem",
      display: "flex",
      //flexDirection: "row",
    },
    2: {
      display: "flex",
      flexDirection: "row",
      marginLeft: "2rem",
      alignItems: "center",
    },
    3: { display: "flex", flexDirection: "col", paddingLeft: "2rem", paddingTop: '1rem'},
    table: {paddingLeft: "25px", paddingRight: "30px", paddingTop: "20px"}
  },
  addCircleIcon: {
    marginTop: "auto",
    marginBottom: "auto",
    //marginLeft: "1rem",
  },
  circularProgress: { marginTop: ".6rem", marginRight: ".25rem" },
  typography: {
    1: { marginRight: "1rem !important" },
    2: { marginLeft: "2rem" },
  },
  divider: { marginTop: "1rem", marginBottom: "1rem" },
};