import { CircularProgress, TextField, Zoom } from "@mui/material";
import {
    ErrorTooltip,
    InfoTooltip,
    SuccessTooltip,
} from "../../../StyleToolTips.js";
import React, { useEffect, useState, } from "react";
import {
    testStratusStatusConnection,
    updateUploadKey,
} from "../../../data/queries/queryAPI.js";
import {
    useMutation,
    useQuery,
} from "react-query";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { Paper } from "@mui/material";
import {ThemeProvider} from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { colors } from "../../styles";
import { styled } from '@mui/material/styles';
import {theme} from "components/Theme/Theme.js";
import { withStyles } from "@mui/styles";

const CssTextField = styled(TextField)(({theme}) => ({
  "&.MuiTextField-root": {
    "& label": {
      color: "#F15A29",
      fontSize: "1rem",
    },
    "& label.Mui-focused": {
      fontSize: "1rem",
      color: "#F15A29"
    },
    "& .MuiOutlinedInput-root": {
    backgroundColor: 'white',
      borderRadius: 4,
      color: "#F15A29",
      width: "30rem",
      fontSize: "1rem",
      "& fieldset": {
        borderColor: "#F15A29",
        borderWidth: 2,
      },
      "&:hover fieldset": {
        borderColor: "#F15A29",
        borderWidth: 2,
      },
      "&.Mui-focused fieldset": {
        borderColor: "#F15A29",
        borderWidth: 2,
      },
    },
  },
 }));


export default function StratusAPIKey(props){

    const companyId = props.companyId;
    //const CssTextField = props.CssTextField;

    const [appKeyInput, setAppKeyInput] = useState("");
    const [saveApiKeyLoading, setSaveApiKeyLoading] = useState(false);
    const [isTestError, setIsTestError] = useState(false);
    const [uploadedKey, setUploadedKey] = useState(false);
    const [responseStatus, setResponseStatus] = useState(null);
    const [testLoading, setTestLoading] = useState(false);


    const { mutate: uploadKey } = useMutation(
        (payload) => updateUploadKey(payload),
        {
          onSettled: (data) => {
            if (data.status === 200) {
            //   setPropertiesChanged(false);
            //   setUpdating(false);
              setSaveApiKeyLoading(false);
              setIsTestError(false);
              setUploadedKey(true);
            //   setIsError(false);
            }

            if(data.status === 500){
                setUploadedKey(false)
                setSaveApiKeyLoading(false);
                setIsTestError(true);
            }

          },
          onError: (error) => {
            //console.error("error mutating data:", error);
            alert("There was an error: ", error);
            //setIsTestError(true);
            //setSaveApiKeyLoading(false);
            setSaveApiKeyLoading(false);
            setIsTestError(true);
          },
        }
      );

      const handleTestAPI = async () => {
        setTestLoading(true);
        const { status } = await testStratusStatusConnection();
        setResponseStatus(status);
        setTestLoading(false);
        setTimeout(() => {
          setResponseStatus(null);
        }, 10000);
      };

      const handleSaveAPI = () => {
        if(!saveApiKeyLoading){
            const payload = {};
            //payload.data.companyId = companyId;
            //payload.data = {name: nameInput};
            payload.data = {key: appKeyInput};
            uploadKey(payload);
            setSaveApiKeyLoading(true);
        }
      }

      const Div1 = styled('div')`
        display: flex;
      `;

      const styles = {
        button: {
          1: {
            position: "relative",
            borderColor: "#F15A29",
            background: "#F15A29",
            color: "white",
            paddingLeft: "20px",
            paddingRight: "20px",
            borderRadius: "4px",
            fontWeight: "400",
            textAlign: "center",
            cursor: "pointer",
            marginLeft: "20px",
            fontSize: "14px",
            marginTop: "9px",
            marginBottom: "10px",
            textAlign: "center",
            height: "40px",
            width: "80px"
          },
          2: {
            position: "relative",
            borderColor: "#F15A29",
            background: "#F15A29",
            color: "white",
            paddingLeft: "20px",
            paddingRight: "20px",
            borderRadius: "4px",
            fontWeight: "400",
            textAlign: "center",
            marginLeft: "20px",
            marginTop: "9px",
            marginBottom: "10px",
            fontSize: "14px",
            height: "40px",
            width: "80px",
            cursor: "pointer",
          }
        },
        errorIcon: {
          fill: "#A41623",
          position: "absolute",
          top: "-.25rem",
          right: "-.25rem",
          },
        checkCircleIcon: {
          fill: "forestgreen", //colors.secondary.green,
          position: "absolute",
          top: "-.25rem",
          right: "-.25rem",
          // "pointer-events": "none",
          },
        header: {
            margintop: "25px",
            marginbottom: "40px",
            width: "100%",
            height: "100%",
          },
      };

      return(
          <>
          <br/>
            <Typography variant="h5">Integrations</Typography>
            <Div1>
                <CssTextField
                    label="STRATUS API Key"
                    value={appKeyInput}
                    onChange={(e) => setAppKeyInput(e.target.value)}
                />

                <InfoTooltip
                    title="Save your app key securely in the cloud. Field Orderz will use this key in all future requests to STRATUS"
                    enterDelay={400}

                >

                <button
                    type="button"
                    style={styles.button[1]}
                    onClick={
                    //     () =>(
                    // !isLoading && uploadKey({data: { companyId, key: appKeyInput }})
                    // )
                        handleSaveAPI
                    }
                >
                    {!saveApiKeyLoading ? (
                    <>
                        <span>Save</span>
                    </>
                    ) : (
                    <CircularProgress size={"1rem"} color="inherit" />
                    )}

                    <ErrorTooltip
                        title="Key upload rejected, please double-check formatting and retry"
                        placement="right"

                    >
                        <Zoom in={isTestError} timeout={400}>
                        <ErrorIcon
                            style={styles.errorIcon}
                        />
                        </Zoom>
                    </ErrorTooltip>

                    <SuccessTooltip
                        title="Key upload successful!"
                        placement="right"
                       
                    >
                        <Zoom in={uploadedKey} timeout={400}>
                          <CheckCircleIcon
                              style={styles.checkCircleIcon}
                          />
                        </Zoom>
                    </SuccessTooltip>
                    </button>
                </InfoTooltip>

                <InfoTooltip
                    title="Using your saved app key, Field Orderz will confirm if it is able to connect to STRATUS"
                    enterDelay={400}
                >
                    <button
                    style={styles.button[2]}
                    type="button"

                    onClick={

                        handleTestAPI
                    }
                    >
                    {!testLoading ? (
                        <>
                        <button1>Test</button1>
                        </>
                    ) : (
                        <CircularProgress size={"1rem"} color="inherit" />
                    )}

                    <ErrorTooltip
                        title={"Some error occurred. Please wait and try again." || ""}
                        placement="top-start"
                    >
                        <Zoom in={responseStatus !== 200 && responseStatus !== null} timeout={10}>
                        <ErrorIcon
                            style={styles.errorIcon}
                        />
                        </Zoom>
                    </ErrorTooltip>

                    <SuccessTooltip
                        title="STRATUS Connection Successful!"
                        placement="right"
                       
                    >
                        <Zoom in={responseStatus === 200} timeout={10}>
                          <CheckCircleIcon
                              style={styles.checkCircleIcon}
                          />
                        </Zoom>
                    </SuccessTooltip>
                    </button>
                </InfoTooltip>
            </Div1>
          </>
      )
}
