import {
Box,
Dialog,
DialogTitle,
Divider,
Grid,
TextField,
Typography,
} from "@mui/material"

import { AmplifyButton } from "@aws-amplify/ui-react";
import { pilotApply } from "data/queries/queryAPI";
import { styled } from "@mui/material/styles";

/** Pilot Program Application-Form Modal
 * 
 * @param {*} props 
 * @returns 
 */
const PilotDialog = props => {

    const Title = `Pilot Program Application`;
    const Headline = `Sign up for your FieldOrderz Pilot!`;
    const Caption = `Once Pilot is approved, you'll have 2 weeks of full FieldOrderz testing FREE!`;

    const signUp = () => {

        const companyName = props.state.get('companyName');
        const companyAddress = props.state.get('companyAddress');
        const employeeCount = props.state.get('companyEmployeeCount');
        const licenseCount = props.state.get('licenseCount');
        const email = props.state.get('email');
        const phoneNumber = props.state.get('phoneNumber');
        const referral = props.state.get('referral');

        const payload = {
            name: companyName,
            date: new Date().getTime(),
            address: companyAddress,
            employeeCount: employeeCount,
            licenseCount: licenseCount,
            email: email,
            phoneNumber: phoneNumber,
            referral: referral,
            status: "Pending"
        };

        if(
            companyName != null && companyName != undefined && companyName != '' &&
            companyAddress != null && companyAddress != undefined && companyAddress != '' &&
            employeeCount != null && employeeCount != undefined  && employeeCount != '' &&
            licenseCount != null && licenseCount != undefined  && licenseCount != '' &&
            email != null && email != undefined && email != '' &&
            phoneNumber != null && phoneNumber != undefined && phoneNumber != '' &&
            referral != null && referral != undefined && referral != ''
        ) {
            pilotApply(payload).then( res => {
                    props.applied(res.status)
            });
        }
        

    };

    return(
        <Dialog
            open={ props.isOpen }
            onClose={ () => props.setIsOpen(false) }
        >
            <DialogTitle> {Title} </DialogTitle>

            <Box sx={{ paddingLeft: 3, paddingRight: 3, paddingBottom: 3 }} >


                <Box sx={{ textAlign: 'justify', width: '75%' }} >
                    <Typography variant='caption'> {Caption} </Typography>
                </Box>   
                <Divider/>
                <Grid spacing={1} container direction='column' sx={{marginTop: 1}}>

                    <Grid item>
                        <TextField 
                            fullWidth 
                            label='Company Name' 
                            value={ props.state.get('companyName') }
                            onChange={ e => props.dispatch({ type: 'set-company-name', value: e.target.value})}
                        />   
                    </Grid>

                    <Grid item>
                        <TextField 
                            fullWidth 
                            label='Company Address' 
                            value={ props.state.get('companyAddress') }
                            onChange={ e => props.dispatch({ type: 'set-company-address', value: e.target.value})}
                        />                       
                    </Grid>

                    <Grid item>
                        <TextField 
                            fullWidth 
                            label='Company Employee Count' 
                            value={ props.state.get('companyEmployeeCount') }
                            onChange={ e => props.dispatch({ type: 'set-company-employee-count', value: e.target.value})}                        />  
                    </Grid>

                    <Grid item>
                        <TextField 
                            fullWidth 
                            label='Email' 
                            value={ props.state.get('email') }
                            onChange={ e => props.dispatch({ type: 'set-email', value: e.target.value})}
                        /> 
                    </Grid>

                    <Grid item>
                        <TextField 
                            fullWidth 
                            label='Phone Number' 
                            value={ props.state.get('phoneNumber') }
                            onChange={ e => props.dispatch({ type: 'set-phone-number', value: e.target.value})}    
                        />  
                    </Grid>

                    <Grid item>
                        <TextField 
                            fullWidth 
                            label='Desired Pilot License Count' 
                            value={ props.state.get('licenseCount') }
                            onChange={ e => props.dispatch({ type: 'set-license-count', value: e.target.value})}                           />  
                    </Grid>

                    <Grid item>
                        <TextField 
                            fullWidth 
                            label='Where did you hear about us?' 
                            value={ props.state.get('referral') }
                            onChange={ e => props.dispatch({ type: 'set-referral', value: e.target.value})}                           />  
                    </Grid>








                </Grid>
                    

            </Box>

            <AmplifyButton 
                onClick={ signUp } 
                sx={ styles.signUpbtn }
            >
                <Typography variant='button'>Sign Up</Typography>
            </AmplifyButton>

        </Dialog>
    )
}

const styles = {

    signUpbtn: {

        backgroundColor:'#FF9900', 
        padding: 12, 
        color: '#fff', 
        width: 153,
        maxWidth: 153,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '1rem',
        marginTop: '.5rem',
        textAlign: 'center'
    }
}



export default PilotDialog;
