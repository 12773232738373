import React, { useState } from 'react';

const AccountInfoContext = React.createContext([{}, () => {}]);

const AccountInfoProvider = (props) => {
  const {
    initialState
  } = props;

  const [state, setState] = useState(initialState);

  return (
    <AccountInfoContext.Provider value={[state, setState]}>
      {props.children}
    </AccountInfoContext.Provider>
  );
}

export { AccountInfoContext, AccountInfoProvider };