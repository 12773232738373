import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Container,
  Divider,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { Contained_btn, Outlined_btn, Text_btn } from "components/Theme/Styled";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import {
    closeConnectorsServicesConfigDrawer,
    closeServicesConfigDrawer,
    closeSizesServicesConfigDrawer,
    connectorsConfigDrawerOpen,
    openConnectorsServicesConfigDrawer,
    openServicesConfigDrawer,
    openSizesServicesConfigDrawer,
    servicesConfigDrawerOpen,
    sizesConfigDrawerOpen,
} from "Context/DrawerStates";
import {
  convertConfigurationToCatalog,
  convertServiceToCatalog,
  createServiceConfigurations,
  deleteServiceConfigurations,
  getFabGeometry,
  getServiceConfigurations,
  getServiceConnectors,
  getServiceItems,
  getServices,
  postFabGeometry,
  updateServiceAccessory,
  updateServiceConfigurations,
  updateServiceConnectionTypes,
  updateServiceItems,
  updateServiceMaterials,
  updateServiceSpecifications,
} from "../../../data/queries/queryAPI";
import {
  useAPICallback,
  useAPIData,
  useAPIEffect,
} from "../../../data/hooks/customHooks";
import {useEffect, useRef, useState} from 'react';
import { useMutation, useQuery, useQueryClient } from "react-query";

import CancelIcon from "@mui/icons-material/Cancel";
import ConnectorsContainer from "../../../ConnectorsContainer";
import MaterialsContainer from "../../../MaterialsContainer";
import MeshViewer from '../../../MeshViewer';
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import PropertyContainer from '../../../PropertyContainer';
import ServiceConfig_panel from "ServiceConfig_panel";
import SpecificationsContainer from "../../../SpecificationsContainer";
import { isAnyOf } from "@reduxjs/toolkit";
import {styled} from "@mui/material/styles";

export const ServicesConfigDrawer = (props)=> {

  const queryClient = useQueryClient();
  const [meshData, setMeshData] = useState([]);
  const itemStatuses = ["Auto Fill", "Excluded", "Supported", "Unsupported"]
  const [selectedConfig, setSelectedConfig] = useState([]);
  const [selectedItem, setSelectedItem] = useState(props.selectedItem);
  const [selectedItemStatus, setSelectedItemStatus] = useState('');
  const dataForDisplay = queryClient.getQueryData(['dataForDisplay'])
  const [serviceParts, setServiceParts] = useState(props.serviceParts)
  const [serviceGroups, setServiceGroups] = useState(props.serviceGroups)
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [selectedItemMeshData, setSelectedItemMeshData] = useState([]);
  const [selectedService, setSelectedService] = useState(props.selectedService);
  const [selectedConnectionTypes, setSelectedConnectionTypes] = useState({connTypes: [{Connectivity: "", Domain: 0, FabId: -1, Group: "Blank", Name: "Blank", Profile: "", Sex: 2, Shape: 0}],});
  const [selectedSpecifications, setSelectedSpecifications] = useState({specs: []});
  const [selectedMaterials, setSelectedMaterials] = useState({materials: []});
  const [isPlaceholder, setPlaceholder] = useState(false);
  const [meshId, setMeshId] = useState({isLoading: false,isError: false, data: []})
  const [meshDimensions, setDimensions] = useState([])
  const [selectedProperties, setSelectedProperties] = useState('none');
  const [menuIconFile, setMenuIconFile] = useState(props.selectedItem.materialSprite);
  const [propertiesChanged, setPropertiesChanged] = useState(false);
  const [connectionTypes, setConnectionTypes] = useState(props.connectionTypes);
  const [materials, setMaterials] = useState(props.materials);
  const [specifications, setSpecifications] = useState(props.specifications);

  const [currentConnectionTypes, setCurrentConnectionTypes] = useState(props.currentConnectionTypes);
  const [currentMaterials, setCurrentMaterials] = useState(props.currentMaterials);
  const [currentSpecifications, setCurrentSpecifications] = useState(props.currentSpecifications);
  const [sizesConfigDrawerOpen, setSizesConfigDrawerOpen] = useState(false);
  const [connectorsConfigDrawerOpen, setConnectorsConfigDrawerOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [itemName, setItemName] = useState(props.selectedItem.description)

  const duct_types = [1, 2, 3, 30, 32, 33, 34, 35, 36];
  const [itemsUploading, updateItemsError, updateAllServiceItems] = useAPIEffect(async (payload) => {

    const resp = await updateServiceItems(payload);
    setIsUpdating(false);
    return resp

  })

  const [connectionTypesUploading, connectionTypesError, updateAllServiceConnectionTypes] = useAPIEffect(async (payload) => {

    const resp = await updateServiceConnectionTypes(payload);
    return resp

  })

  const [materialsUploading, materialsError, updateAllServiceMaterials] = useAPIEffect(async (payload) => {
    const resp = await updateServiceMaterials(payload);
    return resp
  })

  const [specificationsUploading, specificationsError, updateAllServiceSpecifications] = useAPIEffect(async (payload) => {
    const resp = await updateServiceSpecifications(payload);
    return resp
  })

  const [updateDrawerItemUploading, updateDrawerItemError, updateDrawerItem] = useAPIEffect(async (payload) => {

    const resp = await updateServiceAccessory(payload);
    //setIsUpdating(false);
    return resp

  })

  const { mutate: convertItem } = useMutation(convertServiceToCatalog, {
    onSettled: (data) => {
      queryClient.refetchQueries(['allOnlyCatalogs']).then(()=> {
        setIsUpdating(false);
      })
    },
    onError: (error) => {
      console.error("error converting service(s)", error);
      alert("An error occurred while converting a service: ", error);
    },
  });



  useEffect(() => {
    setServiceParts(props.serviceParts)
  }, [props.serviceParts])

  useEffect(() => {
    setServiceGroups(props.serviceGroups)
  }, [props.serviceGroups])

  useEffect(() =>{
    setSelectedService(props.selectedService)
    setSelectedConnectionTypes({connTypes: [{Connectivity: "", Domain: 0, FabId: -1, Group: "Blank", Name: "Blank", Profile: "", Sex: 2, Shape: 0}],})
  },[props.selectedService])

  const updateItemInService = () =>{
    let alreadyExists = false;
    setIsUpdating(true);
    if(serviceParts.data){
      if(selectedItem !== undefined && selectedProperties !== undefined){
        serviceParts.data.map((part, index) => {
          if(part.partNumber === selectedItem.partNumber){
              if(!alreadyExists){
                let isSameConnectionTypes = false;
                let isSameProperties = false;
                if(JSON.stringify(part.properties) === JSON.stringify(selectedProperties)){
                  isSameProperties = true;
                }
                if(JSON.stringify(part.connections) === JSON.stringify(selectedItem.connections)){
                  isSameConnectionTypes = true
                }
                let groupPayload = {serviceId: '', groupId: '', data: {menuIcon: '', isExcluded: false}};
                if(selectedItemStatus !== ''){
                  if(selectedItemStatus === 'Auto Fill'){
                    part.supported = true;
                    part.isExcluded = false;
                    part.auto = true;
                    groupPayload.data.isExcluded = false
                  }else if(selectedItemStatus === 'Excluded'){
                    part.supported = true;
                    part.isExcluded = true;
                    part.auto = false;
                    groupPayload.data.isExcluded = true
                  }else if(selectedItemStatus === 'Supported'){
                    part.supported = true;
                    part.isExcluded = false;
                    part.auto = false
                    groupPayload.data.isExcluded = false
                  }else if(selectedItemStatus === 'Unsupported'){
                    part.supported = false;
                    part.isExcluded = true;
                    part.auto = false;
                    groupPayload.data.isExcluded = true
                  }
                }

                part = JSON.parse(JSON.stringify(selectedItem));
                if(selectedProperties !== 'none'){
                  part.properties = JSON.parse(JSON.stringify(selectedProperties));
                }
                part.description = itemName
                let partsTemp = [...serviceParts.data]
                partsTemp[index] = part
                let payload = {};
                payload.id = selectedService;
                part.connections.forEach((connection) => {
                  if(connection.type.toString().includes('.')){
                    connection.type = connection.type.toString().replace('.', '')
                  }
                })
                payload.items = part;
                payload.connectionTypes = selectedConnectionTypes
                //For Duct Services make sure not to overwrite service item connection types
                if(part.ServiceTypeFabId !== 0){
                  payload.connectionTypes = []
                }

                payload.companyId = props.companyId;
                groupPayload.serviceId = selectedService;
                groupPayload.groupId = selectedGroup.id;
                groupPayload.data.menuIcon = menuIconFile;
                groupPayload.data.supported = selectedItem.supported;
                groupPayload.data.isExcluded = selectedItem.isExcluded;
                groupPayload.data.item = selectedItem;
                groupPayload.companyId = props.companyId;
                updateServiceItemData(groupPayload, payload).then(() => {
                  convertItem(selectedService);
                })

                return partsTemp[index] = part;


              }else{
                return serviceParts.data;
              }
            }
        })
      }
    }
  }


  const updateConnectionTypesInService = () =>{
    setIsUpdating(true)
    let payload = {};
    payload.id = selectedService;
    selectedConnectionTypes.connTypes.forEach((connection) => {
      if(connection.Group.toString().includes('.')){
        connection.Group = connection.Group.toString().replace('.', '')
      }
    })
    payload.companyId = props.companyId;
    payload.data = {};
    payload.data.connectionTypes = selectedConnectionTypes.connTypes
    payload.data.specifications = selectedSpecifications.specs
    payload.data.materials = selectedMaterials.materials
    updateServiceData(payload).then(() =>{
      convertItem(selectedService);
      props.setServiceCurrentConnectionTypes({isLoading: true, isError: false, data: selectedConnectionTypes.connTypes});
      props.setServiceCurrentSpecifications({isLoading: true, isError: false, data: selectedSpecifications.specs});
      props.setServiceCurrentMaterials({isLoading: false, isError: false, data: selectedMaterials.materials});
    })
    return;
  }

  async function updateServiceItemData(groupPayload,payload) {
    updateDrawerItem(groupPayload);
    updateAllServiceItems(payload);
  }

  async function updateServiceData(payload){
    updateAllServiceMaterials(payload);
    updateAllServiceSpecifications(payload);
    updateAllServiceConnectionTypes(payload);
  }

  const decodeConditionMax = () => {
    if(selectedItem.conditionId !== undefined){
      if(dataForDisplay !== undefined){
          let service = dataForDisplay.find(i => i.id === selectedService);
          if(service?.conditions){
              let condition = service?.conditions?.find(i => i.conditionId === selectedItem.conditionId);
              if(condition?.max_val){
                if(condition.max_val === -1){
                    return(
                      `None`
                    )
                }else{
                    return(
                      `${condition.max_val}`
                    )
                }
              }


          }
      }
    }
  }
  const decodeConditionMin = () => {
    if(selectedItem.conditionId !== undefined){
      if(dataForDisplay !== undefined){
          let service = dataForDisplay.find(i => i.id === selectedService);
          if(service?.conditions){
              let condition = service?.conditions?.find(i => i.conditionId === selectedItem.conditionId);
              if(condition?.min_val){
                if(condition.min_val === -1){
                  return(
                    `None`
                  )
                }else{
                    return(
                      `${condition.min_val}`
                    )
                }

              }

          }
      }
    }
  }

  const decodeStatus = () => {
    if(selectedItem.supported && selectedItem.isExcluded){
      return (
          "Excluded"
      )
    }else if(selectedItem.supported && !selectedItem.isExcluded){
        if(selectedItem.auto){
            return (
                "Auto Fill"
            )
        }else{
            return(
                "Supported"
            )
        }
    }else if(!selectedItem.supported){
        return(
            "Unsupported"
        )
    }
  }




  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  useEffect(() => {
    setConnectionTypes(props.connectionTypes)
  }, [props.connectionTypes])

  useEffect(() => {
    setMaterials(props.materials)
  }, [props.materials])

  useEffect(() => {
    setSpecifications(props.specifications)
  }, [props.specifications])

  useEffect(() => {
    setCurrentSpecifications(props.currentSpecifications)
  }, [props.currentSpecifications])

  useEffect(() => {
    setCurrentMaterials(props.currentMaterials)
  }, [props.currentMaterials])

  useEffect(() => {
    setCurrentConnectionTypes(props.currentConnectionTypes)
  }, [props.currentConnectionTypes])

  const handleIconDelete = () => {
    setMenuIconFile("");
    setPropertiesChanged(true);
  };
  const handleIconUpdate = async (file) => {
    const base64 = await getBase64(file);
    setMenuIconFile(base64);
    props.selectedItem.materialSprite = base64
    setPropertiesChanged(true);
  };
  useEffect(() => {
    setSelectedItem(props.selectedItem)
    setItemName(props.selectedItem.description)
    let found = false
    serviceGroups?.data?.find((group) => {

        group.items.forEach((item) => {
          if(item.id === props.selectedItem.partNumber){
            found = true;

            return setSelectedGroup(group)
          }
        })
        if(found){
          return
        }
    })






  }, [props.selectedItem])

  useEffect(() => {
  }, [selectedGroup])

  useEffect(() => {
    setItemName(selectedItem.description)
    setSelectedItemMeshData({Name: selectedItem.description,
      PatternNumber: selectedItem.cid,
      DatabaseId: selectedItem.databaseId,
      ItemPath: selectedItem.itemPath,
      Dimensions: selectedItem.properties,
      Connectors: selectedItem.connectors,
      Seams: selectedItem.seams,
      InletConnector: selectedItem.inletIndex,
      OutletConnector: selectedItem.outletIndex,
      InsulationSpecFabId: selectedItem.InsulationSpecFabId,
      MaterialFabId: selectedItem.MaterialFabId,
      MaterialGaugeFabId: selectedItem.MaterialGaugeFabId,
      ServiceTypeFabId: selectedItem.ServiceTypeFabId,
      SpecFabId: selectedItem.SpecFabId,
      Order: "",
      ItemNumber: "",
      Notes: "",
      Quantity: 1})
    setSelectedItemStatus(decodeStatus())
    setMenuIconFile(selectedItem.materialSprite)

  }, [selectedItem])

  const [getMeshLoading, getMeshError, getMesh] = useAPIEffect(async (serviceId) => {
    const resp = await getFabGeometry(serviceId, {meshId});
    setMeshData({
      isLoading: getMeshLoading,
      isError: getMeshError,
      data: resp.data,
    });
    if(resp.data.isComplete){
      if(resp.data.processedGeometry !== null){
        let geo = JSON.parse(JSON.parse(JSON.stringify(resp.data.processedGeometry)))[0]
        setDimensions(geo.Shell.Dimensions);
      }
    }else{
      if(resp.data.processedGeometry !== null){
        let geo = JSON.parse(JSON.parse(JSON.stringify(resp.data.processedGeometry)))[0]
        setDimensions(geo.Shell.Dimensions);
      }else{
        getMesh(selectedService)
      }

    }


    return resp;
  })


  const [postMeshLoading, postMeshError, postMesh] = useAPIEffect(async (serviceId) => {
    const resp = await postFabGeometry(serviceId, {selectedItemMeshData});
    setMeshId({
      isLoading: postMeshLoading,
      isError: postMeshError,
      data: resp,
    })
    return resp;
  });

  const generateMesh = () => {
    if(selectedItemMeshData !== []) {
      PostMesh();
    }
  };


  const PostMesh = () => {
    if(selectedItemMeshData !== []) {
      postMesh(props.selectedService);
    }
  };

  const GetMesh = () =>{
    if(meshId.data !== [] && meshId.data.length !== 0){
      getMesh(selectedService)
    }
  }

  useEffect(() => {
    if(meshId){
      GetMesh()
    }
  }, [meshId])

  const openSizesConfig = () => {
    setSizesConfigDrawerOpen(true);
    setConnectorsConfigDrawerOpen(false);
  }
  const openMainConfig = () => {
    setSizesConfigDrawerOpen(false);
    setConnectorsConfigDrawerOpen(false);
  }

  const openConnectorsConfig = () => {
    setSizesConfigDrawerOpen(false);
    setConnectorsConfigDrawerOpen(true);
  }

  const updateItemStatus = (event) => {
    setSelectedItemStatus(event.target.value);
  }

  const handleNameChange = (event) => {
    setItemName(event.target.value);
  }



  const render = () => {
    if(!sizesConfigDrawerOpen && !connectorsConfigDrawerOpen){
      return(
        <>
        < Card
          variant="outlined"
          raised={false}
          sx={{ alignItems: 'center', marginTop: "1rem", width: 310, border: " 1px solid #F15A29 !important", paddingTop: "1rem" }}
        >
          <Box sx={{marginLeft: ".5rem", marginRight: ".5rem"}}>
            <StyledTextField
              fullwidth
              disabled
              id="name"
              label="Name"
              variant="outlined"
              defaultValue={itemName}
              onChange={handleNameChange}
            />
          </Box>
          <Box sx={{marginTop: "1rem", display: "flex", marginLeft: ".5rem", marginRight: ".5rem"}}>
            <Box sx={{width: "112.5px"}}>
              <StyledTextField
                fullwidth
                disabled
                id="constraint-min"
                label="Min"
                variant="outlined"
                defaultValue={decodeConditionMin()}
              />
            </Box>
            <Box sx={{width: "112.5px", marginLeft: ".5rem", marginRight: ".5rem"}}>
              <StyledTextField
                fullwidth
                disabled
                id="constraint-max"
                label="Max"
                variant="outlined"
                defaultValue={decodeConditionMax()}
              />
            </Box>
          </Box>
          <Box sx={{marginTop: "1rem", marginBottom: ".75rem", marginLeft: ".5rem", marginRight: ".5rem"}}>
            <FormControl sx={{ minWidth: "225px" }}>
              <InputLabel id="status-label">Status</InputLabel>
                <Select
                  sx={{width: "225px", height: "55px"}}
                  labelId="status-label"
                  id="status"
                  label="Status"
                  value={selectedItemStatus}
                  onChange={updateItemStatus}
                >
                  {itemStatuses.map((status) => (
                    <MenuItem_ key={status} value={status}>
                      <Box sx={styles.row}>
                        <ListItemText primary={status} />
                      </Box>

                    </MenuItem_>
                  ))}
                </Select>
              </FormControl>
          </Box>
        </Card>
        </>
      );
    } else if( sizesConfigDrawerOpen && !connectorsConfigDrawerOpen ) {
      if(duct_types.includes(selectedItem?.ServiceTypeFabId)) {
          return(
          <Box sx={{marginTop: "1rem"}}>
            <Typography color="secondary">Dimensions</Typography>
            <PropertyContainer item={selectedItem} setSelectedProperties={setSelectedProperties} dimensions={meshDimensions}/>
          </Box>
        );
      } else {
        return (
          <Box sx={{marginTop: "1rem"}}>
            <Typography color="secondary" >Part Connectors</Typography>
          </Box>
        );
      }

    } else if( !sizesConfigDrawerOpen && connectorsConfigDrawerOpen ) {
      if(duct_types.includes(selectedItem?.ServiceTypeFabId)) {
        return(
          < Box id="ServicesConfigPanel" sx={{marginTop: "1rem"}} >
              {/* <Typography color="secondary" id="panelTitle">Panel</Typography> */}
              < ServiceConfig_panel
                  specs={
                    < Box id="specs" >
                      <SpecificationsContainer config={selectedConfig} specifications={specifications} serviceParts={serviceParts} setSelectedSpecificationsMain={setSelectedSpecifications}/>
                    </ Box >
                  }
                  materials={
                    < Box id="materials">
                      <MaterialsContainer config={selectedConfig} materials={materials} serviceParts={serviceParts} setSelectedMaterialsMain={setSelectedMaterials}/>
                    </ Box >
                  }
                  connectors={
                    < Box id="connectors" >
                      <ConnectorsContainer config={selectedConfig} connectionTypes={connectionTypes} serviceParts={serviceParts} setSelectedConnectionTypesMain={setSelectedConnectionTypes}/>
                    </ Box >
                  }
              />
          </ Box >
        )

      } else {
          return(
          <Box>
            <Box sx={{marginTop: "1rem"}}>
              <Typography color="secondary">Connectors</Typography>
              <ConnectorsContainer config={selectedConfig} connectionTypes={connectionTypes} serviceParts={serviceParts} setSelectedConnectionTypesMain={setSelectedConnectionTypes}/>
            </Box>

          </Box>
        );
      }

    } else {
      return(
        <Box>
          <Typography color="secondary">NAME</Typography>
        </Box>
      );
    }
  }

    const drawerWidth = 400;
    const DrawerHeader = styled("div")(({ theme }) => ({
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: "flex-start",
      }));

    return(
        <Drawer
            anchor="right"
            open={servicesConfigDrawerOpen.use()}
            onClose={() => {
              openMainConfig()
              closeServicesConfigDrawer()

            }}
            variant="temporary"
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                  width: drawerWidth,
                  boxSizing: "border-box",
                },
              }}
            BackdropProps={{sx: {background: "rgba(0,0,0,0)"}}}
        >
            <DrawerHeader
                sx={styles.drawer.drawerHeader}
            >
                <Typography variant="h6" gutterBottom component="div">
                Services Configuration
                </Typography>
                <IconButton
                sx={styles.iconButton}
                onClick={() => {
                  openMainConfig()
                  closeServicesConfigDrawer()

                }}
                >
                <CancelIcon sx={styles.cancelIcon} />
                </IconButton>
            </DrawerHeader>

            <Box id="DrawerNavBar"
              sx={[styles.box, {maxWidth: 310} ]}
              display="flex"
              flexDirection="row"
              alignItems="center"
              alignSelf="center"
              mb={2}
            >
              <Box sx={{flex: 1, border: "1px solid #FFA988"}} >
                { !sizesConfigDrawerOpen && !connectorsConfigDrawerOpen ?
                  <CurrentCustomListItem button key="main" onClick={openMainConfig}>
                    <ListItemText primary="MAIN"/>
                  </CurrentCustomListItem>
                  :
                  <CustomListItem button key="main" onClick={openMainConfig}>
                      <ListItemText primary="MAIN" />
                  </CustomListItem>
                }
              </Box>
              <Box sx={{ flex: 1, border: "1px solid #FFA988"}}>
                { sizesConfigDrawerOpen && !connectorsConfigDrawerOpen ?
                  <CurrentCustomListItem button key="size" onClick={openSizesConfig}>
                      <ListItemText primary="PART" />
                  </CurrentCustomListItem>
                  :
                  <CustomListItem button key="size" onClick={openSizesConfig}>
                      <ListItemText primary="PART" />
                  </CustomListItem>
                }
              </Box>
              <Box sx={{ flex: 1, border: "1px solid #FFA988"}}>
                { !sizesConfigDrawerOpen && connectorsConfigDrawerOpen ?
                  <CurrentCustomListItem button key="connectors" onClick={openConnectorsConfig}>
                      <ListItemText primary="SERVICE" />
                  </CurrentCustomListItem>
                  :
                  <CustomListItem button key="connectors" onClick={openConnectorsConfig}>
                      <ListItemText primary="SERVICE" />
                  </CustomListItem>
                }
              </Box>
            </Box>

            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"

            >
              <>
              < Card id="TitleCard"
                sx={{ display: 'flex', border: "1px solid #F15A29 !important", maxHeight: 64, width: 310 }}
                variant="outlined"
              >
                { menuIconFile && menuIconFile.length > 55 ?
                    < CardMedia
                      component="img"
                      sx={{ width: 64, height: 64 }}
                      // image={selectedItem.materialSprite}
                      image={menuIconFile}
                      alt={selectedItem.description}
                      key={selectedItem.partNumber}
                    />
                    :
                      <PhotoCameraIcon sx={styles.tenrem} />

                }
                <Divider orientation="vertical" />

                <Box sx={{flex: 1, textAlign: "center !important" }}>
                      <Typography variant="body1">
                        { ( selectedItem?.description?.toString()?.includes('?') ) ?
                            selectedItem.description.toString().replace("?", "\u00B0")
                            :
                            selectedItem.description
                        }
                      </Typography>
                      <Divider fullwidth/>

                      <Box sx={{flexDirection: "row"}}>
                        <Button
                          size="large"
                          onClick={handleIconDelete}
                          sx={{marginLeft: ".5rem !important", marginRight: ".5rem !important"}}
                        >
                          Delete
                        </Button>

                        <FormControl id='sv-csv-form'>
                          <input
                          type="file"
                          accept=".png"
                          id="newIconImg"
                          sx={styles.txt}
                          onChange={(e) => {
                              handleIconUpdate(e.target.files[0])

                          }}
                          hidden
                          />

                          <label htmlFor="newIconImg">
                            <Button
                            variant="raised"
                            component="span"
                            size="large"
                            sx={{marginLeft: ".5rem !important",
                            marginRight: ".5rem !important",
                            marginTop: ".10rem !important",
                            color: "#F15A29 !important"}}

                            >
                              New
                            </Button>
                          </label>

                        </FormControl>

                      </Box>
                </Box>
              </Card>
              </>

              {
                render()
              }


              <Box sx={{marginTop: "1rem"}} id="MeshViewer">
                {
                  ( !connectorsConfigDrawerOpen && sizesConfigDrawerOpen) ? (
                    <Box>

                      <Typography color="secondary">Mesh Viewer</Typography>
                      <MeshViewer
                        config={selectedConfig}
                        meshData={meshData}
                        item={selectedItem}
                        buttons={
                            <Box
                              sx={{display: "flex", justifyContent: "center"}}
                            >
                              <GenerateMesh_btn variant="contained" color="secondary" onClick={generateMesh}> Preview </GenerateMesh_btn>
                            </Box>
                        }
                      />
                    </Box>
                  ) : null
                }

              </Box>
              <Box id="updateButtons">
                { !sizesConfigDrawerOpen && connectorsConfigDrawerOpen ?
                            <Outlined_btn
                              sx={{width: "310px", marginBottom: "1rem !important"}}
                              size="small"
                              onClick={ updateConnectionTypesInService }
                            >
                              { isUpdating? <CircularProgress/> : "Update Service" }
                            </Outlined_btn>
                            : !connectorsConfigDrawerOpen ?
                            <Outlined_btn
                              sx={{width: "310px", marginBottom: "1rem !important"}}
                              size="small"
                              onClick={updateItemInService}
                            >
                              { isUpdating? <CircularProgress/> : "Update Part" }
                            </Outlined_btn>
                            : null
                  }
              </Box>
            </Grid>

        </Drawer>
    );
};

const styles = {
    box: { paddingTop: "1rem" },
    drawer: {
        drawerHeader: {
          backgroundColor: "#000",
          color: "#fff",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }
      },
    iconButton: { color: "#fff", textAnchor: "right" },
    btn: { paddingLeft: "2rem", paddingRight: "2rem" },
    cancelIcon: { color: "#fff", textAlign: "right" },
    row: {display: "flex", flexDirection: "row"},
    tenrem: { width: "64px", height: "64px"},
};

const GenerateMesh_btn = styled(Button)`
  //margin-top: .75rem !important;
`;

const IMG_ = styled('img')`
  margin-right: 10px;
  margin-left: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 128px;
  height: 128px;
`;

const Input = styled("input")({
  display: "none",
});

const CustomListItem = styled(ListItem)`
:hover { background: transparent; };
color: #F15A29 !important;
text-decoration: none;
border: 1px solid #FFA988;
border-color: #FFA988 !important;
background: #fff;
text-align: center !important;
width: 101px !important;
`;

const CurrentCustomListItem = styled(ListItem)`
  :hover { background: #F15A29 !important; };
  color: #fff !important;
  text-decoration: none;
  border: 1px solid #FFA988;
  border-color: #FFA988 !important;
  background: #F15A29 !important;
  text-align: center !important;
  width: 101px !important;
`;

const MenuItem_ = styled(MenuItem)`
  display: flex !important;
  flex-direction: column !important;
`;


const StyledTextField = styled(TextField)(({theme}) => ({
  "&.MuiTextField-root": {
    padding: ".25rem !importantƒ",
    height: "55.5px",
    "& label": {
      fontSize: "1rem",
    },
    "& label.Mui-focused": {
      fontSize: "1rem",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: 'white',
      borderRadius: 4,
      height: "55.5px",

      fontSize: "1rem",
      "& fieldset": {
        borderWidth: 2,
      },
      "&:hover fieldset": {
        borderWidth: 2,
      },
      "&.Mui-focused fieldset": {
        borderWidth: 2,
      },
    },
  },
 }));
