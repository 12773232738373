import { Box, Breadcrumbs, Button, CircularProgress, Drawer, Grid, IconButton, Link, Tooltip, Typography } from "@mui/material";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import React, { createContext, useEffect, useMemo, useState } from "react";
import { deleteCatalog, getCatalogItems, getProjects, getServiceConnectors, getServiceItems } from "../../../data/queries/queryAPI";
import {
    openCreateCatalogPartDrawer,
    setCatalogIdForDrawer,
} from "Context/DrawerStates";
import { useMutation, useQuery, useQueryClient } from "react-query";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {CatalogConfiguration} from './CatalogConfiguration/CatalogConfiguration'
import CatalogTable_renderCell from "./CatalogTable_renderCell";
import CustomDataGrid from "./CustomDataGrid"
import CustomToolbar from "./CustomToolbar";
import ErrorDialog from "components/Utilities/ErrorDialog";
import { Warning } from "@mui/icons-material";
import clone from 'just-clone'
import { styled } from "@mui/material/styles";

export const CatalogTableContext = createContext();

export const CatalogsTable = (props)=> {
    
 /*  const CatalogItemCards_ = styled(CatalogItemCards)`
  width: 80px; 
  display: flex;  
  `; */

  const queryClient = useQueryClient();
  const [selectionModel, setSelectionModel] = useState([]);
  const [selectionTabModel, setSelectionTabModel] = useState([]);
  const [selectionGroupModel, setSelectionGroupModel] = useState([]);
  const [showSelected, setShowSelected] = useState(false);
  const [selectedCatalog, setSelectedCatalog] = useState(props.selectedCatalog)
  const [catalogName, setCatalogName] = useState('None')
  const [catalogTabs, setCatalogTabs] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleteMode, setIsDeleteMode] = useState(false);

  const rows = props.dataForDisplay;

  const [dialogOpen, setDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleCloseDialog = () => {
      setDialogOpen(false);
  };

  useEffect(() => {
    setSelectedCatalog(props.selectedCatalog)
  }, [props.selectedCatalog])
 

  const columns = [
    { field: "renderCellmenu", headerName: "Menu", width: 120, renderCell: (params) => (
      <CatalogTable_renderCell 
        variant="menu"
        params={params} 
        props={props} 
        setRenameFormOpen={props.setRenameFormOpen} 
        setCatalogId={props.setCatalogId} 
        catalogId={params?.row?.id}
        catalogName={params?.row?.name}
        catalog={params?.row}
        setIsConfiguring={props.setIsConfiguring}
        setSelectedCatalog={props.setSelectedCatalog}
        setCatalogName={setCatalogName}
      />
    )},
    { field: "name", headerName: "Catalog Name", width: 550, renderCell: (params)=> {
      if(params.value === "" || params.value === undefined){
          return(
          <Typography variant="h6">{params.row.name}</Typography>
        )
      }else{
        return(
          <Typography variant="h6">{params.value}</Typography>
        )
      }
      
      
    }
      
    },
    { field: "type", headerName: "Source", width: 550, renderCell: (params)=> {
      if(params.value === 0 || params.value === 1){
          return(
            <p>Service</p>
        )
      }else if(params.value === 2){
        return(
          <p>CSV</p>
          )
      }else{
        return(
          <Tooltip title = "Catalog requires re-import">
              <Warning color="secondary"/>
            </Tooltip>
        )
      }
    }
    },
  ];
  const deleteColumns = [
      { field: "name", headerName: "Catalog Name", width: 550, renderCell: (params)=> {
        if(params.value === "" || params.value === undefined){
            return(
            <Typography variant="h6">{params.row.name}</Typography>
          )
        }else{
          return(
            <Typography variant="h6">{params.value}</Typography>
          )
        } 
      }
      },
      { field: "type", headerName: "Source", width: 550, renderCell: (params)=> {
        if(params.value === 0 || params.value === 1){
            return(
            <p>Service</p>
          )
        }else if(params.value === 2){
          return(
            <p>CSV</p>
          )
        }else{
          return(
            <Tooltip title = "Catalog requires re-import">
              <Warning color="secondary"/>
            </Tooltip>
          )
        }
      }
      },
    ];
    

    
  const toggleDelete = () => {
      if(!isDeleteMode){
        setIsDeleteMode(true);
      }else{
        setIsDeleteMode(false);
      }
      
  }
  const { mutate: deleteItem } = useMutation(deleteCatalog, {
      onSettled: (data) => {
        queryClient.refetchQueries(["allCatalogs"]);
      },
      onError: (error) => {
        setErrorMessage(error.message || 'An error occurred');
        setDialogOpen(true);
      },
    });
  const deleteCatalogs = () => {
      setIsDeleting(true);

      selectionModel.forEach((catalogId) => {
        deleteItem(catalogId)
      })

      queryClient
      .refetchQueries(["allCatalogs"])
      .then(() => {
        setIsDeleting(false);
      })
      .then(() => {
      })
      .catch((error) => {
        setIsDeleting(false);
        setErrorMessage(error.message || 'An error occurred');
        setDialogOpen(true);
      });
      
  }
  const ArrowBackIosNewIcon_ = styled(ArrowBackIosNewIcon)`
    transform: scale(.5);
  `;
  const backToCatalogs = () => {
      props.setSelectedCatalog(undefined)
      setCatalogName('None');
      props.setIsConfiguring(false)
  }
  const handleAddProduct = () => {
    openCreateCatalogPartDrawer();
  }
  const BacktoCatalogs = () => (
    <Button sx={{marginLeft: "-0.75rem !important", marginBottom: "1rem !important"}} size="small" onClick={backToCatalogs}>
      <ArrowBackIosNewIcon_ fontSize="small" sx={{color: "rgba(0,0,0, 0.4)", marginBottom: ".25rem"}} />
        <Link color="rgba(0,0,0, 0.4)" variant="caption" underline="hover"> Back to Catalogs</Link>
      </Button> 
  );
  const AddProduct = () => (
    <Button sx={{marginLeft: "-.25rem !important", marginBottom: "1rem !important"}} size="small" onClick={handleAddProduct}>
      <AddCircleIcon fontSize="small" sx={{color: "rgba(0,0,0, 0.4)", marginBottom: ".25rem", marginRight: "0.25rem"}} />
      <Link color="rgba(0,0,0, 0.4)" variant="caption" underline="hover"> Add Product</Link>
    </Button> 
  );
  
  const table = {
    functions: {
      deleteCatalogs: () => deleteCatalogs(),
      toggleDelete: () => toggleDelete(),
    },
    isConfiguring: props.isConfiguring,
    isDeleting: isDeleting,
    isDeleteMode: isDeleteMode,
    selectionModel: selectionModel,
  };

  return (
      <CatalogTableContext.Provider value={table} >
        {
          props.isConfiguring ?
          <Grid direction="column" sx={{marginLeft:".5rem"}}>
                <Box>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link sx={{color: "black !important"}} variant="h5" size="large" onClick={backToCatalogs} underline="hover">
                      Catalogs
                    </Link>
                    <Typography variant="h6">Catalog: {selectedCatalog?.name}</Typography>
                  </Breadcrumbs>
                </Box> 
                  <BacktoCatalogs/>
                  {selectedCatalog !== undefined ? (
                    <AddProduct/>
                  ): null}
                  
              </Grid>: null
        }  
        {
          !props.isConfiguring && !isDeleteMode ?
          <CustomDataGrid
          rows={rows}
          columns={columns}
          autoHeight={true}
          hideFooter={true}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
            props.setSelectedCatalog(newSelectionModel);
            setSelectedCatalog(newSelectionModel)
            
          }}
          selectionModel={selectionModel}
          disableSelectionOnClick={true}
          disableColumnMenu={true}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
        : null 
        }
        {
          (isDeleteMode && !props.isConfiguring) ? (
            (<CustomDataGrid
              rows={rows}
              columns={deleteColumns}
              autoHeight={true}
              hideFooter={true}
              onRowClick={(params) => {
                  props.setSelectedCatalog(params.id);
                  
                  
              }}
  
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
                //props.setSelectedService(newSelectionModel);
                
              }}
              selectionModel={selectionModel}
              disableSelectionOnClick={true}
              disableColumnMenu={true}
              checkboxSelection
              components={{
                Toolbar: CustomToolbar,
              }}
            />)
          ) : null
        }
        {
        ( props.isConfiguring && selectedCatalog?.items?.length > 0)? <CatalogConfiguration
            selectedCatalog={selectedCatalog}
            setSelectedCatalog={props.setSelectedCatalog}
          />
          : null
          
      }
      {
          (props.isConfiguring && !isDeleteMode && (!selectedCatalog?.items || selectedCatalog?.items === [] || selectedCatalog?.items?.length === 0)) ?
          <Box sx={styles.color.gray} open={true}>
            <Box sx={styles.box}>
              <Typography variant="h3">Building Catalog Items...</Typography>
              <CircularProgress color="inherit" />
            </Box>
          </Box> : null
      }
      <ErrorDialog open={dialogOpen} handleClose={handleCloseDialog} errorMessage={errorMessage} />
      </CatalogTableContext.Provider>
    );
};

const styles = {
  color: {
    white: {color: "#fff"},
    gray: {color: "gray"}
  },
  box: { display: "flex", flexDirection: "col" }
};


const DataGridPro_ = styled(DataGridPro)`
  .MuiDataGrid-columnHeaderTitle {
    color: #F15A29;
  };
  .MuiDataGrid-paper {
    margin-left: 20px !important;
  };
  '& .MuiTypography-root': {
    color: '#F15A29',
    fontSize: 20,
  },
  '& .MuiDataGrid-filterForm': {
    bgcolor: 'gray',
  },
  '& .MuiTypography-input': {
      color: "#F15A29"
    },
  '& .MuiSwitch-root': {
    color: '#F15A29'
  },
  '& .MuiButton-label':{
    color: '#F15A29'
  },
  '& .MuiSwitch-input': {
    color: '#F15A29'
  },

`;
