import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useQuery, useQueryClient } from "react-query";

import { ActionBar } from "./ActionBar";
import { Circle } from "@mui/icons-material";
import CustomToolbarCards from "./CustomToolbarCards";
import LinkIcon from "@mui/icons-material/Link";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import clone from 'just-clone'
import { debounce } from 'lodash';
import groupBy from "just-group-by";
import { statusPalette } from "../../../data/constants";
import {styled} from "@mui/material/styles";
import {
  updateOrder,
} from "data/queries/queryAPI";

export const CardsGrid = (props) => {
  const [selectionModel, setSelectionModel] = useState([]);
  const [projectUsers, setProjectUsers] = useState([]);
  const [assignableViewers, setAssignableViewers] = useState([]);
  const [assignedViewers, setAssignedViewers] = useState([]);
  const [selectedGrouping, setSelectedGrouping] = useState("");
  const [groupToRender, setGroupToRender] = useState([]);
  const [packagesByGroup, setPackagesByGroup] = useState({});
  const [activeOrders, setActiveOrders] = useState(props.orders);
  const [division, setDivision] = useState(props.division);
  const [project, setProject] = useState(props.project);
  const [users, setUsers] = useState(props.users);
  const [packagesForDrawer, setPackagesForDrawer] = useState([]);
  const [checkingIn, setCheckingIn] = useState(false);
  const [filteredOrders, setFilteredOrders] = useState(activeOrders);

  //User lookup table for performance optimization
  const userLookup = useMemo(() => {
    const lookup = {};
    users.forEach((user) => {
      lookup[user.id] = user.name;
    });
    return lookup;
  }, [users]);
  
  //Columns to Sort By
  const columns = [
    { 
      field: "targetDate", 
      headerName: "On Site", 
      width: 150, 
      valueGetter: params => {
        // CONVERTS TO UNIX EPOCH SO THE MUI NUMBER COMPARATOR CAN HANDLE SORTING
        var nums = params.row?.orderData?.targetDate.split('/');
        var dateParsed = new Date( Number(nums[2]), Number(nums[0]) - 1, Number(nums[1]) ).getTime();
        return dateParsed;
      },
      valueFormatter: params => {
        var str = new Date( params.value ).toLocaleDateString();
        return str;
      }
    },
    { field: "name", headerName: "Package Name", width: 250, valueGetter: (params) => {
        return params.row?.orderData?.name 
    }, },
    { field: "package", headerName: "Order Name", width: 250, valueGetter: (params) => {
        return params.row?.orderData?.package
    }},
    { field: "status", headerName: "Status", width: 150, valueGetter: (params) => {
        return params.row?.orderData?.status
    } },
    { field: "createdBy", headerName: "Created By", width: 175, valueGetter: (params) => {
        return users.find((user) => user.id === params.row?.orderData?.authorId)?.name
    } },
    { 
      field: "lastModified", 
      headerName: "Last Modified", 
      width: 175, 
      valueGetter: params => {

        // CONVERTS TO UNIX EPOCH SO THE MUI NUMBER COMPARATOR CAN HANDLE SORTING
        return new Date(params.row?.orderData?.lastModified).getTime();
      },
      valueFormatter: params => {

        var str = new Date( params.value ).toLocaleString();
        return str;
      }        
    },
    { field: "checkedOutBy", headerName: "In/Out", width: 250, valueGetter: (params) => {
        if((users.find((user) => {return user.id === params.row?.orderData?.assignedTo}) === undefined)){
            return (
                    "Checked In"
            );
        } else if((users.find((user) => {return user.id === params.row?.orderData?.assignedTo}) !== undefined)) {
          return (
                  users.find((user) => {return user.id === params.row?.orderData?.assignedTo})?.name
        )
      }
    }, },
]

  const handleSort = (field, order) => {
    const sortedOrders = [...activeOrders].sort((a, b) => {
      if (field === 'createdBy') {
        const userA = userLookup[a.orderData.authorId];
        const userB = userLookup[b.orderData.authorId];
      
        if (!userA || !userB) return 0;
      
        if (userA < userB) return order ? -1 : 1;
        if (userA > userB) return order ? 1 : -1;
        return 0;
      } else if (field === 'checkedOutBy') {
        const userA = userLookup[a.orderData.assignedTo] || 'Checked In';
        const userB = userLookup[b.orderData.assignedTo] || 'Checked In';
      
        if (userA < userB) return order ? -1 : 1;
        if (userA > userB) return order ? 1 : -1;
        return 0;
      } else {
        if (a.orderData[field] < b.orderData[field]) return order ? -1 : 1;
        if (a.orderData[field] > b.orderData[field]) return order ? 1 : -1;
        return 0;
      }
    });
  
    setFilteredOrders(sortedOrders);
  };


  //Debounced filtering function so filtering is less expensive for UX
  const debouncedHandleFilter = debounce((field, filterTerm) => {
    if (filterTerm === "") {
      setFilteredOrders(activeOrders);
    } else {
      const newFilteredOrders = activeOrders.filter((order) => {
        if (field === 'createdBy') {
          const username = userLookup[order.orderData.authorId];
          return username ? username.toLowerCase().includes(filterTerm.toLowerCase()) : false;
        } else if (field === 'checkedOutBy') {
          const username = userLookup[order.orderData.assignedTo] || 'Checked In';
          return username.toLowerCase().includes(filterTerm.toLowerCase());
        } else {
          return order.orderData[field].toLowerCase().includes(filterTerm.toLowerCase());
        }
      });
      setFilteredOrders(newFilteredOrders);
    }
  }, 300);

  const handleFilter = (field, filterTerm) => {
    debouncedHandleFilter(field, filterTerm);
  };

  useEffect(() => {
    setActiveOrders(props.orders);
  }, [props.orders])
  useEffect(() => {
    setDivision(props.division)
  }, [props.division])
  useEffect(() => {
    setProject(props.project)
  }, [props.project])
  useEffect(() => {
    setUsers(props.users)
  }, [props.users])

  useEffect(() => {
    if(props.clearSelectionFlag){
        setSelectionModel([]);
        setPackagesForDrawer([]);
        props.setPackagesForDrawer([]);
        props.setClearSelectionFlag(!props.clearSelectionFlag);
    }
  }, [props.clearSelectionFlag]);

  

  //LOOK UP USER NAMES FOR "CREATED BY" AND "ASSIGNED TO" FIELDS
  const findUser = (id) => {
    const user = props.users.find((user) => user.id === id);
    return user;
  };

  //HANDLE SELECTION/DISPLAY OF GROUPS
  const handleGroupByClick = (e) => {
    setSelectedGrouping(e.target.value);
    setGroupToRender(packagesByGroup[e.target.value]);
    localStorage.setItem("preferredGrouping", JSON.stringify(e.target.value));
  };
  //BUILD A LARGE OBJECT WHERE EACH ENTRY'S KEY IS A GROUPING AND ITS VALUE IS AN ARRAY OF ALL PACKAGE ITEMS GROUPED BY THE VARIOUS VALUES OF THAT PROPERTY
  useEffect(() => {
    const groupsCollectorObj = {};
    const tempCopy = clone(filteredOrders);
    if(Array.isArray(tempCopy)){
      const targetDateArray = groupBy(tempCopy, function (item) {
        return item.data?.targetDate;
      });
      groupsCollectorObj.required = targetDateArray;
  
      const statusArray = groupBy(tempCopy, function (item) {
        return item.data?.status;
      });
      groupsCollectorObj.status = statusArray;
  
      const serviceArray = groupBy(tempCopy, function (item) {
        return item.data?.service;
      });
      groupsCollectorObj.service = serviceArray;
  
      const areaArray = groupBy(tempCopy, function (item) {
        return item.data?.area;
      });
      groupsCollectorObj.area = areaArray;
      setPackagesByGroup(groupsCollectorObj);
    }
    
  }, [filteredOrders]);//orderQueryData]);

  //AFTER OBJECT IS BUILT FOR THE FIRST TIME SET THE DEFAULT DISPLAY VIEW TO EITHER VALUE STORED IN LOCAL STORAGE OR BY DATE REQUIRED
  useEffect(() => {
    const preferredGrouping = JSON.parse(
      localStorage.getItem("preferredGrouping")
    );

    if (preferredGrouping && packagesByGroup[preferredGrouping]) {
      setSelectedGrouping(preferredGrouping);
      setGroupToRender(packagesByGroup[preferredGrouping]);
    } else if (packagesByGroup["required"]) {
      setSelectedGrouping("required");
      setGroupToRender(packagesByGroup["required"]);
    }
  }, [packagesByGroup]);

  //HANDLE CHECKING AND UNCHECKING OF CARDS
  const addOrRemove = (item) => {
    const array = selectionModel.slice();
    const exists = array.some((arrVal) => arrVal.id === item.id);

    if (exists) {
      return array.filter((c) => {
        return c.id !== item.id;
      });
    } else {
      const result = array;
      result.push(item);
      return result;
    }
  };
  const handleCheckboxClick = (packageItem) => {
    const packageForDrawer = packageItem;
    const newSelectionModel = addOrRemove(packageForDrawer);
    setPackagesForDrawer(newSelectionModel)
    props.setPackagesForDrawer(newSelectionModel)
    setSelectionModel(newSelectionModel);
  };

  useEffect(() => {
    if(project !== undefined && users !== undefined){
      const assignable = project.users.filter((user) => !project.viewOnly.includes(user));
      const assigned = project.users.filter((user) => project.viewOnly.includes(user));
      const assignableUsers = assignable.map((user) => {
          const userObject = users.find((item) => item.id === user)
          return userObject;
      })
      const assignedUsers = assigned.map((user) => {
          const assignedUserObject = users.find((item) => item.id === user)
          return assignedUserObject;
      })
      setAssignableViewers(assignableUsers);
      setAssignedViewers(assignedUsers);
  }
  }, [
    project,
    users
  ]);

  const handleCheckIn = async () => {
    setCheckingIn(true)
    let clonedOrders = clone(activeOrders);
    for(let i = 0; i < packagesForDrawer.length; i ++){
        let payload = {}
        payload.updateObject = {assignedTo: ""}
        payload.projectId = project?.id;
        payload.packageId = packagesForDrawer[i]?.id;
        let data = await updateOrder(payload);
        if(!data.error){
            let index = clonedOrders.findIndex((packageItem) => packageItem?.id === data?.id);
            clonedOrders[index] = data;
        }
    }
    setCheckingIn(false);
    setActiveOrders(clonedOrders);
    props.setOrders(clonedOrders);
}


  const handleCardClick = (cardPackage) => {
    if (
      cardPackage?.data?.orderData?.assignedTo?.length > 1 ||
      cardPackage?.data?.orderData?.stratusId?.length > 0
    ) {
      return;
    }
    const packageForDrawer = cardPackage;
    const packages = [packageForDrawer];
    setSelectionModel([packages]);
    setPackagesForDrawer(packages)
    props.setPackagesForDrawer(packages)
  };

  const getStratusNameFromId = (id) => {
    const status = props.statuses.find((status) => status.id === id);
    return status?.name;
  };
  const getStratusColorFromId = (id) => {
    const status = props.statuses.find((status) => status.id === id);
    return status?.color;
  };

  if (activeOrders !== undefined && activeOrders.length === 0) {
    return <Box sx={{display: 'flex', color: "gray", marginLeft: "1.9rem"}}>
              <Typography variant="h4">No Packages</Typography>
            </Box>;
  }
  return (
    <>
      {division !== undefined && project !== undefined ? (
        <Box sx={styles.box[1]}>
          {selectionModel?.length > 0 ? (
            (users?.find((user) => {return user.id === packagesForDrawer[0]?.orderData?.assignedTo}) === undefined) ? (
              <Box sx={{marginLeft: '1.9rem', marginBottom: '2rem'}}>
                <ActionBar
                  openDrawer={props.openDrawer}
                  projectId={project.id}
                  isStratusProject={(project?.stratusModelId) ? true : false}
                  packages={packagesForDrawer}
                  assignedViewers={assignedViewers}
                  companyId={props.companyId}
                  assignableViewers={assignableViewers}
                  packageUrl
                  selectionModel={selectionModel}
                  setOrders={props.setOrders}
                  orders={activeOrders}
                />
              </Box>
            ) : (
              !checkingIn ? (
                <Box sx={{marginLeft: '1.9rem', marginBottom: '3rem'}}>
                  <Button
                    size="large"
                    variant="contained"
                    color="secondary"
                    onClick={handleCheckIn}
                  >
                    <span>
                      Convert to Checked In
                    </span>
                  </Button>
                </Box>
              ) : (
                <Box sx={{marginLeft: '1.9rem'}}>
                  <CircularProgress size={24} />
                </Box>
              )
            )
          ) : null}
  
          <Box sx={{width: '77.5%', marginLeft: '1.75rem', boxShadow: '0 0 5px 2px lightgray'}}>
            <CustomToolbarCards columns={columns} onSort={handleSort} onFilter={handleFilter} />
            {filteredOrders.length > 0 ? (
              <>
                {Object.keys(groupToRender).map((key) => {
                  return (
                    <Box key={key} sx={styles.box[2]}>
                      <Grid container spacing={4}>
                        {groupToRender[key].map((packageItem) => {
                          return (
                            <Grid key={packageItem.id} item sx={{marginLeft: '2rem', marginBottom: '2rem'}} xs={6} sm={4} md={3} lg={2}>
                              <Card
                                elevation={3}
                                onClick={() => {
                                  if((packagesForDrawer?.length !== 0) ? (
                                    ((users.find((user) => {return user.id === packagesForDrawer[0]?.orderData?.assignedTo}) === undefined)) ?
                                    (users.find((user) => {return user.id === packageItem?.orderData?.assignedTo}) === undefined):
                                    (users.find((user) => {return user.id === packageItem?.orderData?.assignedTo}) !== undefined)
                                  ) : true){
                                  handleCheckboxClick(packageItem)
                                }
                              }}
                              key={packageItem.id}
                              sx={
                                packageItem?.orderData?.assignedTo?.length >
                                  1 ||
                                packageItem?.orderData?.stratusId?.length > 0
                                  ? styles.box[3]
                                  : styles.box[4]
                              }
                            >
                              {
                                packageItem?.orderData?.hasSketch ?
                                <>
                                  <CardMedia
                                    component="img"
                                    height="140"
                                    image={packageItem?.thumbnail}
                                    alt={packageItem?.data?.name}
                                    loading="lazy"
                                  />
                                </>
  
                                :
                                <>
                                  <CardMedia
                                    component="div"
                                    sx={{background: "#F8F8FF", height: "140px"}}
                                    alt={packageItem?.data?.name}
                                    loading="lazy"
                                  >
                                    <Box sx={{ display: "flex", paddingTop: "20px", justifyContent: "center", alignItems: "center"}}>
                                        <StyledShoppingCartIcon/>
                                    </Box>
                                  </CardMedia>
                                </>
                              }
  
                              <CardActions sx={styles.box[5]}>
                                <Box sx={{width: '70%'}}>
                                <Tooltip title={packageItem?.orderData?.name}>
                                  <Typography variant="h6" noWrap sx={styles.fontBold}>
                                    {packageItem?.orderData?.name}
                                  </Typography>
                                </Tooltip>
                                </Box>
  
                                  <Badge 
                                    badgeContent={
                                      (packageItem?.orderData?.takeoffListLength > 0)?
                                      packageItem?.orderData?.takeoffListLength
                                      :
                                      "0"
                                    } 
                                    color="secondary"
                                  >
                                    <ShoppingCartOutlinedIcon
                                      fontSize="medium"
                                      sx={{color: "gray"}}
                                    />
                                  </Badge>
  
                                {packageItem?.orderData?.stratusId?.length >
                                0 ? (
                                    <LinkIcon color="primary" />
                                ) : null}
                                <Checkbox
                                  checked={selectionModel.some(
                                    (packageInArray) =>
                                      packageInArray.id === packageItem.id
                                  )}
                                  disabled={(packagesForDrawer?.length !== 0) ? (
                                    ((users.find((user) => {return user.id === packagesForDrawer[0]?.orderData?.assignedTo}) === undefined)) ?
                                    !(users.find((user) => {return user.id === packageItem?.orderData?.assignedTo}) === undefined):
                                    !(users.find((user) => {return user.id === packageItem?.orderData?.assignedTo}) !== undefined)
                                  ) : false
                                  }
                                  onChange={(e) =>
                                    handleCheckboxClick(packageItem)
                                  }
                                  sx={styles.checkBox}
                                />
                              </CardActions>
                              <CardContent>
  
                                <Box sx={styles.box[8]}>
                                  <Box sx={{ display: 'flex', alignItems: 'center', width: 'auto', minWidth: '123.8px' }}>
                                    <Typography variant="body1">
                                      Checked Out:
                                    </Typography>
                                  </Box>
                                  <Tooltip title={
                                    packageItem?.orderData?.assignedTo?.length > 0
                                      ? findUser(packageItem?.orderData?.assignedTo)?.name
                                      : "Checked In"
                                  }>
                                    <Typography variant="body1" noWrap>
                                      {packageItem?.orderData?.assignedTo?.length > 0
                                        ? findUser(packageItem?.orderData?.assignedTo)?.name
                                        : "Checked In"}
                                    </Typography>
                                  </Tooltip>
                                </Box>
                                <Box sx={styles.box[8]}>
                                  <Typography variant="body1">On Site:</Typography>
                                  <Typography variant="body1">
                                    {packageItem?.orderData?.targetDate}
                                  </Typography>
                                </Box>
                              </CardContent>
                              <CardActions>
                                <Circle 
                                  sx={{
                                    color: `${
                                      packageItem?.orderData.statusId
                                        ?.length > 0
                                        ? getStratusColorFromId(
                                            packageItem?.orderData?.statusId
                                          )
                                        : statusPalette[
                                            packageItem?.orderData?.status?.toUpperCase()
                                          ]?.main
                                    } !important`,
                                    marginRight: "4px"
                                  }}
                                />
                                <Typography variant="button">
                                  {packageItem?.orderData?.statusId?.length >
                                  0
                                    ? getStratusNameFromId(
                                        packageItem?.orderData?.statusId
                                      )
                                    : packageItem?.orderData?.status}
                                </Typography>
                              </CardActions>
                            </Card>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Box>
                );
              })}
            </>
            ) : (
              <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh'}}>
                <Typography variant="h4">No Orders</Typography>
              </Box>
            )}
          </Box>
        </Box>
      ) : null}
    </>
  );
} 

const styles = {
  box: {
    1: {
      flexGrow: 1,
      width: "100%",
      height: "auto",
      marginTop: "25px",
      marginBottom: "25px",
    },
    2: { topMargin: "20px", bottomPadding: "20px" },
    3: {
      border: "1px solid #F15A29",
      backgroundColor: "#e0e0e0",
      width: "16rem",
      height: "19rem"
    },
    4: {
      border: "1px solid #F15A29",
      backgroundColor: "#e0e0e0",
      width: "16rem",
      height: "19rem"
    },
    5: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    6: {
      height: "200px",
      display: "flex",
      flexDirection: "column",
      alignContent: "center",
      alignItems: "center",
    },
    7: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "10px",
      marginLeft: "10px",
      marginRight: "10px",
    },
    8: {
      display: "flex",
      justifyContent: "space-between",
      marginLeft: "10px",
      marginRight: "10px",
    }
  },
  fontBold: { fontWeight: "bold" },
  checkBox: {
    color: "#F15A29 !important",
    "&.Mui-checked": {
      color: "#F15A29",
    },
  },
  shoppingCartOutlinedIcon: {
    1: {
      position: "absolute",
      marginTop: "5px",
      marginLeft: "5px",
    },
    2: {
      height: "100%",
      fontSize: "100px",
      objectFit: "fill",
      alignText: "center",
    }
  },
  img: {
    width: "100%",
    objectFit: "contain",
  }
};

const IMG_ = styled('img')`
  width: 100%;
  object-fit: contain;
`;

const StyledShoppingCartIcon = styled(ShoppingCartOutlinedIcon)(({ theme }) => ({
  fontSize: "90px",
  color: theme.palette.primary.main,
}));