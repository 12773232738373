// Configuration required for aws-amplify's cognito authentication sdk

export const awsConfig = {
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    mandatorySignIn: true,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_URL,
      scope: ["email", "openid", "aws.cognito.signin.user.admin", "profile"],
      redirectSignIn: process.env.REACT_APP_COGNITO_CALLBACK_URL,
      redirectSignOut: process.env.REACT_APP_COGNITO_CALLBACK_URL,
      responseType: "code",
    },
  },
};
