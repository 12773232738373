import { ErrorTooltip, InfoTooltip } from "../../../StyleToolTips.js";
import React, { useState } from "react";

import { CircularProgress } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { importStratusStatuses } from "data/queries/queryAPI.js";
import { styled } from "@mui/material/styles";
import { useMutation } from "react-query";
import { useQueryClient } from "react-query";

export default function ImportStatuses(props) {
  const [statusesLoading, setStatusesLoading] = useState(false);
  const [statusesError, setStatusesError] = useState(false);
  const queryClient = useQueryClient();
  const { mutate: importStatuses } = useMutation(
    () => importStratusStatuses(),
    {
      onSettled: (data) => {
        queryClient.refetchQueries(['companyStatuses']).then(() => {
          setStatusesLoading(false);
          setStatusesError(false);
        })
      },
      onError: (error) => {
        console.error("Error importing statuses. Using current statuses or default statuses");
        setStatusesError(true);
        setStatusesLoading(false);
      },
    }
  );


  const handleImportStatuses = () => {
    if (!statusesLoading) {
      importStatuses();
      setStatusesLoading(true);
    }
  };

  const Button_ = styled('button')`
    cursor: pointer;
    border-color: #F15A29;
    background: #EFEFEF;
    color: #F15A29;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 4px;
    font-weight: 400;
    text-align: center;
  `;
  const ErrorIcon_ = styled(ErrorIcon)`
    position: absolute;
    top: -.25rem;
    right: -.25rem;
    color: #ec5747;
  `;

  return (
    <>
      <div>
        <InfoTooltip title="STRATUS Statuses will be imported into the Company">
          <Button_
            onClick={handleImportStatuses}
          >
            {!statusesLoading ? (
              <>
                <span>IMPORT</span>
                {statusesError && (
                  <ErrorTooltip title="An error occurred during import, please try again">
                    <ErrorIcon_/>
                  </ErrorTooltip>
                )}
              </>
            ) : (
              <CircularProgress size={"1rem"} color="inherit" />
            )}
          </Button_>
        </InfoTooltip>
      </div>
    </>
  );
}
