import * as THREE from "three";

import {
  Box,
  Card,
  CardContent,
  Grid,
} from "@mui/material";
import { Canvas, extend, useFrame, useThree } from "@react-three/fiber";
import {Line, OrbitControls, Stars} from "@react-three/drei";
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
  useAPICallback,
  useAPIData,
  useAPIEffect,
} from "./data/hooks/customHooks";

import Alata from "./fonts/Alata_Regular.json"
import { BufferAttribute } from "three";
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader'
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry";
import Loading from "./Loading";
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry'

extend({ TextGeometry })

const MeshViewer = (props) => {
    const {config, meshData, item} = props;
    const [meshGeometry, setMeshGeometry] = useState([])
    const [meshAnnotations, setMeshAnnotations] = useState([])
    const [meshVertices, setMeshVertices] = useState([])
    const [meshTriangles, setMeshTriangles] = useState([])
    const [meshNormals, setMeshNormals] = useState([])
    const [insulationVertices, setInsulationVertices] = useState([])
    const [insulationTriangles, setInsulationTriangles] = useState([])
    const [insulationNormals, setInsulationNormals] = useState([])
    const [meshConnectors, setMeshConnectors] = useState([])
    const [meshDimensions, setMeshDimensions] = useState([])
    const [currentVertices, setCurrentVertices] = useState([])
    const [currentTriangles, setCurrentTriangles] = useState([])
    const [currentNormals, setCurrentNormals] = useState([])
    const [currentColors, setCurrentColors] = useState([])
    const [currentInsideVertices, setInsideVertices] = useState([])
    const [currentInsideTriangles, setInsideTriangles] = useState([])
    const [currentInsideNormals, setInsideNormals] = useState([])
    var cam = {};

    const GenerateMesh = (meshVertices, meshTriangles, meshNormals, insulationVertices, insulationTriangles, insulationNormals) =>{
      let verts = []
      let insideVerts = []
      let triangles = []
      let insideTriangles = []
      let normals = []
      let insideNormals = []
      let colors = []
      if(meshVertices){
        if(Array.isArray(meshVertices)){
          meshVertices.map((vert, index) => {
            verts.push(vert.Z, vert.Y, vert.X)
            insideVerts.push(vert.X, vert.Y, vert.Z)
            let color = {r: 1, g: 1, b: 1}
            colors.push(color.r, color.g, color.b)
          })
          setCurrentVertices(verts)
          setInsideVertices(insideVerts)
          setCurrentColors(colors)
        }
      }
      if(meshTriangles){
        if(Array.isArray(meshTriangles)){
          meshTriangles.map((triangle, index) =>{
            triangles.push(triangle.Index3, triangle.Index2, triangle.Index1)
            insideTriangles.push(triangle.Index1, triangle.Index2, triangle.Index3)

          })
          setCurrentTriangles(triangles)
          setInsideTriangles(insideTriangles)
        }
      }
      if(meshNormals){
        if(Array.isArray(meshNormals)){
          meshNormals.map((normal, index) =>{
            normals.push(normal.Z, normal.Y, normal.X)
            insideNormals.push(normal.X, normal.Y, normal.Z)
          })
          setCurrentNormals(normals)
          setInsideNormals(insideNormals)
        }
      }
    }
    const ConstructMeshData = (meshGeometry) =>{
      if(meshGeometry){
        if(meshGeometry.Shell){
          if(meshGeometry.Shell.Annotations){
            setMeshAnnotations(meshGeometry.Shell.Annotations);
          }
          if(meshGeometry.Shell.Body){
            if(meshGeometry.Shell.Body.Vertices){
              setMeshVertices(meshGeometry.Shell.Body.Vertices)
            }
            if(meshGeometry.Shell.Body.Triangles){
              setMeshTriangles(meshGeometry.Shell.Body.Triangles)
            }
            if(meshGeometry.Shell.Body.Normals){
              setMeshNormals(meshGeometry.Shell.Body.Normals)
            }
          }
          if(meshGeometry.Shell.Connectors){
            setMeshConnectors(meshGeometry.Shell.Connectors)
          }
          if(meshGeometry.Shell.Dimensions){
            setMeshDimensions(meshGeometry.Shell.Dimensions)
            props.setDimensions(meshGeometry.Shell.Dimensions)
          }
          if(meshGeometry.Shell.Insulation){
            if(meshGeometry.Shell.Insulation.Vertices){
              setInsulationVertices(meshGeometry.Shell.Insulation.Vertices)
            }
            if(meshGeometry.Shell.Insulation.Triangles){
              setInsulationTriangles(meshGeometry.Shell.Insulation.Triangles)
            }
            if(meshGeometry.Shell.Insulation.Normals){
              setInsulationNormals(meshGeometry.Shell.Insulation.Normals)
            }
          }
          GenerateMesh(meshVertices, meshTriangles, meshNormals, insulationVertices, insulationTriangles, insulationNormals)
        }
      }
    }

    useEffect(() => {
      setInsideVertices([])
      setInsideTriangles([])
      setInsideNormals([])
      GenerateMesh(meshVertices, meshTriangles, meshNormals, insulationVertices, insulationTriangles, insulationNormals)
    }, [meshVertices, meshTriangles, meshNormals, insulationVertices, insulationTriangles, insulationNormals])

    useEffect(() => {
      ConstructMeshData(meshGeometry)
    }, [meshGeometry])

    useEffect(() =>{
      if(meshData.data){
        if(meshData.data.processedGeometry){
          setMeshGeometry(JSON.parse(JSON.parse(JSON.stringify(meshData.data.processedGeometry)))[0])
        }else{
          setMeshGeometry([]);
          setInsideVertices([])
          setInsideTriangles([])
          setInsideNormals([])
          setMeshAnnotations([])
          setMeshDimensions([])
          setMeshVertices([])
          setMeshTriangles([])
          setMeshNormals([])
        }
      }
    }, [meshData])

    useEffect(() => {
          setMeshGeometry([]);
          setInsideVertices([])
          setInsideTriangles([])
          setInsideNormals([])
          setMeshAnnotations([])
          setMeshDimensions([])
          setMeshVertices([])
          setMeshTriangles([])
          setMeshNormals([])
    }, [props.item])
    function getPointInBetweenByPerc(pointA, pointB, percentage) {

      var dir = pointB.clone().sub(pointA);
      var len = dir.length();
      dir = dir.normalize().multiplyScalar(len*percentage);
      return pointA.clone().add(dir);
  
  }
    const CustomMesh = (props) => {


      const font = new FontLoader().parse(Alata)
      let textOptions = {
        font,
        size: 2,
        height: 1
      }

      if(Array.isArray(item?.connectors)){
        if(item?.connectors[0]?.WidthOrDiameter){
          textOptions = {
            font,
            size: item?.connectors[0]?.WidthOrDiameter * 0.25,
            height: item?.connectors[0]?.WidthOrDiameter * 0.25,
          }
        }
      }
      const memoVerts = useMemo(() => { return new Float32Array(props.verts)}, [props.verts])
      const memoTris = useMemo(() => {return new Uint32Array(props.tris)}, [props.tris])
      const memoNorms = useMemo(() => {return new Float32Array(props.norms)}, [props.norms])
      const memoAnnotations = useMemo(() => {return props.annotations}, [props.annotations])
      const memoDimensions = useMemo(() => {return props.dimensions}, [props.dimensions])

      useEffect(() => {

      }, [props.verts, props.tris, props.norms, props.annotations, props.dimensions])
      
      
      return (
        <mesh {...props} >
          <bufferGeometry attach="geometry">
            <bufferAttribute
              attachObject={["attributes", "position"]}
              array={memoVerts}
              itemSize={3}
              count={memoVerts.length / 3}
              needsUpdate={true}
            />
            <bufferAttribute 
              attach="index"
              array={memoTris}
              count={memoTris.length}
              itemSize={1}
            />
            <bufferAttribute
              attachObject={["attributes", "normal"]}
              count={memoNorms.length}
              array={memoNorms}
              itemSize={3}
            />
          </bufferGeometry>
          <meshPhongMaterial attach="material" side={THREE.DoubleSide} color="gray" />
          {memoAnnotations && memoAnnotations.map((e, index) => {
            let linePoints = [];
            let point1 = new THREE.Vector3(e.Pt1.X, e.Pt1.Y, e.Pt1.Z)
            let point2 = new THREE.Vector3(e.Pt2.X, e.Pt2.Y, e.Pt2.Z)
            linePoints.push(point1)
            linePoints.push(point2)
            let lineGeometry = new THREE.BufferGeometry().setFromPoints(linePoints)
            return (
              <mesh key={index}><line geometry={lineGeometry}>
                  <lineBasicMaterial attach="material" color={'blue'} linewidth={50} linecap={'round'} linejoin={'round'} />
                </line>
              <mesh  position={[e.Position.X, e.Position.Y, e.Position.Z]} rotation={props.camera.rotation}>
                <textGeometry attach='geometry' args={[e.Prefix, textOptions]} />
                <meshStandardMaterial attach='material' color={'blue'}/>
                
              </mesh>
              </mesh>
              
            )
          })}
          {memoDimensions && memoDimensions.map((e, index) =>{
            let linePoints = []
            let point1 = new THREE.Vector3(e.From.X, e.From.Y, e.From.Z);
            let point2 = new THREE.Vector3(e.To.X, e.To.Y, e.To.Z)
            let midPoint = getPointInBetweenByPerc(point1, point2, 0.5)
            linePoints.push(point1)
            linePoints.push(point2)
            let lineGeometry = new THREE.BufferGeometry().setFromPoints(linePoints);
            return (
              <mesh key={index}>
                <mesh position={midPoint} rotation={props.camera.rotation}>
                <textGeometry attach='geometry' args={[e.Prefix, textOptions]} />
                <meshStandardMaterial attach='material' color={'orange'}/>
                
              </mesh>
              <line geometry={lineGeometry}>
                  <lineBasicMaterial attach="material" color={'orange'} linewidth={50} linecap={'round'} linejoin={'round'} />
                </line>
              </mesh>
              
              
            )
          })}
          
        </mesh>
      );
    }
    
    return(
      <Card variant="outlined" sx={styles.card}>
            <Canvas onCreated={({camera})=> {
              cam = camera
            }}>
                <ambientLight intensity={0.5}/>
                <spotLight position={[40, 50, 40]} angle={0.9}/>
                <Stars/>
                <OrbitControls/>
                {
                  currentInsideVertices !== [] && currentInsideNormals !== [] && currentInsideTriangles !== [] &&
                  <CustomMesh verts={currentInsideVertices} tris={currentInsideTriangles} norms={currentInsideNormals} annotations={meshAnnotations} dimensions={meshDimensions} item={item} camera={cam} />
                }                
            </Canvas>
            <CardContent>
                {props.buttons}
            </CardContent>
      </Card>   
    );  
};
const styles = {
  div: {
    1: {width: 310, height: 260, border: '4px solid #003E70'}
  },
  card: {width: 310, overflowY: 'auto', border: '1px solid #F15A29 !important', marginBottom: "1rem"}
};

export default MeshViewer;