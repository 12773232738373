const main = {
  lightest:'#596392',
  light:'#3e4471',
  darker:'#2c3350',
  darkest:'#182035',
  lightgray:'#cfd0da',
  darkgray:'#adafbc',
  black:'#000000'
};

const secondary = {
  orange: '#f68d4b',
  red: '#ec5747',
  yellow: '#f6b81c',
  green: '#5ec093',
  blue: '#003E70',
}

const colors = {
  main, 
  secondary
}

export {
  colors
}