import { Button, ButtonGroup, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { hardDeleteDivision, restoreDivision, softDeleteDivision, updateDivision } from "data/queries/queryAPI";
import { openDivisionHardDeleteDialog, openDivisionRenameDialog, openDivisionUsersDialog } from "Context/DrawerStates";
import { useMutation, useQueryClient } from "react-query";

import { Outlined_btn } from "components/Theme/Styled";
import { RenameDivisionForm } from "./RenameDivisionForm";

export const PopoverButtons = (props) => {
  const [isRenaming, setIsRenaming] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isRestoring, setIsRestoring] = useState(false);
  const [newDivisionName, setNewDivisionName] = useState("");
  const queryClient = useQueryClient();

  props.setDivisionForDialog(props.division);

  const { mutate: deleteItem } = useMutation( softDeleteDivision, {
    onSettled: (data) => {
      queryClient
        .refetchQueries(["allDivisions"])
        .then(() => {
          setIsDeleting(false);
        })
        .then(() => {
          props.handleClose();
        })
        .catch((error) => {
          console.error("error deleting division", error);
        });
    },
    onError: (error) => {
      console.error("error deleting division", error);
      alert("An error occurred while deleting a division: ", error);
    },
  });

  const { mutate: restoreItem } = useMutation(restoreDivision, {
    onSettled: (data) => {
      queryClient
        .refetchQueries(["allDivisions"])
        .then(() => {
          setIsRestoring(false);
        })
        .then(() => {
          props.handleClose();
        })
        .catch((error) => {
          console.error("error restoring division", error);
        });
    },
    onError: (error) => {
      console.error("error restoring division", error);
      alert("An error occurred while restoring a division: ", error);
    },
  });

  const { mutate: renameItem } = useMutation(updateDivision, {
    onSettled: async (data) => {
      await data.json();
      queryClient
        .refetchQueries(["allDivisions"])
        .then(() => {
          setIsRenaming(false);
        })
        .then(() => {
          props.handleClose();
        })
        .catch((error) => {
          console.error("error renaming division", error);
        });
    },
    onError: (error) => {
      console.error("error renaming division", error);
      alert("An error occurred while renaming division: ", error);
    },
  });

  const handleDelete = (e) => {
    e.stopPropagation();
    openDivisionHardDeleteDialog();
    props.handleClose();

  };

  const handleRestore = (e) => {
    e.stopPropagation();
    setIsRestoring(true);
    restoreItem(props.division.id);
  };

  return (

      <ButtonGroup
        orientation="vertical"
        aria-label="vertical outlined button group"
      >
        {
          (props.division?.id !== "stratus0-0000-0000-0000-000000000000") ? (
            <Outlined_btn
              key="rename"
              onClick={(e) => {
                e.stopPropagation();
                openDivisionRenameDialog();
                props.handleClose();
              }}
            >
              Rename
            </Outlined_btn>
          ) : null
        }
        <Outlined_btn
          onClick={(e) => handleDelete(e)} key="delete">
          Delete
          {isDeleting ? (
            <CircularProgress sx={{ marginLeft: "1rem" }} size={12} />
          ) : null}
        </Outlined_btn>
      </ButtonGroup>
  );
};
