//import {Switch} from "@mui/material";

import Switch from "@material-ui/core/Switch";
import {styled} from "@mui/material/styles";

const _Switch = (props) => {
    const Switch_ = styled(Switch)`
        //PLACE ANY DESIRED STYLES HERE
        .Mui-checked {
            .MuiSwitch-thumb {
                background: #F15A29 !important;
            }
        }

        .MuiSwitch-thumb {
            background: #000000 !important;
        }

    `;
    return (
        <Switch_ 
            checked={props.checked}
            onChange={props.onChange}
            sx={{
                ".MuiSwitch-track": { 
                    background: props.checked ? 
                    "#F15A29 !important" 
                    : 
                    "#a0a0a0 !important"
                }
            }}
            //color="secondary"
        />
    );
};

export default _Switch;