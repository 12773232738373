import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";

export const CardSwitch = styled(Switch)(({ theme }) => ({
    width: 80,
    height: 40,
    padding: 8,
    "& .MuiSwitch-switchBase": {
      margin: 1,
      padding: 0,
      width: 30,
      height: 30,
      transform: "translateX(6px)",
      marginTop: 5,
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(20px)",
        "& .MuiSwitch-thumb:before": {
          width: "80%",
          height: "80%",
          top: 1,
          left: 1,
  
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            "#fff"
          )}" d="M4 8h4V4H4v4zm6 12h4v-4h-4v4zm-6 0h4v-4H4v4zm0-6h4v-4H4v4zm6 0h4v-4h-4v4zm6-10v4h4V4h-4zm-6 4h4V4h-4v4zm6 6h4v-4h-4v4zm0 6h4v-4h-4v4z"/></svg>')`,
  
          "& + .MuiSwitch-track": {
            opacity: 1,
            backgroundColor:
              theme.palette.mode === "dark" ? "#F15A19" : "#F15A19",
          },
        },
      },
      "& .MuiSwitch-thumb": {
        backgroundColor: theme.palette.mode === "dark" ? "#000000" : "#000000",
        width: 30,
        height: 30,
        "&:before": {
          content: "''",
          position: "absolute",
          width: "80%",
          height: "80%",
          left: 2,
          top: 2,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 23"><path fill="${encodeURIComponent(
            "#fff"
          )}" d="M20 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h15c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 2v3H5V5h15zm-5 14h-5v-9h5v9zM5 10h3v9H5v-9zm12 9v-9h3v9h-3z"/></svg>')`,
        },
      },
      "& .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#F15A19" : "#F15A19",
        height: 30,
        width: 100,
        borderRadius: 20 / 2,
      },
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      
      height: 20,
      borderRadius: 20 / 2,
  
    },
  }));