import { Box, Breadcrumbs, Button, CircularProgress, Drawer, Grid, IconButton, Link, Typography } from "@mui/material";
import {
  DataGridPro,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import React, {createContext, useContext, useEffect, useMemo, useState} from "react";
import { deleteService, getProjects, getServiceConnectors, getServiceItems, getUsersWithCognitoData, importStratusServicesArray } from "../../../data/queries/queryAPI";
import { servicesImportIncrement, servicesImportProgress } from "Context/componentStates";
import {
  useAPICallback,
  useAPIData,
  useAPIEffect,
} from "../../../data/hooks/customHooks.js";
import { useMutation, useQuery, useQueryClient } from "react-query";

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Delete from "@mui/icons-material/Delete";
import DeleteForever from "@mui/icons-material/DeleteForever"
import DeleteOutline from "@mui/icons-material/DeleteOutline"
import ErrorDialog from "components/Utilities/ErrorDialog";
import NewCustomDataGrid from "./CustomDataGrid";
import { Scale } from "@mui/icons-material";
import { ServiceConfiguration } from "./ServiceConfiguration/ServiceConfiguration"
import ServiceTable_renderCell from "./ServiceTable_renderCell";
import { Service_Groups_Table } from './Service_Groups_Table';
import { Service_Tabs_Table } from './Service_Tabs_Table';
import { Service_items_Table } from './Service_items_Table';
import { ServicesRefreshDialog } from "./ServicesRefreshDialog";
import SyncIcon from "@mui/icons-material/Sync";
import Tooltip from '@mui/material/Tooltip';
import WarningIcon from '@mui/icons-material/Warning';
import { styled } from "@mui/material/styles";

export const TableContext = createContext();

export const ServicesTable = (props)=> {

  const queryClient = useQueryClient();
  
  const [selectionModel, setSelectionModel] = useState([]);
  const [serviceName, setServiceName] = useState('None')
  const [serviceId, setServiceId] = useState(props.serviceId)
  const [selectedService, setSelectedService] = useState(props.selectedService)
  const [isDeleting, setIsDeleting] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const [isRefreshMode, setIsRefreshMode] = useState(false);  
  const [rows, setRows] = useState(props.dataForDisplay);
  const [serviceGeneratorVersion, setServiceGeneratorVersion] = useState(props.serviceGeneratorVersion);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const [sortModel, setSortModel] = useState([
    {
      field: "name",
      sort: "asc",
    },
  ]);
  
  useEffect(() => {
    setServiceId(props.serviceId)
  }, [props.serviceId])

  useEffect(() => {
    setRows(props.dataForDisplay)
  }, [props.dataForDisplay])

  useEffect(() => {
    setSelectedService(props.selectedService)
  }, [props.selectedService])

  useEffect(() => {
    setServiceGeneratorVersion(props.serviceGeneratorVersion)
  }, [props.serviceGeneratorVersion])

  const { mutate: importArrayFromStratus } = useMutation(
    importStratusServicesArray,
    {
      onSettled: (data) => {
        if (data.every((el) => el === "SUCCESS")) {
          queryClient
          .refetchQueries(["allServices"])
          .then(() => {
            setIsRefreshing(false);
            props.setImporting(false);
            toggleRefresh();
          })
          .catch((error) => {
            setErrorMessage(error.message || "An error occurred");
            setDialogOpen(true);
          });
        }
        if (data.some((el) => el === "ERROR")) {
          //props.setImporting(false);
          setErrorMessage("There was an error importing a service. Services that were successfully imported will be shown on the screen.");
          setDialogOpen(true);
        }
        if (data.some((el) => el === "TIMEOUT")) {
          //props.setImporting(false);
          setErrorMessage("There was a timeout importing a service. Services that were successfully imported will be shown on the screen.");
          setDialogOpen(true);
        }
      },
      onError: (error) => {
        setErrorMessage(error.message || "An error occurred");
        setDialogOpen(true);
      },
    }
  );

  const { mutate: deleteItem } = useMutation(deleteService, {
    onSettled: (data) => {
    },
    onError: (error) => {
        setErrorMessage(error.message || "An error occurred");
        setDialogOpen(true);
    },
  });
    const columns = [
      { field: "renderCellmenu", headerName: "Menu", width: 120, renderCell: (params) => (
        <ServiceTable_renderCell 
          variant="menu"
          params={params} 
          setRenameFormOpen={props.setRenameFormOpen} 
          setServiceId={props.setServiceId} 
          serviceId={params?.row?.id}
          serviceName={params?.row?.name}
          setIsConfiguring={props.setIsConfiguring}
          serviceData={params?.row}
          setSelectedService={props.setSelectedService}
          setServiceName={setServiceName}
          setErrorMessage={setErrorMessage}
          setDialogOpen={setDialogOpen}
        />
      )},
      { field: "name", headerName: "Service Name", sortField: "name", sortComparator: (a, b) => { return a.localeCompare(b); }, width: 550, renderCell: (params)=> {
        if(params.value === "" || params.value === undefined){
          if(params.row.serviceGeneratorVersion !== undefined){
            if (params.row.serviceGeneratorVersion < serviceGeneratorVersion){
              return (
                <Box display='flex'>
                <Tooltip title='Service requires refresh'>
                  <WarningIcon color="secondary"/>
                </Tooltip>
                <Typography variant="h6">{params.row.name}</Typography>
                </Box>
              )
            }else{
              return(
                <Typography variant="h6">{params.row.name}</Typography>
              )
            }
          }else{
            return (
              <Box display='flex'>
              <Tooltip title='Service requires refresh'>
                <WarningIcon color="secondary"/>
              </Tooltip>
              <Typography variant="h6">{params.row.name}</Typography>
              </Box>
            )
          }
            
        }else{
          if(params.row.serviceGeneratorVersion !== undefined){
            if (params.row.serviceGeneratorVersion < serviceGeneratorVersion){
              return (
                <Box display='flex'>
                <Tooltip title='Service requires refresh'>
                  <WarningIcon color="secondary"/>
                </Tooltip>
                <Typography variant="h6">{params.row.name}</Typography>
                </Box>
              )
            }
            else{
              return(
                <Typography variant="h6">{params.row.name}</Typography>
              )
            }
          }else{
            return (
              <Box display='flex'>
              <Tooltip title='Service requires refresh'>
                <WarningIcon color="secondary"/>
              </Tooltip>
              <Typography variant="h6">{params.row.name}</Typography>
              </Box>
            )
          }
        }
      }
      },
      { field: "serviceGroup", headerName: "Group", width: 200 },
      { field: "abbreviation", headerName: "ABV", width: 100 },
/*       { field: "current", headerName: "Current", width: 150 }, */
      { field: "serviceProfile", headerName: "Profile", width: 150, renderCell: (params)=> { 
        if(params.value === ''){
          return <p>NONE</p>
        }
      }},
      { field: "isCatalog", headerName: "Catalog Exists?", width: 150, renderCell: (params)=> {
        if(params.value){
          return (<CheckIcon color='primary'/>)
        }else{
          return (<CloseIcon />)
        }
      }},
      /*{ field: "dateImported", headerName: "Date Imported", width: 175 }, */
    ];

    const deleteColumns = [
      { field: "name", headerName: "Service Name", width: 550, renderCell: (params)=> {
        if(params.value === "" || params.value === undefined){
          if(params.row.serviceGeneratorVersion !== undefined){
            if (params.row.serviceGeneratorVersion < serviceGeneratorVersion){
              return (
                <Box display='flex'>
                <Tooltip title='Service requires refresh'>
                  <WarningIcon color="secondary"/>
                </Tooltip>
                <Typography variant="h6">{params.row.name}</Typography>
                </Box>
              )
            }else{
              return(
                <Typography variant="h6">{params.row.name}</Typography>
              )
            }
          }else{
            return (
              <Box display='flex'>
              <Tooltip title='Service requires refresh'>
                <WarningIcon color="secondary"/>
              </Tooltip>
              <Typography variant="h6">{params.row.name}</Typography>
              </Box>
            )
          }
            
        }else{
          if(params.row.serviceGeneratorVersion !== undefined){
            if (params.row.serviceGeneratorVersion < serviceGeneratorVersion){
              return (
                <Box display='flex'>
                <Tooltip title='Service requires refresh'>
                  <WarningIcon color="secondary"/>
                </Tooltip>
                <Typography variant="h6">{params.row.name}</Typography>
                </Box>
              )
            }
            else{
              return(
                <Typography variant="h6">{params.row.name}</Typography>
              )
            }
          }else{
            return (
              <Box display='flex'>
              <Tooltip title='Service requires refresh'>
                <WarningIcon color="secondary"/>
              </Tooltip>
              <Typography variant="h6">{params.row.name}</Typography>
              </Box>
            )
          }
        }
      }
        
      },
      { field: "serviceGroup", headerName: "Group", width: 200 },
      { field: "abbreviation", headerName: "ABV", width: 100 },
/*       { field: "current", headerName: "Current", width: 150 }, */
      { field: "serviceProfile", headerName: "Profile", width: 150, renderCell: (params)=> { 
        if(params.value === ''){
          return <p>NONE</p>
        }
      }},
      { field: "isCatalog", headerName: "Catalog Exists?", width: 150, renderCell: (params)=> {
        if(params.value){
          return (<CheckIcon color='primary'/>)
        }else{
          return (<CloseIcon/>)
        }
      }},
/*       { field: "database", headerName: "DataBase", width: 200 },
      { field: "dateImported", headerName: "Date Imported", width: 175 }, */
    ];
    
    const BacktoServices = () => (
      <Button sx={{marginLeft: "-0.75rem !important", marginBottom: "1rem !important"}} size="small" onClick={backToServices}>
        <ArrowBackIosNewIcon_ fontSize="small" sx={{color: "rgba(0,0,0, 0.4)", marginBottom: ".25rem"}} />
        <Link color="rgba(0,0,0, 0.4)" variant="caption" underline="hover"> Back to Services</Link>
      </Button> 
    );

    const backToServices = () => {
      setServiceName('None');
      props.setServiceId('None');
      props.setSelectedService(undefined);
      props.setIsConfiguring(false);
      setIsDeleteMode(false);
      setIsRefreshMode(false);
    }

    const reloadServices = () => {
      const servicesToImport = [];
      props.dataForDisplay.forEach((service) => {
        if(service.fabId && service.id && service.fileGroupId && service.templateId){
          const serviceToImport = {};
          serviceToImport.id = service.templateId;
          serviceToImport.fabId = service.fabId;
          serviceToImport.fileGroupId = service.fileGroupId;
          serviceToImport.serviceColor = "#C47C25";
          serviceToImport.metric = service.metric;
          servicesToImport.push(serviceToImport);
        } else {
          console.log("Service: ", service.id, " will not be reloaded due to missing data")
        }
        
      });
      //props.setImporting(true);
      //props.setOpen(false);
      if(Array.isArray(servicesToImport) && servicesToImport.length > 0){
        servicesImportProgress.set(0);
        servicesImportIncrement.set( Math.ceil( 100 / servicesToImport.length ) );
        importArrayFromStratus(servicesToImport);
      }
      
    }
    
    const toggleDelete = () => {
        if(!isDeleteMode){
          setIsDeleteMode(true);
          setIsRefreshMode(false);
        } else {
          setIsDeleteMode(false);
        }    
    }

    const toggleRefresh = () => {
      if( !isRefreshMode ) {
        setIsRefreshMode(true);
        setIsDeleteMode(false);
      } else {
        setIsRefreshMode(false);
      }    
    }    

    const deleteServices = async () => {
      setIsDeleting(true);
    
      const deleteItemPromise = (serviceId) => {
        return new Promise((resolve, reject) => {
          deleteItem(serviceId, {
            onSettled: (data) => {
              if(data.status !== 200){
                setErrorMessage("An error occurred");
                setDialogOpen(true);
              }
              resolve();
            },
            onError: (error) => {
              setErrorMessage(error.message || "An error occurred");
              setDialogOpen(true);
              reject(error);
            },
          });
        });
      };
    
      try {
        const deletePromises = selectionModel.map((serviceId) => {
          return deleteItemPromise(serviceId);
        });
    
        await Promise.all(deletePromises);
    
        await queryClient.refetchQueries(["allServices"]);
    
        setIsDeleteMode(false);
        setIsDeleting(false);
      } catch (error) {
        setErrorMessage(error.message || "An error occurred");
        setDialogOpen(true);
      }
    };

    const refreshServices = async () => {

      setIsRefreshing(true);
      const servicesToImport = [];
      selectionModel.forEach( service => {
        props.dataForDisplay.map( el => {

          if( el.id === service && el.fabId && el.fileGroupId && el.templateId ) {
            const serviceToImport = {};
            serviceToImport.id = el.templateId;
            serviceToImport.fabId = el.fabId;
            serviceToImport.fileGroupId = el.fileGroupId;
            serviceToImport.serviceColor = "#C47C25";
            serviceToImport.metric = el.metric;
            if(el.serviceProfile !== undefined && el.hasSeparateItems !== undefined){
              if(el.serviceProfile === ""){
                serviceToImport.profile = {'Name': "Global", 'HasSeparateItems' : el.hasSeparateItems};

              }else{
                serviceToImport.profile = {'Name': el.serviceProfile, 'HasSeparateItems' : el.hasSeparateItems};
              }
            }
            servicesToImport.push(serviceToImport);
          } else if ( el.id !== service && el.fabId && el.fileGroupId && el.templateId ) {
            return
          } else if ( el.id === service && !el.fabId && el.fileGroupId && el.templateId ) {
            console.log("Service: ", service, " will not be reloaded due to missing fabId data")
          } else if ( el.id === service && el.fabId && !el.fileGroupId && el.templateId ) {
            console.log("Service: ", service, " will not be reloaded due to missing fileGroupId data")
          } else if ( el.id === service && el.fabId && el.fileGroupId && !el.templateId ) {
            console.log("Service: ", service, " will not be reloaded due to missing templateId data")
          } else console.log("Service: ", service, " will not be reloaded to missing data")
        })
      })       

      if( Array.isArray(servicesToImport) && servicesToImport.length > 0 ) {
        servicesImportProgress.set(0);
        servicesImportIncrement.set( Math.ceil( 100 / servicesToImport.length ) );
        importArrayFromStratus(servicesToImport);
      }
    }
    
    
    const table = {
      // OBJECT TO PROVIDE CONTEXT TO CUSTOM GRID MENU
      isConfiguring: props.isConfiguring,
      isDeleting: isDeleting,
      isDeleteMode: isDeleteMode,
      isRefreshing: isRefreshing,
      isRefreshMode: isRefreshMode,
      selectionModel: selectionModel,
      functions: {
        toggleDelete: () => toggleDelete(),
        toggleRefresh: () => toggleRefresh(),
        deleteServices: () => deleteServices(),
        refreshServices: () => refreshServices(),
        reloadServices: () => reloadServices()
      }
    }

    return (
        <TableContext.Provider value={table}>
          {
            props.isConfiguring ?
            <Grid direction="column" sx={{marginLeft:".5rem"}}>
        
              <Box>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link sx={{color: "black !important"}} variant="h5" size="large" onClick={backToServices} underline="hover">
                    Services
                  </Link>
                  <Typography variant="h6">Service: {selectedService?.name}</Typography>
                </Breadcrumbs>
              </Box> 
              <BacktoServices/>
                   
            </Grid>: null
          }
          {
            !props.isConfiguring && !isDeleteMode && !isRefreshMode ?
            <NewCustomDataGrid
            rows={rows}
            columns={columns}
            autoHeight={true}
            hideFooter={true}
            disableSelectionOnClick={true}
            disableColumnMenu={true}
            sortModel={sortModel}
            onSortModelChange={setSortModel}
          />
          : null
          }
          {
            (!props.isConfiguring && isDeleteMode || isRefreshMode ) ? (
              (<NewCustomDataGrid
                rows={rows}
                columns={deleteColumns}
                autoHeight={true}
                hideFooter={true}
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectionModel(newSelectionModel);                  
                }}
                selectionModel={selectionModel}
                disableSelectionOnClick={true}
                disableColumnMenu={true}
                checkboxSelection
                sortModel={sortModel}
                onSortModelChange={setSortModel}
              />)
            ) : null
          }
          
          {
            (props.isConfiguring && selectedService !== undefined) ? 
            <ServiceConfiguration companyId={props.companyId} selectedService={selectedService} setSelectedService={props.setSelectedService} service_id={serviceId}/>
            : null
                      
          }
          {
            (props.isConfiguring && !isDeleteMode && !isRefreshMode && selectedService === undefined) ? (
              <Box sx={styles.color.gray} open={true}>
              <Box sx={styles.box}>
                <Typography variant="h3">Building Service Items Table...</Typography>
                <CircularProgress color="inherit" />
              </Box>
            </Box>
            )  : null
          }
          
          <ServicesRefreshDialog
              dataForDisplay={props.dataForDisplay}
              refreshServices={refreshServices}
              setImporting={props.setImporting}
          />

        <ErrorDialog open={dialogOpen} handleClose={handleCloseDialog} errorMessage={errorMessage} />

        </TableContext.Provider>
      );

};

const styles = {
  color: {
    white: {color: "#fff"},
    gray: {color: "gray"}
  },
  box: { display: "flex", flexDirection: "col" },
  syncIcon: {color:"#F15A29" },
};

const ArrowBackIosNewIcon_ = styled(ArrowBackIosNewIcon)`
  transform: scale(.5);
`;
