import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { closeDivisionRenameDialog, openUsersDrawer } from "Context/DrawerStates";
import { deleteDivision, updateDivision } from "data/queries/queryAPI";
import { getDivisions, getUsers } from "../../data/queries/queryAPI";
import { useMutation, useQueryClient } from "react-query";

import { CompanyNavButtons } from "components/Navigation/CompanyNavButtons";
import { DivisionsList } from "./components/DivisionsList";
import { EditUsersDialog } from "./components/EditUsersDialog";
import ErrorDialog from "components/Utilities/ErrorDialog";
import { HardDeleteDialog } from "./components/HardDeleteDialog";
import { Paper } from "@mui/material";
import { PersistentNavButtons } from "../Navigation/PersistentNavButtons";
import { RenameDivisionForm } from "./components/RenameDivisionForm";
import { useQuery } from "react-query";

export const Divisions = (props) => {
  //CLOSE ALL OTHER PAGE'S DRAWERS ON INITIAL LOAD
  const [isRenaming, setIsRenaming] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [newDivisionName, setNewDivisionName] = useState("");
  const queryClient = useQueryClient();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleCloseDialog = () => {
      setDialogOpen(false);
  };


  const { mutate: renameItem } = useMutation(updateDivision, {
    onSettled: async (data) => {
      await data.json();
      queryClient
        .refetchQueries(["allDivisions"])
        .then(() => {
          setNewDivisionName("");
          setIsRenaming(false);
          closeDivisionRenameDialog();
        })
        .then(() => {
          props.handleClose();
        })
        .catch((error) => {
          setIsRenaming(false);
          setErrorMessage(error.message || 'An error occurred');
          setDialogOpen(true);
        });
    },
    onError: (error) => {
      setIsRenaming(false);
      setErrorMessage(error.message || 'An error occurred');
      setDialogOpen(true);
    },
  });
  useEffect(() => {
    props.setCatalogBrowserDialogOpen(false);
    props.setCatalogDrawerOpen(false);
    props.setProjectServiceDialogOpen(false);
    props.setProjectUserDialogOpen(false);
    props.closeCreateCatalogPartDrawer(false);
    props.closeCatalogImportDialog();
    props.handleCloseOrdersDrawer();
    props.closeServicesDrawer();
    props.closeCreateUserDrawer();
    props.closeUsersDrawer();
    props.closeProjectsDrawer();
  });

  const [divisionForDialog, setDivisionForDialog] = useState(null);

  //FETCH DATA FROM API
  const divisionsQuery = useQuery(["allDivisions"], () => getDivisions(), {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  const openDrawer = (userId) => {
    props.setUserForDrawer(userId);
    openUsersDrawer();
  };

  return (
    <Box
      sx={styles.paper}
    >
      <CompanyNavButtons />

      <DivisionsList
        openDrawer={openDrawer}
        setDivisionForDialog={setDivisionForDialog}
      />

      <EditUsersDialog
        divisionForDialog={divisionForDialog}
      />

      <RenameDivisionForm
        division={divisionForDialog}
        isRenaming={isRenaming}
        setIsRenaming={setIsRenaming}
        newDivisionName={newDivisionName}
        setNewDivisionName={setNewDivisionName}
        renameItem={renameItem}
      />

      <HardDeleteDialog
        division={divisionForDialog}
      />
      <ErrorDialog open={dialogOpen} handleClose={handleCloseDialog} errorMessage={errorMessage} />

    </Box>
  );
};

const styles = {
  paper: {
    height: "auto",
    width: "100%",
    marginTop: "30px",
    backgroundColor: "#fff",
    flex: 1,
    flexDirection: "column",
  }
};
