import {
  Box,
  Button,
  Card,
  CircularProgress,
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getCatalogs,
  getServices,
  getUsers,
  getUsersWithCognitoData,
  unassignCatalogProject,
  unassignServiceProject,
  unassignUserProject,
  unassignViewOnlyUser,
  updateProject,
} from "../../../data/queries/queryAPI";
import { useMutation, useQuery, useQueryClient } from "react-query";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Contained_btn } from "components/Theme/Styled";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { FlareSharp } from "@material-ui/icons";
import clone from "just-clone";
import { projectsDrawerOpen } from "Context/DrawerStates";
import { styled } from "@mui/material/styles";

export const ProjectsDrawer = (props) => {
  const [projectModified, setProjectModified] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [isRemovingCatalog, setIsRemovingCatalog] = useState(false);
  const [isUpdatingProject, setIsUpdatingProject] = useState(false);
  const queryClient = useQueryClient();
  const drawerWidth = 400;
  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  }));


  //////////////////////////////////////////////////////////////////////////////
  // HANDLING CATALOG NAME
  //////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (props.project) {
      setProjectName(props.project?.name);
    }
  }, [props.project]);

  const { mutate: commitProjectChanges } = useMutation(updateProject, {
    onSettled: (data) => {
      if (data.status === 200) {
        queryClient
          .refetchQueries(["allProjects"])
          .then(() => {
            setIsUpdatingProject(false);
            setIsRemovingCatalog(false);
          })
          .catch((err) => console.error(err));
      }
    },
    onError: (error) => {
      console.error("error mutating data:", error);
      alert("There was an error: ", error);
    },
  });
  const submitChanges = () => {
    const projectCopy = clone(props.project);
    projectCopy.name = projectName;
    projectCopy.catalogs = catalogList.map((catalog) => catalog.id);
    projectCopy.services = servicesList.map((service) => service.id);
    projectCopy.users = usersList.map((user) => user.id);
    setIsUpdatingProject(true);

    commitProjectChanges(projectCopy);
  };
  //////////////////////////////////////////////////////////////////////////////
  // HANDLING CATALOG DATA
  //////////////////////////////////////////////////////////////////////////////
  const [catalogsOpen, setCatalogsOpen] = useState(false);
  const [catalogList, setCatalogList] = useState([]);

  const catalogsQuery = useQuery(["allCatalogs"], () => getCatalogs(), {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  const addCatalogToProject = () => {
    props.setProjectForProjectDialogs(props.project);
    props.setProjectCatalogDialogOpen(true);
  };

  const { mutate: removeCatalogFromProject } = useMutation(
    unassignCatalogProject,
    {
      onSettled: (data) => {
        if (data.status === 200) {
          queryClient
            .refetchQueries(["allProjects"])
            .then(() => {
              setIsRemovingCatalog(false);
            })
            .catch((err) => console.error(err));
        }
      },
      onError: (error) => {
        console.error("error mutating data:", error);
        alert("There was an error: ", error);
      },
    }
  );

  const handleRemoveCatalog = (catalogId) => {
    const payload = {};
    payload.catalogId = catalogId;
    payload.projectId = props.project.id;
    setIsRemovingCatalog(true);
    removeCatalogFromProject(payload);
  };

  useEffect(() => {
    if (props.project) {
      const collector = props.project.catalogs.map((catalogId) => {
        return catalogsQuery.data?.data.find((catalogObj) => {
          return catalogObj.id === catalogId;
        });
      });

      const alphabetized = collector.sort((a, b) => {
        if (a.name.toUpperCase() === b.name.toUpperCase()) {
          return 0;
        }
        return a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1;
      });

      setCatalogList(alphabetized);
    }
  }, [catalogsQuery.data, props.project, props.project?.catalogs]);

  //////////////////////////////////////////////////////////////////////////////
  // HANDLING SERVICES DATA
  //////////////////////////////////////////////////////////////////////////////
  const [servicesOpen, setServicesOpen] = useState(false);
  const [servicesList, setServicesList] = useState([]);
  const [isRemovingService, setIsRemovingService] = useState(false);
  const servicesBasicQuery = useQuery(["allServices"], () => getServices(), {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  const addServiceToProject = () => {
    props.setProjectForProjectDialogs(props.project);
    props.setProjectServiceDialogOpen(true);
  };

  const { mutate: removeServiceFromProject } = useMutation(
    unassignServiceProject,
    {
      onSettled: (data) => {
        if (data.status === 200) {
          queryClient
            .refetchQueries(["allProjects"])
            .then(() => {
              setIsRemovingService(false);
            })
            .catch((err) => console.error(err));
        }
      },
      onError: (error) => {
        console.error("error mutating data:", error);
        alert("There was an error: ", error);
      },
    }
  );
  const handleRemoveService = (serviceId) => {
    const payload = {};
    payload.serviceId = serviceId;
    payload.projectId = props.project.id;
    setIsRemovingService(true);
    removeServiceFromProject(payload);
  };

  useEffect(() => {
    if (props.project) {
      const collector = props.project.services.map((serviceId) => {
        return servicesBasicQuery.data?.data.find((serviceObj) => {
          return serviceObj.id === serviceId;
        });
      });

      const alphabetized = collector.sort((a, b) => {
        if (a.name.toUpperCase() === b.name.toUpperCase()) {
          return 0;
        }
        return a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1;
      });

      setServicesList(alphabetized);
    }
  }, [servicesBasicQuery.data, props.project?.services, props.project]);

  //////////////////////////////////////////////////////////////////////////////
  // HANDLING USERS DATA
  //////////////////////////////////////////////////////////////////////////////
  const [usersOpen, setUsersOpen] = useState(false);
  const [viewOnlyUsersOpen, setViewOnlyUsersOpen] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [viewOnlyUsersList, setViewOnlyUsersList] = useState([]);
  const [isRemovingUser, setIsRemovingUser] = useState(false);

  const usersQuery = useQuery(["allDetailedUsers"], () => getUsersWithCognitoData(), {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  const { mutate: removeUserFromProject } = useMutation(unassignUserProject, {
    onSettled: (data) => {
      if (data.status === 200) {
        queryClient
          .refetchQueries(["allProjects"])
          .then(() => {
            setIsRemovingUser(false);
          })
          .catch((err) => console.error(err));
      }
    },
    onError: (error) => {
      console.error("error mutating data:", error);
      alert("There was an error: ", error);
    },
  });

  const { mutate: removeViewOnlyUserFromProject } = useMutation(unassignViewOnlyUser, {
    onSettled: (data) => {
      if (data.status === 200) {
        queryClient
          .refetchQueries(["allProjects"])
          .then(() => {
            setIsRemovingUser(false);
          })
          .catch((err) => console.error(err));
      }
    },
    onError: (error) => {
      console.error("error mutating data:", error);
      alert("There was an error: ", error);
    },
  });  

  const handleRemoveUser = async (userId) => {
    const payload = {};
    payload.userId = userId;
    payload.projectId = props.project.id;
    setIsRemovingUser(true);
    removeUserFromProject(payload);
  };

  const handleRemoveViewOnlyUser = async (userId) => {
    const payload = {};
    payload.userId = userId;
    payload.projectId = props.project.id;
    setIsRemovingUser(true);
    removeViewOnlyUserFromProject(payload);
  };

  const addUserToProject = () => {
    props.setProjectForProjectDialogs(props.project);
    props.setProjectUserDialogOpen(true);
  };

  const addViewOnlyUserToProject = () => {
    props.setProjectForProjectDialogs(props.project);
    props.setProjectUserDialogOpen(true);
    props.setProjectViewOnlyUser(true);
  };  

  useEffect( () => {
    if (props.project) {

      const removalQueue = [];
      const viewOnlyremovalQueue = [];
      
      const collector = props.project.users.map( (userId) => {
        return usersQuery.data?.find( (userObj) => {
          return userObj.id === userId;
        });
      });

      const viewOnlycollector = props.project.viewOnly?.map( userId => {
        return usersQuery.data?.find( userObj => {
          return userObj.id === userId;
        });
      });

      // collector.map( user => {
      //   if( !user.enabled ) {
      //     removalQueue.push(user.id);
      //   } else return;
      // });

      const alphabetized = collector.sort( (a, b) => {
        if (a.name.toUpperCase() === b.name.toUpperCase()) {
          return 0;
        }
        return a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1;
      });

      const viewOnlyalphabetized = viewOnlycollector?.sort( (a, b) => {
        if (a.name.toUpperCase() === b.name.toUpperCase()) {
          return 0;
        }
        return a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1;
      });
      
      alphabetized.map( user => {
        if( !user?.enabled ) {
          removalQueue.push(user?.id);
        } else return;
      });

      viewOnlyalphabetized?.map( user => {
        if( !user?.enabled ) {
          viewOnlyremovalQueue.push(user?.id);
        } else return;
      });

      setUsersList(alphabetized);
      setViewOnlyUsersList(viewOnlyalphabetized);

      removalQueue.map( id => {
        handleRemoveUser(id);
      });
      viewOnlyremovalQueue.map( id => {
        handleRemoveViewOnlyUser(id);
      });
    }
  }, [usersQuery.data, props.project?.users, props.project]);


  return (
    <Drawer
      sx={{
        ///backgroundColor: "#000",
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="temporary"
      anchor="right"
      onClose={ () => projectsDrawerOpen.set(false) }
      open={projectsDrawerOpen.use()}
      BackdropProps={{sx: { background: "rgba(0,0,0,0)" }}}
    >
      <DrawerHeader
        sx={styles.drawerHeader}
      >
        <Typography variant="h6" gutterBottom component="div">
          EDIT PROJECT...
        </Typography>
        <IconButton
          sx={styles.iconButton[1]}
          onClick={props.closeDrawer}
        >
          <CancelIcon sx={styles.cancelIcon} />
        </IconButton>
      </DrawerHeader>

      <TextField
        disabled={props.project?.stratusModelId}
        color="secondary"
        sx={styles.textField[1]}
        id="outlined-name"
        label="Name"
        value={projectName}
        onChange={(e) => {
          setProjectModified(true);
          setProjectName(e.target.value);
        }}
      />
        <Card sx={styles.card} variant="outlined" >


          <Box>
            <Box
              sx={styles.box[1]}
            >
              <ListItemButton
                onClick={(e) => {
                  setCatalogsOpen(!catalogsOpen);
                }}
              >
                {catalogsOpen ? <ExpandMore /> : <ChevronRightIcon />}
                <ListItemText primary={`Catalogs (${catalogList.filter(catalog => catalog).length})`} />
              </ListItemButton>
              <IconButton
                onClick={(e) => {
                  addCatalogToProject();
                }}
                sx={styles.iconButton[2]}
                aria-label="add catalog to project"
              >
                <AddCircleIcon color="secondary"/>
              </IconButton>
            </Box>
            <Box sx={styles.box[2]}>
              <Collapse in={catalogsOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {catalogList.filter(catalog => catalog).length > 0 ? (
                    catalogList.filter(catalog => catalog).map((catalog) => {
                      return (
                        <Box
                          key={catalog.id}
                          sx={styles.box[3]}
                        >
                          <ListItemText primary={catalog.name} />
                        </Box>
                      );
                    })
                  ) : (
                    <ListItemText>No catalogs found</ListItemText>
                  )}
                </List>
              </Collapse>
            </Box>
          </Box>

          <Box>
            <Box
              sx={styles.box[4]}
            >
              <ListItemButton
                onClick={(e) => {
                  setServicesOpen(!servicesOpen);
                }}
              >
                {servicesOpen ? <ExpandMore /> : <ChevronRightIcon />}
                <ListItemText primary={`Services (${servicesList.filter(service => service).length})`} />
              </ListItemButton>
              <IconButton
                onClick={(e) => {
                  addServiceToProject();
                }}
                sx={styles.iconButton[2]}
                aria-label="add service to project"
              >
                <AddCircleIcon color="secondary"/>
              </IconButton>
            </Box>
            <Box sx={styles.box[2]}>
              <Collapse in={servicesOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {servicesList.filter(service => service).length > 0 ? (
                    servicesList.filter(service => service).map((service) => {
                      return (
                        <Box
                          key={service.id}
                          sx={styles.box[3]}
                        >
                          <ListItemText primary={service.name} />
                        </Box>
                      );
                    })
                  ) : (
                    <ListItemText>No services found</ListItemText>
                  )}
                </List>
              </Collapse>
            </Box>
          </Box>

          <Box>
            {/* USERS */}
            
            <Box
              sx={styles.box[1]}
            >
              <ListItemButton
                onClick={(e) => {
                  setUsersOpen(!usersOpen);
                }}
              >
                {usersOpen ? <ExpandMore /> : <ChevronRightIcon />}
                <ListItemText primary={`Users (${usersList.length})`} />
              </ListItemButton>
              <IconButton
                onClick={(e) => {
                  addUserToProject();
                }}
                sx={styles.iconButton[1]}
                aria-label="add user to project"
              >
                <AddCircleIcon color="secondary"/>
              </IconButton>
            </Box>
            <Box sx={styles.box[2]}>
              <Collapse in={usersOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  { usersList !== undefined && usersList.length > 0 ? (
                    
                    usersList.map((user) => {
                      if(user !== undefined){
                        return (
                          <Box
                            key={user.id}
                            sx={styles.box[3]}
                          >
                            <ListItemText primary={user.name} />
                          </Box>
                        );
                      }
                    })
                  ) : (
                    <ListItemText>No users found</ListItemText>
                  )}
                </List>
              </Collapse>
            </Box>
          </Box>

        {/* 
          <Box>
            <Box
              sx={styles.box[4]}
            >
              <ListItemButton
                onClick={(e) => {
                  setViewOnlyUsersOpen(!viewOnlyUsersOpen);
                }}
              >
                {viewOnlyUsersOpen ? <ExpandMore /> : <ChevronRightIcon />}
                <ListItemText primary={`View Only Users (${viewOnlyUsersList?.length ? viewOnlyUsersList?.length : 0})`} />
              </ListItemButton>
              <IconButton
                onClick={(e) => {
                  addViewOnlyUserToProject();
                }}
                sx={styles.iconButton[2]}
                aria-label="add view only user to project"
              >
                <AddCircleIcon color="secondary"/>
              </IconButton>
            </Box>
            <Box sx={styles.box[2]}>
              <Collapse in={viewOnlyUsersOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  { viewOnlyUsersList?.filter(user => user).length > 0 ? (
                    viewOnlyUsersList?.filter(user => user).map( user => {
                      return (
                        <Box
                          key={user.id}
                          sx={styles.box[3]}
                        >
                          <ListItemText primary={user.name} />
                        </Box>
                      );
                    })
                  ) : (
                    <ListItemText>No users found</ListItemText>
                  )}
                </List>
              </Collapse>
            </Box>
          </Box> */}


        </Card>

          <Contained_btn
            sx={styles.btn}
            color="secondary"
            disabled={projectModified === false}
            type="submit"
            variant="contained"
            onClick={submitChanges}
          >
            {isUpdatingProject === false ? (

              <span>Submit</span>

            ) : (

              <CircularProgress size={24} color="white" />

            )}
        </Contained_btn>

    </Drawer>
  );
};

const styles = {
  card:{
    alignSelf: "center",
    width: 310,
    border: "1px solid #F15A29 !important",
    paddingBottom: "1rem",
    marginBottom: "1rem"
  },
  drawerHeader: {
    backgroundColor: "#000",
    color: "#fff",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  iconButton: {
    1: { color: "#fff", textAnchor: "right" },
    2: { marginRight: "4rem" },
  },
  cancelIcon: { textAlign: "right", color: "#fff" },
  borderColor: {
    flame: {borderColor: "#F15A29"}
  },
  textField: {
    1: { marginBottom: "1rem", marginTop: "2rem", alignSelf: "center", width: "310px" },
  },
  box: {
    1: {
      display: "flex",
      flexDirection: "row",
    },
    2: { marginLeft: "4rem" },
    3: { display: "flex", flexDirection: "row" },
    4: {
      display: "flex",
      flexDirection: "row",
    }

  },
  circularProgress: { marginRight: "5rem" },
  BGcolor: {
    flame: {color: "#F15A29"}
  },
  btn: { width: "310px", marginTop: "1rem", alignSelf: "center" },
  color: {
    white: {color: "white"}
  },
  marginRight: { marginRight: "5rem" },
  marginRight4: { marginRight: "4rem"}
};
