import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from 'react';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Loading from './Loading';
import { styled } from "@mui/material/styles";

const MaterialsContainer = (props) =>{

    const groupBy =  (xs, key) => {
      return xs.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    const {materials, serviceParts, currentMaterials} = props;
    const [orderedSet, setOrderedSet] = useState(groupBy(props.materials.data.filter((ele, ind) => ind === materials.data.findIndex(elem => elem.Name === ele.Name  && elem.Group === ele.Group) && !ele.IsInsulationMaterial), 'Type'));
    var checkedState = useRef([])
    const [expanded, setExpanded] = React.useState(false);
    const [selectedMaterials, setSelectedMaterials] = useState({materials: []})

    useEffect(() =>{
        let pp = materials.data.filter((ele, ind) => ind === materials.data.findIndex(elem => elem.Name === ele.Name && elem.Group === ele.Group) && !ele.IsInsulationMaterial)
        let newArray = []
        let newOrderedSet = []
        newOrderedSet = groupBy(pp, 'Type')
        let pp2 = materials.data.filter((ele, ind) => ind === materials.data.findIndex(elem => elem.Name === ele.Name && elem.Group === ele.Group) && ele.IsInsulationMaterial)
        if (pp2 !== []){
          newOrderedSet["Insulation"] = pp2
          orderedSet["Insulation"] = pp2
        }
        setOrderedSet(newOrderedSet)
        Object.keys(newOrderedSet).map((group) =>{
            return newArray.push(new Array(newOrderedSet[group].length).fill(false))
        })
        checkedState.current = newArray
        checkedState.current = checkedState.current

        let existingSpecifications = [];
      if(Array.isArray(currentMaterials.data)){
        
        currentMaterials.data.map((spec) => {
          if(existingSpecifications.indexOf(spec.FabId) < 0){
            existingSpecifications.push(spec.FabId);
          }
        })
      }
      
      Object.keys(orderedSet).map((group) =>{
        orderedSet[group].map((spec, index) => {
          if(Array.isArray(currentMaterials.data)){
            if(existingSpecifications.indexOf(spec.FabId) >= 0){
              checkMaterial(index, group)
            } 
          }
        })
      })
    }, [props.materials, props.config, serviceParts, currentMaterials])

    useEffect(() => {

    }, [orderedSet, checkedState])

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    const checkMaterial = (position, group) => {
      const index = Object.keys(orderedSet).indexOf(group);
      if(checkedState.current[index] !== undefined){
        const updatedCheckedState = checkedState.current[index].map((item, idx) =>
          idx === position ? !item : item
        );
        
        checkedState.current[index] = updatedCheckedState
        checkedState.current = checkedState.current
        if(updatedCheckedState.at(position))
          addSelectedMaterial(position, group);
        else
          removeSelectedMaterial(position, group); 
      }      
      
    };

    const removeSelectedMaterial = (position, group) =>{
      let connTypes = selectedMaterials.materials
      let typeToRemove = connTypes.indexOf(orderedSet[group].at(position))
      connTypes.splice(typeToRemove, 1);
      setSelectedMaterials({materials: Array.from(new Set(connTypes))})
      props.setSelectedMaterialsMain({materials: Array.from(new Set(connTypes))})
      if(props.config)
        props.config.connectionTypes = connTypes
    }

    const addSelectedMaterial = (position, group) =>{
      let connTypes = selectedMaterials.materials
      if(connTypes.indexOf(JSON.parse(JSON.stringify(orderedSet[group].at(position)))) < 0){
        connTypes.push(orderedSet[group].at(position))
      }
      setSelectedMaterials({materials: Array.from(new Set(connTypes))})
      if(props.config)
        props.config.connectionTypes = connTypes
      props.setSelectedMaterialsMain({materials: Array.from(new Set(connTypes))})
    }

    const getNameByType = (type) =>{
      if(type === "Insulation"){
        return "Insulation"
      }
      else if(Number(type) === 0){
        return "Pipe"
      }else if(Number(type) === 1){
        return "Duct"
      }else if(Number(type) === 9){
        return "Insulation"
      }
    }
    return(
            < Box >
                {
                  !materials.isLoading  && materials.data !== [] && (orderedSet !== undefined) ? 
                  Object.keys(orderedSet).map((group, index) => (
                    
                    (orderedSet[group].length !== 0) ? (<Box key={index} ref={React.createRef()} sx={{marginLeft: "1rem !important"}}>
                    <Accordion expanded={expanded === index} onChange={handleChange(index)} elevation={false}>
                      <AccordionSummary
                        ref={React.createRef()}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography_ 
                          noWrap={true}
                          ref={React.createRef()} 
                          //sx={styles.typography}
                        >
                          { getNameByType(group)}
                        </Typography_>
                      </AccordionSummary>
                      <AccordionDetails>
                        <List> 
                          {
                            checkedState.current !== [] &&
                            orderedSet[group].map((type, idx) => {
                              const index = Object.keys(orderedSet).indexOf(group);
                              if(checkedState.current[index] && Array.isArray(checkedState.current[index])){
                                return (
                                  <ListItem key={idx}>
                                    <ListItemText key={idx} primary={type.Name} />
                                    <ListItemIcon>
                                      <Checkbox 
                                        sx={{marginLeft: ".5rem"}}
                                        checked={checkedState.current[index][idx]}
                                        onChange={() => checkMaterial(idx, group)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                      />
                                    </ListItemIcon>
                                  </ListItem>
                                )
                              }   
                            })
                          }
                        </List>
                        
                      </AccordionDetails>
                    </Accordion>
                  </Box>) : null
                    )) : <Loading msg="Loading Materials"></Loading>
                }  
          </ Box >
    )
};

const styles = {
  typography: { width: '75%', flexShrink: 0 },
};

const Typography_ = styled(Typography)`
  //width: 75% !important; 
  flex-shrink: 0 !important;
`;

export default MaterialsContainer;