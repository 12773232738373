import { Paper, Typography } from "@mui/material";

import { AmplifyButton } from "@aws-amplify/ui-react";

/** Panel for bottom of Signup screen - provides a signup button
 * 
 * @param {*} props 
 * @returns 
 */
const PilotPanel = props => {

  const open = () => {

    props.setIsOpen(true);

  };

    return(
      <Paper variant="outlined" sx={{alignContent: 'center', padding: '1rem'}} >
        <Typography variant='body1'>Field Orderz Pilot Program</Typography>
        <Typography variant='body2' sx={{color: ' rgba( 0, 0, 0, .3 ) '}}>Field Orderz Pilot Program Application</Typography>
  
        <AmplifyButton 
          onClick={ open } 
          sx={ styles.signUpbtn }
        >
          <Typography variant='button'>Sign Up</Typography>
        </AmplifyButton>
      </Paper>
    );
};

const styles = {
    signUpbtn: {
        backgroundColor:'#FF9900', 
        padding: 12, 
        color: '#fff', 
        maxWidth: 153,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '.25rem',
        marginTop: '.5rem'
      }
}

export default PilotPanel;