import 'typeface-roboto';

import { Box, Button } from "@mui/material";
import { FOAppBar, FONavDrawer } from "./Navigation";
import { Navigate, Route, Routes } from "react-router-dom";
import { QueryClientProvider, useQuery, useQueryClient } from "react-query";
import React, { createContext, useContext, useEffect, useReducer, useRef, useState } from "react";
import {
  catalogPartDrawerOpen,
  closeCatalogImportDialog,
  closeCreateCatalogPartDrawer,
  closeCreateUserDrawer,
  closeOrdersDrawer,
  closeProjectsDrawer,
  closeServicesConfigDrawer,
  closeServicesDrawer,
  closeUsersDrawer,
  ordersDrawerOpen,
  projectsDrawerOpen,
  servicesConfigDrawerOpen,
  usersDrawerOpen
} from "../Context/DrawerStates";
import {
          getCatalogItems,
          getCompanyExpirationDate,
          getProjects,
          getServiceConnectors,
          getServiceCurrentConnectors,
          getServiceCurrentMaterials,
          getServiceCurrentSpecifications,
          getServiceGroups,
          getServiceItems,
          getServiceMaterials,
          getServiceSpecifications,
          getUsersWithCognitoData,
} from "../data/queries/queryAPI";
import { getUserPermissions, initUserPermissions } from "../Context/currentUser";
import { setCurrentUser, set_currentUser_viewOnlyProjects, use_currentUser_viewOnlyProjects } from "../Context/currentUser";
import {theme, theme_v4} from "./Theme/Theme";
import {
  useAPICallback,
  useAPIData,
  useAPIEffect,
} from "../data/hooks/customHooks";

import { AccountInfoContext } from "../Context/AccountContext";
import { CatalogImportDialog } from "./Catalogs/components/CatalogImportDialog";
import { Catalogs } from "./Catalogs/Catalogs";
import { Company } from "./Company/Company";
import { Container } from "@mui/material";
import { CreateCatalogPartDrawer } from "./Catalogs/components/CreateCatalogPartDrawer";
import { CreateUserDrawer } from "./Users/components/CreateUserDrawer";
import { Divisions } from "./Divsions/Divisions";
import { DuctConfig } from "../DuctConfig"
import  ExpireDialog  from './Login/Pilot/ExpireDialog';
import { GlobalStyles } from '@mui/system';
import { Orders } from "./Packages/Orders";
import { OrdersDrawer } from "./Packages/components/OrdersDrawer";
import { ProjectCatalogDialog } from "./Projects/components/ProjectCatalogDialog";
import { ProjectServicesDialog } from "./Projects/components/ProjectServicesDialog";
import { ProjectUserDialog } from "./Projects/components/ProjectUserDialog";
import { Projects } from "./Projects/Projects";
import { ProjectsDrawer } from "./Projects/components/ProjectsDrawer";
import { ServiceItemDrawer } from "./Services/components/ServiceItemDrawer";
import { Services } from "./Services/Services";
import ServicesConfigDrawer from "./Services/components/ServicesConfigDrawer";
import { Settings } from './Settings/Settings';
import SuperAdminPanel from './SuperAdmin/SuperAdminPanel';
import { ThemeProvider } from "@mui/material/styles";
import { Users } from "./Users/Users";
import { UsersDrawer } from "./Users/components/UsersDrawer";
import { setOrdersSelectionModel } from "../Context/DrawerStates";

// import { oldOrders } from "./Orders/Orders";

export const ViewOnlyUser = createContext(false);


export const Main = (props) => {

  // MANAGE COGNITO USER
  const [account, setAccount] = useContext(AccountInfoContext);
  const user = props.cognitoUser;
  setCurrentUser(props.cognitoUser);
  const queryClient = useQueryClient()
  const userPermissions_key = "JxXSoSKKP^N9tot9x4qDEGZZ%L2t^vYS";
  initUserPermissions(userPermissions_key, user.signInUserSession.idToken.payload["cognito:groups"]);
  const userPermissions = getUserPermissions(userPermissions_key);

  const [admin, setAdmin] = useState(userPermissions.includes("AdminUsers"));
  const [superAdmin, setSuperAdmin] = useState(userPermissions.includes("SuperAdmins"));
  const [servicePermissions, isServicePermissions] = useState(userPermissions.includes("ServiceCatalogEditors"));
  const [projectEditor, isProjectEditor] = useState(userPermissions.includes("ProjectEditors"));
  
  console.log("Currently signed in with as", user);

  // HANDLE CATALOGS DRAWER STATE VARS
  const [catalogItemForDrawer, setCatalogItemForDrawer] = useState({});
  const [catalogForDrawer, setCatalogForDrawer] = useState("");
  const [catalogDrawerOpen, setCatalogDrawerOpen] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [deleting, setDeleting] = useState(false);

  // HANDLE ORDERS DRAWER STATE VARS
  //const [packages, setPackages] = useState([]);
  //const [selectionModel, setSelectionModel] = useState([]);
  const [projectId, setProjectId] = useState(null);
  const [navDrawerShift, setNavDrawerShift] = useState(false);

  //const [ordersDrawerKey, setOrdersDrawerKey] = useState(0);
  const handleCloseOrdersDrawer = () => {

    //setOrdersSelectionModel([]);

    closeOrdersDrawer();

  };
  const companyExpirationQuery = useQuery(["companyExpiration"], () => getCompanyExpirationDate(user.attributes["custom:companyId"]), {
    staleTime: 900000,
    refetchOnMount: 'always',
    refetchOnReconnect: 'always'
  })

  // HANDLE PROJECTS DRAWER STATE VARS
  const projectsQuery = useQuery(["allProjects"], () => getProjects(), {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
  const [projectForDrawer, setProjectForDrawer] = useState({});
  const [projectsDrawerKey, setProjectsDrawerKey] = useState(1);
  const getProjectDetails = (projectId) => {
    return projectsQuery.data?.data.find((project) => project.id === projectId);
  };

  // HANDLE SELECTION DIALOGS ON PROJECTS PAGE
  const [projectForProjectDialogs, setProjectForProjectDialogs] = useState({});
  const [catalogBrowserDialogOpen, setCatalogBrowserDialogOpen] =
    useState(false);
  const [projectUserDialogOpen, setProjectUserDialogOpen] = useState(false);
  const [projectViewOnlyUser, setProjectViewOnlyUser] = useState(false);
  const [projectServiceDialogOpen, setProjectServiceDialogOpen] =
    useState(false);

  // HANDLE USER DRAWER & CREATE USER DRAWER STATE VARS
  const usersQuery = useQuery(
    ["allDetailedUsers"],
    () => getUsersWithCognitoData(),
    {
      staleTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
  const [userForDrawer, setUserForDrawer] = useState({});
  const [usersDrawerKey, setUsersDrawerKey] = useState(2);
  const getUserDetails = (userId) => {
    return usersQuery?.data?.find((user) => user?.id === userId);
  };

  // RETRIEVES AND SETS CONTEXT TO THE CURRENT USERS VIEWONLY PROJECTS
  const handleViewOnly = async () => {

    const usr = await getUserDetails( user?.attributes?.sub )
    set_currentUser_viewOnlyProjects( usr?.viewOnly );
  };

  handleViewOnly();

  function isExpired(date){
    const today = new Date();
    return date < today
  }

  const [ isExpireOpen, setIsExpireOpen ] = useState(false);

  useEffect(() => {
    if (companyExpirationQuery?.data?.data?.expirationDate){
      setIsExpireOpen(isExpired(companyExpirationQuery?.data?.data?.expirationDate))
    }
  }, [companyExpirationQuery])

  //HANDLE HAMBURGER MENU
  const [navMenuOpen, setNavMenuOpen] = useState(false);

  const styles = {
    container: {
      backgroundColor: "#EEEEEE",
      padding: "0px",
      margin: "0px",
      height: "100vh",
      width: "100vw",
    }
  };

  if (user.attributes) {
    return (
      <ThemeProvider theme={theme}>
        <GlobalStyles
          styles={{
            html: { margin: 0, padding: 0, width: '100%', height: '100%' },
            body: { margin: 0, padding: 0, width: '100%', height: '100%' },
            '#root': { margin: 0, padding: 0, width: '100%', height: '100%' },
          }}
        />
        < FOAppBar 
          handleDrawerOpen={(e) => { setNavMenuOpen(true); setNavDrawerShift(true); }}
          setAccount={setAccount}
        />
        <FONavDrawer
          admin={admin}
          superAdmin={superAdmin}
          servicePermissions={servicePermissions}
          projectEditor={projectEditor}
          account={account}
          setAccount={setAccount}
          open={navMenuOpen}
          handleDrawerClose={ (e) => { setNavMenuOpen(false); setNavDrawerShift(false); }}
          handleDrawersCloseToDivision = {(e) =>
            {
              setNavMenuOpen(false);
              if(window.location.pathname.includes("users")){
                closeUsersDrawer();
              }
            }}
            handleDrawersCloseToUsers = {(e) =>
              {
                setNavMenuOpen(false);
                if(window.location.pathname.includes("divisions"))
                {
                  closeUsersDrawer();
                }
              }}
        />
        <Box sx={{ marginLeft: navDrawerShift ? "240px !important" : "0px !important" }}>

          <ProjectsDrawer
            key={projectsDrawerKey}
            open={projectsDrawerOpen}
            closeDrawer={closeProjectsDrawer}
            hideBackdrop={true}
            project={getProjectDetails(projectForDrawer)}
            setProjectForProjectDialogs={setProjectForProjectDialogs}
            setProjectCatalogDialogOpen={setCatalogBrowserDialogOpen}
            setProjectUserDialogOpen={setProjectUserDialogOpen}
            setProjectServiceDialogOpen={setProjectServiceDialogOpen}
            setProjectViewOnlyUser={setProjectViewOnlyUser}
          />
          <ProjectCatalogDialog
            projectCatalogDialogOpen={catalogBrowserDialogOpen}
            setProjectCatalogDialogOpen={setCatalogBrowserDialogOpen}
            project={projectForProjectDialogs}
          />
          <ProjectUserDialog
            projectUserDialogOpen={projectUserDialogOpen}
            setProjectUserDialogOpen={setProjectUserDialogOpen}
            project={projectForProjectDialogs}
            projectViewOnlyUser={projectViewOnlyUser}
            setProjectViewOnlyUser={setProjectViewOnlyUser}
          />
          <ProjectServicesDialog
            projectServiceDialogOpen={projectServiceDialogOpen}
            setProjectServiceDialogOpen={setProjectServiceDialogOpen}
            project={projectForProjectDialogs}
          />
          <CreateUserDrawer key={"3"} Button={Button} />
          <UsersDrawer
            key={usersDrawerKey}
            open={usersDrawerOpen}
            closeDrawer={closeUsersDrawer}
            hideBackdrop={true}
            user={getUserDetails(userForDrawer)}
          />
          <ExpireDialog
            setIsOpen={value => setIsExpireOpen(value)}
            isOpen={isExpireOpen}
            setAccount={setAccount}
          />
          <Routes>
          
            {

              //superAdmin &&   
              true &&

              <Route
                path="/adminPanel"
                element={<SuperAdminPanel/>}
              />

            }

            <Route path="/" element={<Navigate to="/packages" replace />} />

            { admin ?
            <>
              <Route
                path="/company/divisions"
                element={
                  <Divisions
                    setCatalogBrowserDialogOpen={setCatalogBrowserDialogOpen}
                    setCatalogDrawerOpen={setCatalogDrawerOpen}
                    setProjectServiceDialogOpen={setProjectServiceDialogOpen}
                    setProjectUserDialogOpen={setProjectUserDialogOpen}
                    closeCreateCatalogPartDrawer={closeCreateCatalogPartDrawer}
                    closeCatalogImportDialog={closeCatalogImportDialog}
                    handleCloseOrdersDrawer={handleCloseOrdersDrawer}
                    closeServicesDrawer={closeServicesDrawer}
                    closeCreateUserDrawer={closeCreateUserDrawer}
                    closeUsersDrawer={closeUsersDrawer}
                    setUserForDrawer={setUserForDrawer}
                    closeProjectsDrawer={closeProjectsDrawer}
                  />
                }
              />
              <Route
                path="/company/general"
                element={
                  <Company
                    setCatalogBrowserDialogOpen={setCatalogBrowserDialogOpen}
                    setCatalogDrawerOpen={setCatalogDrawerOpen}
                    setProjectServiceDialogOpen={setProjectServiceDialogOpen}
                    setProjectUserDialogOpen={setProjectUserDialogOpen}
                    closeCreateCatalogPartDrawer={closeCreateCatalogPartDrawer}
                    closeCatalogImportDialog={closeCatalogImportDialog}
                    handleCloseOrdersDrawer={handleCloseOrdersDrawer}
                    closeServicesDrawer={closeServicesDrawer}
                    closeCreateUserDrawer={closeCreateUserDrawer}
                    closeUsersDrawer={closeUsersDrawer}
                    companyId={user.attributes["custom:companyId"]}
                    companyName={user.attributes["custom:companyName"]}
                    stratusEnabled={user.attributes["custom:stratusEnabled"]}
                    name={user.attributes["name"]}
                    closeProjectsDrawer={closeProjectsDrawer}
                  />
                }
              />
              <Route
                path="/company/settings"
                element={

                      <Settings
                        setCatalogBrowserDialogOpen={setCatalogBrowserDialogOpen}
                        setCatalogDrawerOpen={setCatalogDrawerOpen}
                        setProjectServiceDialogOpen={setProjectServiceDialogOpen}
                        setProjectUserDialogOpen={setProjectUserDialogOpen}
                        closeCreateCatalogPartDrawer={closeCreateCatalogPartDrawer}
                        closeCatalogImportDialog={closeCatalogImportDialog}
                        handleCloseOrdersDrawer={handleCloseOrdersDrawer}
                        closeServicesDrawer={closeServicesDrawer}
                        closeCreateUserDrawer={closeCreateUserDrawer}
                        closeUsersDrawer={closeUsersDrawer}
                        closeProjectsDrawer={closeProjectsDrawer}
                        companyId={user.attributes["custom:companyId"]}
                      />

                }
              />
              <Route
                path="/users"
                element={
                  <Users
                    setCatalogBrowserDialogOpen={setCatalogBrowserDialogOpen}
                    setCatalogDrawerOpen={setCatalogDrawerOpen}
                    setProjectServiceDialogOpen={setProjectServiceDialogOpen}
                    setProjectUserDialogOpen={setProjectUserDialogOpen}
                    closeCreateCatalogPartDrawer={closeCreateCatalogPartDrawer}
                    closeCatalogImportDialog={closeCatalogImportDialog}
                    handleCloseOrdersDrawer={handleCloseOrdersDrawer}
                    closeServicesDrawer={closeServicesDrawer}
                    closeCreateUserDrawer={closeCreateUserDrawer}
                    closeUsersDrawer={closeUsersDrawer}
                    setUserForDrawer={setUserForDrawer}
                    setDrawerKey={setUsersDrawerKey}
                    companyId={user.attributes["custom:companyId"]}
                    closeProjectsDrawer={closeProjectsDrawer}
                  />
                }
              />
              </>
              : null
            }
            <Route
              path="/packages"
              element={

                    <Orders
                      setCatalogBrowserDialogOpen={setCatalogBrowserDialogOpen}
                      setCatalogDrawerOpen={setCatalogDrawerOpen}
                      setProjectServiceDialogOpen={setProjectServiceDialogOpen}
                      setProjectUserDialogOpen={setProjectUserDialogOpen}
                      closeCreateCatalogPartDrawer={closeCreateCatalogPartDrawer}
                      closeCatalogImportDialog={closeCatalogImportDialog}
                      handleCloseOrdersDrawer={handleCloseOrdersDrawer}
                      closeServicesDrawer={closeServicesDrawer}
                      closeCreateUserDrawer={closeCreateUserDrawer}
                      closeUsersDrawer={closeUsersDrawer}
                      projectId={projectId}
                      setProjectId={setProjectId}
                      companyId={user.attributes["custom:companyId"]}
                      closeProjectsDrawer={closeProjectsDrawer}
                      setUpdating={setUpdating}
                      updating={updating}
                      deleting={deleting}
                      setDeleting={setDeleting}
                      setOrdersSelectionModel={setOrdersSelectionModel}
                      user={user}
                    />

              }
            />

            { projectEditor ? 
              <>
                <Route
                  path="/projects"
                  element={
                    <Projects
                      setCatalogBrowserDialogOpen={setCatalogBrowserDialogOpen}
                      setCatalogDrawerOpen={setCatalogDrawerOpen}
                      setProjectServiceDialogOpen={setProjectServiceDialogOpen}
                      setProjectUserDialogOpen={setProjectUserDialogOpen}
                      closeCreateCatalogPartDrawer={closeCreateCatalogPartDrawer}
                      closeCatalogImportDialog={closeCatalogImportDialog}
                      handleCloseOrdersDrawer={handleCloseOrdersDrawer}
                      closeServicesDrawer={closeServicesDrawer}
                      closeCreateUserDrawer={closeCreateUserDrawer}
                      closeUsersDrawer={closeUsersDrawer}
                      setProjectForDrawer={setProjectForDrawer}
                      setDrawerKey={setProjectsDrawerKey}
                    />
                  }
                />              
              </>
            
              : null
            }
            { servicePermissions ?
              <>
                <Route
                  path="/services"
                  element={
                    <Services
                      setCatalogBrowserDialogOpen={setCatalogBrowserDialogOpen}
                      setCatalogDrawerOpen={setCatalogDrawerOpen}
                      setProjectServiceDialogOpen={setProjectServiceDialogOpen}
                      setProjectUserDialogOpen={setProjectUserDialogOpen}
                      closeCreateCatalogPartDrawer={closeCreateCatalogPartDrawer}
                      closeCatalogImportDialog={closeCatalogImportDialog}
                      handleCloseOrdersDrawer={handleCloseOrdersDrawer}
                      closeServicesDrawer={closeServicesDrawer}
                      closeCreateUserDrawer={closeCreateUserDrawer}
                      closeUsersDrawer={closeUsersDrawer}
                      closeProjectsDrawer={closeProjectsDrawer}
                      companyId={user.attributes["custom:companyId"]}
                    />
                  }
                />

                <Route
                  path="/catalogs"
                  element={
                    <Catalogs
                      setCatalogBrowserDialogOpen={setCatalogBrowserDialogOpen}
                      setCatalogDrawerOpen={setCatalogDrawerOpen}
                      setProjectServiceDialogOpen={setProjectServiceDialogOpen}
                      setProjectUserDialogOpen={setProjectUserDialogOpen}
                      closeCreateCatalogPartDrawer={closeCreateCatalogPartDrawer}
                      closeCatalogImportDialog={closeCatalogImportDialog}
                      handleCloseOrdersDrawer={handleCloseOrdersDrawer}
                      closeServicesDrawer={closeServicesDrawer}
                      closeCreateUserDrawer={closeCreateUserDrawer}
                      closeUsersDrawer={closeUsersDrawer}
                      setCatalogItemForDrawer={setCatalogItemForDrawer}
                      setCatalogForDrawer={setCatalogForDrawer}
                      closeProjectsDrawer={closeProjectsDrawer}
                    />
                  }
                />              
              </>
              : null

            }

            <Route path="/configure" element={<DuctConfig />} />
          </Routes>
        </Box>
      </ThemeProvider>
    );
  } else {
    return "Error loading user";
  }

};
