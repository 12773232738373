import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import {Contained_btn, Outlined_btn, Text_btn} from "components/Theme/Styled";
import React, { useEffect, useState } from "react";
import {
  closeProjectRenameDialog,
  projectRenameDialogOpen
} from "../../../Context/DrawerStates";
import { useMutation, useQuery, useQueryClient } from "react-query";

import clone from "just-clone";
import { renameProject } from "data/queries/queryAPI";

export const RenameForm = (props) => {
  const [open, setOpen] = React.useState(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const queryClient = useQueryClient();

  const { mutate: renameItem } = useMutation(renameProject, {
    onSettled: async (data) => {
      queryClient
        .refetchQueries(["allProjects"])
        .then(() => {
          setIsRenaming(false);
          props.setNewProjectName(" ")
        })
        .then(() => {
          handleClose();
        })
        .catch((error) => {
          console.error("error renaming project", error);
        });
    },
    onError: (error) => {
      console.error("error renaming project(s)", error);
      alert("An error occurred while renaming a project: ", error);
    },
  });

  const handleClickOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = (e) => {
    closeProjectRenameDialog();
    setOpen(false);
  };

  const handleRename = () => {
    try {
      // Extract projects from props
      const allProjects = props.projects;
      
      // Take the new name for the project
      const newName = props.newProjectName.trim();
      
      // If the name hasn't changed, exit early
      if (newName === props.project.name) {
        setIsRenaming(false);
        setOpen(false);
        handleClose();
        return;
      }
    
      // Find projects with names that match or are similar to the new name
      const similarProjects = allProjects.filter(project => 
        project.name === newName || project.name.startsWith(`${newName} (`)
      );
      
      // Determine the unique identifier to append to the new project's name, if necessary
      let uniqueIdentifier = "";
      if (similarProjects.length > 0) {
        const counts = similarProjects.map(project => {
          const match = project.name.slice(newName.length).match(/\((\d+)\)$/);
          return match ? parseInt(match[1], 10) : 0;
        });
        const maxCount = Math.max(...counts);
        uniqueIdentifier = `(${maxCount + 1})`;
      }
    
      const finalName = `${newName} ${uniqueIdentifier}`.trim();
    
      // Clone the project and rename it
      const projectClone = clone(props.project);
      projectClone.name = finalName;
    
      renameItem({
        projectId: props.project.id,
        data: {
          ...projectClone,
        },
      });
    
      setIsRenaming(true);
      setOpen(false);
  
    } catch (error) {
      console.error("Error while renaming project: " + error.message);
    }
  };



  return (
    <>
        <Dialog open={projectRenameDialogOpen.use()} onClose={handleClose}>
        <Box sx={{backgroundColor: '#F15A19', color: '#fff', display: 'flex', justifyContent: 'left', alignItems: 'center', height: '60px'}}>
          <Box sx={{marginLeft: '2rem'}}>
            <Typography variant="h5" >Rename Project</Typography>
          </Box>
        </Box>
          <DialogContent>
            <TextField
              autoFocus
              id="name"
              label="New Project Name"
              type="text"
              sx={{width: '250px'}}
              variant="outlined"
              value={props.newProjectName}
              onChange={ (e) => props.setNewProjectName(e.target.value)}
              autoComplete='off'
            />
          </DialogContent>
          <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '1rem'}}>
            <Box sx={{marginRight: '1rem'}}>
            <Outlined_btn
              onClick={
                (e) => {
                  handleClose(e)
                  props.setNewProjectName(" ")
                }}
            >
              Cancel
            </Outlined_btn>
            </Box>
            <Box sx={{marginLeft: '1rem'}}>
            <Contained_btn
              onClick={handleRename}>
              Rename
              {isRenaming ? <CircularProgress size={12} color='white'/> : null}
            </Contained_btn>
            </Box>
          </Box>
        </Dialog>
    </>
  );
};
