import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from "@mui/material";
import { Contained_btn, Outlined_btn } from "components/Theme/Styled";
import React, { useContext, useEffect, useState } from "react";
import {
    closeServicesRefreshDialog,
    servicesRefreshDialogOpen
} from "../../../Context/DrawerStates";
import { servicesImportIncrement, servicesImportProgress } from "../../../Context/componentStates";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { TableContext } from "./ServicesTable";
import { Text_btn } from "components/Theme/Styled";
import { importStratusServicesArray } from "../../../data/queries/queryAPI";
import {styled} from "@mui/material/styles";

export const ServicesRefreshDialog = (props) => {
    const [open, setOpen] = useState(false);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [dataForDisplay, setDataForDisplay] = useState("");

    const queryClient = useQueryClient();

    useEffect(()=> {
      setDataForDisplay(props?.dataForDisplay)
    }, [props])

    const handleClose = () => {
      closeServicesRefreshDialog();
      setOpen(false);
      setIsRefreshing(false);
    }

    const handleConfirmation = () => {
      props.refreshServices();
      closeServicesRefreshDialog();
      setOpen(false);
    }

      
    const Bold = styled('span')`
    font-weight: bold;
  `;

    return(
        <>
        {/* CHANGE DIALOG OPEN PROP*/}
        <Dialog open={servicesRefreshDialogOpen.use()} onClose={handleClose}>
          <DialogTitle color="secondary" ><Bold>WARNING</Bold></DialogTitle>
          <DialogContent>
            {/* TODO::: SWITCH TO TYPOGRAPHY COMPONENT ? */}
            <Content_>
              <Bold>WARNING:</Bold>
              Refreshing Services will take an extended amount of time.
            </Content_>
          </DialogContent>
          <DialogActions>
            <Outlined_btn
              onClick={(e) => handleClose(e)}
            >
              Cancel
            </Outlined_btn>
            <Contained_btn

              onClick={handleConfirmation}
            >
              Refresh
              {isRefreshing ? <Progress_ size={12} /> : null}
            </Contained_btn>
          </DialogActions>
        </Dialog>
    </>
    )
};

const Progress_ = styled(CircularProgress)`
color: white !important;
margin-left: 6px;

`;

const Content_ = styled('p')`
font-size: 18px;
`;
