import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import { getUsers } from "../../../data/queries/queryAPI";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";

export const CollapseUsers = (props) => {
  const [usersList, setUsersList] = useState([]);
  const usersQuery = useQuery(["allUsers"], () => getUsers(), {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    const collector = props.users.map((userId) => {
      return usersQuery.data.data.find((userObj) => {
        return userObj.id === userId;
      });
    });

    const alphabetized = collector.sort((a, b) => {
      if (a.name.toUpperCase() === b.name.toUpperCase()) {
        return 0;
      }
      return a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1;
    });

    setUsersList(alphabetized);
  }, [usersQuery.data, props.users]);

  return (
    <Box sx={styles.box[1]}>
      <Collapse in={props.usersOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {usersList.length > 0 ? (
            usersList.map((user) => {
              return <ListItemText key={user.id} primary={user.name} />;
            })
          ) : (
            <Typography variant="body">No users assigned</Typography>
          )}
        </List>
      </Collapse>
    </Box>
  );
};

const styles = {
  box: {
    1: { marginLeft: "4rem" }
  }
};