import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ErrorDialog from "components/Utilities/ErrorDialog";
import TextField from "@mui/material/TextField";
import { Text_btn } from "components/Theme/Styled";
import { renameService } from "data/queries/queryAPI";

export const RenameForm = (props) => {
  const queryClient = useQueryClient();
  const [isRenaming, setIsRenaming] = useState(false);
  const [newName, setNewName] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const { mutate: renameItem } = useMutation(renameService, {
    onSettled: async (data) => {
      await data.json();
      queryClient
        .refetchQueries(["allServices"])
        .then(() => {
          props.setRenameFormOpen(false);
          setIsRenaming(false);
        })
        .catch((error) => {
          setErrorMessage(error.message || "An error occurred");
          setDialogOpen(true);
        });
    },
    onError: (error) => {
      setErrorMessage(error.message || "An error occurred");
      setDialogOpen(true);
    },
  });

  const handleRename = () => {
    renameItem({
      serviceId: props.serviceId,
      data: {
        nameOverride: newName,
      },
    });
    setIsRenaming(true);
    setNewName(" ")
  };

  return (
    <>
      <Dialog
        open={props.renameFormOpen}
        onClose={(e) => props.setRenameFormOpen(false)}
        fullwidth
      >
        <DialogTitle>Rename Service</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label=" New Service Name:"
            type="text"
            fullwidth
            variant="standard"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Text_btn
            onClick={(e) => {
              props.setRenameFormOpen(false)
              setNewName(" ")
            }}
          >
            Cancel
          </Text_btn>
          {isRenaming === false ? (
            <Text_btn
              onClick={handleRename}
            >
                Rename
            </Text_btn>
          ) : (
            <CircularProgress size={24} />
          )}
        </DialogActions>
      </Dialog>
    <ErrorDialog open={dialogOpen} handleClose={handleCloseDialog} errorMessage={errorMessage} />
    </>
  );
};
