import React, { useContext, useEffect, useState } from "react";
import {
  convertServiceToCatalog,
  createServiceConfigurations,
  deleteService,
} from "data/queries/queryAPI";
import {
  getCatalogItems,
  getProjects,
  getServiceConnectionTypes,
  getServiceCurrentConnectionTypes,
  getServiceCurrentMaterials,
  getServiceCurrentSpecifications,
  getServiceGroups,
  getServiceItems,
  getServiceMaterials,
  getServiceSpecifications,
  getUsersWithCognitoData
} from "../../../../data/queries/queryAPI";
import { useMutation, useQuery, useQueryClient } from "react-query";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import CircularProgress from "@mui/material/CircularProgress";
import { Outlined_btn } from "components/Theme/Styled";
import {openConfigureConnectionTypeDialog} from "../../../../Context/DrawerStates"

export const ServiceConfigurationConnectorsPopoverButtons = (props) => {
  // const [isDuplicating, setIsDuplicating] = useState(false);
  const handleOpenRenameForm = (e) => {
    e.stopPropagation();
    props.setSelectedConnectionType(props.connectionType)
    openConfigureConnectionTypeDialog()
  };

  return (
      <ButtonGroup
        orientation="vertical"
        aria-label="vertical outlined button group"
      >
        <Outlined_btn
          //color="secondary"
          onClick={(e) => handleOpenRenameForm(e)}
        >
          Rename
        </Outlined_btn>
      </ButtonGroup>
  );
};

const styles = {
  marginLeft: { marginLeft: "1rem" },
};
