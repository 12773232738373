import React, { useContext, useEffect, useState } from "react";
import {
  convertServiceToCatalog,
  createServiceConfigurations,
  deleteService,
} from "data/queries/queryAPI";
import {
  getCatalogItems,
  getProjects,
  getServiceConnectionTypes,
  getServiceCurrentConnectionTypes,
  getServiceCurrentMaterials,
  getServiceCurrentSpecifications,
  getServiceGroups,
  getServiceItems,
  getServiceMaterials,
  getServiceSpecifications,
  getUsersWithCognitoData,
} from "../../../data/queries/queryAPI";
import {
  useAPICallback,
  useAPIData,
  useAPIEffect,
} from "./../../../data/hooks/customHooks.js";
import { useMutation, useQuery, useQueryClient } from "react-query";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorDialog from "components/Utilities/ErrorDialog";
import { Outlined_btn } from "components/Theme/Styled";

export const PopoverButtons = (props) => {
  // const [isDuplicating, setIsDuplicating] = useState(false);
  const [isConverting, setIsConverting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const queryClient = useQueryClient();
  const [serviceData, setServiceData] = useState(props.serviceData);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };


  const { mutate: convertItem } = useMutation(convertServiceToCatalog, {
    onSettled: (data) => {
      queryClient.refetchQueries(['allOnlyCatalogs']).then(()=> {
        setIsConverting(false);
      })
      props.handleClose();
    },
    onError: (error) => {
      setIsConverting(false);
      setErrorMessage(error.message || 'An error occurred');
      setDialogOpen(true);
    },
  });

  const { mutate: deleteItem } = useMutation(deleteService, {
    onSuccess: (data) => {
      if(data.status !== 200){
          setIsDeleting(false);
          setErrorMessage('An error occurred deleting a service');
          setDialogOpen(true);
      }else{
        queryClient
        .refetchQueries(["allServices"])
        .then(() => {
          setIsDeleting(false);
        })
        .then(() => {
          props.handleClose();
        })
        .catch((error) => {
          setIsDeleting(false);
          setErrorMessage(error.message || 'An error occurred');
          setDialogOpen(true);
        });
      }
      
    },
    onError: (error) => {
        setIsDeleting(false);
        setErrorMessage(error.message || 'An error occurred');
        setDialogOpen(true);
    },
  });

  const handleConvert = (e) => {
    e.stopPropagation();
    setIsConverting(true);
    convertItem(props.serviceId);
  };
  const handleDelete = (e) => {
    e.stopPropagation();
    setIsDeleting(true);
    deleteItem(props.serviceId);
  };

  const handleConfigure = async (e) => {
    props.setIsConfiguring(true);
    try{
      let service_items = await getServiceItems(props.serviceId);
      let service_specs = await getServiceSpecifications(props.serviceId);
      let service_materials = await getServiceMaterials(props.serviceId);
      let service_connTypes = await getServiceConnectionTypes(props.serviceId);
      let service_groups = await getServiceGroups(props.serviceId);

      let updatedObject = serviceData
      if(updatedObject !== undefined){
        if (service_items.data){
          updatedObject['items'] = service_items.data
          updatedObject['pipes'] = service_items.data.pipes;
          updatedObject['ducts'] = service_items.data.ducts;
        }
        if(service_specs.specifications){
          updatedObject['specifications'] = service_specs.specifications
        }
        if(service_materials.materials){
          updatedObject['materials'] = service_materials.materials
        }
        if(service_connTypes.connectionTypes){
          updatedObject['connectionTypes'] = service_connTypes.connectionTypes
        }
        if(service_groups?.data){
          updatedObject['groups'] = service_groups.data
        }
        props.setSelectedService(updatedObject)
        props.setServiceId(props.serviceId)


      }
    }catch(e){
      props.setErrorMessage(e.error || 'An error occurred');
      props.setDialogOpen(true);
      props.setIsConfiguring(false);
    }
    
  }
  

  return (
    <>
      <ButtonGroup
        orientation="vertical"
        aria-label="vertical outlined button group"
      >
        <Outlined_btn
          //color="secondary"
          onClick={(e) => handleConfigure(e)}
        >
          Configure
        </Outlined_btn>
        <Outlined_btn
          //color="secondary"
          //variant="outlined"
          onClick={(e) => handleConvert(e)}
        >
          Duplicate as Catalog
          {isConverting ? (
            <CircularProgress sx={styles.marginLeft} size={12} />
          ) : null}
        </Outlined_btn>
        <Outlined_btn
          //color="secondary"
          onClick={(e) => handleDelete(e)}
        >
          Delete
          {isDeleting ? (
            <CircularProgress sx={styles.marginLeft} color="secondary" size={12} />
          ) : null}
        </Outlined_btn>
      </ButtonGroup>
      <ErrorDialog open={dialogOpen} handleClose={handleCloseDialog} errorMessage={errorMessage} />

      </>
  );
};

const styles = {
  marginLeft: { marginLeft: "1rem" },
};
