import {
    Box,
    Button,
    Card,
    CardMedia,
    CircularProgress,
    Container,
    Divider,
    Drawer,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material'
import React, {useEffect, useState} from 'react';
import { catalogPartDrawerOpen, closeCatalogPartDrawer } from 'Context/DrawerStates';
import {
    deleteCatalogItem,
    updateCatalogItem,
} from "../../../../data/queries/queryAPI"
import { useMutation, useQueryClient } from 'react-query';

import CancelIcon from '@mui/icons-material/Cancel'
import { Contained_btn } from "components/Theme/Styled";
import ErrorDialog from 'components/Utilities/ErrorDialog';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera'
import clone from 'just-clone'
import {styled} from '@mui/material/styles'

export const CatalogConfigurationDrawer = (props) => {
    //const drawerWidth = 400;
    const [name, setName] = useState(props.selectedPart?.name);
    const [manufacturer, setManufacturer] = useState((props.selectedPart?.manufacturer !== undefined) ? props.selectedPart?.manufacturer : "")
    const [tab, setTab] = useState((props.selectedPart?.tab !== undefined) ? props.selectedPart?.tab : "")
    const [partNumber, setPartNumber] = useState(
      props.selectedPart?.partNumber
    );
    const [variableLength, setVariableLength] = useState(
      props.selectedPart?.variableLength
    );
    const [quantity, setQuantity] = useState(
      (props?.selectedPart?.packageQuantity === "Each") ? "1":
      props?.selectedPart?.packageQuantity?.toString().replace(/\D/g,'')
    );
    const [sizes, setSizes] = useState(props.selectedPart?.sizes || []);
    const [menuIconFile, setMenuIconFile] = useState(
      props?.selectedPart?.iconImage
    );
    const [selectedCatalog, setSelectedCatalog] = useState(props.selectedCatalog)
    const [propertiesChanged, setPropertiesChanged] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [catalogTabs, setCatalogTabs] = useState(Array.from(new Set(props.selectedCatalog.items.map((item) => {
        return item.tab;
    }))));
    const queryClient = useQueryClient();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    useEffect(() => {
        setName(props.selectedPart?.name)
        setManufacturer(props.selectedPart?.manufacturer)
        setTab(props.selectedPart?.tab)
        setPartNumber(props.selectedPart?.partNumber)
        setVariableLength(props.selectedPart?.variableLength)
        setQuantity(props.selectedPart?.packageQuantity)
        setSizes(props.selectedPart?.sizes)
        setMenuIconFile(props.selectedPart?.iconImage)
    }, [props.selectedPart])

    useEffect(() => {
      setSelectedCatalog(props.selectedCatalog)
      setCatalogTabs(Array.from(new Set(props.selectedCatalog.items.map((item) => {
        return item.tab;
    }))))
    }, [props.selectedCatalog])

    const { mutate: updateDrawerItem } = useMutation(updateCatalogItem, {
        onSettled: (data) => {
            if(data.status === 200){
                queryClient.refetchQueries(["allCatalogs"])
                const newItem = clone(props.selectedPart);
                newItem.name = name;
                newItem.tab = tab;
                newItem.manufacturer = manufacturer;
                if(isNaN(Number(partNumber))){
                  newItem.partNumber = props.selectedPart.partNumber;
                }else{
                  newItem.partNumber = Number(partNumber);
                }
                newItem.variableLength = variableLength;
                newItem.quantity = quantity;
                newItem.packageQuantity = quantity;
                newItem.sizes = sizes;
                newItem.iconImage = menuIconFile;
                newItem.id = props.selectedPart.id;
                newItem.companyId = props.selectedPart.companyId;
                newItem.tab = props.selectedPart.tab;
                newItem.material = props.selectedPart.material;
                newItem.sizeUnits = props.selectedPart.sizeUnits;
                let tempCatalog = clone(props.selectedCatalog);
                let tempItems = clone(tempCatalog.items);
                let updatedItems = clone(tempCatalog.items);
                tempItems.forEach((item, idx) => {
                    if(item.id === props.selectedPart.id){
                        updatedItems[idx] = newItem;
                    }
                })
                tempCatalog.items = updatedItems;
                props.setSelectedCatalog(tempCatalog);
                setUpdating(false);
                closeCatalogPartDrawer();
            }
        },
        onError: (error) => {
          setPropertiesChanged(true);
          setUpdating(false);
          setErrorMessage(error.message || 'An error occurred');
          setDialogOpen(true);
        }
    })

    const { mutate: deleteDrawerItem } = useMutation(deleteCatalogItem, {
        onSettled: (data) => {
            if(data.status === 200){
                queryClient.refetchQueries(['allCatalogs'])
                let tempCatalog = clone(props.selectedCatalog)
                let tempItems = clone(tempCatalog.items)
                let updatedItems = clone(tempCatalog.items)
                tempItems.forEach((item, index) => {
                    if(item.id === props.selectedPart.id){
                        updatedItems.splice(index, 1);
                    }
                })
                tempCatalog.items = updatedItems;
                props.setSelectedCatalog(tempCatalog);
                setDeleting(false);
                closeCatalogPartDrawer();
            }
        },
        onError: (error) => {
          setDeleting(false);
          setErrorMessage(error.message || 'An error occurred');
          setDialogOpen(true);
        }
    })

    const handleNameUpdate = (value) => {
        setName(value);
        setPropertiesChanged(true);
    };

    const handleManufacturerUpdate = (value) => {
      setManufacturer(value);
      setPropertiesChanged(true);
    }

    const handleTabUpdate = (value) => {
      setTab(value);
      setPropertiesChanged(true);
    }
    const handlePartNumberUpdate = (value) => {
      setPartNumber(value);
      setPropertiesChanged(true);
    };
    const handleVariableLengthUpdate = (value) => {
      setVariableLength(value);
      setPropertiesChanged(true);
    };
    const handleQuantityUpdate = (value) => {
      setQuantity(value);
      setPropertiesChanged(true);
    };
    const handleSizesUpdate = (value) => {
      const newArray = value.split(/[ ,]+/);
      setSizes(newArray);
      setPropertiesChanged(true);
    };
    const getBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    };
    const handleIconUpdate = async (file) => {
      const base64 = await getBase64(file);
      setMenuIconFile(base64);
      setPropertiesChanged(true);
    };
    const handleIconDelete = () => {
      setMenuIconFile("");
      setPropertiesChanged(true);
    };

    const handleUpdateSubmit = ()  => {
        const payload = {};
        const newItem = clone(props.selectedPart);
        newItem.name = name;
        newItem.tab = tab;
        newItem.manufacturer = manufacturer;
        if(isNaN(Number(partNumber))){
          newItem.partNumber = props.selectedPart.partNumber;
        }else{
          newItem.partNumber = Number(partNumber);
        }
        newItem.variableLength = variableLength;
        newItem.quantity = quantity;
        newItem.packageQuantity = quantity;
        newItem.sizes = sizes;
        newItem.iconImage = menuIconFile;
        newItem.id = props.selectedPart.id;
        newItem.companyId = props.selectedPart.companyId;
        newItem.tab = props.selectedPart.tab;
        newItem.material = props.selectedPart.material;
        newItem.sizeUnits = props.selectedPart.sizeUnits;
        payload.data = newItem;
        payload.catalogId = selectedCatalog.id;
        payload.itemId = props.selectedPart.id;
        setUpdating(true);
        updateDrawerItem(payload);
    }
    const handleDeleteItem = () => {
        const payload = {};
        payload.catalogId = selectedCatalog.id;
        payload.itemId = props.selectedPart.id;
        setDeleting(true);
        deleteDrawerItem(payload)
    }


    return (
        <>
        <Drawer
        sx={styles.drawer.drawer}
        anchor="right"
        open={catalogPartDrawerOpen.use()}
        onClose={ () => closeCatalogPartDrawer()}
      >
        <DrawerHeader
          sx={styles.drawer.drawerHeader}
        >
          <Typography variant="h6" gutterBottom component="div">
            PROPERTIES
          </Typography>
          <IconButton
            sx={styles.iconButton}
            onClick={(e) => closeCatalogPartDrawer()}
          >
            <CancelIcon
              sx={styles.cancelIcon} />
          </IconButton>
        </DrawerHeader>
        <Divider />

        {/* TODO: HANDLE ITEM IMAGE */}
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"

        >

        <Card sx={styles.titleCard} variant="outlined">
            {
            menuIconFile && menuIconFile.length > 20 ?

                < CardMedia
                component="img"
                sx={{ width: 64, height: 64 }}
                image={menuIconFile}
                alt={props.selectedPart?.name}
                key={props.selectedPart?.id}
                />

            :
                <PhotoCameraIcon sx={styles.cameraIcon} />
            }
            <Divider orientation="vertical" variant="fullWidth"/>

            <Box sx={{flex: 1, flexDirection: "column !important", textAlign: "center"}}>

                <Typography variant="body1" >{name?.length <= 30 ? name: name?.substring(0, 30)}</Typography>
                <Divider variant="fullWidth" />
                <Box sx={{flexDirection: "row" }}>
                    <Button
                      size="large"
                      onClick={handleIconDelete}
                      sx={{marginLeft: ".5rem !important", marginRight: ".5rem !important"}}
                    >
                      Remove
                    </Button>

                    <FormControl id='user-csv-form'>
                        <input
                        type="file"
                        accept=".png"
                        id="newIconImg"
                        sx={styles.txt}
                        onChange={(e) => {
                            handleIconUpdate(e.target.files[0])

                        }}
                        hidden
                        />

                        <label htmlFor="newIconImg">
                            <Button
                            variant="raised"
                            component="span"
                            size="large"
                            sx={{marginLeft: ".5rem !important",
                            marginRight: ".5rem !important",
                            marginTop: ".10rem !important",
                            color: "#F15A29 !important"}}

                            >
                                New
                            </Button>
                        </label>

                    </FormControl>

                </Box>
            </Box>

        </Card>

          <Box
            component="form"
            sx={styles.box[4]}
            noValidate
            autoComplete="off"
          >
            <Card id="catalogTextInputs"
              sx={styles.card}
              variant="outlined"
            >

              <TextField
                id="name"
                label="Name"
                value={name}
                onChange={(e) => handleNameUpdate(e.target.value)}
                InputLabelProps={{ sx: { marginLeft: ".7rem !important"} }}
              />
              <TextField
                id="manufacturer"
                label="Manufacturer"
                value={manufacturer}
                onChange={(e) => handleManufacturerUpdate(e.target.value)}
                InputLabelProps={{ sx: { marginLeft: ".7rem !important"} }}
              />
              <TextField
                id="partNumber"
                label="Part Number"
                value={partNumber}
                onChange={(e) => handlePartNumberUpdate(e.target.value)}
                InputLabelProps={{ sx: { marginLeft: ".7rem !important"} }}
              />

              <FormControl fullWidth>
                <InputLabel sx={styles.selectLabel} id="length-label">
                  Length
                </InputLabel>
                <Select
                  labelId="length-label"
                  id="length"
                  defaultValue=""
                  value={variableLength}
                  label="Length"
                  onChange={(e) => handleVariableLengthUpdate(e.target.value)}
                  sx={ styles.select }
                >
                  <MenuItem key="fixed" value={false}>
                    Fixed
                  </MenuItem>
                  <br></br>
                  <MenuItem key="variable" value={true}>
                    Variable
                  </MenuItem>
                </Select>
              </FormControl>

              <TextField
                id="quantity"
                label="Package QTY"
                type="number"
                value={quantity}
                onChange={(e) => handleQuantityUpdate(e.target.value)}
                InputLabelProps={{ sx: { marginLeft: ".7rem !important"} }}
              />
              <TextField
                id="sizes"
                label="Sizes"
                value={sizes?.join(", ")}
                onChange={(e) => handleSizesUpdate(e.target.value)}
                InputLabelProps={{ sx: { marginLeft: ".7rem !important"} }}
              />
              <FormControl fullWidth>

                <InputLabel sx={styles.selectLabel} id="length-label">
                  Tabs
                </InputLabel>

                <Select
                  labelId="tabs-label"
                  id="Tabs"
                  value={tab}
                  label="Tabs"
                  onChange={(e) => handleTabUpdate(e.target.value)}
                  sx={styles.select}
                >
                  {catalogTabs.map((e, index) => {
                    return <MenuItem_ key={index} value={String(e)}>{String(e)}</MenuItem_>

                  })}
                </Select>

              </FormControl>

              <Box id="catalogPartDrawerButtons"
                display="flex"
                justifyContent="space-evenly"
                marginLeft="1rem"
                marginTop="1rem"
              >
                <Contained_btn
                  size="large"
                  onClick={handleUpdateSubmit}
                  sx={
                    propertiesChanged ?
                    styles.display.flex
                    :
                    styles.display.none
                  }
                >
                  {updating ? <CircularProgress color="white" />
                  :
                  <>
                    <span>Update</span>
                  </>
                  }
                </Contained_btn>
                {/* {propertiesChanged ? (
                  <Box
                    sx={{
                      marginTop: "2rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button size="large" variant="contained" onClick={handleSubmit}>
                      Update
                    </Button>
                  </Box>
                ) : null}
                {updating ? <CircularProgress /> : null} */}
                  <Contained_btn
                    size="large"
                    onClick={handleDeleteItem}
                  >
                  {deleting ? <CircularProgress color="white" />
                    :
                    <>
                      <Container><span>Delete</span></Container>
                    </>
                    }
                  </Contained_btn>
              </Box>
            </Card>

        </Box>

        </Grid>
      </Drawer>
      <ErrorDialog open={dialogOpen} handleClose={handleCloseDialog} errorMessage={errorMessage} />

        </>
    )
}

const styles = {
    card: {
      width: 310,
      height: "auto",
      overflowX: 'hidden',
      border: '1px solid #F15A29 !important',
      backgroundColor: 'white',
      paddingBottom: "1rem !important",
      paddingTop: ".5rem !important"
    },
    titleCard: {
      width: 310,
      maxHeight: "64px !important",
      overflowX: 'hidden',
      border: '1px solid #F15A29 !important',
      backgroundColor: 'white',
      display: "flex",
      marginBottom: "1rem",
      marginTop: "1rem",
    },
    drawer: {
      drawer: {
        //backgroundColor: "#000",
        width: 400,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: 400,
          boxSizing: "border-box",
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent"
        }
      },
      drawerHeader: {
        backgroundColor: "#000",
        color: "#fff",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }
    },
    box: {
      1: {
        // display: "flex",
        // flexDirection: "column",
        // alignItems: "center",
        // justifyContent: "center",
      },
      2: {
        width: "100%",
        display: "flex",
        flexDirection: "col",
        justifyContent: "left",
        alignItems: "left",
      },
      3: {
        width: "60%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      },
      4: {
        "& .MuiTextField-root": { width: "290px !important", marginTop: "1rem", paddingLeft: "10px !important" },
      }
    },
    marginLeft: { marginLeft: ".7rem" },
    color: {
      white: {color: "#fff"}
    },
    BGcolor: {
      flame: {backgroundColor: "#F15A29"}
    },
    alignSelf: { center: {alignSelf: 'center'}},
    display: {
      none: {display: "none"},
      flex: {display: "flex"}
    },
    img: {
      marginRight: "10px",
      marginLeft: "10px",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    cancelIcon: { textAlign: "right", color: "#fff"},
    iconButton: {textAnchor: "right" },
    cameraIcon: { width: "64px", height: "64px" },
    btn: { paddingLeft: "2rem", paddingRight: "2rem" },
    txt: {color: "#F15A29 !important"},
    justifyContent: {justifyContent: "space-evenly"},
    borderColor: {
      flame: {borderColor: "#F15A29"}
    },
    select: {
      maxWidth: "290px !important",
      marginTop: "1rem",
      marginLeft: ".7rem"
    },
    selectLabel: {
      marginLeft: ".7rem",
      marginTop: "1rem"
    }
  };

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  }));

  const MenuItem_ = styled(MenuItem)`
  display: flex !important;
  flex-direction: column !important;
`;