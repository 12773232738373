import React from "react";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import Input from "@mui/material/Input";
import { Button, Box} from "@mui/material";
import {styled} from "@mui/material/styles";

export const IconSelector = (props) => {
  const { onChange, displayIcon, labelId } = props;

  const IMG_ = styled('img')`
    max-width: 100%;
    max-height: 100%;
    margin-left: auto;
    margin-right: auto;
  `;

  const Label_ = styled('label')`
    justify-content: center; 
    cursor: pointer;
  `;

  return (
    <Button
      sx={styles.btn}
    >
      <Label_
        htmlFor={labelId}
        className="icon-container flex-v aligned"
        //style={styles.label}
      >
        {/* TODO: FIND A BETTER WAY TO DETERMINE IF THE ICON IS A VALID FILE */}
        {displayIcon?.length > 50 ? (
          <IMG_
            className="thumbnail"
            //style={styles.img}
            src={displayIcon}
            alt="icon preview"
          />
        ) : (
          <Box
            className="flex-v aligned"
            sx={styles.div}
          >
            <div>Upload Image</div>
            <PhotoCameraIcon />
          </Box>
        )}
      </Label_>
      <Input
        sx={styles.input}
        id={labelId}
        type="file"
        accept=".png"
        onChange={(e) => {
          onChange(e.target.files[0]);
        }}
      />
    </Button>
  );
};

const styles = {
  btn: {
    borderRadius: 5,
    color: "black",
    backgroundColor: "white",
    width: 40,
    height: 40,
  },
  div: { cursor: "pointer", justifyContent: "center" },
  input: { display: "none" }

};



