import "./components/Company.css";

import React, { useEffect, useState } from "react";
import { TextField, Typography } from "@mui/material";
import {
  getCompanies,
  getCompany,
  getCompanyStatuses,
  updateCompany,
} from "../../data/queries/queryAPI.js";
import { useMutation, useQuery } from "react-query";

import { Box } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";
import { CompanyNavButtons } from "components/Navigation/CompanyNavButtons";
import {CreateStatusDialog} from './components/CreateStatusDialog.jsx'
import ImportProjects from "./components/ImportProjects";
import NameAndThumbnail from "./components/SetCompany.jsx";
import { Paper } from "@mui/material";
import { PersistentNavButtons } from "../Navigation/PersistentNavButtons";
import StatusBuilder from "./components/StatusBuilder.jsx";
import StratusAPIKey from "./components/StratusAPIKey.jsx";
import UploadStatus from "./components/UploadStatus.jsx";
import { styled } from '@mui/material/styles';
import {theme} from "components/Theme/Theme.js";
import { withStyles } from "@mui/styles";

export const Company = (props) => {
  //CLOSE ALL OTHER PAGE'S DRAWERS ON INITIAL LOAD
  useEffect(() => {
    props.setCatalogBrowserDialogOpen(false);
    props.setCatalogDrawerOpen(false);
    props.setProjectServiceDialogOpen(false);
    props.setProjectUserDialogOpen(false);
    props.closeCreateCatalogPartDrawer(false);
    props.closeCatalogImportDialog();
    props.handleCloseOrdersDrawer();
    props.closeServicesDrawer();
    props.closeCreateUserDrawer();
    props.closeUsersDrawer();
    props.closeProjectsDrawer();
  });

  const [propertiesChanged, setPropertiesChanged] = useState(false);
  const [updating, setUpdating] = useState(false);

  const companyId = props.companyId;

  //get stratusStasuses associated with companyId

  //update company name
  const { mutate: setCompany } = useMutation(
    (payload) => updateCompany(payload),
    {
      onSettled: (data) => {
        setPropertiesChanged(false);
        setUpdating(false);
      },
      onError: (error) => {
        console.error("error mutating data:", error);
        alert("There was an error: ", error);
      },
    }
  );

  //ReactQuery Method for getting all of the companies:
  //(should not be used on frontend due to different rendering based on permissions)
  // const companyQuery = useQuery(["allCompanies"], getCompanies, {
  //   staleTime: Infinity,
  //   refetchOnMount: false,
  //   refetchOnWindowFocus: false,
  // });

  //React Query Method for getting the company that matches the companyId passed from Jwt in props
  const companyData = useQuery(["getOneCompany"], () => getCompany(companyId), {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  let companyName = companyData?.data?.data?.name;
  const styles = {
    paper: {
      height: "205%",
      width: "100%",
      marginTop: "30px",
      backgroundColor: "#fff",
      flex: 1,
      flexDirection: "column",
    },
    div: {marginLeft: "30px"},
    header: {
      margintop: "25px",
      marginbottom: "40px",
      width: "100%",
      height: "100%",
    },
  };

  if(companyData.isStale) {
    return (
      <Box
      sx={styles.paper}
      >
      <CompanyNavButtons />
      <CircularProgress/>
      </Box>
    );
  } else
  return (
    <Box sx={{ ...styles.paper, display: 'grid', gridTemplateColumns: '1fr auto' }}>
      <Box>
        <CompanyNavButtons />
        <Box sx={{ marginLeft: '30px' }}>
          <Typography sx={styles.header} variant="h5">
            General
          </Typography>
          <br />
          <NameAndThumbnail
            theme={theme}
            setCompany={setCompany}
            companyId={companyId}
            companyData={companyData}
          />
          <StratusAPIKey companyId={companyId} />

          <br />
          <UploadStatus
            companyId={companyId}
            companyData={companyData}
          />
          <br />
          <CreateStatusDialog companyId={companyId} />
          <br />
          <br />
        </Box>
      </Box>
      <Box sx={{ marginRight: '30px' , marginTop: '78px' }}>
        <StatusBuilder
          companyId={companyId}
          companyData={companyData}
          selectedStatus={props.selectedStatus}
          setSelectedStatus={props.setSelectedStatus}
        />
      </Box>
    </Box>
  );
};
