import {
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import {
    DataGridPro,
    GridToolbar,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
    LicenseInfo,
    useGridApiRef
} from "@mui/x-data-grid-pro";

import { CatalogTableContext } from "./CatalogsTable";
import Delete from "@mui/icons-material/Delete";
import DeleteForever from "@mui/icons-material/DeleteForever"
import DeleteOutline from "@mui/icons-material/DeleteOutline"
import { useContext } from "react";

function CustomToolbar() {

  const table = useContext(CatalogTableContext);

    return (
        <GridToolbarContainer>
          <GridToolbarExport/>
          <GridToolbarColumnsButton/>
          <GridToolbarFilterButton/>
          <GridToolbarDensitySelector />
          {
              !table.isConfiguring ? 
              <Box sx={{ display: "flex"}} >
                <Button  
                  onClick={table.functions.toggleDelete}>
                    {table.isDeleteMode ? <Delete color="secondary" size="small"/> : <DeleteOutline size="small" color="secondary"/>}
                    <Typography style={styles.typography} >Toggle Delete</Typography>
                  </Button>
                  {table.isDeleteMode && table.selectionModel.length > 0 ? 
                  <Button  
                    onClick={table.functions.deleteCatalogs}
                  >
                      <DeleteForever/> 
                      { !table.isDeleting ? 
                          <Typography style={styles.typography} >Delete</Typography> 
                        : <CircularProgress size={24} color="secondary"/>
                      }
                  </Button> : null} 
              </Box>
            : null
          }          
        </GridToolbarContainer>
    );
  }

  const styles = {
    cardSwitch: { marginTop: "2rem" },
    typography: {fontSize: '13px', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', fontWeight: 400, lineHeight: 1.5, letterSpacing: "0.02857em"}
  }

  export default CustomToolbar;
  
