import { Button, Chip, Divider, Typography } from "@mui/material";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { useEffect, useState } from 'react';

import CustomDataGrid from "./CustomDataGrid"
import { styled } from "@mui/material/styles";

export const Service_Groups_Table = (props) => {

    const [selectionModel, setSelectionModel] = useState([])
    const columns = [
        { field: "name", headerName: "Name", width: 150 },
        { field: "tab", headerName: "Tab", width: 140},
        { field: 'menuCategory', headerName: 'Menu Category', width: 160 },
        { field: 'items', headerName: "Number of Parts", width: 160},
        { field: "isExcluded", headerName: "Excluded", width: 140},
    ];

    useEffect(()=> {
    }, [props.rows])
    return(
        <>
            <Divider sx={styles.margin_bottom}><Typography color="secondary">Groups</Typography></Divider>
            <CustomDataGrid
                sx={styles.margin_bottom}
                rows={props.rows}
                columns={columns}
                autoHeight={true}
                hideFooter={true}
                disableSelectionOnClick={true}
                onSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel);
                    props.setSelectionGroupModel(newSelectionModel);
                  }}
                  selectionModel={selectionModel}
                  checkboxSelection
                disableColumnMenu={true}
                components={{
                    Toolbar: GridToolbar,
                  }}
                variant="elevation"
                
            />
        </>
    );

};


const DataGridPro_ = styled(DataGridPro)`
  .MuiDataGrid-columnHeaderTitle {
    color: #F15A29;
  };
`;

const styles = {
    margin_bottom: {marginBottom: "20px"}
}