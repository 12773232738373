import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";

import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Collapse from "@mui/material/Collapse";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LinkIcon from "@mui/icons-material/Link";
import ListItemButton from "@mui/material/ListItemButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Popover from "@mui/material/Popover";
import { PopoverButtons } from "./PopoverButtons";
import Typography from "@mui/material/Typography";
import { UserTable } from "./UserTable";

export const DivisionRow = (props) => {
  const [open, setOpen] = useState(false);
  const [usersLength, setUsersLength] = useState(props.division.users.length);
  const queryClient = useQueryClient();

  const handleDivisionClick = () => {
    setOpen(!open);
  };

  //HANDLING POPOVER
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenPopover = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const popoverOpen = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    setUsersLength(props.division.users.length);
    queryClient.invalidateQueries("allUsers");
  }, [props.division.users])

  return (
    <Box sx={{marginLeft: "1rem"}}>
      <ListItemButton
        onClick={handleDivisionClick}
        sx={styles.listItemButton}
      >
        <Typography variant="h6">{`${props.division.name}`}</Typography>

        {props.division.id === "stratus0-0000-0000-0000-000000000000"?
          <LinkIcon
            sx={styles.linkIcon}
            color="secondary"
          /> :
          null
        }

        <Button
          sx={styles.btn}
          onClick={(e) => handleOpenPopover(e)}
        >
          <MoreHorizIcon color="secondary"/>
        </Button>
        
        <Popover
          id={id}
          open={popoverOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <PopoverButtons
            handleClose={handleClose}
            division={props.division}
            setDivisionForDialog={props.setDivisionForDialog}
          />
        </Popover>
      </ListItemButton>
    </Box>
  );
};

const styles = {
  listItemButton: { display: "flex", flexDirection: "row" },
  paddingTop: { paddingTop: ".25rem" },
  btn: {
    paddingLeft: "0rem",
    marginLeft: "0rem",
  },
  box: {
    1: { paddingLeft: "2rem" }
  },
  color: {
    flame: {color: "#F15A29"}
  },
  syncIcon: {
    color: "#F15A29"
  },
  linkIcon:{ marginLeft: "1rem", color: "#F15A29" }
};
