import {
  Alert,
  Box,
  Card,
  CircularProgress,
  Divider,
  Drawer,
  FormControl,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Button, Container } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  archivePackage,
  deletePackage,
  getCompanyStatuses,
  getProject,
  getUsers,
  restorePackage,
  softDeletePackage,
  unarchivePackage,
  updateOrder
} from "data/queries/queryAPI";
import { closeOrdersDrawer, ordersDrawerOpen } from "Context/DrawerStates";
import { get_currentUser_viewOnlyProjects, use_currentUser_viewOnlyProjects } from "Context/currentUser";
import { ordersShowDeleted, packageDeleting, packageRestoring } from "Context/componentStates";
import { useMutation, useQuery, useQueryClient } from "react-query";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import CancelIcon from "@mui/icons-material/Cancel";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { ShoppingCartOutlined } from "@mui/icons-material";
import clone from 'just-clone'
import { forEach } from "jszip";
import { styled } from "@mui/material/styles";

export const OrdersDrawer = (props) => {
  const queryClient = useQueryClient();
  const drawerWidth = 400;
  const [containsStratusPackages, setContainsStratusPackages] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [archiving, setArchiving] = useState(false);
  const [infoAlert, set_infoAlert] = useState(null);
  const [clonedPackages, setClonedPackages] = useState(props.packages.slice());
  const [newProject, setNewProject] = useState(undefined)
  const [selectedProject, setSelectedProject] = useState(props.project);
  const [projects, setProjects] = useState(props.projects);
  const [selectedDivision, setSelectedDivision] = useState(props.division);
  const [users, setUsers] = useState(props.users);
  const [orders, setOrders] = useState(props.orders);
  const [statuses, setStatuses] = useState(props.statuses);
  const drawerId = clonedPackages.map((packageItem) => packageItem.id).join("+");
  const [viewOnlyUser, setViewOnly] = useState(false);
  const companyId = props.companyId;

  const toTitleCase = (str) => {
    return str?.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };
  const [status, setStatus] = useState(
    clonedPackages ? toTitleCase(clonedPackages[0]?.orderData?.status) : "Design"
  );
  const [description, setDescription] = useState(
    clonedPackages?.length === 1
      ? clonedPackages[0]?.orderData?.description === undefined
        ? ""
        : `${clonedPackages[0]?.orderData?.description}`
      : clonedPackages?.map((packageItem) => packageItem?.orderData?.name).join(", ")
  );
  const [propertiesChanged, setPropertiesChanged] = useState(false);
  const [sketchName, setSketchName] = useState(
    clonedPackages?.length === 1
      ? `${clonedPackages[0]?.orderData?.name}`
      : clonedPackages?.map((packageItem) => packageItem?.orderData?.name).join(", ")
  );
  const [sketchOrder, setSketchOrder] = useState(
    clonedPackages?.length === 1
      ? `${clonedPackages[0]?.orderData?.name}`
      : clonedPackages?.map((packageItem) => packageItem?.orderData?.name).join(", ")
  );
  const [assignedTo, setAssignedTo] = useState("");
  const [onSiteDate, setOnSiteDate] = useState(
    clonedPackages ? new Date(clonedPackages[0]?.orderData?.targetDate) : null
  );
  const isViewOnlyUser = async () => {

    if (await get_currentUser_viewOnlyProjects()?.includes(drawerId)) {
      setViewOnly(true)
      //viewOnlyUser.current = true;
    } else setViewOnly(false);//viewOnlyUser.current = false;
  };

  useEffect(() => {
    setDescription(clonedPackages?.length === 1
      ? clonedPackages[0]?.orderData?.description === undefined
        ? ""
        : `${clonedPackages[0]?.orderData?.description}`
      : clonedPackages?.map((packageItem) => packageItem?.orderData?.name).join(", "))
    setSketchName(clonedPackages?.length === 1
      ? `${clonedPackages[0]?.orderData?.name}`
      : clonedPackages?.map((packageItem) => packageItem?.orderData?.name).join(", "))
    setSketchOrder(clonedPackages?.length === 1
      ? `${clonedPackages[0]?.orderData?.name}`
      : clonedPackages?.map((packageItem) => packageItem?.orderData?.name).join(", "))

    //If all selected packages have the same targetDate or status, then display that info
    //otherwise display nothing
    if (clonedPackages) {
      if (clonedPackages.length > 1) {
        if (clonedPackages.every((packageItem) => packageItem?.orderData?.targetDate === clonedPackages[0]?.orderData?.targetDate)) {
          setOnSiteDate(clonedPackages[0]?.orderData?.targetDate)
        } else {
          setOnSiteDate("")
        }
        if (clonedPackages.every((packageItem) => packageItem?.orderData?.status === clonedPackages[0]?.orderData?.status)) {
          setStatus(toTitleCase(clonedPackages[0]?.orderData?.status))
        } else {
          setStatus("")
        }
      } else {
        setOnSiteDate(clonedPackages[0]?.orderData?.targetDate)
        setStatus(toTitleCase(clonedPackages[0]?.orderData?.status))
      }
    }
    if (
      clonedPackages.some((packageItem) => packageItem.orderData.stratusId?.length > 0)
    ) {
      setContainsStratusPackages(true);
    } else {
      setContainsStratusPackages(false);
    }
  }, [clonedPackages])

  useEffect(() => {
    setClonedPackages(props.packages.slice());
  }, [props.packages])

  useEffect(() => {
    setSelectedProject(props.project)
  }, [props.project])

  useEffect(() => {
    setSelectedDivision(props.division)
  }, [props.division])

  useEffect(() => {
    setProjects(props.projects)
  }, [props.projects])

  useEffect(() => {
    setUsers(props.users)
  }, [props.users])

  useEffect(() => {
    setOrders(props.orders)
  }, [props.orders])

  useEffect(() => {
    setStatuses(props.statuses)
  }, [props.statuses])

  const clearSelectionModel = () => {
    props.setClearSelectionFlag(!props.clearSelectionFlag);  // Toggle the flag
  }


  //UPDATE FUNCTIONS
  const { mutate: updateDrawerItem } = useMutation(updateOrder, {
    onSettled: async (data) => {
      if (data) {
        queryClient
          .refetchQueries(["allProjects"])
          .then(() => {
            setPropertiesChanged(false);
            if (data?.orderData?.project !== selectedProject?.name) {
              let clonedOrders = clone(orders)
              for (let i = 0; i < clonedOrders.length; i++) {
                if (clonedOrders[i].id === data?.id) {
                  clonedOrders.splice(i, 1);
                  break
                }
              }
              props.setOrders(clonedOrders);
              if (!props.showArchived) {
                props.setActivePackages(clonedOrders)
              } else {
                props.setArchivedPackages(clonedOrders)
              }
            } else {
              let clonedOrders = clone(orders);
              for (let i = 0; i < clonedOrders.length; i++) {
                if (clonedOrders[i]?.id === data?.id) {
                  clonedOrders[i] = data;
                  break
                }
              }
              props.setOrders(clonedOrders)
              if (!props.showArchived) {
                props.setActivePackages(clonedOrders)
              } else {
                props.setArchivedPackages(clonedOrders)
              }
            }
            setUpdating(false);
            clearSelectionModel();
            closeOrdersDrawer();
          })
          .catch((error) => {
            setUpdating(false);
            set_infoAlert(error.message || 'An error occurred');
          });
      }
    },
    onError: (error) => {
      setUpdating(false);
      set_infoAlert(error.message || 'An error occurred');

    },
  });

  const handleAssignToProjectUser = (e) => {
    setAssignedTo(e.target.value);
    setPropertiesChanged(true);
  };
  const handleStatusUpdate = (e) => {
    setStatus(e.target.value);
    setPropertiesChanged(true);
  };
  const handleSketchNameUpdate = (value) => {
    setSketchName(value);
    setPropertiesChanged(true);
  };

  const handleSketchOrderUpdate = (value) => {
    setSketchOrder(value);
    setPropertiesChanged(true);
  }

  const handleNewProjectChange = (value) => {
    setNewProject(value);
    setPropertiesChanged(true);
  }
  const handleDateChange = (value) => {
    setOnSiteDate(value);
    setPropertiesChanged(true);
  };
  const handleDescriptionUpdate = (value) => {
    setDescription(value);
    setPropertiesChanged(true);
  };


  const handleUpdate = () => {
    setUpdating(true);
    let updateObject = {};
    if (newProject) {
      updateObject.newProjectId = newProject?.id;
      updateObject.newProjectName = newProject?.name;
    } else {
      updateObject.newProjectId = "";
      updateObject.newProjectName = "";
    }
    if (props.packages.length === 1) {

      updateObject.name = sketchName;
      updateObject.status = status;
      updateObject.assignedTo = assignedTo;
      updateObject.package = sketchOrder
      updateObject.targetDate = new Date(onSiteDate)
        .toLocaleDateString("en-US")
        .split(",")[0];
      updateObject.description = description;
    } else {

      updateObject.status = status;
      updateObject.assignedTo = assignedTo;
      updateObject.targetDate = new Date(onSiteDate)
        .toLocaleDateString("en-US")
        .split(",")[0];
    }

    props.packages.forEach(packageItem => {

      const payload = {};
      payload.projectId = selectedProject?.id;
      payload.packageId = packageItem.id;
      payload.updateObject = updateObject;
      if (updateObject.name !== undefined) {
        packageItem.name = updateObject.name;
      }
      if (updateObject.package !== undefined) {
        packageItem.package = updateObject.package;
      }
      packageItem.status = updateObject.status;
      packageItem.targetDate = updateObject.targetDate;
      updateDrawerItem(payload);
    })
  };
  const { mutate: archivePackageItem } = useMutation((payload) => archivePackage(payload),
    {
      onSettled: async data => {
        let orderListClone = clone(orders);
        let idsToProcess = drawerId.includes('+') ? drawerId.split('+') : [drawerId];
        let ordersToArchive = [];

        orderListClone = orderListClone.filter(order => {
          if (idsToProcess.includes(order.id)) {
            ordersToArchive.push(order);
            return false;  // remove from orderListClone
          }
          return true;
        });

        let clonedActivePackages = clone(props.activePackages);
        clonedActivePackages = clonedActivePackages.filter(order => !idsToProcess.includes(order.id));

        let clonedArchivedPackages = clone(props.archivedPackages);
        clonedArchivedPackages = clonedArchivedPackages.concat(ordersToArchive);

        props.setOrders(orderListClone);
        props.setActivePackages(clonedActivePackages);
        props.setArchivedPackages(clonedArchivedPackages);
        clearSelectionModel();

        setArchiving(false);
      },
      onError: (error) => {
        setArchiving(false);
        set_infoAlert(error.message || 'An error occurred');
      },
    });





  const { mutate: unarchivePackageItem } = useMutation((payload) => unarchivePackage(payload),
    {
      onSettled: async data => {
        let orderListClone = clone(orders);
        let idsToProcess = drawerId.includes('+') ? drawerId.split('+') : [drawerId];
        let ordersToUnarchive = [];

        orderListClone = orderListClone.filter(order => {
          if (idsToProcess.includes(order.id)) {
            ordersToUnarchive.push(order);
            return false;  // remove from orderListClone
          }
          return true;
        });

        let clonedArchivedPackages = clone(props.archivedPackages);
        clonedArchivedPackages = clonedArchivedPackages.filter(order => !idsToProcess.includes(order.id));

        let clonedActivePackages = clone(props.activePackages);
        clonedActivePackages = clonedActivePackages.concat(ordersToUnarchive);

        props.setOrders(orderListClone);
        props.setArchivedPackages(clonedArchivedPackages);
        props.setActivePackages(clonedActivePackages);
        clearSelectionModel();

        setArchiving(false);
      },
      onError: (error) => {
        setArchiving(false);
        set_infoAlert(error.message || 'An error occurred');
      },
    });






  const { mutate: deletePackageItem } = useMutation(
    payload => deletePackage(payload), {
    onSettled: async data => {
      queryClient
        .refetchQueries(["allProjects"])
        .then(() => {
          let orderListClone = clone(orders);
          let selected;

          if (drawerId.includes('+')) {

            selected = drawerId.split('+');

          }
          else selected = drawerId;
          if (Array.isArray(selected)) {
            for (let i = 0; i < orderListClone.length; i++) {
              if (selected.includes(orderListClone[i].id)) {
                if (orderListClone[i]?.orderData?.stratusId?.length < 1) {
                  orderListClone.splice(i, 1);
                } else {
                  set_infoAlert("Stratus package deletion isn't supported yet...");
                }
              }
            }
          } else {
            for (let i = 0; i < orderListClone.length; i++) {
              if (orderListClone[i].id === selected) {
                if (orderListClone[i]?.orderData?.stratusId?.length < 1) {
                  orderListClone.splice(i, 1);
                } else {
                  set_infoAlert("Stratus package deletion isn't supported yet...");
                }
                break;
              }
            }
          }
          props.setOrders(orderListClone);
          if (props.showArchived) {
            props.setArchivedPackages(orderListClone);
          } else {
            props.setActivePackages(orderListClone);
          }
        })
        .then(() => {
          setPropertiesChanged(false);
          clearSelectionModel();
          setDeleting(false);
        })
        .catch((error) => {
          setDeleting(false);
          set_infoAlert(error.message || 'An error occurred');
        })

    },
    onError: (error) => {
      setDeleting(false);
      set_infoAlert(error.message || 'An error occurred');
    },
  });
  const handleDelete = e => {
    if (selectedProject && drawerId) {
      e.stopPropagation();
      setDeleting(true);
      const payload = {};
      payload.projectId = selectedProject?.id;
      payload.orderId = 'deletingOrders';
      payload.packageId = drawerId;
      payload.companyId = companyId;
      deletePackageItem(payload);
    }
  };

  const handleArchive = (e) => {
    e.stopPropagation();
    setArchiving(true);
    const payload = {};
    payload.projectId = selectedProject?.id;
    payload.orderId = 'archivingOrders';
    payload.packageId = drawerId;
    payload.companyId = companyId;
    archivePackageItem(payload);
  }

  const handleUnarchive = (e) => {
    e.stopPropagation();
    setArchiving(true);
    const payload = {};
    payload.projectId = selectedProject?.id;
    payload.orderId = 'unarchivingOrders';
    payload.packageId = drawerId;
    payload.companyId = companyId;
    unarchivePackageItem(payload);
  }

  return (
    <Drawer
      open={ordersDrawerOpen.use()}
      sx={{
        backgroundColor: "#000",
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: "border-box",
        }

      }}
      variant="persistent"
      anchor="right"
    >
      <DrawerHeader
        sx={styles.drawerHeader}
      >
        <Typography variant="h6" gutterBottom component="div">
          PROPERTIES
        </Typography>
        <IconButton
          sx={styles.iconButton}
          color="white"
          //style={styles.color.white}
          onClick={() => { closeOrdersDrawer() }}
        >
          <CancelIcon sx={styles.cancelIcon} />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <Card variant="outlined" sx={styles.imgCard}>
        {
          props.packages?.length > 1 ?

            <ImageList sx={{ width: "100%", height: 215 }} cols={2} rowHeight={215}>
              {
                props.packages?.map((packageItem) => {
                  return (
                    <ImageListItem key={packageItem.id} sx={{ border: "1px solid lightgray", height: "110px !important", marginLeft: ".25rem", marginRight: ".25rem" }}>
                      {
                        !packageItem?.orderData?.hasSketch ?
                          <Box sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}>
                            <StyledShoppingCartIcon />
                          </Box>
                          :
                          <CustomImage
                            key={packageItem.id}
                            alt={`${packageItem?.orderData?.name} sketch`}
                            src={packageItem?.thumbnail}
                            width="120px !important"
                            height="90px !important"
                            loading="lazy"
                          />
                      }

                      <ImageListItemBar
                        title={`${packageItem?.orderData?.name} sketch`}
                        subtitle={packageItem?.orderData?.id}
                        sx={{ opacity: "0%", ":hover": { opacity: "50%" } }}
                      />
                    </ImageListItem>
                  );
                })
              }
            </ImageList>

            :
            props.packages?.map((packageItem) => {
              return (
                <ImageListItem key={packageItem.id}>
                  {
                    !packageItem?.orderData?.hasSketch ?
                      <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                        <StyledShoppingCartIcon />
                      </Box>
                      :
                      <CustomImage
                        key={packageItem?.orderData?.id}
                        alt={`${packageItem?.orderData?.name} sketch`}
                        src={packageItem?.thumbnail}
                        width="290px"
                        height="210px"
                      />
                  }

                  <ImageListItemBar
                    title={`${packageItem?.orderData?.name} sketch`}
                    subtitle={packageItem?.orderData?.id}
                    sx={{ opacity: "0%", ":hover": { opacity: "50%" } }}
                  />
                </ImageListItem>
              )
            })

        }

      </Card>

      <Card sx={styles.card} variant="outlined">
        <Box
          component="form"
          sx={styles.box[2]}
          noValidate
          autoComplete="off"
        >
          {containsStratusPackages ? (
            <Typography
              sx={styles.typography}
              color="#F15A29"
              variant="body1"
              gutterBottom
            >
              Selection group contains stratus packages. Remove stratus-linked
              packages to enable editing of fields below.
            </Typography>
          ) : null}
          {
            props.packages?.length < 2 &&
            <TextField
              id="sketch"
              label="Sketch Name(s)"
              value={sketchName}
              onChange={(e) => handleSketchNameUpdate(e.target.value)}
              disabled={containsStratusPackages || props.packages?.length > 1 || viewOnlyUser.current}
              helperText={
                props.packages?.length > 1
                  ? "Cannot change multiple package names at the same time."
                  : null
              }
            />
          }

          <FormControl fullWidth>
            <InputLabel
              disabled={viewOnlyUser.current}
              sx={[styles.marginLeft, styles.color.flame]}
              id="assigned-to-label"
              color="secondary"
            //style={styles.color.flame}
            >
              Assign To:
            </InputLabel>
            <Select
              disabled={containsStratusPackages}
              labelId="assigned-to-label"
              id="assigned-to"
              defaultValue=""
              value={assignedTo}
              label="Assigned To"
              onChange={handleAssignToProjectUser}
              sx={styles.select}
              //style={styles.select_}
              variant="outlined"
              color="secondary"
            >
              <CustomMenuItem key={""} value={""}>
                None
              </CustomMenuItem>
              {selectedProject?.users?.map((userId) => {
                const userObj = users?.find(
                  (aUser) => aUser.id === userId
                );
                if (userObj) {
                  return (
                    <CustomMenuItem key={userObj.id} value={userObj.id}>
                      {userObj.name}
                    </CustomMenuItem>
                  );
                }

              })}
            </Select>
          </FormControl>
          <br />
          <br />
          <FormControl fullWidth>
            <InputLabel
              sx={styles.marginLeft}
              id="move-proj"
            //color="secondary"
            //style={styles.color.flame}
            >
              Move to Project:
            </InputLabel>
            <Select
              labelId="move-proj"
              id="move-project"
              placeholder={newProject?.name || ""}
              value={newProject?.name || ""}
              label="Move to Project"
              onChange={(event) => {
                setNewProject(projects?.find((project) => {
                  if (project.name === event.target.value) {
                    return project
                  } else {
                    return undefined;
                  }
                }))
                setPropertiesChanged(true);
              }}
              sx={styles.select}
              variant="outlined"
              color="secondary"
            >
              {(Array.isArray(clonedPackages)) ? projects?.filter((project) => { return project?.divisionId === selectedDivision?.id })?.filter(el => {
                return el.id !== selectedProject?.id
              }).map(el => {
                return (
                  <CustomMenuItem key={el.id} value={el.name}>
                    {el.name}
                  </CustomMenuItem>
                );
              }) : null}
            </Select>
          </FormControl>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              disabled={containsStratusPackages || viewOnlyUser.current}
              label="On Site Date"
              value={onSiteDate}
              color="secondary"
              //style={styles.color.flame}
              minDate={new Date("2017-01-01")}
              onChange={(newValue) => {
                handleDateChange(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <FormControl fullWidth>
            <InputLabel
              sx={styles.marginLeft}
              id="status-label"
              color="secondary"
            //style={styles.color.flame}
            >
              Status:
            </InputLabel>
            <Select
              disabled={containsStratusPackages || viewOnlyUser.current}
              labelId="status-label"
              id="status"
              defaultValue={toTitleCase(props.packages[0]?.orderData?.status)}
              value={status || ""}
              label="Status"
              onChange={(e) => handleStatusUpdate(e)}
              sx={styles.select}
              color="secondary"
              variant="outlined"
            >
              {statuses?.map((status) => {
                return (
                  <CustomMenuItem key={status?.name || "Design"} value={status?.name || "Design"}>
                    {status?.name || "Design"}
                  </CustomMenuItem>
                );
              })}
            </Select>
            {
              props.packages?.length < 2 &&
              <TextField
                id="sketch"
                label="Order Name(s)"
                value={sketchOrder}
                onChange={(e) => handleSketchOrderUpdate(e.target.value)}
                disabled={containsStratusPackages || props.packages?.length > 1 || viewOnlyUser.current}
                helperText={
                  props.packages?.length > 1
                    ? "Cannot change multiple package orders at the same time."
                    : null
                }
              />
            }

          </FormControl>
          <Box
            sx={styles.box[2]}
          >
            {!props.showArchived ?
              <CustomButton
                disabled={viewOnlyUser.current}
                size="large"
                variant="contained"
                onClick={handleArchive}
                color="secondary"
                sx={{ width: 280 }}
              >
                {archiving ? <CircularProgress color="white" /> : "Archive"}
              </CustomButton>
              :
              <CustomButton
                disabled={viewOnlyUser.current}
                size="large"
                variant="contained"
                onClick={handleUnarchive}
                color="secondary"
                sx={{ width: 280 }}
              >
                {archiving ? <CircularProgress color="white" /> : "Unarchive"}
              </CustomButton>
            }


            <CustomButton
              disabled={viewOnlyUser.current}
              size="large"
              variant="contained"
              onClick={handleDelete}
              color="secondary"
              sx={{ width: 280 }}
            >
              {deleting ? <CircularProgress color="white" /> : "Delete"}
            </CustomButton>
            {
              propertiesChanged &&

              <CustomButton
                size="large"
                variant="contained"
                onClick={handleUpdate}
                color="secondary"
                sx={{ width: 280 }}
              >
                {updating ? <CircularProgress color="white" /> : "Save Changes"}
              </CustomButton>
            }
          </Box>
        </Box>
      </Card>

      <Snackbar open={infoAlert} autoHideDuration={6000} onClose={() => set_infoAlert(null)}>
        <Alert onClose={() => set_infoAlert(null)} variant="filled" severity="info" sx={{ width: '100%' }}>
          {infoAlert}
        </Alert>
      </Snackbar>

    </Drawer>
  );
};

const styles = {
  card: {
    width: 310,
    height: "auto",
    overflowY: "auto",
    border: "1px solid #F15A29",
    paddingBottom: "1rem",
    paddingTop: "1rem",
    alignSelf: "center"
  },
  imgCard: {
    width: 310,
    height: "auto",
    maxHeight: "215px !important",
    border: "1px solid #F15A29",
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    marginBottom: "1rem",
    marginTop: "1rem"
  },
  drawerHeader: {
    backgroundColor: "#000",
    color: "#fff",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  iconButton: { color: "#fff", textAnchor: "right" },
  cancelIcon: { color: "#fff", textAlign: "right" },
  box: {
    1: { display: "flex", flexDirection: "column", overflowX: "hidden", overflowY: "scroll" },
    2: { "& .MuiTextField-root": { margin: 2, width: "90%" } },
    3: {
      marginTop: "2rem",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  img: {
    1: {
      marginRight: "10px",
      marginLeft: "10px",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
  },
  button: {
    1: { color: "white", backgroundColor: "#F15A29", border: "#F15A29" },
    2: { color: "white", backgroundColor: "#F15A29" },
  },
  color: {
    white: { color: "white" },
    flame: { color: "#F15A29" },
  },
  BGcolor: {
    flame: { backgroundColor: "#F15A29" },
  },
  alignSelf: {
    center: { alignSelf: 'center' },
  },
  select: { width: "90%", marginLeft: "16px" },
  marginLeft: { marginLeft: "20px" },
  menuItem: { display: 'flex', flexDirection: 'column' },
  typography: { paddingLeft: "2rem", paddingRight: "2rem" },
};

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

const CustomButton = styled(Button)`
  margin-left: 1rem !important;
  margin-top: 1rem !important;
`;
const CustomMenuItem = styled(MenuItem)`
  display: flex !important;
  flex-direction: column !important;
`;

const CustomImage = styled('img')`
  margin-right: 10px;
  margin-left: 10px;
  max-height: 215px !important;
`;

const StyledShoppingCartIcon = styled(ShoppingCartOutlined)(({ theme }) => ({
  fontSize: "90px",
  color: theme.palette.primary.main,
}));
