import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { convertServiceToCatalog, updateServiceConnectionTypes } from "../../../../data/queries/queryAPI";
import { useMutation, useQueryClient } from "react-query";

import CustomDataGrid from "./../CustomDataGrid";
import ErrorDialog from "components/Utilities/ErrorDialog";
import ServiceConfigurationConnectorsMenu from "./ServiceConfigurationConnectorsMenu";
import { ServiceConfigurationConnectorsRenameForm } from "./ServiceConfigurationConnectorsRenameForm";
import clone from "just-clone";

const filterConnectionTypes = (connectionTypes, pipesLength) => {
  const domainFilter = pipesLength > 0 ? [0, 3, 4] : [1];
  return connectionTypes?.filter(({ Domain }) => domainFilter.includes(Domain));
};

export const ServiceConfigurationConnectors = (props) => {
  const {
    connectionTypes: allConnectionTypes,
    selectedService: initialSelectedService,
    setSelectedService,
    setSelectedConnectionTypes,
  } = props;

  const [selectedService, setSelectedServiceState] = useState(initialSelectedService);
  const [connectionTypes, setConnectionTypes] = useState(filterConnectionTypes(allConnectionTypes, selectedService.pipes.length));
  const [selectedConnectionType, setSelectedConnectionType] = useState(undefined);
  const [selectionModel, setSelectionModel] = useState([]);
  const [currentSelectionModel, setCurrentSelectionModel] = useState([]);
  const [needsUpdate, setNeedsUpdate] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const queryClient = useQueryClient();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleCloseDialog = () => {
      setDialogOpen(false);
  };
  useEffect(() => {
    setSelectedServiceState(initialSelectedService);
  }, [initialSelectedService]);

  const { mutate: updateConnectionTypes } = useMutation(updateServiceConnectionTypes, {
    onSettled: () => {
      queryClient.refetchQueries(["allServices"]).then(() => {
        convertItem(selectedService.id);
      });
    },
    onError: (error) => {
      setIsUpdating(false);
      setErrorMessage(error.message || 'An error occurred');
      setDialogOpen(true);
    },
  });

  const { mutate: convertItem } = useMutation(convertServiceToCatalog, {
    onSettled: (data) => {
      queryClient.refetchQueries(['allOnlyCatalogs']).then(()=> {
        setIsUpdating(false);
        setNeedsUpdate(false);
      })
    },
    onError: (error) => {
      setIsUpdating(false);
      setErrorMessage(error.message || 'An error occurred');
      setDialogOpen(true);
    },
  });

  const updateServiceConnTypes = () => {
    setIsUpdating(true);
    let newConnectionTypes = clone(connectionTypes);
    newConnectionTypes.forEach((connectionType, index) => {
      if(selectionModel.find(fabId => connectionType.FabId === fabId) !== undefined){
        newConnectionTypes[index].isApp = true;
      }else{
        newConnectionTypes[index].isApp = false;
      }
    })

    const payload = {
      id: selectedService.id,
      data: { connectionTypes: newConnectionTypes },
    };
    updateConnectionTypes(payload);

    let updatedObject = clone(selectedService);
    if (updatedObject) {
      updatedObject['connectionTypes'] = newConnectionTypes;
      setSelectedService(updatedObject);
    }
  };

  

  useEffect(() => {
    setConnectionTypes(filterConnectionTypes(allConnectionTypes, selectedService.pipes.length));
  }, [allConnectionTypes, selectedService]);

  useEffect(() => {
    const existingConnectionTypes = connectionTypes
      .filter((connectionType) => connectionType?.isApp)
      .map(({ FabId }) => FabId);

    if (existingConnectionTypes.length > 0 && existingConnectionTypes !== selectionModel) {
      setSelectionModel(existingConnectionTypes);
    }
    setCurrentSelectionModel(existingConnectionTypes);
  }, [connectionTypes]);

  const handleSelectionModelChange = useCallback(
    (newSelectionModel) => {
      if (newSelectionModel !== currentSelectionModel) {
        setNeedsUpdate(true);
      } else {
        setNeedsUpdate(false);
      }
      setSelectionModel(newSelectionModel);
      let newConnectionTypes = clone(connectionTypes);

      newConnectionTypes.forEach((connectionType, index) => {
        if(newSelectionModel.find(fabId => connectionType.FabId === fabId)){
          newConnectionTypes[index].isApp = true;
        }else{
          newConnectionTypes[index].isApp = false;
        }
      })
    },
    [connectionTypes, currentSelectionModel, selectedService]
  );

  const columns = useMemo(
    () => [
      {
        field: "renderCellmenu",
        headerName: "",
        width: 80,
        renderCell: (params) => (
          <ServiceConfigurationConnectorsMenu
            variant="menu"
            params={params}
            connectionType={params?.row}
            setSelectedConnectionType={setSelectedConnectionType}
          />
        ),
      },
      {
        field: "Name",
        headerName: "Name",
        width: 250,
        renderCell: (params) =>
          params.value?.includes("?") ? params.value.toString().replace("?", "\u00B0") : params.value,
      },
      {
        field: "Alias",
        headerName: "Alias",
        width: 250,
        renderCell: (params) =>
          params.value?.includes("?") ? params.value.toString().replace("?", "\u00B0") : params.value,
      },
      { field: "FabId", hide: true },
      {
        field: "Group",
        headerName: "Group",
        width: 150,
        renderCell: (params) => (params.value.includes(".") ? params.value.toString().replace(".", "") : params.value),
      },
      { field: "Connectivity", headerName: "Connectivity", width: 150 },
      {
        field: "Sex",
        headerName: "Sex",
        width: 100,
        renderCell: (params) => (params.value === 0 ? "M" : "F"),
      },
      {
        field: "Domain",
        headerName: "Domain",
        width: 250,
        renderCell: (params) => {
          if (params.row.Domain === 1) {
            return "Duct";
          } else if (params.row.Domain === 0 || params.row.Domain === 3 || params.row.Domain === 4) {
            return "Pipe";
          }
        },
      },
    ],
    []
  );
  return (
    <Box>
      {connectionTypes === undefined ? (
        <Typography>Loading...</Typography>
      ) : null}
      {needsUpdate ? (
        <Button variant="contained" color="secondary" onClick={updateServiceConnTypes}>
          {isUpdating ? <CircularProgress color="white" /> : "Update Connectors"}
        </Button>
      ) : null}
      {connectionTypes !== undefined && Array.isArray(connectionTypes) && connectionTypes.length > 0 ? (
        <CustomDataGrid
        columns={columns}
        rows={connectionTypes}
        autoHeight={true}
        pagination={true}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[25, 100, 250, 500]}
        getRowId={(row) => row.FabId}
        selectionModel={selectionModel}
        checkboxSelection
        onSelectionModelChange={handleSelectionModelChange}
      />
      ) : null}
      <ServiceConfigurationConnectorsRenameForm
        selectedConnectionType={selectedConnectionType}
        selectedService={selectedService}
        setSelectedService={setSelectedService}
        onAliasUpdate={(updatedConnectionType) => {
          const newSelectionModel = selectionModel.map((fabId) => {
            const connectionType = connectionTypes.find((spec) => spec.FabId === fabId);
            if (connectionType && connectionType.Connectivity === updatedConnectionType.Connectivity && connectionType.Sex === updatedConnectionType.Sex) {
              return updatedConnectionType.FabId;
            }
            return fabId;
          });
          setSelectionModel(newSelectionModel);
        }}
      />
      <ErrorDialog open={dialogOpen} handleClose={handleCloseDialog} errorMessage={errorMessage} />

    </Box>
  );
  
}
