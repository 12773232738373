import { Box, Select, Typography } from "@mui/material";
import React, {useEffect, useRef, useState} from 'react';

import { MenuItem } from '@material-ui/core';
import {styled} from "@mui/material/styles"

export const ServiceConfigurationPartConnectorSelector = (props) =>{
    const [selectedPart, setSelectedPart] = useState(props.selectedPart)
    const [connection, setConnection] = useState(props.connection)
    const [connectionIndex, setConnectionIndex] = useState(props.connectionIndex)
    const [selectedConnectionTypes, setSelectedConnectionTypes] = useState(props.selectedConnectionTypes)
    const [connectionValue, setConnectionValue] = useState(props.value)
    useEffect(() =>{
        selectedConnectionTypes.sort((a,b) => {return a.Name.localeCompare(b.Name)}).map((conn => {
            if(conn.FabId === connection.fabId){
                setConnectionValue(conn.Name)      
            }
        }))
    }, [connection.fabId, selectedConnectionTypes, props.connection])

    const handleChange = (event, e, index) =>{
        const { myValue, myIndex } = event.currentTarget.dataset;
        setConnectionValue(myValue);
        let incomingConnection = selectedConnectionTypes.find(e => e.Name === myValue) 
        selectedPart.connections[connectionIndex].fabId = incomingConnection.FabId
        selectedPart.connections[connectionIndex].type = incomingConnection.Connectivity
        selectedPart.connections[connectionIndex].sex = incomingConnection.Sex
        props.setPropertiesChanged(true);
    }
    
    return(
    (connectionValue !== undefined) &&
    <Box key={connectionIndex} sx={styles.box[1]}>
        <Box sx={styles.box[2]}>
            <Typography color="secondary">C{connectionIndex + 1}</Typography>
        </Box> 
        <Box sx={styles.box[3]}>
            <Select_  
                sx={styles.select} 
                labelId="demo-simple-select-label" 
                id="demo-simple-select" 
                value={connectionValue}  
                onSelect={(event) => handleChange(event, connection, connectionIndex)} 
            >
                { selectedConnectionTypes.sort((a,b) => {return a.Name.localeCompare(b.Name)}).map((type, index) => {
                    return <MenuItem  
                                onClick={(event) => handleChange(event, connection)} 
                                key={index} 
                                data-my-index={index} 
                                value={type.Name} 
                                data-my-value={type.Name}>{type.Name}
                            </MenuItem>
                })}
            </Select_>
        </Box>   
    </Box>)
    
};

const styles = {
    select: {
        width: '260px', 
        //border: '3px solid lightgray', 
        backgroundColor: 'white',
    },
    box: {
        1: {display: 'flex'},
        2: {display: 'inline-block', marginTop: '25px', marginLeft: '.75rem', justifyContent: 'center'},
        3: {display: 'inline-block', width: 'auto', alignItems: 'center !important', paddingLeft: ".6rem"}
    }
};

const Select_ = styled(Select)`
    width: 200px; 
    margin-top: .5rem;
    background: white;
`;