import { Box, Divider, Typography } from '@mui/material'
import React, {useEffect, useState} from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CustomDataGrid from '../CustomDataGrid'
import Tooltip from '@mui/material/Tooltip';
import clone from 'just-clone'
import { openCatalogPartDrawer } from 'Context/DrawerStates';
import {styled} from '@mui/material/styles'

export const CatalogConfigurationPartsTable = (props) => {
    const [selectionModel, setSelectionModel] = useState([])
    const [catalogItems, setCatalogItems] = useState(props.parts);
    useEffect(()=> {
        setCatalogItems(props.parts)
    }, [props.parts])

    const styles = {
        margin_bottom: {marginBottom: "20px"}
    }

    const IMG_ = styled('img')`
        padding-left: 0px !important;
        padding-top: 0px !important;
        width: 48px !important;
        height: 48px !important;
    `;

    const columns = [
        {
            field: "iconImage",
            headerName: "Icon",
            width: 100,
            renderCell: (params) => (
              <IMG_ src={params.value} alt="" />
            ),
          },
          { field: "name", headerName: "Name", width: 300 },
          { field: "partNumber", headerName: "Number", width: 150 },
          {
            field: "variableLength",
            headerName: "Length",
            width: 100,
            renderCell: (params) => {
              if(params.value){
                return "Variable"
              }else{
                return "Fixed"
              }}
          },
          { field: "packageQuantity", headerName: "Package QTY", width: 150, renderCell: (params) => {
            if(params.row.packageQuantity === "Each"){
              return "1"
            }else{
              return params.row.packageQuantity.toString()?.replace(/\D/g,'')
            }
          }},
          { field: "sizeUnits", headerName: "Units", width: 150 },
          {
            field: "sizes",
            headerName: "Sizes",
            width: 250,
            renderCell: (params) => (
              <p>
                {params.value && params.value.length > 0
                  ? params.value.join(", ")
                  : ""}
              </p>
            ),
          },
    ];

    const handleRowClick = (itemId) => {
        const item = catalogItems.find((item) => item.name === itemId);
        props.setSelectedPart(clone(item));
        openCatalogPartDrawer();
    };
    return(
        <>
            <Divider sx={styles.margin_bottom}><Typography color="secondary">Items</Typography></Divider>
            {
              catalogItems === undefined ? (
                <Typography>Loading...</Typography>
              ): null
            }
            {
              catalogItems !== undefined && Array.isArray(catalogItems) ? (
                <CustomDataGrid
                    sx={styles.margin_bottom}
                    rows={catalogItems}
                    columns={columns}
                    autoHeight={true}
                    hideFooter={true}
                    disableSelectionOnClick={true}
                    getRowId={(row) => row.name}
                    onSelectionModelChange={(newSelectionModel) => {
                        setSelectionModel(newSelectionModel);
                        //props.setSelectionTabModel(newSelectionModel);
                      }}
                    
                    onRowClick={(params) => {
                      handleRowClick(params.id);
                    }}
                    selectionModel={selectionModel}
                    disableColumnMenu={true}
                    variant="elevation"

                />
              ) : null
          }
        </>
    );

    
}