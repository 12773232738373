import { Box, Button, CircularProgress } from "@mui/material";
import {
  GetPackageGeometryFiles,
  StratusPackageBOMUpload,
  StratusPackageCUTUpload,
  StratusPackageGeometryUpload,
  StratusPackageMAJUpload,
  StratusPackagePCFUpload,
  StratusPackagePDFUpload,
  StratusPackageTakeoffMAJUpload,
  StratusPackageUpload,
  stratusSyncPackage,
  getCutList,
  getEstimatingBOM,
  getOverallBOM,
  getPDFExport,
  getPickList,
  getProjects,
  getSketchBOM,
  getSketchMAJ,
  getSketchPCF,
  stratusUploadPackage,
} from "../../../data/queries/queryAPI";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import ButtonGroup from "@mui/material/ButtonGroup";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorDialog from "components/Utilities/ErrorDialog";
import { Typography } from "@material-ui/core";
import { ViewOnlyUsersDialog } from "./ViewOnlyUsersDialog";
import clone from "just-clone";
import { openOrdersDrawer } from "Context/DrawerStates";

/** Button Bar for top of package table
 *
 * @property {*} props
 * @states
 * + isUploadingToStratus
 * + loading
 * ## Buttons
 * <h1 style="color: red; " >frank</h1>
 *
 * + **[Edit]**
 * + **[Export PDF]**
 * *downloads a pdf file*
 * + **[Cut List]**
 * + **[Pick List]**
 * + **[Sketch BOM]**
 * + **[OverAll BOM]**
 * + **[Open-in WebViewer]**
 * + **[Upload to Stratus]**
 * + **[MAJ]**
 * *downloads a MAJ file*
 * + **[PCF]**
 * *downloads a PCF file*
 *
 * @returns ActionBar component
 */
export const ActionBar = (props) => {
  const queryClient = useQueryClient();
  const [isUploadingToStratus, setIsUploadingToStratus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [exportingPDF, setExportingPDF] = useState(false);
  const [exportingCut, setExportingCut] = useState(false);
  const [exportingPick, setExportingPick] = useState(false);
  const [exportingEstimatingBOM, setExportingEstimatingBOM] = useState(false);
  const [exportingSketchBOM, setExportingSketchBOM] = useState(false);
  const [exportingOverallBOM, setExportingOverallBOM] = useState(false);
  const [exportingMAJ, setExportingMAJ] = useState(false);
  const [exportingPCF, setExportingPCF] = useState(false);

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const edit = () => {
    openOrdersDrawer();
  };
  const openWebViewer = () => {
    const packageItem = props.packages[0];
    const viewerURL =
      "https://d3rxt0canyk32q.cloudfront.net?" + packageItem.orderURL;
    window.open(`${viewerURL}`, "PromoteFirefoxWindowName", "popup");
  };

  const uploadToStratus = async (e) => {
    for (const packageItem of props.packages) {
      const payload = {
        projectId: props.projectId,
        packageId: packageItem.id,
        companyId: props.companyId,
      };
      let returned_package = {};
      try {
        returned_package = await StratusPackageUpload(payload);
        await StratusPackagePDFUpload(payload);
        await StratusPackageBOMUpload(payload);
        await stratusSyncPackage(payload);
      } catch (error) {
        setIsUploadingToStratus(false);
        setErrorMessage(error.message || "An error occurred");
        setDialogOpen(true);
      } finally {
        let temp = clone(props.orders);
        const index = temp.findIndex(
          (packageItem) => packageItem.id === returned_package.id
        );
        if (index !== -1) {
          temp[index] = returned_package;
        }
        props.setOrders(temp);
        setIsUploadingToStratus(false);
      }
    }
  };

  const buttonGroupOne = [
    <Button
      onClick={(e) => edit()}
      variant="contained"
      color="secondary"
      key="edit"
    >
      Edit
    </Button>,
    /*     <ViewOnlyUsersDialog
      projectId={props.projectId}
      packageId={props.packages[0]?.id}
      assignedViewers={props.assignedViewers}
      assignableViewers={props.assignableViewers}
      selectedPackagesCount={props.packages.length}
      key="viewOnly"
    />, */
    <Button
      onClick={(e) => {
        const idsArray = props.packages.map((packageItem) => packageItem.id);
        const namesArray = props.packages.map(
          (packageItem) => packageItem.orderData.name
        );
        setExportingPDF(true);
        getPDFExport(props.projectId, idsArray, namesArray)
          .then(() => {
            setExportingPDF(false);
          })
          .catch((error) => {
            setExportingPDF(false);
            setErrorMessage(error.message || "An error occurred");
            setDialogOpen(true);
          });
      }}
      variant="contained"
      color="secondary"
      key="pdf"
    >
      Export PDF
      {exportingPDF ? (
        <CircularProgress
          size={24}
          thickness={5}
          color="white"
          sx={styles.circularProgress}
        />
      ) : null}
    </Button>,
    <Button
      onClick={(e) => {
        const idsArray = props.packages.map((packageItem) => packageItem.id);
        const namesArray = props.packages.map(
          (packageItem) => packageItem.orderData.name
        );
        setExportingCut(true);
        getCutList(props.projectId, idsArray, namesArray)
          .then(() => {
            setExportingCut(false);
          })
          .catch((error) => {
            setExportingCut(false);
            setErrorMessage(error.message || "An error occurred");
            setDialogOpen(true);
          });
      }}
      variant="contained"
      color="secondary"
      key="cut"
    >
      Cut List
      {exportingCut ? (
        <CircularProgress
          size={24}
          color="white"
          thickness={5}
          sx={styles.circularProgress}
        />
      ) : null}
    </Button>,
    <Button
      onClick={(e) => {
        const idsArray = props.packages.map((packageItem) => packageItem.id);
        const namesArray = props.packages.map(
          (packageItem) => packageItem.orderData.name
        );
        setExportingPick(true);
        getPickList(props.projectId, idsArray, namesArray)
          .then(() => {
            setExportingPick(false);
          })
          .catch((error) => {
            setExportingPick(false);
            setErrorMessage(error.message || "An error occurred");
            setDialogOpen(true);
          });
      }}
      variant="contained"
      color="secondary"
      key="pick"
    >
      Pick List
      {exportingPick ? (
        <CircularProgress
          size={24}
          color="white"
          thickness={5}
          sx={styles.circularProgress}
        />
      ) : null}
    </Button>,
    <Button
      onClick={(e) => {
        const idsArray = props.packages.map((packageItem) => packageItem.id);
        const namesArray = props.packages.map(
          (packageItem) => packageItem.orderData.name
        );
        setExportingSketchBOM(true);
        getSketchBOM(props.projectId, idsArray, namesArray)
          .then(() => {
            setExportingSketchBOM(false);
          })
          .catch((error) => {
            setExportingSketchBOM(false);
            setErrorMessage(error.message || "An error occurred");
            setDialogOpen(true);
          });
      }}
      variant="contained"
      color="secondary"
      key="sketch"
    >
      Sketch BOM
      {exportingSketchBOM ? (
        <CircularProgress
          size={24}
          color="white"
          thickness={5}
          sx={styles.circularProgress}
        />
      ) : null}
    </Button>,
    <Button
      onClick={(e) => {
        const idsArray = props.packages.map((packageItem) => packageItem.id);
        const namesArray = props.packages.map(
          (packageItem) => packageItem.orderData.name
        );
        setExportingOverallBOM(true);
        getOverallBOM(props.projectId, idsArray, namesArray)
          .then(() => {
            setExportingOverallBOM(false);
          })
          .catch((error) => {
            setExportingOverallBOM(false);
            setErrorMessage(error.message || "An error occurred");
            setDialogOpen(true);
          });
      }}
      variant="contained"
      color="secondary"
      key="bom"
    >
      Overall BOM
      {exportingOverallBOM ? (
        <CircularProgress
          size={24}
          color="white"
          thickness={5}
          sx={styles.circularProgress}
        />
      ) : null}
    </Button>,
    <Button
      onClick={(e) => {
        const idsArray = props.packages.map((packageItem) => packageItem.id);
        const namesArray = props.packages.map(
          (packageItem) => packageItem.orderData.name
        );
        setExportingEstimatingBOM(true);
        getEstimatingBOM(props.projectId, idsArray, namesArray)
          .then(() => {
            setExportingEstimatingBOM(false);
          })
          .catch((error) => {
            setExportingEstimatingBOM(false);
            setErrorMessage(error.message || "An error occurred");
            setDialogOpen(true);
          });
      }}
      variant="contained"
      color="secondary"
      key="pick"
    >
      Estimating BOM
      {exportingEstimatingBOM ? (
        <CircularProgress
          size={24}
          color="white"
          thickness={5}
          sx={styles.circularProgress}
        />
      ) : null}
    </Button>,
    <Button
      onClick={async (e) => {
        const idsArray = props.packages.map((packageItem) => packageItem.id);
        setExportingMAJ(true);

        if (idsArray.length > 1) {
          const ids = idsArray.join("+");
          getSketchMAJ(props.projectId, ids)
            .then(() => {
              setExportingMAJ(false);
            })
            .catch((error) => {
              setExportingMAJ(false);
              setErrorMessage(error.message || "An error occurred");
              setDialogOpen(true);
            });
        } else {
          const ids = idsArray[0];

          getSketchMAJ(props.projectId, ids)
            .then(() => {
              setExportingMAJ(false);
            })
            .catch((error) => {
              setExportingMAJ(false);
              setErrorMessage(error.message || "An error occurred");
              setDialogOpen(true);
            });
        }
      }}
      variant="contained"
      color="secondary"
      key="maj"
    >
      MAJ
      {exportingMAJ ? (
        <CircularProgress
          size={24}
          color="white"
          thickness={5}
          sx={styles.circularProgress}
        />
      ) : null}
    </Button>,
    <Button
      onClick={async (e) => {
        const idsArray = props.packages.map((packageItem) => packageItem.id);
        setExportingPCF(true);

        if (idsArray.length > 1) {
          const ids = idsArray.join("+");
          getSketchPCF(props.projectId, ids)
            .then(() => {
              setExportingPCF(false);
            })
            .catch((error) => {
              setExportingPCF(false);
              setErrorMessage(error.message || "An error occurred");
              setDialogOpen(true);
            });
        } else {
          const ids = idsArray[0];
          getSketchPCF(props.projectId, ids)
            .then(() => {
              setExportingPCF(false);
            })
            .catch((error) => {
              setExportingPCF(false);
              setErrorMessage(error.message || "An error occurred");
              setDialogOpen(true);
            });
        }
      }}
      variant="contained"
      color="secondary"
      key="pcf"
    >
      PCF
      {exportingPCF ? (
        <CircularProgress
          size={24}
          color="white"
          thickness={5}
          sx={styles.circularProgress}
        />
      ) : null}
    </Button>,
    <Button
      disabled={props.packages?.length > 1}
      onClick={(e) => openWebViewer()}
      variant="contained"
      color="secondary"
      key="view"
    >
      Open In Web Viewer
    </Button>,
    <Button
      disabled={
        props.packages?.length === 0 ||
        props.isStratusProject === false ||
        props.packages?.some(
          (packageItem) => packageItem?.orderData?.stratusId?.length > 0
        )
      }
      onClick={(e) => {
        setIsUploadingToStratus(true);
        uploadToStratus(e);
      }}
      variant="contained"
      color="secondary"
      key="upload"
    >
      Upload to Stratus
      {isUploadingToStratus ? (
        <CircularProgress
          size={24}
          thickness={5}
          sx={styles.circularProgress}
          color="white"
        />
      ) : null}
    </Button>,
    <Box>
      <ErrorDialog
        open={dialogOpen}
        handleClose={handleCloseDialog}
        errorMessage={errorMessage}
      />
    </Box>,
  ];

  return (
    <Box display="flex">
      <ButtonGroup size="large" aria-label="actions" sx={styles.buttonGroup[1]}>
        {buttonGroupOne}
      </ButtonGroup>
    </Box>
  );
};

const styles = {
  BGcolor: {
    flame: { backgroundColor: "#F15A29" },
  },
  btn: { backgroundColor: "#F15A29", color: "#fff" },
  buttonGroup: {
    1: {
      marginTop: "1rem",
      marginBottom: "1rem",
      marginRight: "20px",
      display: "flex",
    },
    2: {
      marginTop: "1rem",
      marginBottom: "1rem",
      marginRight: "20px",
      display: "flex",
    },
    3: { marginTop: "1rem", marginBottom: "1rem", display: "flex" },
  },
  circularProgress: { marginLeft: "1rem", verticalAlign: "middle" },
  color: {
    flame: { color: "#F15A29" },
  },
};
