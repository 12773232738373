import {
  Box,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { alpha, styled } from '@mui/material/styles';
import { makeStyles, useStyles } from '@mui/styles';

import AddCircleIcon from "@mui/icons-material/AddCircle";
import { CreateDivisionDialog } from "./CreateDivisionDialog";
import { DivisionRow } from "./DivisionRow";
import Switch from '@material-ui/core/Switch';
import { getDivisions } from "../../../data/queries/queryAPI";
import { pink } from '@mui/material/colors';
import { useQuery } from "react-query";

export const DivisionsList = (props) => {
  const [dataForDisplay, setDataForDisplay] = useState([]);
  const [lengthForDisplay, setLengthForDisplay] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);


  const divisionsQuery = useQuery(
    ["allDivisions"],
    async () => {
      const data = await getDivisions();
      return data;
    },
    {
      staleTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const length = divisionsQuery?.data?.data?.length


  const ShowDeletedSwitch = styled(Switch)(({theme}) => ({
      '& .MuiSwitch-switchBase':{
        transition: 'translateX(10px)',
        transitionDuration: '300 ms',

        '& .MuiSwitch-thumb':{
          color: 'black',
          transitionDuration: '300 ms',

        },
        '&.Mui-checked': {
          '& .MuiSwitch-thumb' : {
            color: '#F15A29'
          },
          '& ~ .MuiSwitch-track':{
            backgroundColor: '#F15A29',

          }
        },
        '& ~ .MuiSwitch-track':{
          backgroundColor: 'black',

        }
      },
  }));


  const handleDialogOpen = (e) => {
    setModalOpen(true);
  };

  useEffect(() => {
    if (divisionsQuery.data?.data?.length > 0) {
      // ALPHABETIZE THE DIVISIONS
      const alphabetized = divisionsQuery.data.data.sort((a, b) => {
        if (a.name.toUpperCase() === b.name.toUpperCase()) {
          return 0;
        }
        return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1;
      });

      const filtered = alphabetized.filter((item) => item !== undefined);
      setDataForDisplay(filtered);
      setLengthForDisplay(filtered.length)
    }
  }, [divisionsQuery.data]);

  if (divisionsQuery?.data?.data?.length > 0) {
    if(dataForDisplay?.length > 0 ){
    return (
      <>
          <Box
            sx={styles.box[1]}
          >
            <Box
              sx={styles.box[2]}
            >
              <Typography variant="h5">
                {`Divisions (${length === 0 || length === undefined ? 0 : lengthForDisplay })`}
              </Typography>

              <AddCircleIcon
                onClick={(e) => handleDialogOpen(e)}
                sx={styles.addCircleIcon}
              />
            </Box>
          </Box>
        <Divider sx={styles.divider} />
        {dataForDisplay.map((division) => {
          return (
            <DivisionRow
              openDrawer={props.openDrawer}
              key={division?.id}
              division={division}
              setDivisionForDialog={props.setDivisionForDialog}
            />
          );
        })}
        <CreateDivisionDialog open={modalOpen} setOpen={setModalOpen} />
      </>
    );
      }
   else if(dataForDisplay?.length === 0){
    return(
      <Box>
        <Box
            sx={styles.box[1]}
          >
            <Box
              sx={styles.box[2]}
            >
              <Typography variant="h5">
                {`Divisions (${length === 0 || length === undefined ? 0 : lengthForDisplay })`}
              </Typography>

                <AddCircleIcon
                  onClick={(e) => {handleDialogOpen(e)} }
                  sx={styles.addCircleIcon}
                />
            </Box>
          </Box>
          <Divider sx={styles.divider} />
        <Box sx={styles.color.gray} open={true}>
          <Box sx={styles.box[4]}>
            <Typography variant="h3">No Divisions</Typography>
          </Box>
        </Box>
        <CreateDivisionDialog open={modalOpen} setOpen={setModalOpen} />
      </Box>
    )
  } else{
    return (
      <Box>
      <Box
            sx={styles.box[1]}
          >
            <Box
              sx={styles.box[2]}
            >
              <Typography variant="h5">
                {`Divisions (${length === 0 || length === undefined ? 0 : lengthForDisplay })`}
              </Typography>

              <AddCircleIcon
                onClick={(e) => handleDialogOpen(e)}
                sx={styles.addCircleIcon}
              />
            </Box>
          </Box>
          <Divider sx={styles.divider} />
      <Box sx={styles.color.gray} open={true}>
        <Box sx={styles.box[4]}>
          <Typography variant="h3">Building Divisions Tree...</Typography>
          <CircularProgress color="inherit" />
        </Box>
      </Box>
      <CreateDivisionDialog open={modalOpen} setOpen={setModalOpen} />
    </Box>
    );

  }
  } else{
    return(
      <Box>
        <Box
            sx={styles.box[1]}
          >
            <Box
              sx={styles.box[2]}
            >
              <Typography variant="h5">
                {`Divisions (${length === 0 || length === undefined ? 0 : lengthForDisplay })`}
              </Typography>

                <AddCircleIcon
                  onClick={(e) => {handleDialogOpen(e)} }
                  sx={styles.addCircleIcon}
                />
            </Box>
          </Box>
          <Divider sx={styles.divider} />
        <Box sx={styles.color.gray} open={true}>
          <Box sx={styles.box[4]}>
            <Typography variant="h3">No Divisions</Typography>
          </Box>
        </Box>
        <CreateDivisionDialog open={modalOpen} setOpen={setModalOpen} />
      </Box>
    );

  }
};

const styles = {
  box: {
    1: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    2: {
      paddingTop: "1rem",
      paddingLeft: "2rem",
      display: "flex",
      flexDirection: "row",
    },
    3: { display: "flex", flexDirection: "col" },
    4: { display: "flex", flexDirection: "row", margin: "8px" },
  },
  addCircleIcon: {
    marginTop: "auto",
    marginBottom: "auto",
    marginLeft: "1rem",
    color: "#F15A29"
  },
  divider: { marginTop: "1rem", marginBottom: "1rem" },
  color: {
    white: {color: "#fff"},
    flame: {color: "#F15A29"},
    gray: {color: "darkgray"}
  }
};
