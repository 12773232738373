import
{
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { Contained_btn, Outlined_btn, Text_btn } from "components/Theme/Styled";
import React, { useState } from "react";
import {
  catalogIdForRenameForm,
  closeRenameCatalogForm,
  renameCatalogFormOpen,
} from "../../../Context/DrawerStates";
import { useMutation, useQueryClient } from "react-query";

import { CatalogImportDialog } from "./CatalogImportDialog";
import ErrorDialog from "components/Utilities/ErrorDialog";
import clone from "just-clone";
import { renameCatalog } from "../../../data/queries/queryAPI";

export const RenameCatalogForm = (props) => {
  const queryClient = useQueryClient();
  const [newName, setNewName] = useState("");
  const [open, setOpen] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleCloseDialog = () => {
      setDialogOpen(false);
  };

  const handleClickOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };
  const catalogId = catalogIdForRenameForm.use();

  const { mutate: renameItem } = useMutation(renameCatalog, {
    onSettled: async (data) => {
      await data.json();
      queryClient
        .refetchQueries(["allCatalogs"])
        .then(() => {
          let queriedData = queryClient.getQueryData(['allCatalogs']);
          let temp = clone(queriedData);
          if(queriedData){
            if(Array.isArray(queriedData)){
              queriedData.forEach((catalog, index) => {
                if(catalog.id === catalogId){
                  temp[index].name = newName
                }
              })
              queryClient.setQueryData(['allCatalogs'], temp)
            }
          }
          setNewName(" ");
          setIsRenaming(false);
        })
        .then(() => {
          closeRenameCatalogForm();
        })
        .catch((error) => {
          setIsRenaming(false);
          setErrorMessage(error.message || 'An error occurred');
          setDialogOpen(true);
        });
    },
    onError: (error) => {
      setIsRenaming(false);
      setErrorMessage(error.message || 'An error occurred');
      setDialogOpen(true);
    },
  });

  const handleRename = () => {
    setIsRenaming(true);
    const payload = {};
    payload.catalogId = catalogId;
    payload.data = {};
    payload.data.name = newName;
    renameItem(payload);
  };

  return (
    <>
        <Dialog
          open={renameCatalogFormOpen.use()}
          onClose={closeRenameCatalogForm}
        >
          <Box sx={{backgroundColor: '#F15A19', color: '#fff', display: 'flex', justifyContent: 'left', alignItems: 'center', height: '60px'}}>
            <Box sx={{marginLeft: '2rem'}}>
              <Typography variant="h5" >Rename Catalog</Typography>
            </Box>
          </Box>
          <DialogContent>
            <TextField
              autoFocus
              id="name"
              label="New Catalog Name"
              type="text"
              sx={{width: '250px'}}
              variant="outlined"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
          </DialogContent>
          <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '1rem'}}>
            <Box sx={{marginRight: '1rem'}}>
            <Outlined_btn onClick={() => {
              closeRenameCatalogForm()
              setNewName(" ")
            }

              }>Cancel</Outlined_btn>
            </Box>
            <Box sx={{marginLeft: '1rem'}}>
            <Contained_btn onClick={handleRename}>
              Rename
              {isRenaming ? <CircularProgress size={12} color='white'/> : null}
            </Contained_btn>
            </Box>
          </Box>
        </Dialog>
        <ErrorDialog open={dialogOpen} handleClose={handleCloseDialog} errorMessage={errorMessage} />

    </>

  );
};
