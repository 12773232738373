import {
    Box,
    Button,
    Card,
    CardMedia,
    CircularProgress,
    Container,
    Divider,
    Drawer,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material'
import { Contained_btn, Outlined_btn } from "components/Theme/Styled";
import React, {useEffect, useState} from 'react'
import { closeCreateCatalogPartDrawer, createCatalogPartDrawerOpen } from 'Context/DrawerStates';
import { useMutation, useQueryClient } from 'react-query';

import CancelIcon from '@mui/icons-material/Cancel'
import ErrorDialog from 'components/Utilities/ErrorDialog';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera'
import clone from 'just-clone'
import {createCatalogItem} from "../../../../data/queries/queryAPI"
import {styled} from '@mui/material/styles'

export const CatalogConfigurationCreateDrawer = (props) => {
    const companyId = props.companyId
    const drawerWidth = 400;
    const [name, setName] = useState("");
    const [partNumber, setPartNumber] = useState("");
    const [variableLength, setVariableLength] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [sizes, setSizes] = useState([]);
    const [menuIconFile, setMenuIconFile] = useState("");
    const [lengthIsMetric, setLengthIsMetric] = useState(false);
    const [metricUnit, setMetricUnit] = useState("");
    const [standardUnit, setStandardUnit] = useState("");
    const [material, setMaterial] = useState("");
    const [tab, setTab] = useState("");
    const [manufacturer, setManufacturer] = useState("")
    const [propertiesChanged, setPropertiesChanged] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [selectedCatalog, setSelectedCatalog] = useState(props.selectedCatalog);
    const [catalogTabs, setCatalogTabs] = useState(Array.from(new Set(props.selectedCatalog.items.map((item) => {
        return item.tab;
    }))));

    const [dialogOpen, setDialogOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };


    const handleManufacturerUpdate = (value) => {
      setManufacturer(value);
      setPropertiesChanged(true);
    }

    useEffect(() => {
        setSelectedCatalog(props.selectedCatalog)
        setCatalogTabs(Array.from(new Set(props.selectedCatalog.items.map((item) => {
            return item.tab;
        }))))
    }, [props.selectedCatalog])

    const clearItemData = () => {
        setName("")
        setPartNumber("")
        setVariableLength(false)
        setQuantity(1)
        setSizes([])
        setMenuIconFile("")
        setLengthIsMetric(false)
        setMetricUnit("")
        setStandardUnit("")
        setMaterial("")
        setTab("")
        setManufacturer("");
    }
    //UPDATE FUNCTIONS
    const { mutate: createDrawerItem } = useMutation(createCatalogItem, {
      onSettled: (data) => {
        if (data.status === 200) {
            data.json().then((body => {
              let tempCatalog = clone(selectedCatalog);
              let tempItems = clone(tempCatalog.items);
              tempItems = [...tempItems, body]
              tempCatalog.items = tempItems;
              props.setSelectedCatalog(tempCatalog);
              setPropertiesChanged(false);
              setUpdating(false);
              clearItemData()
              closeCreateCatalogPartDrawer();
          }));
        }
      },
      onError: (error) => {
        setPropertiesChanged(true);
        setUpdating(false);
        setErrorMessage(error.message || 'An error occurred');
        setDialogOpen(true);
      },
    });
    const catalogId = selectedCatalog?.id;
    const MenuItem_ = styled(MenuItem)`
    display: flex !important;
    flex-direction: column !important;
    `;

    const handleTabUpdate = (value) => {
      setTab(value);
      setPropertiesChanged(true);
    }
    const handleNameUpdate = (value) => {
      setName(value);
      setPropertiesChanged(true);
    };
    const handlePartNumberUpdate = (value) => {
      setPartNumber(value);
      setPropertiesChanged(true);
    };
    const handleMaterialUpdate = (value) => {
      setMaterial(value);
      setPropertiesChanged(true);
    };
    const handleVariableLengthUpdate = (value) => {
      setVariableLength(value);
      setPropertiesChanged(true);
    };
    const handleQuantityUpdate = (value) => {
      setQuantity(value);
      setPropertiesChanged(true);
    };
    const handleSizesUpdate = (value) => {
      const newArray = value.split(/[ ,]+/);
      setSizes(newArray);
      setPropertiesChanged(true);
    };
    const handleStandardUpdate = (value) => {
      setLengthIsMetric(value);
      setPropertiesChanged(true);
    };
    const handleUnitUpdate = (value) => {
      if (lengthIsMetric) {
        setMetricUnit(value);
      } else {
        setStandardUnit(value);
      }
      setPropertiesChanged(true);
    };
    const getBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    };
    const handleIconUpdate = async (file) => {
      const base64 = await getBase64(file);
      setMenuIconFile(base64);
      setPropertiesChanged(true);
    };
    const handleIconDelete = () => {
      setMenuIconFile("");
      setPropertiesChanged(true);
    };


    const handleSubmit = () => {
      const payload = {};
      const newItem = {};
      newItem.name = name;
      newItem.companyId = companyId;
      newItem.partNumber = partNumber;
      newItem.material = material;
      newItem.variableLength = variableLength;
      newItem.packageQuantity = parseInt(quantity);
      newItem.sizes = sizes;
      newItem.tab = tab;
      newItem.manufacturer = manufacturer;
      newItem.iconImage = menuIconFile;
      newItem.lengthIsMetric = lengthIsMetric;
      newItem.sizeUnits = lengthIsMetric ? metricUnit : standardUnit;
      payload.data = newItem;
      payload.catalogId = catalogId;
      setUpdating(true);
      createDrawerItem(payload);
    };

    const DrawerHeader = styled("div")(({ theme }) => ({
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-start",
    }));


    const IMG_ = styled('img')`
      marginRight: 10px;
      marginLeft: 10px;
      paddingTop: 10px;
      paddingBottom: 10px;
    `;

    return (
        <>
        <Drawer
        sx={styles.drawer.drawer}
        anchor="right"
        open={createCatalogPartDrawerOpen.use()}
        onClose={ () => closeCreateCatalogPartDrawer()}
      >
        <DrawerHeader
          sx={styles.drawer.drawerHeader}
        >
          <Typography variant="h6" gutterBottom component="div">
            PROPERTIES
          </Typography>
          <IconButton
            sx={styles.iconButton}
            onClick={(e) => closeCreateCatalogPartDrawer()}
          >
            <CancelIcon
              sx={styles.cancelIcon} />
          </IconButton>
        </DrawerHeader>
        <Divider />

        {/* TODO: HANDLE ITEM IMAGE */}
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"

        >

        <Card sx={styles.titleCard} variant="outlined">
            {
            menuIconFile && menuIconFile.length > 20 ?

                < CardMedia
                component="img"
                sx={{ width: 64, height: 64 }}
                image={menuIconFile}
                alt={props.selectedPart?.name}
                key={props.selectedPart?.id}
                />

            :
                <PhotoCameraIcon sx={styles.cameraIcon} />
            }
            <Divider orientation="vertical" variant="fullWidth"/>

            <Box sx={{flex: 1, flexDirection: "column !important", textAlign: "center"}}>

                <Typography variant="body1" >{name?.length <= 30 ? name: name?.substring(0, 30)}</Typography>
                <Divider variant="fullWidth" />
                <Box sx={{flexDirection: "row" }}>
                    <Button
                      size="large"
                      onClick={handleIconDelete}
                      sx={{marginLeft: ".5rem !important", marginRight: ".5rem !important"}}
                    >
                      Remove
                    </Button>

                    <FormControl id='user-csv-form'>
                        <input
                        type="file"
                        accept=".png"
                        id="newIconImg"
                        sx={styles.txt}
                        onChange={(e) => {
                            handleIconUpdate(e.target.files[0])

                        }}
                        hidden
                        />

                        <label htmlFor="newIconImg">
                            <Button
                            variant="raised"
                            component="span"
                            size="large"
                            sx={{marginLeft: ".5rem !important",
                            marginRight: ".5rem !important",
                            marginTop: ".10rem !important",
                            color: "#F15A29 !important"}}

                            >
                                New
                            </Button>
                        </label>

                    </FormControl>

                </Box>
            </Box>

        </Card>

          <Box
            component="form"
            sx={styles.box[4]}
            noValidate
            autoComplete="off"
          >
            <Card id="catalogTextInputs"
              sx={styles.card}
              variant="outlined"
            >

              <TextField
                id="name"
                label="Name"
                value={name}
                onChange={(e) => handleNameUpdate(e.target.value)}
                InputLabelProps={{ sx: { marginLeft: ".7rem !important"} }}
              />
              <TextField
                id="manufacturer"
                label="Manufacturer"
                value={manufacturer}
                onChange={(e) => handleManufacturerUpdate(e.target.value)}
                InputLabelProps={{ sx: { marginLeft: ".7rem !important"} }}
              />
              <TextField
                id="partNumber"
                label="Part Number"
                value={partNumber}
                onChange={(e) => handlePartNumberUpdate(e.target.value)}
                InputLabelProps={{ sx: { marginLeft: ".7rem !important"} }}
              />
              <TextField
                id="material"
                label="Material"
                value={material}
                onChange={(e) => handleMaterialUpdate(e.target.value)}
                InputLabelProps={{ sx: { marginLeft: ".7rem !important"} }}
              />

              <FormControl fullWidth>
                <InputLabel sx={styles.selectLabel} id="length-label">
                  Length
                </InputLabel>
                <Select
                  labelId="length-label"
                  id="length"
                  defaultValue=""
                  value={variableLength}
                  label="Length"
                  onChange={(e) => handleVariableLengthUpdate(e.target.value)}
                  sx={ styles.select }
                >
                  <MenuItem key="fixed" value={false}>
                    Fixed
                  </MenuItem>
                  <br></br>
                  <MenuItem key="variable" value={true}>
                    Variable
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel sx={styles.selectLabel} id="standard-label">
                  Standard
                </InputLabel>
                <Select
                  labelId="standard-label"
                  id="standard"
                  defaultValue=""
                  value={lengthIsMetric}
                  label="Standard"
                  onChange={(e) => handleStandardUpdate(e.target.value)}
                  sx={ styles.select }
                >
                  <MenuItem key="imperial" value={false}>
                    Imperial
                  </MenuItem>
                  <br></br>
                  <MenuItem key="metric" value={true}>
                    Metric
                  </MenuItem>
                </Select>
              </FormControl>
              {lengthIsMetric ? (
              <FormControl fullWidth>
                <InputLabel sx={styles.selectLabel} id="metric-units-label">
                  Units
                </InputLabel>
                <Select
                  labelId="metric-units-label"
                  id="metricUnits"
                  defaultValue=""
                  value={metricUnit}
                  label="Units"
                  onChange={(e) => handleUnitUpdate(e.target.value)}
                  sx={ styles.select }
                >
                  <MenuItem key="mm" value={"mm"}>
                    mm
                  </MenuItem>
                  <br></br>
                  <MenuItem key="cm" value={"cm"}>
                    cm
                  </MenuItem>
                  <br></br>
                  <MenuItem key="m" value={"m"}>
                    m
                  </MenuItem>
                </Select>
              </FormControl>
              ) : 
              <FormControl fullWidth>
                <InputLabel sx={styles.selectLabel} id="standard-units-label">
                  Units
                </InputLabel>
                <Select
                  labelId="standard-units-label"
                  id="standardUnits"
                  defaultValue=""
                  value={standardUnit}
                  label="Units"
                  onChange={(e) => handleUnitUpdate(e.target.value)}
                  sx={ styles.select }
                >
                  <MenuItem key="inches" value={"inches"}>
                    Inches
                  </MenuItem>
                  <br></br>
                  <MenuItem key="feet" value={"feet"}>
                    Feet
                  </MenuItem>
                </Select>
              </FormControl>
              }

              <TextField
                id="quantity"
                label="Package QTY"
                type="number"
                value={quantity}
                onChange={(e) => handleQuantityUpdate(e.target.value)}
                InputLabelProps={{ sx: { marginLeft: ".7rem !important"} }}
              />
              <TextField
                id="sizes"
                label="Sizes"
                value={sizes?.join(", ")}
                onChange={(e) => handleSizesUpdate(e.target.value)}
                InputLabelProps={{ sx: { marginLeft: ".7rem !important"} }}
              />
              <FormControl fullWidth>

                <InputLabel sx={styles.selectLabel} id="length-label">
                  Tabs
                </InputLabel>

                <Select
                  labelId="tabs-label"
                  id="Tabs"
                  value={tab}
                  label="Tabs"
                  onChange={(e) => handleTabUpdate(e.target.value)}
                  sx={styles.select}
                >
                  {catalogTabs.map((e, index) => {
                    return <MenuItem_ key={index} value={String(e)}>{String(e)}</MenuItem_>

                  })}
                </Select>

              </FormControl>

              <Box id="catalogPartDrawerButtons"
                display="flex"
                justifyContent="space-evenly"
                marginLeft="1rem"
                marginTop="1rem"
              >
                <Contained_btn
                  size="large"
                  onClick={handleSubmit}
                  sx={
                    propertiesChanged ?
                    styles.display.flex
                    :
                    styles.display.none
                  }
                >
                  {updating ? <CircularProgress color="white" />
                  :
                  <>
                    <span>Create</span>
                  </>
                  }
                </Contained_btn>
              </Box>
            </Card>

        </Box>

        </Grid>
      </Drawer>
      <ErrorDialog open={dialogOpen} handleClose={handleCloseDialog} errorMessage={errorMessage} />

        </>
    )
}

const styles = {
    card: {
      width: 310,
      height: "auto",
      overflowX: 'hidden',
      border: '1px solid #F15A29 !important',
      backgroundColor: 'white',
      paddingBottom: "1rem !important",
      paddingTop: ".5rem !important"
    },
    titleCard: {
      width: 310,
      maxHeight: "64px !important",
      overflowX: 'hidden',
      border: '1px solid #F15A29 !important',
      backgroundColor: 'white',
      display: "flex",
      marginBottom: "1rem",
      marginTop: "1rem",
    },
    drawer: {
      drawer: {
        //backgroundColor: "#000",
        width: 400,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: 400,
          boxSizing: "border-box",
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent"
        }
      },
      drawerHeader: {
        backgroundColor: "#000",
        color: "#fff",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }
    },
    box: {
      1: {
        // display: "flex",
        // flexDirection: "column",
        // alignItems: "center",
        // justifyContent: "center",
      },
      2: {
        width: "100%",
        display: "flex",
        flexDirection: "col",
        justifyContent: "left",
        alignItems: "left",
      },
      3: {
        width: "60%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      },
      4: {
        "& .MuiTextField-root": { width: "290px !important", marginTop: "1rem", paddingLeft: "10px !important" },
      }
    },
    marginLeft: { marginLeft: ".7rem" },
    color: {
      white: {color: "#fff"}
    },
    BGcolor: {
      flame: {backgroundColor: "#F15A29"}
    },
    alignSelf: { center: {alignSelf: 'center'}},
    display: {
      none: {display: "none"},
      flex: {display: "flex"}
    },
    img: {
      marginRight: "10px",
      marginLeft: "10px",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    cancelIcon: { textAlign: "right", color: "#fff"},
    iconButton: {textAnchor: "right" },
    cameraIcon: { width: "64px", height: "64px" },
    btn: { paddingLeft: "2rem", paddingRight: "2rem" },
    txt: {color: "#F15A29 !important"},
    justifyContent: {justifyContent: "space-evenly"},
    borderColor: {
      flame: {borderColor: "#F15A29"}
    },
    select: {
      maxWidth: "290px !important",
      marginTop: "1rem",
      marginLeft: ".7rem"
    },
    selectLabel: {
      marginLeft: ".7rem",
      marginTop: "1rem"
    }
  };

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  }));

  const MenuItem_ = styled(MenuItem)`
  display: flex !important;
  flex-direction: column !important;
`;