import {React, useContext, useEffect, useState} from 'react';

import {Box} from '@mui/material'
import {ServiceConfigurationConnectors} from './ServiceConfigurationConnectors'
import {ServiceConfigurationDrawables} from './ServiceConfigurationDrawables'
import {ServiceConfigurationDrawer} from './ServiceConfigurationDrawer';
import {ServiceConfigurationInsulation} from './ServiceConfigurationInsulation';
import {ServiceConfigurationMaterials} from './ServiceConfigurationMaterials'
import {ServiceConfigurationNavBar} from './ServiceConfigurationNavBar'
import {ServiceConfigurationParts} from './ServiceConfigurationParts'
import {ServiceConfigurationSpecifications} from './ServiceConfigurationSpecifications'

export const ServiceConfiguration = (props) => {

    const ductServiceTypeFabIds = [1, 2, 3, 30, 32, 33, 34, 35, 36, 56];
    const [selectedTab, setSelectedTab] = useState((props.selectedService !== undefined) ? (ductServiceTypeFabIds.includes(props.selectedService?.serviceType) ? "Ducts" : "Pipes") : "Pipes")
    const [selectedService, setSelectedService] = useState(props.selectedService);
    const [serviceId, setServiceId] = useState(props.serviceId);
    const [selectedPart, setSelectedPart] = useState(undefined);
    const [selectedConnectionTypes, setSelectedConnectionTypes] = useState(props.selectedService.connectionTypes.filter(connectionType => connectionType.isApp));

    useEffect(() => {
        setSelectedService(props.selectedService)
        setSelectedConnectionTypes(props.selectedService.connectionTypes.filter(connectionType => connectionType.isApp));
    }, [props.selectedService])

    return (
        <>
        {
            selectedService !== undefined ?
            (
                <Box>
                <ServiceConfigurationNavBar selectedTab={selectedTab} setSelectedTab={setSelectedTab} selectedService={selectedService}/>
                {
                    selectedTab === 'Pipes' || selectedTab === 'Ducts' ? (
                        <ServiceConfigurationDrawables selectedService={selectedService} setSelectedService={props.setSelectedService} pipes={selectedService.pipes} ducts={selectedService.ducts} conditions={selectedService.conditions}/>
                    ) : null
                }
                {
                    selectedTab === 'Specs/Liner' ? (
                        <ServiceConfigurationSpecifications selectedService={selectedService} setSelectedService={props.setSelectedService} specifications={selectedService.specifications?.filter(i => i.Type !== 9)}/>
                    ) : null
                }
                {
                    selectedTab === 'Insulation' ? (
                        <ServiceConfigurationInsulation selectedService={selectedService} setSelectedService={props.setSelectedService} specifications={selectedService.specifications?.filter(i => i.Type === 9)} />
                    ) : null
                }
                {
                    selectedTab === 'Materials' ? (
                        <ServiceConfigurationMaterials selectedService={selectedService} setSelectedService={props.setSelectedService} materials={selectedService.materials}/>
                    ) : null
                }
                {
                    selectedTab === 'Connectors' ? (
                        <ServiceConfigurationConnectors selectedService={selectedService} setSelectedService={props.setSelectedService} connectionTypes={selectedService.connectionTypes} setSelectedConnectionTypes={setSelectedConnectionTypes}/>
                    ) : null
                }
                {
                    selectedTab === 'Parts' ? (
                        <ServiceConfigurationParts parts={selectedService.items.connectors} conditions={selectedService?.conditions} groups={selectedService?.groups} setSelectedPart={setSelectedPart}/>
                    ) : null
                }
                <ServiceConfigurationDrawer companyId={props.companyId} selectedPart={selectedPart} setSelectedPart={setSelectedPart} selectedService={selectedService} setSelectedService={props.setSelectedService} selectedConnectionTypes={selectedService.connectionTypes?.filter(i => {return i?.isApp === true})}/>
                </Box>
            ): (<p>Undefined</p>)
        }
        </>
        
    )
}