//import {Button} from "@mui/material";

import Button from "@material-ui/core/Button";
import {styled} from "@mui/material/styles";

const Outlined_btn_v4 = (props) => {
    const Button_ = styled(Button)`
        //PLACE STYLES TO BE APPLIED HERE

    `;
    return (
        <Button_ 
            key={props.key}
            onClick={props.onClick} 
            variant="outlined"
            disabled={props.disabled}
            size={props.size}
            component={props.component}
            sx={[props.sx, {borderColor: "#fff !important", color: "#fff !important"}]}
            type={props.type}
            fullWidth={props.fullWidth}
        >
            {props.children}
        </Button_>
    );
};

export default Outlined_btn_v4;
