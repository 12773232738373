import './Generic.css';

import CircularProgress from '@material-ui/core/circularprogress'
import React from 'react';
import { colors } from './components/styles';

const defaultColor = colors.secondary.yellow;

export default function Loading (props){
  const { message, spinnerColor } = props;

  const color = (spinnerColor) ? spinnerColor : defaultColor;
  return (
      <div className="flex-v-center h-center">
        {
          !(message==="") &&
          <h3>
            {(message || message==="") ? message : "Loading"} 
          </h3>
        }
      </div>
  )
};