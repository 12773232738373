import {Box, Button, CircularProgress, Typography} from '@mui/material'
import React, {useEffect, useState} from 'react';
import {convertServiceToCatalog, updateServiceMaterials} from "../../../../data/queries/queryAPI"
import { useMutation, useQueryClient } from 'react-query';

import CustomDataGrid from './../CustomDataGrid'
import ErrorDialog from 'components/Utilities/ErrorDialog';
import clone from 'just-clone'

export const ServiceConfigurationMaterials = (props) => {
    const [selectedService, setSelectedService] = useState(props.selectedService)
    const [materials, setMaterials] = useState(props.materials);
    const [selectionModel, setSelectionModel] = useState([]);
    const [needsUpdate, setNeedsUpdate] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false);
    const [pageSize, setPageSize] = useState(25);
    const queryClient = useQueryClient();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const { mutate: updateMaterials } = useMutation(updateServiceMaterials, {
        onSettled: (data) => {
            convertItem(selectedService.id);
        },
        onError: (error) => {
            setIsUpdating(false);
            setErrorMessage(error.message || 'An error occurred');
            setDialogOpen(true);
        },
    });

    const { mutate: convertItem } = useMutation(convertServiceToCatalog, {
        onSettled: (data) => {
          queryClient.refetchQueries(['allOnlyCatalogs']).then(()=> {
            setIsUpdating(false);
            setNeedsUpdate(false);
          })
        },
        onError: (error) => {
            setIsUpdating(false);
            setErrorMessage(error.message || 'An error occurred');
            setDialogOpen(true);
        },
    });
    const updateServiceMats = () => {
        setIsUpdating(true)
        let newMaterials = clone(materials);
        if(selectionModel !== undefined && Array.isArray(selectionModel)
         && materials !== undefined && Array.isArray(materials)){
            newMaterials.forEach((material, index) => {
                if(selectionModel.find(fabId => fabId === material.FabId) !== undefined){
                    newMaterials[index].isApp = true;
                }else{
                    newMaterials[index].isApp = false;
                }
            })
        }
        let payload = {
            id: selectedService.id,
            data: {materials: newMaterials}
        }
        updateMaterials(payload)
        let updatedObject = selectedService;
        if(updatedObject!== undefined){
            updatedObject['materials'] = newMaterials
            props.setSelectedService(updatedObject)
        }
    }

    useEffect(() => {
        setMaterials(props.materials)
    }, [props.materials])

    useEffect(() =>{
        setSelectedService(props.selectedService)
    }, [props.selectedService])

    useEffect(() => {
        if(Array.isArray(materials)){
            let existingMaterials = []
            materials.forEach((material) => {
                if(material.isApp)
                    existingMaterials.push(material.FabId)
            })
            setSelectionModel(existingMaterials)
        }
    }, [materials])


    const columns = [
        { field: "Name", headerName: "Name", width: 250, renderCell: (params) => {
            if(params.value.includes("?")){
                return(
                    params.value.toString().replace("?", "\u00B0")
                )
            }
        } },
        { field: "FabId", hide: true},
        { field: "Group", headerName: "Group", width: 150},
        { field: "IsInsulationMaterial", headerName: "Insulation", width: 150, renderCell: (params) => {
            if(params.row.IsInsulationMaterial === true){
                return (
                    "Yes"
                )
            }else{
                return(
                    "No"
                )
            }
        }},
       
    ];
    return (
        <Box>
            {
                materials === undefined ? (
                    <Typography>Loading...</Typography>
                ) : null
            }
            {
                needsUpdate ?(
                    <Button variant="contained" color="secondary" onClick={updateServiceMats}>{isUpdating ? (<CircularProgress color="white"/>): "Update Materials"}</Button>
                ): null
            }
            {
                materials !== undefined && Array.isArray(materials) && materials.length > 0 ? (
                    <CustomDataGrid
                columns={columns}
                rows={materials}
                autoHeight={true}
                pagination={true}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[25, 100, 250, 500]}
                getRowId={(row) => row.FabId}
                selectionModel={selectionModel}
                checkboxSelection
                onSelectionModelChange={(newSelectionModel => {
                    if(newSelectionModel !== selectionModel){
                        setNeedsUpdate(true);
                    }else{
                        setNeedsUpdate(false)
                    }
                    setSelectionModel(newSelectionModel)
                })}
            />
                ) : null
            }
            <ErrorDialog open={dialogOpen} handleClose={handleCloseDialog} errorMessage={errorMessage} />

        </Box>
    )
}