import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Input,
    List,
    Tooltip,
    Typography
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import CustomDataGrid from './../CustomDataGrid';
import ErrorDialog from 'components/Utilities/ErrorDialog';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import WarningIcon from '@mui/icons-material/Warning';
import axios from 'axios'; // assuming you use axios for http requests
import clone from 'just-clone';
import { styled } from "@mui/material/styles";
import { updateServiceDrawable } from 'data/queries/queryAPI';

export const ServiceConfigurationDrawables = (props) => {
    const [selectedService, setSelectedService] = useState(props.selectedService)
    const [pipes, setPipes] = useState(props.pipes);
    const [conditions, setConditions] = useState(props.conditions);
    const [ducts, setDucts] = useState(props.ducts);
    const [pageSize, setPageSize] = useState(25);
    const [colorDialogOpen, setColorDialogOpen] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null); // new state to hold selected row
    const colorRef = useRef('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    useEffect(() =>  {
        setSelectedService(props.selectedService);
    }, [props.selectedService])

    useEffect(() => {
        setPipes(props.pipes);
    }, [props.pipes])

    useEffect(() => {
        setDucts(props.ducts);
    }, [props.ducts])

    const handleColorDialogOpen = (params) => {
        setSelectedRow(params.row); // setting selected row
        colorRef.current = params.row.materialColor; 
        setColorDialogOpen(true);
    };

    const handleColorDialogClose = () => {
        setColorDialogOpen(false);
    };

    const handleColorChange = (event) => {
        colorRef.current = event.target.value;
    };

    const handleColorConfirm = () => {
        setUpdating(true);
        let payloadData = clone(selectedRow);
        let payload = {}
        payloadData.materialColor = colorRef.current;
        payload['id'] = selectedService.id;
        if(pipes.length > 0){
            payload['data'] = {drawable: payloadData, type: 0};
        }
        if(ducts.length > 0){
            payload['data'] = {drawable: payloadData, type: 1};
        }
        

        updateServiceDrawable(payload).then(() => {
            let service = clone(selectedService);
            if(pipes.length > 0){
                service['pipes'] = service['pipes'].map(pipe => pipe.id === selectedRow.id ? payloadData : pipe);
            }
            if(ducts.length > 0){
                service['ducts'] = service['ducts'].map(duct => duct.id === selectedRow.id ? payloadData : duct);
            }

            setSelectedService(service);
            props.setSelectedService(service);
            setUpdating(false);
            setColorDialogOpen(false);
        }).catch(error => {
            // Set the error message and open the dialog
            setUpdating(false);
            setErrorMessage(error.message || 'An error occurred');
            setDialogOpen(true);
        });
    };


    const columns = [
        { field: "description", headerName: "Name", width: 250, renderCell: (params) => {
            if(params.value.includes("?")){
                return(
                    params.value.toString().replace("?", "\u00B0")
                )
            }
        } },
        { field: "conditionId", headerName: "Constraint", width: 150, renderCell: (params) => {
            if(params.row.conditionId !== undefined){
                if(conditions){
                    let condition = conditions.find(i => i.conditionId === params.row.conditionId);
                    if(!condition){
                        return(
                            <Tooltip title='Condition not found. Check service data.'>
                              <WarningIcon color="secondary"/>
                            </Tooltip>)
                    }else{
                        if(condition?.min_val && condition?.max_val){
                            if(params.row.conditionId === 0){
                                return (
                                    "None"
                                )
                            }
                            else if(condition.min_val === -1 && condition.max_val === -1){
                                return (
                                    "None"
                                )
                            }else if(condition.min_val === -1 && condition.max_val !== -1){
                                return(
                                    `<= ${condition.max_val}`
                                )
                            }else if(condition.min_val !== -1 && condition.max_val === -1){
                                return (
                                    `>= ${condition.min_val}`
                                )
                            } else if(condition.min_val !== -1 && condition.max_val !== -1){
                                return(
                                    `${condition.min_val} to ${condition.max_val}`
                                )
                            }
                        }
                    }
                }
            }
        }},
        { field: 'materialSprite', headerName: "Icon", width: 100, renderCell: (params) => (
            <IMG_ 
                src={params.value} 
                alt="Failed"
            />
        )},
        { field: "partNumber", hide: true },
        { field: "connections", headerName: "Connections", width: 250, renderCell: (params) => {

            //NEEDS CORRECTED IMPLEMENTATION
            if(params.row.connections !== undefined){
                return params.row.connections.map((e, index) => {
                    if(index === params.row.connections.length || index === params.row.connections.length-1){
                        if(e.sex === 0){
                           return `M-${e.type},`   
                        }else{
                            return `F-${e.type},`
                        }
                        
                    }else{
                        if(e.sex === 0){
                            return `M-${e.type}`   
                         }else{
                             return `F-${e.type}`
                         }
                    }
                    
                })
                
            }
        }},
        { field: 'material', headerName: "Material", width: 200},
        { field: "materialColor", headerName: "Color", width: 80 , renderCell: (params) => (
            <Box sx={{color: `${params.value}`, backgroundColor:`${params.value}`, width: 50}} onClick={() => handleColorDialogOpen(params)}>
                color
            </Box>
        )},
    ];

    return (
        <Box>
            {
                pipes === undefined || ducts === undefined ? (
                    <Typography>Loading...</Typography>
                ) : null
            }
            {
                pipes !== undefined && Array.isArray(pipes) && pipes.length > 0 ? (
                    <CustomDataGrid
                        columns={columns}
                        rows={pipes}
                        pagination={true}
                        pageSize={pageSize}
                        disableSelectionOnClick={true}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[25, 100, 250, 500]}
                        autoHeight={true}
                        getRowId={(row) => row.partNumber ? row.partNumber : row.id}
                    />
                ) : null
            }
            {
                ducts !== undefined && Array.isArray(ducts) && ducts.length > 0 ? (
                    <CustomDataGrid
                        columns={columns}
                        rows={ducts}
                        autoHeight={true}
                        pagination={true}
                        pageSize={pageSize}
                        disableSelectionOnClick={true}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[25, 100, 250, 500]}
                        getRowId={(row) => row.partNumber ? row.partNumber : row.id}
                    />
                ) : null
            }
            <ColorPickerDialog
                open={colorDialogOpen}
                onClose={handleColorDialogClose}
                initialColor={selectedRow?.materialColor}
                onColorChange={handleColorChange}
                onColorConfirm={handleColorConfirm}
                updating={updating}
            />
            <ErrorDialog open={dialogOpen} handleClose={handleCloseDialog} errorMessage={errorMessage} />
        </Box>
    )
}

const ColorPickerDialog = ({ open, onClose, initialColor, onColorChange, onColorConfirm, updating }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Pick a color</DialogTitle>
            <DialogContent>
                <Input type="color" defaultValue={initialColor} onChange={onColorChange} sx={{width: '100%'}}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={onColorConfirm}>{updating ? <CircularProgress color='secondary' size={18}/>: "OK" }</Button>
            </DialogActions>
        </Dialog>
    );
}


const IMG_ = styled('img')`
    padding-left: 0px !important;
    padding-top: 0px !important;
    width: 48px !important;
    height: 48px !important;
`;
