import {
  Box,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';
import {
    DataGridPro,
    GridToolbar,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
    LicenseInfo,
    useGridApiRef
} from "@mui/x-data-grid-pro";
import React, { useState } from "react";
import { servicesImportIncrement, servicesImportProgress } from "../../../Context/componentStates";

import Delete from "@mui/icons-material/Delete";
import DeleteForever from "@mui/icons-material/DeleteForever"
import DeleteOutline from "@mui/icons-material/DeleteOutline"
import DoneIcon from '@mui/icons-material/Done';
import LinearProgress from "@mui/material/LinearProgress";
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import SyncIcon from "@mui/icons-material/Sync";
import { TableContext } from "./ServicesTable";
import { openServicesRefreshDialog } from "Context/DrawerStates";
import { useContext } from "react";

const CustomToolbar = props => {

  const table = useContext(TableContext);

    return (
        <GridToolbarContainer sx={{width: "100%"}}>
          <GridToolbarExport/>
          <GridToolbarColumnsButton/>
          <GridToolbarFilterButton/>
          <GridToolbarDensitySelector/>
          {
            !table.isConfiguring ?
            <Box sx={{ display: "flex", width: '70%'}} >
              <Button
                onClick = {table.functions.toggleRefresh}/*() => {
                  handleRefresh();
                }}*/
              >
                <SyncIcon
                  color="secondary"
                  size="small"
                />

                <Typography style={styles.typography} >Toggle Refresh</Typography>
              </Button>
              <Button
                onClick={table.functions.toggleDelete}
              >
                {
                  table.isDeleteMode ?
                    <Delete color="secondary" size="small"/>
                  :
                  <DeleteOutline size="small" color="secondary"/>
                }
                <Typography style={styles.typography}>Toggle Delete</Typography>
              </Button>
                {
                  table.isDeleteMode && table.selectionModel.length > 0 ?
                    <Button
                      onClick={table.functions.deleteServices}
                    >
                      <DeleteForever size="small"/>
                        {
                          !table.isDeleting ?
                            <Typography style={styles.typography}>Delete</Typography>
                          :
                            <CircularProgress size={24} color="secondary"/>
                        }
                    </Button>
                  : table.isRefreshMode && table.selectionModel.length > 0 ?
                  <Box sx={{display: 'flex'}}> 
                      {
                        
                        !table.isRefreshing ?
                          <Button
                            onClick={openServicesRefreshDialog}
                          >
                            <ReplayCircleFilledIcon/>
                            <Typography style={styles.typography} >Refresh</Typography>
                          </Button>
                        :null
                      }
                      {
                        table.isRefreshing ?
                          (
                            <Box sx={{marginTop: ".05rem !important"}}>
                            {
                              servicesImportProgress.get() <= 99 ?
                              <Box sx={{display: "flex"}}>
                                <CircularProgress
                                  sx={{marginTop: ".25rem !important"}}
                                  color="secondary"
                                  size={24}
                                />
                                <Typography
                                  variant="overline"
                                  color="secondary"
                                  sx={{marginLeft: ".25rem !important", marginTop: ".25rem !important"}}
                                >
                                  Importing...
                                </Typography>
                              </Box>
                              :
                              <>
                                <DoneIcon sx={{marginTop: ".05rem !important"}} color="secondary"/>
                              </>
                            }
                          </Box>
                          ): null
                      }
                  </Box>: null
                } </Box>
            : null
          }
        </GridToolbarContainer>
    );
  }

  const styles = {
    syncIcon: {color:"#F15A29" },
    typography: {fontSize: '13px', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', fontWeight: 400, lineHeight: 1.5, letterSpacing: "0.02857em"}
  }

  export default CustomToolbar;
