import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  Typography,
} from "@mui/material";
import { Contained_btn, Outlined_btn, Text_btn } from "components/Theme/Styled";
import { MenuItem, Select } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  closeServicesDrawer,
  itemsGroupParentService,
  serviceGroupToEdit,
  servicesDrawerOpen,
} from "Context/DrawerStates";
import { useMutation, useQueryClient } from "react-query";

import CancelIcon from "@mui/icons-material/Cancel";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import WarningTwoToneIcon from "@mui/icons-material/WarningTwoTone";
import { styled } from "@mui/material/styles";
import { updateServiceAccessory } from "../../../data/queries/queryAPI";

// import ClickAwayListener from "@mui/material/ClickAwayListener";


export const ServiceItemDrawer = (props) => {
  const menuCategories = [
    {
      id: "",
      name: "Select Menu Category...",
    },
    {
      id: "Valve",
      name: "Valve",
    },
    {
      id: "Fitting",
      name: "Fitting",
    },
    {
      id: "Accessory",
      name: "Accessory",
    },
    {
      id: "Special",
      name: "Special",
    },
  ];
  const queryClient = useQueryClient();
  const [showWarning, setShowWarning] = useState(false);
  const [category, setCategory] = useState("");

  const drawerWidth = 400;
  const [updating, setUpdating] = useState(false);
  const [menuIconFile, setMenuIconFile] = useState(null);
  const [propertiesChanged, setPropertiesChanged] = useState(false);

  const group = serviceGroupToEdit.use();


  //TODO: DOUBLE-CHECK THAT MENU-CATEGORIES IS NOT A DYNAMIC VALUE


  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  const handleIconUpdate = async (file) => {
    const base64 = await getBase64(file);
    setMenuIconFile(base64);
    setPropertiesChanged(true);
  };
  const handleIconDelete = () => {
    setMenuIconFile("");
    setPropertiesChanged(true);
  };

  useEffect(() => {
    if (group !== null) {
      if (group.menuIconOverride?.length > 0) {
        setMenuIconFile(group.menuIconOverride);
      } else {
        setMenuIconFile(group.menuIcon);
      }

      if (group.menuCategoryOverride?.length > 0) {
        setCategory(group.menuCategoryOverride);
      } else {
        setCategory(group.menuCategory);
      }

      if (group?.items?.some((item) => item.supported) === false) {
        setShowWarning(true);
      } else if (group?.items?.every((item) => item.supported) === true) {
        setShowWarning(false);
      }
    }
  }, [group]);

  const parentServiceId = itemsGroupParentService.use();

  const { mutate: updateDrawerItem } = useMutation(updateServiceAccessory, {
    onSettled: async (data) => {
      if (data.status === 200) {
        queryClient.refetchQueries(["newServicesWithGroups"]).then(() => {
          setPropertiesChanged(false);
          setUpdating(false);
          closeServicesDrawer();
        });
      }
    },
    onError: (error) => {
      console.error("error mutating data:", error);
      alert("There was an error: ", error);
    },
  });

  const handleUpdate = () => {
    setUpdating(true);
    const payload = {};
    payload.serviceId = parentServiceId;
    payload.groupId = group.id;
    payload.data = {};
    payload.data.menuCategoryOverride = category;
    payload.data.menuIconOverride = menuIconFile;
    updateDrawerItem(payload);
  };

  const handleResetToStratus = () => {
    //TODO: IMPLEMENT RESET TO STRATUS BUTTON
    setUpdating(true);
    const payload = {};
    payload.serviceId = parentServiceId;
    payload.groupId = group.id;
    payload.data = {};
    payload.data.menuCategoryOverride = "";
    payload.data.menuIconOverride = "";
    updateDrawerItem(payload);
  };

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  }));

  return (
    // <ClickAwayListener onClickAway={props.closeDrawer}>
    <Drawer
      sx={{
        backgroundColor: "#000",
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="persistent"
      anchor="right"
      open={servicesDrawerOpen.use()}
    >
      <DrawerHeader
        sx={styles.drawerHeader}
      >
        <Typography variant="h6" gutterBottom component="div">
          PROPERTIES
        </Typography>
        <IconButton
          sx={styles.iconButton}
          onClick={() => {
            setPropertiesChanged(false);
            closeServicesDrawer();
          }}
        >
          <CancelIcon sx={styles.cancelIcon} />
        </IconButton>
      </DrawerHeader>
      <Divider />
        <Box
          component="form"
          sx={styles.box[1]}
          noValidate
          autoComplete="off"
        >
          {showWarning ? (

            <Box sx={styles.box[2]}>
              <Box sx={styles.box[3]}>
                  <WarningTwoToneIcon color="secondary" fontSize="large" />
              </Box>
              <Box>
                <Typography variant="body1">
                  One or more items of this group had no STRATUS data or are
                  unsupported.
                </Typography>
                <Typography variant="body1">
                  Unsupported items will not be accessible from FOZ iOS.
                </Typography>
              </Box>
            </Box>
          ) : null}
          <Box
            sx={styles.box[4]}
          >
            <Box
              sx={styles.box[5]}
            >
              {menuIconFile && menuIconFile.length > 55 ? (
                <IMG_
                  key={group.id}
                  alt={group.name}
                  src={menuIconFile}
                  width="50%"
                  //style={styles.img}
                />
              ) : (
                <PhotoCameraIcon sx={styles.tenrem} />
              )}
              <Box
                sx={styles.box[6]}
              >
                <InputLabel sx={styles.marginLeft} id="icon-label">
                  Icon
                </InputLabel>
              </Box>
              <Box
                sx={styles.box[7]}
              >
                <Outlined_btn
                  sx={styles.btn}
                  size="large"
                  //variant="outlined"
                  //color="secondary"
                  onClick={handleIconDelete}
                >
                  Delete
                </Outlined_btn>
                <label htmlFor="serviceItem-button-file">
                  <Input
                    type="file"
                    accept=".png"
                    id="serviceItem-button-file"
                    multiple={false}
                    onChange={(e) => {
                      handleIconUpdate(e.target.files[0]);
                    }}
                  />
                  <Contained_btn
                    //color="secondary"
                    size="large"
                    //variant="contained"
                    component="span"
                  >
                    New
                  </Contained_btn>
                </label>
              </Box>
              <Divider orientation="vertical" flexItem />
            </Box>
            <Box sx={styles.marginTop}>
              <InputLabel>Menu Category </InputLabel>
              <FormControl fullwidth>
{/*                 <InputLabel id="categories-label">Menu Category</InputLabel>
 */}                <Select sx={styles.style}
                  labelId="categories-label"
                  id="categories"
                  variant="outlined"
                  value={category}
                  onChange={(e) => {
                    setPropertiesChanged(true);
                    setCategory(e.target.value);
                  }}
                >
                  {menuCategories.map((category) => {
                    return (
                      <MenuItem key={category.id} value={category.id}>
                        {category.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box sx={styles.marginTop}>
              <Typography variant="button">Items:</Typography>
              {group?.items?.map((item) => {
                return (
                  <Box key={item.name}>
                    <Typography variant="body1">{item.name}</Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
            <Box
              sx={styles.box[8]}
            >
              <Outlined_btn
                //variant="outlined"
                onClick={handleResetToStratus}
                //color="secondary"
              >
                Reset to Default
              </Outlined_btn>
              {propertiesChanged ? (
                <Contained_btn
                  //variant="contained"
                  onClick={handleUpdate}
                  //color="secondary"
                >
                  Save Changes
                </Contained_btn>
              ) : null}
            </Box>
            {updating ? <CircularProgress color="secondary"/> : null}
        </Box>
    </Drawer>
    // </ClickAwayListener>
  );
};

const styles = {
  drawerHeader: {
    backgroundColor: "#000",
    color: "#fff",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  iconButton: { color: "#fff", textAnchor: "right" },
  cancelIcon: { textAlign: "right", color: "#fff" },
  box: {
    1: {
      "& .MuiTextField-root": { margin: 2, width: "90%" },
    },
    2: { display: "flex", flexDirection: "row" },
    3: { width: "3rem", margin: "auto" },
    4: {
      paddingTop: "1rem",
      paddingLeft: "3rem",
      paddingRight: "3rem",
      marginBottom: "1rem",
    },
    5: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    6: {
      width: "100%",
      display: "flex",
      flexDirection: "col",
      justifyContent: "left",
      alignItems: "left",
    },
    7: {
      width: "60%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    8: {
      marginTop: "2rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }
  },
  marginTop: { marginTop: "2rem" },
  marginLeft: { marginTop: "2rem" },
  img: {
    marginRight: "10px",
    marginLeft: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  btn: { paddingLeft: "2rem", paddingRight: "2rem" },
  tenrem: { fontSize: "10rem" }
};

const Input = styled("input")({
  display: "none",
});

const IMG_ = styled('img')`
  margin-right: 10px;
  margin-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
`;
