import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import { getDivisions } from "../../../data/queries/queryAPI";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";

export const CollapseDivisions = (props) => {
  const [divisionObj, setDivisionObj] = useState("");
  const divisionsQuery = useQuery(["allDivisions"], () => getDivisions(), {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    const division = divisionsQuery?.data?.data.find((divisionObj) => {
      return divisionObj.id === props.divisionId;
    });
    setDivisionObj(division);
  }, [divisionsQuery?.data, props.divisionId]);

  return (
    <Box sx={styles.box[1]}>
      <Collapse in={props.divisionsOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {divisionObj ? (
            <ListItemText key={divisionObj.id} primary={divisionObj.name} />
          ) : (
            <Typography variant="body">No users assigned</Typography>
          )}
        </List>
      </Collapse>
    </Box>
  );
};

const styles = {
  box: {
    1: { marginLeft: "4rem" }
  }
};
