import { Button, Chip, Divider, Typography } from "@mui/material";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import {
    closeServicesConfigDrawer,
    openServicesConfigDrawer,
    servicesConfigDrawerOpen,
} from "Context/DrawerStates";
import { useEffect, useState } from 'react';

import CustomDataGrid from "./CustomDataGrid"
import { styled } from "@mui/material/styles";

export const Service_items_Table = (props) => {

    const [selectionModel, setSelectionModel] = useState([])
    const [selectedService, setSelectedService] = useState(props.selectedService);
    const [serviceData, setServiceData] = useState(props.serviceData);


    const columns = [
        { field: "description", headerName: "Name", width: 250, renderCell: (params) => {
            if(params.value.includes("?")){
                return(
                    <p>{params.value.toString().replace("?", "\u00B0")}</p>
                )
            }
        } },
        { field: "cid", headerName: "CID", width: 100 },
        { field: "conditionId", headerName: "Constraint", width: 250, renderCell: (params) => {
            if(params.row.conditionId !== undefined){
                if(serviceData){
                    let service = serviceData.find(i => i.id === selectedService);
                    if(service?.conditions){
                        let condition = service?.conditions?.find(i => i.conditionId === params.row.conditionId);
                        if(condition?.min_val && condition?.max_val){
                            if(params.row.conditionId === 0){
                                return (
                                    <p>None</p>
                                )
                            }
                            else if(condition.min_val === -1 && condition.max_val === -1){
                                return (
                                    <p>None</p>
                                )
                            }else if(condition.min_val === -1 && condition.max_val !== -1){
                                return(
                                    <p>&lt;&#61; {condition.max_val}</p> 
                                )
                            }else if(condition.min_val !== -1 && condition.max_val === -1){
                                return (
                                    <p>&gt;&#61; {condition.min_val}</p>
                                )
                            } else if(condition.min_val !== -1 && condition.max_val !== -1){
                                return(
                                    <p>{condition.min_val} to {condition.max_val}</p>
                                )
                            }
                        }
                        
                    }
                }
            }
        }},
        { field: 'materialSprite', headerName: "Icon", width: 100, renderCell: (params) => (
            <IMG_ 
                src={params.value} 
                alt="Failed"
            />
          )},
        
        
        { field: "partNumber", hide: true },
        { field: "supported", headerName: "Status", width: 150, renderCell: (params) => {
            if(params.row.supported && params.row.isExcluded){
                return (
                    <p>Excluded</p>
                )
            }else if(params.row.supported && !params.row.isExcluded){
                if(params.row.auto){
                    return (
                        <p>Auto Fill</p>
                    )
                }else{
                    return(
                        <p>Supported</p>
                    )
                }
            }else if(!params.row.supported){
                return(
                    <p>Unsupported</p>
                )
            }
        }},
        { field: "tab", headerName: "Category", width: 200, renderCell: (params) => {
            if(params.row.description !== undefined){
                if(serviceData){
                    let service = serviceData.find(i => i.id === selectedService);
                    if(service.groups){
                        let group = service.groups.find(i => i.name === params.row.description)
                        if(group !== undefined){
                            if(group.tab !== undefined){
                                return(
                                    <p>{group.tab}</p>
                                )
                            }  
                        }
                    }
                }
            }
        }},
        { field: "connector", headerName: "Connector", width: 400, renderCell: (params) => {

            //NEEDS CORRECTED IMPLEMENTATION
            if(params.row.connections !== undefined){
                return params.row.connections.map((e, index) => {
                    if(index === params.row.connections.length || index === params.row.connections.length-1){
                        if(e.sex === 0){
                           return <p>M-{e.type}</p>  
                        }else{
                            return <p>F-{e.type}</p>
                        }
                        
                    }else{
                        if(e.sex === 0){
                            return <p>M-{e.type}, </p>  
                         }else{
                             return <p>F-{e.type}, </p>
                         }
                    }
                    
                })
                
            }
        }}
    ];

    useEffect(()=> {
    }, [props.rows, props.selectedServiceId, selectedService])

    const openDrawer = (userId) => {
        //props.setUserForDrawer(userId);
        props.setSelectedItemForDrawer(props.rows.find(i => i.partNumber === userId));
        openServicesConfigDrawer();
      };

    const handleRowClick = (userId) => {
       openDrawer(userId);
    };
    return(
        <>
            {/* <Divider sx={styles.margin_bottom}><Typography color="secondary">Items</Typography></Divider> */}
            <CustomDataGrid
                sx={styles.margin_bottom}
                rows={props.rows}
                columns={columns}
                autoHeight={true}
                hideFooter={true}
                disableSelectionOnClick={true}
                getRowId={(row) => row.partNumber}
                onSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel);
                    //props.setSelectionTabModel(newSelectionModel);
                  }}

                onRowClick={(params) => {
                  handleRowClick(params.id);
                }}
                selectionModel={selectionModel}
                disableColumnMenu={true}
                components={{
                    Toolbar: GridToolbar,
                  }}
                variant="elevation"
                
            />
        </>
    );

};


const DataGridPro_ = styled(DataGridPro)`
  .MuiDataGrid-columnHeaderTitle {
    color: #F15A29;
  };
`;

const styles = {
    margin_bottom: {marginBottom: "20px"}
}

const IMG_ = styled('img')`
    padding-left: 0px !important;
    padding-top: 0px !important;
    width: 48px !important;
    height: 48px !important;
`;