import { Box, Button, Popover } from "@mui/material";
import React, { useEffect, useState } from "react";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMore from "@mui/icons-material/ExpandMore";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { PopoverButtons } from "./PopoverButtons";

const StatusBuilderTableMenu = (props)=> {

    const [anchorEl, setAnchorEl] = useState(null);



    const handleOpenPopover = async (event) => {
      event.stopPropagation();
      const action = async () => { setAnchorEl(event.currentTarget); };
      await action();
    };
    
    const handleClose = () => {
      setAnchorEl(null);
    };
    const popoverOpen = Boolean(anchorEl);
    const id = popoverOpen ? "simple-popover" : undefined;
    return(
    <div>
        <Button onClick={(e) => handleOpenPopover(e)}>
          {
            (props.variant === "menu" || props.variant === "Menu") ?
            <MoreHorizIcon color="secondary"/>
            :
            (props.variant === "group" || props.variant === "Group") ?
              <>
                { popoverOpen ?
                  <ExpandMore/>
                  :
                  <ChevronRightIcon/>
                }
              </>      
            :
            null
          }
          
        </Button>
     
        <Popover
          id={props.params.id}
          open={popoverOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
            {
              (props.variant === "menu" || props.variant === "Menu") ?
              <PopoverButtons
                elevation={0}
                handleClose={ () => setAnchorEl(null) }
                selectedStatus={props.selectedStatus}
                companyId={props.companyId}
                setIsConfiguring={props.setIsConfiguring}
              />
              :
              // <span>{props.params.row.children[1].name}</span>
              <Box sx={{padding: "20px"}}>
                
              </Box>
            }
            
        </Popover>  

    </div>
  );
};

export default StatusBuilderTableMenu;