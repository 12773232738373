import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  assignUserDivision,
  deleteCognitoUser,
  getDivisions,
  getUsers,
  reinviteCognitoUser,
  unassignUserDivision,
  unassignUserProject,
  updateCognitoUser,
  updateLocalUser,
} from "../../../data/queries/queryAPI";
import {
  closeUsersDrawer,
  usersDrawerOpen,
} from "../../../Context/DrawerStates";
import { useMutation, useQueryClient } from "react-query";

import CancelIcon from "@mui/icons-material/Cancel";
import ErrorDialog from "components/Utilities/ErrorDialog";
import MenuItem from "@material-ui/core/MenuItem";
import { Outlined_btn } from "components/Theme/Styled";
import clone from "just-clone";
import { styled } from "@mui/material/styles";
import { updateUser } from "data/queries/queryAPI";
import { useQuery } from "react-query";

//import { CircularProgress } from "@material-ui/core";







// import ClickAwayListener from "@mui/material/ClickAwayListener";
export const UsersDrawer = (props) => {
  const [propertiesChanged, setPropertiesChanged] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [status, setStatus] = useState(false);
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [canEditProjects, setCanEditProjects] = useState(false);
  const [canEditServices, setCanEditServices] = useState(false);
  const [canEditUsersDivisions, setCanEditUsersDivisions] = useState(false);
  const queryClient = useQueryClient();
  const divisions = queryClient.getQueryData(['allDivisions'])?.data;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleCloseDialog = () => {
      setDialogOpen(false);
  };


  const StyledSwitch = styled(Switch)(({theme}) => ({
    '& .MuiSwitch-switchBase':{
      transition: 'translateX(10px)',
      transitionDuration: '300 ms',

      '& .MuiSwitch-thumb':{
        color: '#000000',
        transitionDuration: '300 ms',
        marginTop: 9,
        marginLeft: 8

      },
      '&.Mui-checked': {
        '& .MuiSwitch-thumb' : {
          color: '#F15A29',
          marginTop: 9,
          marginLeft: 7
        },
        '& ~ .MuiSwitch-track':{
          backgroundColor: '#F15A29',

        }
      },
      '& ~ .MuiSwitch-track':{
        backgroundColor: 'black',

      }
    },
}));

  const drawerWidth = 400;
  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  }));

  useEffect(() => {
    setFullName(props.user?.name);
    setEmail(props.user?.email);
    setStatus(props.user?.enabled);
    setCanEditProjects(props.user?.accesses.canEditProjects);
    setCanEditServices(props.user?.accesses.canEditServices);
    setCanEditUsersDivisions(props.user?.accesses.canEditUsersDivisions);
  }, [props.user]);

  const { mutate: addUserToDivision } = useMutation(assignUserDivision, {
    onSettled: (data) => {
      if (data.status === 200) {
        queryClient
          .refetchQueries(["allDivisions"])
          .catch((error) => {
            setErrorMessage(error.message || 'An error occurred');
            setDialogOpen(true);
          });
      } else {
        setErrorMessage('An error occurred');
        setDialogOpen(true);
      }
    },
    onError: (error) => {
      setErrorMessage(error.message || 'An error occurred');
      setDialogOpen(true);
    },
  });

  const { mutate: editCognitoUser } = useMutation(updateCognitoUser, {
    onSettled: (data) => {
      editLocalUser(data)
    },
    onError: (error) => {
      setErrorMessage(error.message || 'An error occurred');
      setDialogOpen(true);
    },
  });

  const { mutate: editLocalUser } = useMutation(updateLocalUser, {
    onSettled: async (data) => {
      await divisions.forEach((division) => {
        if(!data?.divisions?.includes(division.id)){
          let payload = {}
          payload['userId'] = data.id;
          payload['divisionId'] = division.id;
          addUserToDivision(payload);
        }
      });
      queryClient.refetchQueries(["allDetailedUsers"]).then(()=> {
        setUpdating(false);
        setPropertiesChanged(false);
      });
    },
    onError: (error) => {
      setUpdating(false);
      setErrorMessage(error.message || 'An error occurred');
      setDialogOpen(true);
    },
  });

  const { mutate: deleteUser } = useMutation(deleteCognitoUser, {
    onSettled: (data) => {
      queryClient.refetchQueries(["allDetailedUsers"]).then(() => {
        setStatus(false);
        setEmail("");
        setFullName("");
        setCanEditProjects(false);
        setCanEditServices(false);
        setCanEditUsersDivisions(false);
        setIsDeleting(false);
        closeUsersDrawer();
      });
    },
    onError: (error) => {
      setIsDeleting(false);
      setErrorMessage(error.message || 'An error occurred');
      setDialogOpen(true);
    },
  });

  const handleNameChange = (value) => {
    setFullName(value);
    setPropertiesChanged(true);
  };
  const handleEmailChange = (value) => {
    setEmail(value);
    setPropertiesChanged(true);
  };

  const handleAdminPermissionChange = (value) => {
    setCanEditUsersDivisions((canEditUsersDivisions) => !canEditUsersDivisions);
    setPropertiesChanged(true);
  };
  const handleProjectsPermissionChange = () => {
    setCanEditProjects((canEditProjects) => !canEditProjects);
    setPropertiesChanged(true);
  };
  const handleServicesPermissionChange = (value) => {
    setCanEditServices((canEditServices) => !canEditServices);
    setPropertiesChanged(true);
  };

  const handleStatusChange = (value) => {
    setStatus(value);
    setPropertiesChanged(true);
  };


  //TODO: ADD STATUS REPORTING?
  //TODO: ADD CONFIRMATION DIALOG?
  const removeUser = () => {
    setIsDeleting(true);
    handleRemoveUser(props.user)
    deleteUser(props.user.id);
  };

  const divisionsQuery = useQuery(["allDivisions"], () => getDivisions(), {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  const usersQuery = useQuery(["allUsers"], () => getUsers(), {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  })

  const handleRemoveUser = (user) => {
    const payload = {};
    payload.userId = user.id;
    usersQuery?.data?.data.forEach((userInList) => {
      if(user.username === userInList.username){
        usersQuery?.data?.data.splice(userInList, 1);
      }
    })
    user.divisions.forEach((division) => {
      payload.divisionId = division;
      let divisionToUpdate = divisionsQuery?.data?.data.filter(e => e.id === division);
      if(divisionToUpdate.length > 0){
        divisionToUpdate[0].users.splice(divisionToUpdate[0].users.find(e => e.id === user.id), 1)
      }

      unassignUserDivision(payload);
    })

    return console.log("SUCCESSFULLY DELETED USER AND ALL ASSOCIATED DIVISIONS");
  };

  //TODO: ADD STATUS REPORTING?
  const resendInvite = () => {
    reinviteCognitoUser(props.user.id);
  };

  const handleUpdate = () => {
    setUpdating(true);
    const updatedUser = clone(props.user);
    updatedUser.name = fullName;
    updatedUser.email = email;
    updatedUser.enabled = status;
    updatedUser.accesses.canEditProjects = canEditProjects;
    updatedUser.accesses.canEditServices = canEditServices;
    updatedUser.accesses.canEditUsersDivisions = canEditUsersDivisions;
    editCognitoUser(updatedUser);

    if(!status && props.user.projects.length) {
      Array(props.user.projects).forEach( project => {
        const payload = { userId: props.user.id, projectId: project };
        unassignUserProject(payload);
      })
    }
  };

  const handleClose = ()=> {
    props.closeDrawer();
  };

  return (
    <>
    <Drawer
      sx={{
        //backgroundColor: "#000",
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="temporary"
      anchor="right"
      open={usersDrawerOpen.use()}
      ModalProps={{ onBackdropClick: handleClose }}
      BackdropProps={{sx: {background: "rgba(0,0,0,0)"}}}
    >
      <DrawerHeader
        sx={styles.drawer.drawerHeader}
      >
        <Typography variant="h6" gutterBottom component="div">
          USER PROPERTIES
        </Typography>
        <IconButton
          sx={styles.iconButton}
          onClick={props.closeDrawer}
        >
          <CancelIcon sx={styles.cancelIcon} />
        </IconButton>
      </DrawerHeader>
        <Box
          component="form"
          sx={styles.box[1]}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="name"
            label="Name"
            value={fullName}
            onChange={(e) => handleNameChange(e.target.value)}
          />
          <TextField id="id" value={props.user?.username} disabled={true} />
          <TextField
            id="email"
            label="Email"
            value={email}
            onChange={(e) => handleEmailChange(e.target.value)}
          />
          <InputLabel sx={styles.marginLeft} id="status-label">
            Group:
          </InputLabel>
          <Box
            sx={styles.box[2]}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <StyledSwitch
                    checked={canEditUsersDivisions || false}
                    onChange={handleAdminPermissionChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Admin"
                labelPlacement="start"
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    checked={canEditProjects || false}
                    onChange={handleProjectsPermissionChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Projects"
                labelPlacement="start"
              />
              <FormControlLabel
                control={
                  <StyledSwitch
                    checked={canEditServices || false}
                    onChange={handleServicesPermissionChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Services"
                labelPlacement="start"
              />

            </FormGroup>
          </Box>
          <InputLabel sx={styles.inputLabel} id="status-label">
            Status:
          </InputLabel>
          <Select
            labelId="status-label"
            id="status"
            defaultValue=""
            value={status}
            label="Status"
            onChange={(e) => handleStatusChange(e.target.value)}
            sx={styles.select}
          >
            <MenuItem key="Active" value={true}>
              Active
            </MenuItem>
            <br></br>
            <MenuItem key="Inactive" value={false}>
              Inactive
            </MenuItem>
          </Select>


            <Box
              sx={styles.box[3]}
            >
              {props.user?.status === "FORCE_CHANGE_PASSWORD" ? (
                <Outlined_btn onClick={resendInvite}>
                  Resend Invite
                </Outlined_btn>
              ) : null}
                <Outlined_btn onClick={removeUser}>

                  {isDeleting ? <CircularProgress color="secondary" /> : "Delete User"}
                </Outlined_btn>

              <Button
                variant="contained"
                disabled={propertiesChanged === false}
                id="handle-update-user"
                onClick={handleUpdate}
                color="secondary"
              >

                {updating ? <CircularProgress size="1rem" color="white" /> : "Save Changes"}
              </Button>
            </Box>
        </Box>
    </Drawer>
    <ErrorDialog open={dialogOpen} handleClose={handleCloseDialog} errorMessage={errorMessage} />

    </>
  );
};

const styles = {
  box: {
    1: {"& .MuiTextField-root": { margin: 2, width: "90%" }},
    2: {
      display: "flex",
      flexDirection: "col",
      paddingLeft: "2rem",
      paddingRight: "6rem",
      justifyContent: "center",
    },
    3: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      marginTop: "2rem",
      paddingLeft: "1rem",
      paddingRight: "1rem",
    }
  },
  drawer: {
    drawerHeader: {
      backgroundColor: "#000",
      color: "#fff",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    }
  },
  iconButton: { color: "#fff", textAnchor: "right" },
  cancelIcon: { color: "#fff", textAlign: "right" },
  inputLabel: { marginLeft: "20px" },
  select: { width: "90%", marginLeft: "20px" },
  color: {
    white: {color: "#fff"},
    flame: {color: "#F15A29"}
  },
  borderColor: {
    flame: {borderColor: "#F15A29"}
  },
  btn: {
    outlined: {color: "#F15A29", borderColor: "#F15A29"},
    contained: {color: "white", backgroundColor: "#F15A29"}
  },
  marginLeft: { marginLeft: "20px" }
};
