import './PartContainer.css';

import React, { useEffect, useRef, useState } from 'react';
import {
    useAPICallback,
    useAPIData,
    useAPIEffect,
} from "./data/hooks/customHooks";

import { Box, Card, Grid } from '@mui/material';
import Loading from "./Loading";
import { getServiceItems } from "./data/queries/queryAPI"
import { styled } from "@mui/material/styles";


const PartContainer = (props) => {
    const {service, config, setSelectedItem, setServiceParts, serviceParts} = props;
    const [items, setItems] = useState([]);
    const [standardItems, setStandardItems] = useState([]);
    const [hover, setHover] = useState({hover: false});
    //const [selectedItem, setSelectedItem] = useState([]);
    const [selected, setSelected] = useState('none');
    const [
        serviceItemsLoading,
        serviceItemsError,
        getServiceItemDetails,
      ] = useAPIEffect(async (serviceId) => {
        const resp = await getServiceItems(serviceId);
        setItems({
          isLoading: serviceItemsLoading,
          isError: serviceItemsError,
          data: resp.data.connectors,
        });
        return resp;
      });

    useRef(React.createRef());
    
    useEffect(() => {
        if(service !== 'none'){
          setItems(getServiceItemDetails(service));
        }
        
        if(items){
          setStandardItems(items);
        }
        
    }, [config, config.connectors])

    useEffect(() => {
      if(config && items && Array.isArray(items.data)){
        if(config.connectors !== [] && props.config.connectors){
          config.connectors.map((configedPart) => {
            items.data.map((itemPart, index) => {
              if(configedPart.partNumber === itemPart.partNumber){
                items.data[index] = JSON.parse(JSON.stringify(configedPart));
                setItems(items)
                
              }
            })
          })
        }else{
          if(standardItems){
            setItems(standardItems)
          }
        }
      }
      setServiceParts(JSON.parse(JSON.stringify(items)));
    }, [items])
    const onSelect = (event) =>{
      localStorage.setItem("LAST_SELECTED_DUCT_ITEM", event);
      setSelected(event);
      setSelectedItem(event);
    }
    
    return(
        <div>
        { 
          (items !== null && config) && 
          <Card variant="outlined" sx={styles.card}>
            <Grid_ container spacing={1} >

                {
                (config.name === undefined) &&
                  <Loading message="Waiting for configuration selection..."></Loading>
                }
                {
                  (config.name !== undefined && !items.data) &&
                  <Loading message="Loading Parts..."></Loading>
                }
                {items.data && (items.data.map((e, key) =>{
                    return<Box 
                            key={key} 
                            sx={styles.div[2]} 
                            className="item" 
                            onClick={() =>{onSelect(e)}}
                          >
                            <IMG_ 
                              src={e.materialSprite}
                              alt="sketch"
                            />
                            <Box 
                              sx={styles.div[3]} 
                              className="multiline"
                            >
                              <Span_>{e.description}</Span_>
                            </Box>
                          </Box>
                }))}             
            </Grid_>
          </Card>

        }
        </div>
    );  
};

const styles = {
  div: {
    1: { width: 535,height: 512 , overflowX: 'hidden', overflowY: 'auto', border: '4px solid #003E70', backgroundColor: '#003E70' },
    2: { height:168,width: 73, backgroundColor: 'rgba(0,0,0, .3) !important' },
    3: { objectFit: "contain", overflow:'hidden', height: 60, paddingLeft: 1, paddingRight: 1 }
  },
  grid: { width: 512, height: 512, marginLeft: '1px', marginTop: '1px' },
  card: {
    width: 535, 
    height: 512, 
    overflowY: 'hidden', 
    overflowY: 'auto', 
    border: '2px solid #F15A29 !important', 
    backgroundColor: "white",
    
  },
  img: { height:64, objectFit: "contain", border:'4px solid lightgray' }
};

const IMG_ = styled('img')`
  height: 64px; 
  object-fit: contain; 
  border: 4px solid lightgray;
`;

const Span_ = styled('span')`
  font-size: 12px;
`;

const Grid_ = styled(Grid)`
  width: 512px; 
  height: 512px; 
  margin-left: 1px !important; 
  margin-top: 1px !important;
`;

export default PartContainer;