import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/system";
import WarningTwoToneIcon from "@mui/icons-material/WarningTwoTone";
import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import HistoryIcon from "@mui/icons-material/History";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { IconSelector } from "./IconSelector";

export const EditItemsGroupDialog = (props) => {
  const [showWarning, setShowWarning] = useState(false);
  const [category, setCategory] = useState("");
  const [menuIconFile, setMenuIconFile] = useState(null);
  const open = props.open;

  const handleClose = () => {
    props.setOpen(false);
  };
  const handleSave = () => {
    //TODO: IMPLEMENT SAVE BUTTON
  };

  const handleResetToStratus = () => {
    //TODO: IMPLEMENT RESET TO STRATUS BUTTON
  };

  //TODO: DOUBLE-CHECK THAT MENU-CATEGORIES IS NOT A DYNAMIC VALUE
  const menuCategories = [
    {
      id: "",
      name: "Select...",
    },
    {
      id: "Valve",
      name: "Valve",
    },
    {
      id: "Fitting",
      name: "Fitting",
    },
    {
      id: "Accessory",
      name: "Accessory",
    },
    {
      id: "Special",
      name: "Special",
    },
  ];

  useEffect(() => {
    if (props.groupToEdit) {
      setMenuIconFile(props.groupToEdit?.menuIcon);
      if (props.groupToEdit.items?.some((item) => item.supported) === false) {
        setShowWarning(true);
      }
      setCategory(props.groupToEdit.menuCategory);
    }
  }, [props.groupToEdit]);

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Item Group</DialogTitle>
        <DialogContent>
          {showWarning ? (

            <Box sx={styles.box[1]}>
              <Box sx={styles.box[2]}>

                  <WarningTwoToneIcon color="secondary" fontSize="large" />
              </Box>
              <Box>
                <DialogContentText>
                  One or more items of this group had no STRATUS data or are
                  unsupported.
                </DialogContentText>
                <DialogContentText>
                  Unsupported items will not be accessible from FOZ iOS.
                </DialogContentText>
              </Box>
            </Box>
          ) : null}
          <Divider sx={styles.divider} />
          <Box
            sx={styles.box[3]}
          >
            <Box
              sx={styles.box[4]}
            >
              <Typography variant="button">Menu Icon (.png):</Typography>
              <Tooltip title="Click to upload a new image">
                <Box>
                  <IconSelector
                    labelId="service-group-icon-selector"
                    displayIcon={menuIconFile}
                    onChange={(file) =>
                      setMenuIconFile(Buffer.from(file, "base64"))
                    }
                  />
                </Box>
              </Tooltip>
              <Tooltip title="Click to revert to base STRATUS data">
                <HistoryIcon onClick={handleResetToStratus} />
              </Tooltip>
            </Box>
            <Box sx={styles.marginTop}>
              <FormControl fullWidth>
                <InputLabel id="categories-label">Menu Category</InputLabel>
                <Select
                  labelId="categories-label"
                  id="categories"
                  value={category}
                  label="Menu Category"
                  onChange={(e) => setCategory(e.target.value)}
                >
                  {menuCategories.map((category) => {
                    return (
                      <MenuItem key={category.id} value={category.id}>
                        {category.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box sx={styles.marginTop}>
              <Typography variant="button">Items:</Typography>
              {props.groupToEdit.items?.map((item) => {
                return (
                  <Box key={item.name}>
                    <Typography variant="body1">{item.name}</Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
            <Button color="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button color="secondary" variant="contained" onClick={handleSave}>
              Save
            </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const styles = {
  box: {
    1: { display: "flex", flexDirection: "row" },
    2: { width: "3rem", margin: "auto" },
    3: {
      paddingTop: "1rem",
      paddingLeft: "6rem",
      paddingRight: "6rem",
      marginBottom: "1rem",
    },
    4: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: "2rem",
    }

  },
  divider: { marginTop: "1rem" },
  marginTop: { marginTop: "2rem" }

};