import { MenuItem, Select } from '@material-ui/core';
import React, {useRef} from 'react';

import {ReactComponent as DropdownIcon} from './assets/IconDropdown.svg'
import { black } from 'color-name';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  select: {
    color: '#000000',
    minWidth: '20em',
    height: '2.5em',
    padding: 10,
    borderRadius: 30,
    background: '#ffffff',
    fontSize: '1rem',
    fontWeight: 700,
  },
  selectIcon: {
    width: '2em', 
    height: '2em', 
    paddingRight: 0, 
    top:'auto',
  },
  // paper: {
  //   backgroundColor: theme.palette.background.paper,
  //   border: '2px solid #000',
  //   borderRadius: 5,
  //   boxShadow: theme.shadows[5],
  //   padding: theme.spacing(2, 4, 3),
  //   outline: 0,
  // },
}));


export default function StyledSelect(props) {
  const classes = useStyles();
  const { selected, onSelect, placeholderText, options, style } = props;
  useRef(React.createRef())
  return (
    <Select 
      style={style}
      className={classes.select} 
      variant='outlined' 
      value={selected} 
      onChange={onSelect} 
      IconComponent={(props)=><DropdownIcon {...props} className={props.className + ' ' + classes.selectIcon} />}
      defaultValue={placeholderText}
    >
      <MenuItem value='none' disabled> {placeholderText} </MenuItem>
      {
        options.map(option =>
          <MenuItem key={option.id} value={option.id}> {option.name} </MenuItem>
        )
      }
    </Select>
  );
}