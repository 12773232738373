import { DataGridPro, GridMenu } from "@mui/x-data-grid-pro";

import CustomToolbar from "./CustomToolbar.jsx";
import { withStyles } from "@material-ui/core/styles";

// MATERIAL UI CORES
//


const CustomDataGrid = withStyles((theme) => ({
  root: {
    // ROOT
    height: "100%",
    border: "none",
    // UNCOMMENT THIS TO HIDE SCROLL BAR INSIDE THE DATA GRID
    // '& .MuiDataGrid-window': {
    //   '&::-webkit-scrollbar': {
    //     // width: 10,
    //     // height: 10,
    //     backgroundColor: 'transparent',
    //   },
    //   '&::-webkit-scrollbar-thumb': {
    //     // width: 10,
    //     // height: 10,
    //     backgroundColor: 'transparent',
    //   }
    // },
    //TOOLBAR
    "& .MuiTypography-body1":{
        color: "#F15A29"
    },

    "& .MuiButton-label":{
        color: "#F15A29"
    },

    // CHECKED
    "& .Mui-checked": {
      color: "#F15A29"
    },

    // HEADER
    "& .MuiDataGrid-columnHeader:focus": {
      outline: "none"
    },
    "& .MuiDataGrid-columnHeader:focus-within": {
      outline: "none"
    },
    "& .MuiDataGrid-sortIcon": {
      color: "#F15A29"
    },
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      padding: 0
      // color: colors['slategray'],
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: 600,
      fontSize: 12
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none"
    },
    "& .MuiButtonBase-root": {
      color: "#F15A29"
    },
    // ROW
    "& .MuiDataGrid-row": {
      "&:hover": {
        backgroundColor: "lightgray"
      }
    },
    "& .MuiDataGrid-row.Mui-selected": {
      backgroundColor: `lightgray`,
    },

    // CELL
    "& .MuiDataGrid-cell:focus": {
      outline: "none"
    },
    "& .MuiDataGrid-cell:focus-within": {
      outline: "none"
    },

    // PAGINATION
    "& .MuiTablePagination-caption": {
      fontSize: 12
    },
    "& .MuiTablePagination-select": {
      fontSize: 12
    },
    "& .MuiIconButton-root": {
      padding: 8,
      color: '#F15A29'
    },'& .MuiSwitch-switchBase':{
      transition: 'translateX(10px)',
      transitionDuration: '300 ms',


      '& .MuiSwitch-thumb':{
        color: 'black',
        transitionDuration: '300 ms',
        marginTop: 9,
        marginLeft: 9

      },
      '&.Mui-checked': {
        '& .MuiSwitch-thumb' : {
          color: '#F15A29',
          marginTop: 9,
          marginLeft: 6
        },
        '& ~ .MuiSwitch-track':{
          backgroundColor: '#F15A29',

        }
      },
      '& ~ .MuiSwitch-track':{
        backgroundColor: 'black',

      }
    },
  }
}))(DataGridPro);

const paginationSelectStyles = {
  menu: {
    "& .MuiMenuItem-root": {
      color: "#F15A29",
      fontSize: 14,
      justifyContent: 'left',
    },

    "& .MuiList-root": {
      display: "flex !important",
      flexDirection: "column !important",
      justifyContent: 'left !important'
    }
    
  }
};

const NewCustomDataGrid = withStyles(paginationSelectStyles)((props) => (
  <CustomDataGrid
    {...props}
    components={{
      Toolbar: CustomToolbar,
    }}
    componentsProps={{
    
    panel: {
        sx: {
          '& .MuiTypography-root': {
            color: '#F15A29',
            fontSize: 16,
          },
          '& .MuiDataGrid-filterForm': {
            bgcolor: 'white',
          },
          '& .MuiTypography-input': {
              color: "#F15A29"
            },
          '& .MuiSwitch-root': {
            color: '#F15A29'
          },
          '& .MuiButton-label':{
            color: '#F15A29'
          },
          '& .MuiSwitch-input': {
            color: '#F15A29'
          },
          
        },
      },
    }}
  />
));

export default NewCustomDataGrid;
