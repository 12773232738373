import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import React, { useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import { Box } from "@mui/system";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import DesignServicesOutlinedIcon from "@mui/icons-material/DesignServicesOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import PermMediaOutlinedIcon from "@mui/icons-material/PermMediaOutlined";
import { getUserPermissions } from "Context/currentUser";
import { styled } from '@mui/material/styles';

//import {ListItem, ListItemText} from "@mui/material";

export const PersistentNavButtons = () => {

  const userPermissions_key = "JxXSoSKKP^N9tot9x4qDEGZZ%L2t^vYS";
  const userPermissions = getUserPermissions(userPermissions_key);
  const [admin, isAdmin] = useState(userPermissions.includes("AdminUsers"));
  const [servicePermissions, isServicePermissions] = useState(userPermissions.includes("ServiceCatalogEditors"));
  const [projectEditor, isProjectEditor] = useState(userPermissions.includes("ProjectEditors"));

  const CustomNavLink = ({ children, to, ...props }) => {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });
    if(match) {
      return (
        <MatchLink
          to={to}
          {...props}
        >
          {children}
        </MatchLink>
      );
    }
    else {
      return (
        <UnmatchedLink
          to={to}
          {...props}
        >
            {children}
        </UnmatchedLink>
      );
    }

  };

  const MatchLink = styled(Link)`
    color: #fff;
    text-decoration: none;
    border: 1px solid #FFA988;
    background: #F15A29;
  `;
  const UnmatchedLink = styled(Link)`
    color: #F15A29 !important;
    text-decoration: none;
    border: 1px solid #FFA988;
    background: #fff;
  `;

  const CustomListItem = styled(ListItem)`
    :hover { background: transparent; };
  `;

  return (
      <Box
        sx={styles.box}
        display="flex"
        flexDirection="row"
        alignItems="center"
        mb={2}
      >
        { admin ?
          <>
            <CustomNavLink to="/company">
              <CustomListItem button key="company">
                <ListItemText primary="Company"/>
              </CustomListItem>
            </CustomNavLink>
            <CustomNavLink to="/settings">
              <CustomListItem button key="settings">
                <ListItemText primary="Settings"/>
              </CustomListItem>
            </CustomNavLink>
            <CustomNavLink to="/users">
              <CustomListItem button key="users">
                <ListItemText primary="Users" />
              </CustomListItem>
            </CustomNavLink>

            <CustomNavLink to="/divisions">
              <CustomListItem button key="divisions">
                <ListItemText primary="Divisions" />
              </CustomListItem>
            </CustomNavLink>

          </>
          : null
        }
        { servicePermissions ?
          <>
            <CustomNavLink to="/services">
              <CustomListItem button key="services">
                <ListItemText primary="Services" />
              </CustomListItem>
            </CustomNavLink>
          </>
          : null
        }
        { servicePermissions ?
          <>
            <CustomNavLink to="/catalogs">
              <CustomListItem button key="catalogs">
                <ListItemText primary="Catalogs" />
              </CustomListItem>
            </CustomNavLink>
          </>
          : null
        }



        {/* ALWAYS AVAILABLE */}

        { projectEditor ?
          <>
            <CustomNavLink to="/projects">
              <CustomListItem button key="projects">
                <ListItemText primary="Projects" />
              </CustomListItem>
            </CustomNavLink>
          </>
          : null
        }

        <CustomNavLink to="/packages">
          <CustomListItem button key="packages">
            <ListItemText primary="Packages" />
          </CustomListItem>
        </CustomNavLink>

{/*         <CustomNavLink to="/configure">
          <CustomListItem button key="serviceConfigurations">
            <ListItemText primary="Service Configurations" />
          </CustomListItem>
        </CustomNavLink> */}
      </Box>
  );
};

const styles = {
  box: { paddingTop: "2rem", marginLeft: "2rem"}
};
