import "./Projects.css";

import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import { Contained_btn, Outlined_btn, Text_btn } from "components/Theme/Styled";
import { createProject, getDivisions } from "../../../data/queries/queryAPI";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import ErrorDialog from "components/Utilities/ErrorDialog";
import React from 'react'
import { Typography } from "@material-ui/core";
import { styled } from "@mui/material/styles";

export const CreateProjectDialog = (props) => {
  const [divisionsToDisplay, setDivisionsToDisplay] = useState([]);
  const [isCreatingProject, setIsCreatingProject] = useState(false);
  const [newProjectName, setNewProjectName] = useState(null);
  const [selectedDivision, setSelectedDivision] = useState(null);
  const [divisionError, setDivisionError] = useState(false);
  const queryClient = useQueryClient();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleCloseDialog = () => {
      setDialogOpen(false);
  };

  const divisionsQuery = useQuery(["allDivisions"], () => getDivisions(), {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  const { mutate: createNewProject } = useMutation( createProject, {

    onSettled: data => {

      queryClient.refetchQueries(["allProjects"])
      .then( () => {
        setIsCreatingProject(false);
        handleDialogClose();
      })
      .then( () => {
        setNewProjectName(null);
        setSelectedDivision(null);
      });

    },

    onError: (error) => {
      setIsCreatingProject(false);
      setErrorMessage(error.message || 'An error occurred');
      setDialogOpen(true);
    },

  });

  const handleDialogClose = () => {

    props.handleDialogClose();
    setDivisionError(false);
    setNewProjectName(null);
    setSelectedDivision(null);

  }

  const handleCreateProject = () => {

    if( 
      selectedDivision != null && 
      !divisionError && 
      selectedDivision != null ) {

      const payload = {};
      payload.name = newProjectName;
      payload.divisionId = selectedDivision;
      setIsCreatingProject(true);
      createNewProject(payload);

    } 
    else {

      setDivisionError(true);

    }

  };

  const handleSelectedDivisionChange = e => {

    const eSelect = e.target.value;
    setSelectedDivision(eSelect);
    handleDivisionSelectionLogic(eSelect);

  };

  const handleDivisionSelectionLogic = selection => {

    if( selection !== null && selection !== "" && selection !== " ") {

      setDivisionError(false);

    } 
    else {

      setDivisionError(true);

    }

  };


  useEffect( () => {

    if ( divisionsQuery.data?.data?.length > 0 ) {
      
      // ALPHABETIZE THE SERVICES
      const alphabetized = divisionsQuery.data.data.sort( (a, b) => {

        if ( a.name.toUpperCase() === b.name.toUpperCase() ) {

          return 0;

        }

        return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1;

      });

      setDivisionsToDisplay(alphabetized);

    }
  }, [divisionsQuery.data]);


  return (
    <>
      <Dialog 
        open={props.dialogOpen} 
        onClose={handleDialogClose} 
        fullWidth={true} 
        maxWidth={"sm"}
        sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
      >
        <Box sx={{backgroundColor: '#F15A19', color: '#fff', display: 'flex', justifyContent: 'left', alignItems: 'center', height: '60px'}}>
          <Box sx={{marginLeft: '2rem'}}>
            <Typography variant="h5" >Add Project</Typography>
          </Box>
        </Box>
        <TextField
          autoFocus
          id="name"
          label="New Project Name"
          type="text"
          sx={{width: '400px', margin: '1rem'}}
          variant="outlined"
          value={newProjectName}
          onChange={ (e) => setNewProjectName(e.target.value)}
          autoComplete='off'
        />
        {divisionsToDisplay.length > 0 ? (
          <FormControl
            fullWidth
            error={divisionError}
          >
              <InputLabel sx={{marginLeft: '1rem !important', marginTop: '1rem !important'}} id="division-select-label">Division</InputLabel>
              <Select
                labelId="division-select-label"
                id="division-select"
                value={selectedDivision}
                sx={{
                  width: "100%",
                  minWidth: "400px", // set the minimum width
                  maxWidth: "400px", // set the maximum width
                  margin: '1rem'
                }}
                label="Division"
                onChange={ e => handleSelectedDivisionChange(e) }
              >
                    {/* <div style={{display: "flex", flexDirection: "column"}}> */}
                      {divisionsToDisplay.filter(division => !division?.isDeleted
                      && (division?.id !== "stratus0-0000-0000-0000-000000000000")).map((division) => {
                        return (
                          <MenuItem_
                            key={division.id}
                            value={division.id}
                            //style={styles.menuItem}
                          >
                            {division.name}
                          </MenuItem_>
                        );
                      })}
                    {/* </div> */}
              </Select>
              { divisionError? <FormHelperText>Division selection required.</FormHelperText> : null }
          </FormControl>
        ) : (
          <p>Loading Divisions...</p>
        )}
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '1rem'}}>
          <Box sx={{marginRight: '1rem'}}>
            <Outlined_btn
              sx={{ width: '152px'}}
              onClick={
                () => {
                  setNewProjectName(null);
                  setDivisionError(false);
                  setSelectedDivision(null);
                  props.setDialogOpen(false)
                }
              }
            >
              Cancel
            </Outlined_btn>
          </Box> 
          {isCreatingProject === false ? (
            <Box sx ={{marginLeft: '1rem'}}>
              <Contained_btn
                onClick={handleCreateProject}
                disabled={
                  newProjectName != null ? // if newProjectName isn't null
                    newProjectName != '' ? // and newProjectName isn't an empty string
                      selectedDivision != null ? // and selectedDivision isn't null
                        selectedDivision != '' ? // and selectedDivision isn't and empty string
                        false // then the " Create Project " button isn't disabled
                        : true // else if selectedDivision IS an empty string it's disabled
                      : true // or if selectedDivision IS null it's disabled
                    : true // or if newProjectName IS an empty string it's disabled
                  : true // or if newProjectName IS null it's disabled
                }
              >
                Create Project
              </Contained_btn>
            </Box>
          ) : (
            <CircularProgress size={24} />
          )}
        </Box>
      </Dialog>
      <ErrorDialog open={dialogOpen} handleClose={handleCloseDialog} errorMessage={errorMessage} />

      </>
  );
};

const styles = {
  menuItem: {
    display: "flex",
    flexDirection: "column"
  },
};

const MenuItem_ = styled(MenuItem)`
  display: flex !important;
  flex-direction: column !important;
`;
