import {Box, Typography} from '@mui/material'
import React, {useEffect, useState} from 'react';
import{
    openConfigurePartDialogOpen,
    openServicesConfigDrawer
} from "../../../../Context/DrawerStates";

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CustomDataGrid from './../CustomDataGrid'
import Tooltip from '@mui/material/Tooltip';
import WarningIcon from '@mui/icons-material/Warning';
import clone from 'just-clone'
import { styled } from "@mui/material/styles";

export const ServiceConfigurationParts = (props) => {
    const [selectionModel, setSelectionModel] = useState([])
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(100);

    const [parts, setParts] = useState(props.parts)
    const conditions = props.conditions;
    const groups = props.groups;

    useEffect(() => {
        setParts(props.parts)
    },[props.parts])
    const columns = [
        { field: "description", headerName: "Name", width: 250, renderCell: (params) => {
            if(params.value.includes("?")){
                return(
                    params.value.toString().replace("?", "\u00B0")
                )
            }
        } },
        { field: "cid", headerName: "CID", width: 100 },
        { field: "conditionId", headerName: "Constraint", width: 150, renderCell: (params) => {
            if(params.row.conditionId !== undefined){
                if(conditions){
                    let condition = conditions.find(i => i.conditionId === params.row.conditionId);
                    if(!condition){
                        return(
                            <Tooltip title='Condition not found. Check service data.'>
                              <WarningIcon color="secondary"/>
                            </Tooltip>)
                    }else{
                        if(condition?.min_val && condition?.max_val){
                            if(params.row.conditionId === 0){
                                return (
                                    "None"
                                )
                            }
                            else if(condition.min_val === -1 && condition.max_val === -1){
                                return (
                                    "None"
                                )
                            }else if(condition.min_val === -1 && condition.max_val !== -1){
                                return(
                                    `<= ${condition.max_val}`
                                )
                            }else if(condition.min_val !== -1 && condition.max_val === -1){
                                return (
                                    `>= ${condition.min_val}`
                                )
                            } else if(condition.min_val !== -1 && condition.max_val !== -1){
                                return(
                                    `${condition.min_val} to ${condition.max_val}`
                                )
                            }
                        }
                    }  
                }
            }
        }},
        { field: 'materialSprite', headerName: "Icon", width: 100, renderCell: (params) => {
            if(params.row.imageType !== undefined){
                if(params.row.imageType === "png" || params.row.imageType === "jpg"){
                    return(
                        <IMG_ 
                            src={params.value} 
                            alt="Failed"
                        />
                    )
                }else{
                    return(
                        <>
                            <Tooltip title='Image is not PNG or JPG'>
                                <WarningIcon color="secondary"/>
                            </Tooltip>
                            <IMG_ 
                                src={params.value} 
                                alt="Failed"
                            />
                        </>
                    )
                }
            }else{
                return(
                    <IMG_ 
                        src={params.value} 
                        alt="Failed"
                    />
                )
            }
            
        }},
        { field: "partNumber", hide: true },
        { field: "supported", headerName: "Status", width: 150, renderCell: (params) => {
            if(params.row.supported && params.row.isExcluded){
                return (
                    "Excluded"
                )
            }else if(params.row.supported && !params.row.isExcluded){
                if(params.row.auto){
                    return (
                        "Auto Fill"
                    )
                }else{
                    return(
                        "Supported"
                    )
                }
            }else if(!params.row.supported){
                return(
                    "Unsupported"
                )
            }
        }},
        { field: "tab", headerName: "Category", width: 200, renderCell: (params) => {
            if(params.row.description !== undefined){
                if(groups){
                    let group = groups.find(i => i.name === params.row.description)
                    if(group !== undefined){
                        if(group.tab !== undefined){
                            return(
                                group.tab
                            )
                        }  
                    }
                }
                
            }
        }},
        { field: "connector", headerName: "Connector", width: 400, renderCell: (params) => {
            if(params.row.connections !== undefined){
                return params.row.connections.map((e, index) => {
                    if(index === params.row.connections.length || index === params.row.connections.length-1){
                        if(e.sex === 0){
                           return `M-${e.type}`  
                        }else{
                            return `F-${e.type}`
                        }
                        
                    }else{
                        if(e.sex === 0){
                            return `M-${e.type},` 
                         }else{
                             return `F-${e.type},`
                         }
                    }
                    
                })
                
            }
        }}
    ];

    return(
        <>
            {
                parts === undefined ? (
                    <Typography>Loading...</Typography>
                ) : null
            }
            {
                parts !== undefined && Array.isArray(parts) && parts.length > 0 ? (
                    <CustomDataGrid
                    rows={parts}
                    columns={columns}
                    autoHeight={true}
                    disableSelectionOnClick={true}
                    getRowId={(row) => row.partNumber ? row.partNumber : row.id}
                    onRowClick={(row) => {
                        props.setSelectedPart(clone(row.row))
                        openServicesConfigDrawer();
                    }}
                    selectionModel={selectionModel}
                    disableColumnMenu={true}
                    variant="elevation"
                    pagination={true}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[100, 200, 300, 400]}
                    onPageChange={(newPage) => setPage(newPage)}
                    />
                ) : null
            }
            
        </>
    );
}

const IMG_ = styled('img')`
    padding-left: 0px !important;
    padding-top: 0px !important;
    width: 48px !important;
    height: 48px !important;
`;