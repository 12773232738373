import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Contained_btn, Outlined_btn } from "components/Theme/Styled";
import {
  closeProjectHardDeleteDialog,
  projectHardDeleteDialogOpen
} from "../../../Context/DrawerStates";
import { useMutation, useQuery, useQueryClient } from "react-query";

import ErrorDialog from "components/Utilities/ErrorDialog";
import { deleteProject } from "data/queries/queryAPI";
import {styled} from "@mui/material/styles";
import { useState } from "react";

// TODO :: CREATE DRAWERSTATES FOR HARD DELETE OF APPLICABLE PAGE



export const HardDeleteDialog = (props) => {

  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleCloseDialog = () => {
      setDialogOpen(false);
  };

  const { mutate: deleteItem } = useMutation(deleteProject, {
    onSettled: (data) => {
      queryClient
        .refetchQueries(["allProjects"])
        .then(() => {
          setIsDeleting(false);
        })
        .then(() => {
          handleClose();
        })
        .catch((error) => {
          setIsDeleting(false);
          setErrorMessage(error.message || 'An error occurred');
          setDialogOpen(true);
        });
    },
    onError: (error) => {
      setIsDeleting(false);
      setErrorMessage(error.message || 'An error occurred');
      setDialogOpen(true);
    },
  });

  const handleClose = (e) => {
    //TODO:: CHANGE TO APPLICABLE DRAWER STATE
    closeProjectHardDeleteDialog();
    setOpen(false);
  };

  const handleDelete = () => {
    setIsDeleting(true);
    deleteItem(props.project.id);
  };

  const Bold = styled('span')`
    font-weight: bold;
  `;

  return (
    <>
        {/* CHANGE DIALOG OPEN PROP*/}
        <Dialog open={projectHardDeleteDialogOpen.use()} onClose={handleClose}>
          <DialogTitle color="secondary" ><Bold>WARNING</Bold></DialogTitle>
          <DialogContent>
            {/* TODO::: SWITCH TO TYPOGRAPHY COMPONENT ? */}
            <Typography_>
              <Bold>WARNING: </Bold>
              The project and all associated packages will be permanently deleted. Are you sure?
            </Typography_>
          </DialogContent>
          <DialogActions>
            <Outlined_btn
              onClick={(e) => handleClose(e)}
            >
              Cancel
            </Outlined_btn>
            <Contained_btn
              onClick={handleDelete}
            >
              Delete
              {isDeleting ? <Progress_ size={12} /> : null}
            </Contained_btn>
          </DialogActions>
        </Dialog>
        <ErrorDialog open={dialogOpen} handleClose={handleCloseDialog} errorMessage={errorMessage} />

    </>
  );
};

const Progress_ = styled(CircularProgress)`
  margin-left: 6px;
  color: white !important;
`;

const Content_ = styled('p')`
  font-size: 18px;
`;

const Typography_ = styled(Typography)`
  //width: 75% !important;
  flex-shrink: 0 !important;
`;
