import './PropertyContainer'

import React, {useEffect, useRef, useState} from 'react';

import {Box} from "@mui/material";
import Card from '@mui/material/Card';
import CheckBox from "@material-ui/core/CheckBox"
import { ConsoleLogger } from '@aws-amplify/core';
import Grid from "@material-ui/core/Grid";
import Loading from './Loading';
import MenuItem from "@material-ui/core/MenuItem";
import PartPropertyDropdown from './PartPropertyDropdown';
import PartPropertyValue from './PartPropertyValue';
import Select from "@material-ui/core/Select";

const PropertyContainer = (props) => {
    const[properties, setProperties] = useState([]);
    const[length, setLength] = useState({length: 500});
    const[checkedState, setCheckedState] = useState([])
    const[propsToSend, setPropsToSend] = useState([]);
    useEffect(() => {

    }, [properties])

    const handlePropertyChange = (index, property) => {
        const newList = properties.map((item) =>{
          if(item.index === index){
            const updatedItem = property;
            return updatedItem;
          }
          return item;
        });
        setProperties(newList);
        props.setSelectedProperties(newList);
        
    }
    useRef(React.createRef());

    useEffect(() =>{
      setLength(500);
    }, [])

    useEffect(() => {
      setProperties(props.item.properties);
      props.setSelectedProperties(props.item.properties)
      if(props.item.properties !== undefined){
        setLength(props.item.properties.length * 51);
        let newArray = [];
        newArray = new Array(props.item.properties.length).fill(true)
        setCheckedState(newArray);
      }
    }, [props.item])

    const changeCheckedState=(index, value) =>{
      checkedState[index] = value;
      let sending = []
      checkedState.map((value, index) => {
        if(value === true){
          properties[index].isLocked = false;
          return sending.push(properties[index])
        }else{
          properties[index].isLocked = true;
          return sending.push(properties[index])
        }
      })
      setPropsToSend(sending)
      props.setSelectedProperties(sending)
    }
    const render = () => {
      if(properties !== null && (props.dimensions === [] || props.dimensions === undefined)) {
        return (
          <Card variant="outlined" sx={styles.card}>
            <Grid 
              container 
              direction="column" 
              sx={{
                width: 310, 
                height: length, 
                marginLeft: '1px'
              }} 
            >
              
                {properties && Array.isArray(properties) ? (properties.map((e, index) =>{
                    if(e.possibleValues.length > 0)
                        return <PartPropertyDropdown 
                                  key={index} 
                                  propertyIndex={index} 
                                  property={e} 
                                  handlePropertyChange={handlePropertyChange} 
                                  sendPropertyChange={changeCheckedState}
                                />
                    return <PartPropertyValue 
                              key={index} 
                              propertyIndex={index} 
                              property={e} 
                              handlePropertyChange={handlePropertyChange} 
                              sendPropertyChange={changeCheckedState}
                            />
                    })) : (<Loading message="No Properties for Part..."></Loading>)}
            </Grid>
          </Card>
        )
      }
      else if (properties !== null && (props.dimensions !== [] && props.dimensions !== undefined)){
        return (
          <Card variant="outlined" sx={styles.card}>
            <Grid 
              container 
              direction="column" 
              sx={{
                width: 400, 
                height: length, 
                marginLeft: '1px'
              }} 
            >
                {properties && Array.isArray(properties) ? (properties.map((e, index) =>{
                    if(e.possibleValues.length > 0)
                        return <PartPropertyDropdown 
                                  dimension={props.dimensions[index]} 
                                  key={index} 
                                  propertyIndex={index} 
                                  property={e} 
                                  handlePropertyChange={handlePropertyChange} 
                                  sendPropertyChange={changeCheckedState}
                                />
                    return <PartPropertyValue 
                              dimension={props.dimensions[index]} 
                              key={index} 
                              propertyIndex={index} 
                              property={e} 
                              handlePropertyChange={handlePropertyChange} 
                              sendPropertyChange={changeCheckedState}
                            />
                    })) : (<Loading message="Waiting for Part Selection..."></Loading>)}
            </Grid>
          </Card>
        )
      }
      else {
        return (
          <Card variant="outlined" sx={styles.card}>
            <p>No Properties Loaded</p>
          </Card>
        );
      };
    }
    return(     
        <div>
        {
          render()
        }
        </div>
    );  
};

const styles = {
  div: {
    1: {
      width: 310,
      height: 300, 
      overflowX: 'hidden', 
      overflowY: 'scroll', 
      border: '4px solid #003E70', 
      backgroundColor: '#003E70'
    },
  },
  card: {
    width: 310, 
    height: "auto", 
    overflowX: 'hidden', 
    overflowY: 'scroll', 
    border: '1px solid #F15A29 !important', 
    backgroundColor: 'white',
    paddingBottom: "1rem !important",
    paddingTop: ".5rem !important"
  }
};
export default PropertyContainer;