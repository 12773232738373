import React, { useState } from 'react';
// import {ReactComponent as CameraIcon} from './assets/IconCamera.svg';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { Tooltip } from '@material-ui/core';
import {
  InfoTooltip,
  ErrorTooltip,
  SuccessTooltip,
} from "../../../StyleToolTips.js";

export default function LogoSelector (props) {
  const { onChange, displayIcon, labelId, width, height } = props;
  const w = width || 200;
  const h = height || 100;



  const Button1 = styled('button')`
    border-radius: 20px;
    color: black;
    background: white;
    width: w;
    height: h;
    overflow: hidden;
    padding: 0;
    cursor: pointer;
  `;
  const IMG = styled('img')`
    color: black;
    object-fit: contain;
    height: inherit;
    width: inherit;
    border-radius: inherit; 
  `;
  const Label_ = styled('label')`
    justify-content: center;
    cursor: pointer;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    position: relative;
    background: white;
    width: 180px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 10px;
    object-fit: contain;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
  `

  const Input_ = styled('input')`
    opacity: 0;
    position: absolute;
    pointer-events: none;
    width: 1px;
    height: 1px;
  `;
  return (
    <Button1
    >
      <Label_
        htmlFor={labelId}
      >
        {
          (displayIcon)
          ?
            <IMG
              src={displayIcon}
              alt='icon preview'
              >

            </IMG>
          :
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <div>
                  Upload Image
                </div>
                {/* <CameraIcon style={{width:'32px', height:'32px'}} /> */}
                <i className="fas fa-camera fa-3x"></i>
            </Box>
        }
      </Label_>
      <Input_
        //styled={styled.input}
        id={labelId}
        type="file"
        accept=".png"
        onChange={(e)=>{onChange(e.target.files[0])}}
      />
  </Button1>
  );
};