import {
  DataGridPro,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  LicenseInfo,
  gridDateComparator,
  useGridApiRef
} from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from "react";
import {
  newGetServicesWithGroups,
  stratusGetServicesForFileGroup,
} from "data/queries/queryAPI";
import { useQuery, useQueryClient } from "react-query";

import { Box } from "@mui/system";
import CustomDataGrid from "../../Divsions/components/CustomDataGrid";
import { forEach } from "jszip";
import { makeStyles } from '@material-ui/core/styles';

LicenseInfo.setLicenseKey(
  "b80f8e608cc2088edc19f2569e131cd3T1JERVI6MzIwMzQsRVhQSVJZPTE2Njc2MzcxNjYwMDAsS0VZVkVSU0lPTj0x"
);

export const ImportServiceTable = (props) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [loading, setLoading] = useState(false);
  const setServicesData = props.setServicesData;
  const queryClient = useQueryClient();


  const rows = props.servicesData.map( item => {
    const object = {
      Abbreviation: item.Abbreviation,
      FabId: item.FabId,
      Group: item.Group,
      id: item.FabId + "+" + item.ServiceTemplateId,
      imported: item.imported,
      Name: item.Name,
      ServiceTemplateId: item.ServiceTemplateId,

    }
    return object;
  })

  //const rows = props.servicesData;

  const servicesForFileGroupQuery = useQuery(
    [
      "servicesForFileGroup",
      props.selectedFileGroupId,
      "profile: ", 
      props.selectedProfile,
    ],
    () =>
      stratusGetServicesForFileGroup({
        fileGroupId: props.selectedFileGroupId,
        profile: props.selectedProfile,
      }),
    { staleTime: Infinity, refetchOnWindowFocus: false, keepPreviousData: true }
  );
  const importedServices = queryClient.getQueryData(['allServices'])
  useEffect(() => {
    setLoading(servicesForFileGroupQuery.isLoading);
  }, [servicesForFileGroupQuery]);

  useEffect(() => {
    const alreadyImported = (ServiceTemplateId, FabId, Profile) => {
      if (
        Array.isArray(importedServices?.data) &&
        importedServices?.data?.find((service) => {
          return service.templateId === ServiceTemplateId && service.fabId === FabId && service.serviceProfile === Profile;
        })
      ) {
        return "Yes";
      }
      return "No";
    };

    if (Array.isArray(servicesForFileGroupQuery?.data)) {
      const dataCopy = [...servicesForFileGroupQuery?.data];
      const container = dataCopy.map((service) => {

        service.id = service.ServiceTemplateId;
        service.imported = alreadyImported(service.ServiceTemplateId, service.FabId, service.Profile);
        return service;
      });
      setServicesData(container);
    }
  }, [
    importedServices?.data,
    servicesForFileGroupQuery?.data,
    setServicesData,
  ]);

  function CustomToolbar() {
    return (
        <GridToolbarContainer>
          <GridToolbarColumnsButton/>
          <GridToolbarFilterButton/>
          <GridToolbarDensitySelector/>
          <GridToolbarExport/>
        </GridToolbarContainer>
    );
  }


  return (
    
    <Box
      sx={styles.div}
    >
        <CustomDataGrid
          editMode="row"
          rows={rows}
          columns={[
            { field: "Name", headerName: 'Name', width: 425, minWidth: 150, maxWidth: 425, valueGetter: (params) => {
              return params.row?.Name
            }},
            { field: "Abbreviation", headerName: 'ABV', width: 175, valueGetter: (params) => {
              return params.row?.Abbreviation;
            }},
            { field: "Group", width: 225, valueGetter: (params) => {
              return params.row?.Group
            }},
            { field: "imported", headerName: "Already Imported?", width: 225 , valueGetter: (params) => {
              return params.row?.imported
            }},
          ]}
          checkboxSelection={true}
          pagination={true}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[25, 100, 250, 500]}
          onPageChange={(newPage) => setPage(newPage)}
          loading={loading}
          components={{
            Toolbar: CustomToolbar,
          }}
          onSelectionModelChange={(newSelectionModel) => {
            props.setSelectionModel(newSelectionModel);
          }}
          selectionModel={props.selectionModel}
        /> 
    </Box>
   
  );
};

const styles = {
  div: {
    height: 600,
    width: "100%",
    display: "flex",
    flexDirection: "col",
    justifyItems: "center",
  }
};