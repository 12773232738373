import { Box, Button, Checkbox, Typography } from '@mui/material';
import React, { useEffect, useState } from "react";
import{
    closeCreateStatusDialog,
    createStatusDialogOpen,
    openCreateStatusDialog
} from "../../../Context/DrawerStates";
import {
  getCompanyStatuses,
  stratusSetUploadStatus,
  updateCompanyStatuses,
} from "../../../data/queries/queryAPI.js";
import { useMutation, useQuery, useQueryClient } from "react-query";

import AddCircleIcon from "@mui/icons-material/AddCircle"
import CircularProgress from "@mui/material/CircularProgress";
import { DataGridPro } from "@mui/x-data-grid-pro"
import ErrorDialog from 'components/Utilities/ErrorDialog';
import ImportStatuses from './ImportStatuses';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import StatusBuilderTableMenu from './StatusBuilderTableMenu';
import StyledSelect from "./StyledSelect.jsx";
import clone from "just-clone";
import { styled } from '@mui/material/styles';

export default function StatusBuilder(props) {
  const companyId = props.companyId;
  const companyData = props.companyData;
  const defaultUploadStatus = companyData?.data?.data?.defaultUploadStatus;
  const queryClient = useQueryClient();
  const [statuses, setStatuses] = useState([])
  const [currentStatuses, setCurrentStatuses] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [isConfiguring, setIsConfiguring] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleCloseDialog = () => {
      setDialogOpen(false);
  };



  //get all companyStatuses
  const companyStatuses = useQuery(["companyStatuses"], () => getCompanyStatuses(companyId), {
    staleTime: Infinity,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });


  const { mutate: updateStatuses } = useMutation(updateCompanyStatuses, {
    onSettled: (data) => {
        queryClient.refetchQueries(['companyStatuses']).then(() => {
            setIsConfiguring(false);
            setIsUpdating(false);
        })
    },
    onError: (error) => {
        setIsUpdating(false);
        setIsConfiguring(false);
        setErrorMessage(error.message || 'An error occurred');
        setDialogOpen(true);
    }
  })

  const columns = [
    { field: "renderCellmenu", headerName: "Menu", width: 120, renderCell: (params) => (
      <StatusBuilderTableMenu
            variant="menu"
            params={params}
            props={props}
            setIsConfiguring={setIsConfiguring}
            selectedStatus={params?.row?.name}
            companyId={companyId}

        />
    )},
    { field: "name", headerName: "Status", width: 200 },
    { field: "color", headerName: "Color", width: 80 , renderCell: (params) => (
        <Box sx={{color: `${params.value}`, backgroundColor:`${params.value}`, width: 50}}>color</Box>
    )},
  ];

  const configColumns = [
    { field: "name", headerName: "Status", width: 200 },
    { field: "color", headerName: "Color", width: 80 , renderCell: (params) => (
        <Box sx={{color: `${params.value}`, backgroundColor:`${params.value}`, width: 50}}>color</Box>
    )},
  ];


  useEffect(() => {

    let dataForDisplay = [];

    if(companyStatuses?.data?.data?.statuses && Array.isArray(companyStatuses.data.data.statuses)){
        const temp = companyStatuses.data.data.statuses.map((data, idx) => {
            if(data.appliedAtAssembly !== undefined){
                let tempData = {...data};
                tempData.isStratusStatus = true;
                return tempData
            }else{
                let tempData = {...data};
                tempData.isStratusStatus = false;
                return tempData;
            }

        })
        dataForDisplay = clone(temp);
        dataForDisplay?.sort((a, b) => a?.sequenceNumber - b?.sequenceNumber);
    }
    if(dataForDisplay !== []){
        setStatuses(dataForDisplay)
        setCurrentStatuses(dataForDisplay.filter(data => data?.isApp))
    }
  }, [companyStatuses.data])

  useEffect(() => {

    let selectedStatusNames = [];
    statuses.forEach((status, index) => {
        currentStatuses.forEach((stat, idx) => {
            if(status.name === stat.name){
                selectedStatusNames.push(stat.name);
            }
        })
    })
    setSelectionModel(selectedStatusNames);
  }, [currentStatuses])



    const HandleUpdateStatuses = () => {
        let payload = {companyId: companyId, data: selectionModel}
        setIsUpdating(true);
        updateStatuses(payload);
    }

    const CloseConfigure = () => {
        setIsConfiguring(false);
    }

  return (
    <>
    <br/>
    {
        (statuses !== undefined || statuses !== []) ? (
            <Box>
                <Box sx={{display: 'flex'}}>
                    <Typography variant="h5">Status Builder</Typography>
                    <AddCircleIcon color="secondary" sx={{marginLeft: '0.5rem', marginTop: '.25rem'}}onClick={() => {openCreateStatusDialog()}}/>
                    <Box sx={{marginLeft: '1rem', marginTop: '5px'}}>
                        <ImportStatuses />
                    </Box>
                </Box>
                {
                    isConfiguring ? (
                        <Box>
                            <Button onClick={() => HandleUpdateStatuses()}>{isUpdating ? <CircularProgress size={24}/> : "Update"}</Button>
                            <Button onClick={() => CloseConfigure()}>Close</Button>
                        </Box>
                    ) : null
                }
                <Box sx={{display: 'flex', 'flex-direction': 'column'}}>
                    {
                        !isConfiguring ? (
                            <DataGridPro
                                sx={{width: 475}}
                                rows={statuses}
                                columns={columns}
                                autoHeight={true}
                                hideFooter={true}
                                getRowId={(r) => r.name}
                                onSelectionModelChange={(newSelectionModel) => {
                                  setSelectionModel(newSelectionModel);
                                }}
                                selectionModel={selectionModel}
                                disableSelectionOnClick={true}
                                disableColumnMenu={true}
                            />
                        ) : (
                            <DataGridPro
                                sx={{width: 475}}
                                rows={statuses}
                                columns={configColumns}
                                autoHeight={true}
                                hideFooter={true}
                                getRowId={(r) => r.name}
                                onSelectionModelChange={(newSelectionModel) => {
                                  setSelectionModel(newSelectionModel);
                                }}
                                selectionModel={selectionModel}
                                checkboxSelection
                                disableSelectionOnClick={true}
                                disableColumnMenu={true}
                            />
                                    )
                    }

                </Box>
            </Box>
        ) : (<Box>
            <Box sx={{display: 'flex'}}>
                    <Typography variant="h5">Status Builder</Typography>
                    <Typography variant="h5">Loading... <CircularProgress/></Typography>
                </Box>
        </Box>)
    }
    <ErrorDialog open={dialogOpen} handleClose={handleCloseDialog} errorMessage={errorMessage} />

    </>
  );
}
