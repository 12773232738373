import React, { useEffect, useState } from "react";
import { getProjects, getUsersWithCognitoData, renameProject } from "data/queries/queryAPI";
import { useMutation, useQuery, useQueryClient } from "react-query";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { CreateProjectDialog } from "./components/CreateProjectDialog";
import Divider from "@mui/material/Divider";
import { HardDeleteDialog } from "./components/HardDeleteDialog";
import ImportProjects from "components/Company/components/ImportProjects";
import { Paper } from "@mui/material";
import { PersistentNavButtons } from "../Navigation/PersistentNavButtons";
import { ProjectRow } from "./components/ProjectRow";
import { RenameForm } from "./components/RenameForm";
import SearchIcon from "@mui/icons-material/Search";
import {ShowDeletedSwitch} from "../Packages/components/ViewSwitch"
import {SoftDeleteDialog} from "./components/SoftDeleteDialog";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { openProjectsDrawer } from "../../Context/DrawerStates";
import { styled } from '@mui/material/styles';

export const Projects = (props) => {
  //CLOSE ALL OTHER PAGE'S DRAWERS ON INITIAL LOAD
  const [isRenaming, setIsRenaming] = useState(false);
  const [newProjectName, setNewProjectName] = useState("");


  useEffect(() => {
    props.setCatalogDrawerOpen(false);
    props.closeCreateCatalogPartDrawer(false);
    props.closeCatalogImportDialog();
    props.handleCloseOrdersDrawer();
    props.closeServicesDrawer();
    props.closeCreateUserDrawer();
    props.closeUsersDrawer();
  });

  const [projectForDialog, setProjectForDialog] = useState(null);
  const [dataForDisplay, setDataForDisplay] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  // const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [users, setUsers] = useState(undefined);

  const projectsQuery = useQuery(["allProjects"], () => getProjects(), {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
  const usersQuery = useQuery(["allDetailedUsers"], () => getUsersWithCognitoData(), {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
  const length = projectsQuery?.data?.data?.length

  const openDrawer = (project) => {
    props.setProjectForDrawer(project);
    const key = project.id;
    props.setDrawerKey(key);
    openProjectsDrawer();
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };
  useEffect(() => {
    if (projectsQuery.data?.data?.length > 0) {
      // ALPHABETIZE THE SERVICES
      const alphabetized = projectsQuery.data.data.sort((a, b) => {
        if (a.name.toUpperCase() === b.name.toUpperCase()) {
          return 0;
        }
        return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1;
      });

      setDataForDisplay(alphabetized);
      
    }
  }, [projectsQuery.data]);

  useEffect(() => {
    if (usersQuery.data?.length > 0) {
      setUsers(usersQuery.data);
      
    }
  }, [usersQuery.data]);

  useEffect(() => {
    if (searchTerm.length > 0) {
      const filtered = projectsQuery.data.data.filter((project) => {
        return project.name.toUpperCase().includes(searchTerm.toUpperCase());
      });
      setDataForDisplay(filtered);
    
    }
  }, [projectsQuery.data, searchTerm]);

  return (
      <Box
        sx={styles.paper}
      >

          <Box
            sx={styles.box[1]}
          >
            <Box sx={styles.box[2]}>
              <Typography variant="h5">
                {`Projects (${length === 0 || length === undefined ?
                 0 : dataForDisplay.length})`}
              </Typography>
              <AddCircleIcon
                onClick={handleDialogOpen}
                sx={styles.addCircleIcon}
                color="secondary"
              />
            </Box>
            <Box sx={{marginLeft: '1rem', marginTop: '5px'}}>
                <ImportProjects />
            </Box>
            <Box
              sx={styles.box[3]}
            >
                <TextField
                  id="outlined-name"
                  label="Project Name"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <SearchIcon
                        color="secondary"
                        fontSize="large"
                      />
                    )
                  }}
                />
            </Box>
        </Box>
        <Divider sx={styles.divider} />
        {projectsQuery?.data?.data?.length > 0 && usersQuery?.data?.length > 0 ?

          dataForDisplay?.length > 0 && users?.length > 0 ?
          (
          dataForDisplay.map((project, pos) => {
            return (
              <Box key={pos} sx={{marginLeft: '1rem'}}>
                <ProjectRow
                  key={project.id}
                  projects={dataForDisplay}
                  project={project}
                  openDrawer={openDrawer}
                  setProjectForDialog={setProjectForDialog}
                />
              </Box>
            );
          })
        ): dataForDisplay.length === 0 && !projectsQuery?.isLoading ?
        (<Box sx={styles.color.gray}>
          <Box sx={styles.box[4]}>
            <Typography variant="h3">No Projects</Typography>
          </Box>
        </Box>):
        (<Box sx={styles.color.gray}>
            <Box sx={{display: 'flex'}}>
              <Typography variant="h3">Loading Projects...</Typography>
              <CircularProgress color="inherit" />
            </Box>
          </Box>)
        : (<Box sx={styles.color.gray}>
          <Box sx={{display: 'flex'}}>
            <Typography variant="h3">Loading Projects...</Typography>
            <CircularProgress color="inherit" />
          </Box>
        </Box>)

        }
        <CreateProjectDialog
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
          handleDialogClose={handleDialogClose}
        />
        <RenameForm
          projects={dataForDisplay}
          project={projectForDialog}
          newProjectName={newProjectName}
          setNewProjectName={setNewProjectName}
        />
        <HardDeleteDialog
          project={projectForDialog}
        />
        <SoftDeleteDialog
          project={projectForDialog}
        />
      </Box>
  );
};

const styles = {
  paper: {
    height: "auto",
    width: "100%",
    marginTop: "80px",
    backgroundColor: "#fff",
    flex: 1,
    flexDirection: "column",
  },
  box: {
    1: {
      display: "flex",
      flexDirection: "row",
      marginTop: "1rem",
      justifyContent: "space-between",
      alignItems: "center",
      width: "38.85%"
    },
    2: { display: "flex", flexDirection: "row", paddingLeft: "2rem"},
    3: { display: "flex", flexDirection: "row", alignItems: "center",  paddingRight: 3 },
    4: { display: "flex", flexDirection: "column" }
  },
  addCircleIcon: {
    marginTop: "auto",
    marginBottom: "auto",
    marginLeft: "1rem",
  },
  color: {
    flame: {color: "#F15A29"},
    gray: {color: "gray"}
  },
  divider: { marginTop: "1rem", marginBottom: "1rem" }
};
