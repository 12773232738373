import React, { useState } from "react";

import { Box } from "@mui/system";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Collapse from "@mui/material/Collapse";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { ItemsGroup } from "./ItemsGroup";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Typography } from "@mui/material";

export const ServiceGroup = (props) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <div>
      <ListItemButton
        onClick={handleClick}
      >
        {open ? <ExpandMore /> : <ChevronRightIcon />}
        <ListItemText
          primary={`${props.node.name} (${props.node.children.length})`}
        />
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box sx={styles.paddingLeft}>
          <Box
            sx={styles.box[1]}
          >
            <Box sx={styles.box[2]}>
              <Typography variant="h6" color="#F15A29">
                NAME
              </Typography>
            </Box>
            <Box sx={styles.box[3]}>
              <Typography variant="h6" color="#F15A29">
                CATEGORY
              </Typography>
            </Box>
            <Box sx={styles.box[3]}>
              <Typography variant="h6" color="#F15A29">
                ICON
              </Typography>
            </Box>
          </Box>
          <List component="div" disablePadding>
            {props.node.children.map((child) => {
              return (
                <ItemsGroup
                  key={child.id}
                  node={child}
                  conditions={props.conditions}
                  serviceId={props.serviceId}
                />
              );
            })}
          </List>
        </Box>
      </Collapse>
    </div>
  );
};

const styles = {
  box: {
    1:{
      paddingLeft: "4rem",
      width: "80%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    2: { display: "flex", flex: 1 },
    3: { display: "flex", flex: 1, justifyContent: "center" }
  },
  paddingLeft: { paddingLeft: "2rem" }
};
