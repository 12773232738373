import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormGroup,
    IconButton,
    Input,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import{
    closeCreateStatusDialog,
    createStatusDialogOpen
} from "../../../Context/DrawerStates";
import { useMutation, useQuery, useQueryClient } from "react-query";

import CancelIcon from "@mui/icons-material/Cancel";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorDialog from "components/Utilities/ErrorDialog";
import { Text_btn } from "components/Theme/Styled";
import { createCompanyStatus } from "../../../data/queries/queryAPI";
import {styled} from "@mui/material/styles";
import { useState } from "react";

export const CreateStatusDialog = (props) => {

    const [open, setOpen] = useState(false);
    const [color, setColor] = useState(null);
    const [statusName, setStatusName] = useState("");
    const [isCreating, setIsCreating] = useState(false);
    const queryClient = useQueryClient();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const HandleCreateStatus = () => {
        let status = [{name: statusName, color: color}]
        let payload = {companyId: props.companyId, data: {name: statusName, color: color}}
        setIsCreating(true);
        createStatus(payload);
    }

    const { mutate: createStatus } = useMutation(createCompanyStatus,{
            onSettled: (data) => {
                queryClient.refetchQueries(['companyStatuses']).then(()=> {
                    setIsCreating(false);
                    handleClose();
                })
            },
            onError: (error) => {
                setIsCreating(false);
                setErrorMessage(error.message || 'An error occurred');
                setDialogOpen(true);
            }

        }

    )

    const handleClose = (e) => {
        closeCreateStatusDialog();
        setOpen(false);
    }
    const styles = {
        marginLeft: { marginLeft: "1rem" },
        display: {display: "flex"},
        iconButton: { color: "#000000"},
        cancelIcon: { color: "#000000"},
      };


    return (
        <>
            <Dialog open={createStatusDialogOpen.use()}>
                <Box sx={styles.display}>
                    <IconButton
                        sx={styles.iconButton}
                        onClick={()=>{handleClose()}}
                    >
                        <CancelIcon sx={styles.cancelIcon} />
                    </IconButton>
                <DialogTitle color="secondary">CREATE CUSTOM STATUS</DialogTitle>
                    
                </Box>
                
                <DialogContent>
                    <Box sx={{display: 'flex', 'flex-direction': 'column'}}>
                    <TextField
                        placeholder="Status Name"
                        onChange={(e) => {setStatusName(e.target.value)}}
                    />
                    <br/>
                    <Typography color="secondary">COLOR</Typography>
                    <Input type="color" value={color} onChange={(e) => {setColor(e.target.value)}}/>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Text_btn onClick={(e) => HandleCreateStatus(e)}>CREATE{isCreating ? (
            <CircularProgress sx={styles.marginLeft} color="secondary" size={12} />
          ) : null}</Text_btn>
                </DialogActions>
            </Dialog>
            <ErrorDialog open={dialogOpen} handleClose={handleCloseDialog} errorMessage={errorMessage} />

        </>
    )
}

const Typography_ = styled(Typography)`
  //width: 75% !important;
  flex-shrink: 0 !important;
`;
