import {
  Box,
  CircularProgress,
  Dialog,
  InputLabel,
  OutlinedInput,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { Contained_btn, Outlined_btn } from "components/Theme/Styled";
import {
  catalogImportDialogOpen,
  closeCatalogImportDialog
} from "Context/DrawerStates";
import { useMutation, useQueryClient } from "react-query";
import { useRef, useState } from "react";

import AttachFileIcon from '@mui/icons-material/AttachFile';
import IconButton from '@mui/material/IconButton';
import JSZip from "jszip";
import { MenuItem } from "@material-ui/core";
import React from 'react'
import { importCatalog } from "../../../data/queries/queryAPI";
import { styled } from "@mui/material/styles";

const Span_ = styled('span')`
  font-size: 14;
`;
export const CatalogImportDialog = () => {
  const queryClient = useQueryClient();
  const [catalogFile, setCatalogFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [catalogName, setCatalogName] = useState("");
  const [importRunning, setImportRunning] = useState(false);
  const [selectedType, setSelectedType] = useState("Pipe");

  const { mutate: uploadZippedFile } = useMutation(importCatalog, {
    onSettled: async (data) => {
      if (data === "SUCCESS") {
        queryClient.refetchQueries(["allCatalogs"]).then(() => {
          setCatalogName("");
          setImportRunning(false);
          closeCatalogImportDialog();
        });
      }
    },
    onError: (error) => {
      console.error("error mutating data:", error);
      setImportRunning(false);
      alert("There was an error: ", error);
    },
  });

  const uploadFile = () => {
    setImportRunning(true);
    const zip = new JSZip();
    const zipName = ``;
    const csvName = "catalog.csv";
    zip.folder(zipName).file(csvName, catalogFile);
    zip.generateAsync({ type: "blob" }).then((content) => {
      uploadZippedFile({
        catalogName: catalogName,
        catalogType: selectedType,
        zippedSource: content,
      });
    }); 
  };

  const fileInputRef = useRef();

  const handleFileButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileInputChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setCatalogFile(event.target.files[0]);
      setFileName(event.target.files[0].name);
    }
  };

  const styles = {
    box: {
      1: { display: "flex", flexDirection: "column", padding: "3rem" }
    },
    marginTop: { marginTop: "1rem" },
    btn: {
      borderColor: "#F15A29",
      background: "#F15A29",
      color: "white",
      height: "35px"
    },
    color: {
      white: {color: "white"}
    },
    span: { fontSize: 14 }
  };

  return (
        <Dialog
          open={catalogImportDialogOpen.use()}
          onClose={closeCatalogImportDialog}
          fullWidth={true}
          maxWidth={"sm"}
        >
          
            <Box sx={{backgroundColor: '#F15A19', color: '#fff', height: '60px', display: 'flex', justifyContent: 'left', alignItems: 'center'}}>
              <Box sx={{marginLeft: '2rem '}}>
                <Typography variant="h5">Import Catalog</Typography>
              </Box>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'center', alignContent: 'center'}}> 
              <Box sx={{marginTop: '1rem '}}>
                <Typography variant="body3">Catalogs must be imported in CSV (Comma delimited) (*.csv) format</Typography>
              </Box>
            </Box>
            <TextField
              id="name"
              label="Name"
              sx={{marginLeft: '2rem ', marginRight: '2rem ', marginTop: '1rem ', marginBottom: '1rem '}}
              value={catalogName}
              onChange={(e) => setCatalogName(e.target.value)}
            />
            <InputLabel sx={{marginLeft: '2rem'}} id="select-label">Type</InputLabel>
            <Select
              labelId="select-label"
              id="demo-simple-select"
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
              sx={{marginLeft: '2rem ', marginRight: '2rem ', marginTop: '1rem ', marginBottom: '1rem '}}
            >
              <MenuItem value={"Misc"}>Misc</MenuItem> {/*Both scenes*/}
              <MenuItem value={"Pipe"}>Pipe</MenuItem> {/*Pipe scene only*/}
              <MenuItem value={"Duct"}>Duct</MenuItem> {/*Duct scene only*/}
            </Select>
            <InputLabel sx={{marginLeft: '2rem'}} id="file-label">
            File:
            </InputLabel>
            <input
              type="file"
              accept=".csv"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileInputChange}
            />
            <Box
              component="div"
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '1rem ',
                marginBottom: '1rem ',
                marginLeft: '2rem ', 
                marginRight: '2rem ',
                padding: '15px 12px',
                border: '1px solid #F15A19',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
              onClick={handleFileButtonClick}
            >
              <Typography variant="body1">{fileName || 'Select File...'}</Typography>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '1rem'}}>
              <Box sx={{marginRight: '1rem'}}>
                <Outlined_btn
                  sx={{width: '152px'}}
                  onClick={() => {
                    closeCatalogImportDialog()
                    setCatalogName(" ")
                  }}
                >
                  Cancel
                </Outlined_btn>
              </Box>
              <Box sx={{marginLeft: '1rem'}}>

                <Contained_btn
                  disable={importRunning}
                  sx={{marginLeft: '1rem '}}
                  onClick={() => {
                    uploadFile({
                      newCatalogName: catalogName,
                      catalogCSV: catalogFile,
                    });
                  }}
                >
                  {importRunning ? (
                    <CircularProgress size={16} color="white"/>
                  ) : (
                    <Span_>Create Catalog</Span_>
                  )}
                </Contained_btn>
              </Box>
            </Box>
        </Dialog>
  );
};
