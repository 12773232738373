import React, { useEffect, useState } from "react";
import {closeConfigureConnectionTypeDialog, configureConnectionTypeDialogOpen} from "../../../../Context/DrawerStates"
import { useMutation, useQueryClient } from "react-query";

import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { Text_btn } from "components/Theme/Styled";
import clone from 'just-clone'
import { updateServiceConnectionType } from "../../../../data/queries/queryAPI";

export const ServiceConfigurationConnectorsRenameForm = (props) => {
  const [selectedService, setSelectedService] = useState(props.selectedService)
  const [isRenaming, setIsRenaming] = useState(false);
  const [newName, setNewName] = useState("");
  const [selectedConnectionType, setSelectedConnectionType] = useState(props.selectedConnectionType)

  useEffect(() => {
    setSelectedConnectionType(props.selectedConnectionType)
  }, [props.selectedConnectionType])

  const { mutate: updateAlias } = useMutation(updateServiceConnectionType, {
    onSettled: (data) => {
      setIsRenaming(false);
      let new_connectionType = clone(selectedConnectionType);
      new_connectionType['Alias'] = newName;
      let connectionTypes = clone(selectedService.connectionTypes);
      connectionTypes.forEach((connectionType, idx) => {
        if(connectionType.Connectivity === new_connectionType.Connectivity && connectionType.Sex === new_connectionType.Sex){
          connectionTypes[idx]['Alias'] = new_connectionType['Alias'];
        }
      })
      let selectedServiceUpdate = clone(selectedService)
      selectedServiceUpdate['connectionTypes'] = connectionTypes;
      props.setSelectedService(selectedServiceUpdate);
      handleClose();
    },
    onError: (error) => {
      console.error("[Error] Update connection type alias failed" , error);
      alert("An error  occurred while updating a connection type alias: ", error);
    }
  })

  const handleRename = () => {
    let new_connectionType = clone(selectedConnectionType)
    new_connectionType['Alias'] = newName;
    let payload = {
      id: selectedService?.id,
      data: new_connectionType
    }
    setIsRenaming(true);
    updateAlias(payload, {
      onSuccess: () => {
        if (props.onAliasUpdate) {
          props.onAliasUpdate(new_connectionType);
        }
      },
    });
  };
  const handleClose = () => {
    closeConfigureConnectionTypeDialog();
    setNewName("");
  }

  return (
      <Dialog
        open={configureConnectionTypeDialogOpen.use()} onClose={handleClose}
        fullwidth
      >
        <DialogTitle>Rename Connector</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label=" New Connector Alias:"
            type="text"
            fullwidth
            variant="standard"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Text_btn
            onClick={(e) => {
              handleClose()
              setNewName("")
            }}
          >
            Cancel
          </Text_btn>
          {isRenaming === false ? (
            <Text_btn
              onClick={handleRename}
            >
                Rename
            </Text_btn>
          ) : (
            <CircularProgress size={24} />
          )}
        </DialogActions>
      </Dialog>
  );
};
