import { Box, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";

import { CompanyNavButtons } from "components/Navigation/CompanyNavButtons";
import { PersistentNavButtons } from "../Navigation/PersistentNavButtons";
import { SettingsList } from "./components/SettingsList";
import { getCompanySettings } from 'data/queries/queryAPI'

export const Settings = (props) => {
  //CLOSE ALL OTHER PAGE'S DRAWERS ON INITIAL LOAD
  const queryClient = useQueryClient();
  const [settings, setSettings] = useState(undefined);

  useEffect(() => {
    props.setCatalogBrowserDialogOpen(false);
    props.setCatalogDrawerOpen(false);
    props.setProjectServiceDialogOpen(false);
    props.setProjectUserDialogOpen(false);
    props.closeCreateCatalogPartDrawer(false);
    props.closeCatalogImportDialog();
    props.handleCloseOrdersDrawer();
    props.closeCreateUserDrawer();
    props.closeUsersDrawer();
    props.closeProjectsDrawer();
  });

  const companySettingsQuery = useQuery(["companySettings"], () => getCompanySettings(props.companyId), {
    staleTime: Infinity,
    refetchOnMount: 'always',
    refetchOnReconnect: 'always'
  })

  useEffect(() => {
    if(companySettingsQuery?.data?.data){
      setSettings(companySettingsQuery.data.data)
    } 
  }, [companySettingsQuery.data])

  return (
      <Box
        sx={styles.paper}
      >
        <CompanyNavButtons />
        <SettingsList companyId={props.companyId} settings={settings}/>
      </Box>
  );
};

const styles = {
  paper: {
    height: "auto",
    width: "100%",
    marginTop: "30px",
    backgroundColor: "#fff",
    flex: 1,
    flexDirection: "column",
    paddingBottom: "20px",
  }
};
