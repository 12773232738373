import {Button} from "@mui/material";
import {styled} from "@mui/material/styles";

/**
 * @param {*} props 
 * 
 * @returns an outlined MUI Button
 * ## Available Props
 * - key
 * - onClick
 * - disabled
 * - size
 * - component
 * - sx
 * - type
 * - fullwidth
 */
const Outlined_btn = (props) => {
    const Button_ = styled(Button)`
        //PLACE STYLES TO BE APPLIED HERE

    `;
    return (
        <Button_ 
            key={props.key}
            onClick={props.onClick} 
            variant="outlined"
            disabled={props.disabled}
            size={props.size}
            component={props.component}
            sx={[props.sx, {borderColor: "#F15A29 !important", color: "#F15A29 !important"}]}
            type={props.type}
            fullWidth={props.fullWidth}
        >
            {props.children}
        </Button_>
    );
};

export default Outlined_btn;
