import { 
    Box,
    Card,
    ListItem,
    ListItemText,
    Typography

 } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState, useEffect } from 'react';


const NavLink = (props) => (
    <Box sx={{flex: 1, border: "1px solid #FFA988"}} >
      { props.state > 0 ?
        < CurrentCustomListItem 
            button 
            key={ props.id.toLowerCase() } 
            onClick={ () => props.action( true ) }
        >
            < Typography variant="caption" >
                { props.id.toUpperCase() }
            </ Typography >
        </ CurrentCustomListItem >
        :                   
        < CustomListItem 
            button 
            key={ props.id.toLowerCase() } 
            onClick={ () => props.action( true ) }
        >
            < Typography variant="caption">
                { props.id.toUpperCase() }
            </ Typography >
        </ CustomListItem >
      }
    </Box>
);


const NavBar = (props) => (
    < Box id="DrawerNavBar"
        sx={{ maxWidth: 310, borderBottom: "1px solid #F15A29" }}
        display="flex"
        flexDirection="row"
        alignItems="center"
        alignSelf="center"
        mb={2}
    >
        < NavLink id="specs" 
            action={ value => props.setSpecs(value) } 
            state={ props.specs }
        />
        < NavLink id="materials" 
            action={ value => props.setMaterials(value) } 
            state={ props.materials }
        />
        < NavLink id="connectors" 
            action={ value => props.setConnectors(value) } 
            state={ props.connectors }
        />
    </ Box >
);

const ServiceConfig_panel = (props) => {
    const [specs, setSpecs] = useState(1);
    const [materials, setMaterials] = useState(-1);
    const [connectors, setConnectors] = useState(-1);

    useEffect( () => {
        if( specs > 0 ) {
            setMaterials(-1);
            setConnectors(-1)
        };
    }, [ specs ]);
    useEffect( () => {
        if( materials > 0 ) {
            setSpecs(-1);
            setConnectors(-1);
        };
    }, [ materials ]);
    useEffect( () => {
        if( connectors > 0 ) {
            setSpecs(-1);
            setMaterials(-1);
        };
    }, [ connectors ]);


    return(
        < Card variant="outlined" sx={styles.card} >
            < NavBar 
                specs={ specs }
                setSpecs={ setSpecs }
                materials={ materials }
                setMaterials={ setMaterials }
                connectors={ connectors }
                setConnectors={ setConnectors }
            />
            < Stack >

                <Layer sx={{zIndex: specs}}>
                    {props.specs}
                </Layer>

                <Layer sx={{zIndex: materials}}>
                    {props.materials}
                </Layer>
                
                <Layer sx={{zIndex: connectors}}>
                    {props.connectors}
                </Layer>
                        
            </ Stack >
        </ Card >
    )
};

const styles = {
    card: {
        width: 310, 
        height: "400px !important", 
        overflowX: 'hidden', 
        //overflowY: 'scroll',
        border: '1px solid #F15A29 !important', 
        backgroundColor: 'white',
        paddingBottom: "1rem !important",
        
    },
    container: {
        flex: 1,
        height: "360px",
        overflowX: "hidden",
        //overflowY: "scroll",
        //paddingLeft: "1rem !important"
    }
};

const CustomListItem = styled(ListItem)`
    :hover { background: transparent; };
    color: #F15A29 !important;
    text-decoration: none;
    border: 1px solid #FFA988;
    border-color: #FFA988 !important; 
    background: #fff;
    text-align: center !important;
    //width: 101px !important;
`;

const CurrentCustomListItem = styled(ListItem)`
    :hover { background: #F15A29 !important; };
    color: #fff !important;
    text-decoration: none;
    border: 1px solid #FFA988;
    border-color: #FFA988 !important; 
    background: #F15A29 !important;
    text-align: center !important;
    //width: 101px !important;
`;

const Stack = styled(Box)`
    flex: 1;
    height: 360px;
    overflow-x: hidden;
`;

const Layer = styled(Box)`
    position: absolute !important;
    height: 360px !important;
    width: 310px !important; 
    overflow-x: hidden;
    overflow-y: scroll;
`;

export default ServiceConfig_panel;