import React, { useEffect, useState } from "react";
import {
    closeCatalogPartDrawer,
    openCreateCatalogPartDrawer,
    openRenameCatalogForm,
    setCatalogIdForDrawer,
    setCatalogIdForRenameForm
} from "Context/DrawerStates";
import {
  convertServiceToCatalog,
  createServiceConfigurations,
  deleteCatalog,
  deleteService,
  duplicateCatalog,
  exportCatalog,
} from "data/queries/queryAPI";
import {getCatalogItems, getProjects, getServiceConnectors, getServiceItems, getUsersWithCognitoData} from "../../../data/queries/queryAPI";
import {
  useAPICallback,
  useAPIData,
  useAPIEffect,
} from "./../../../data/hooks/customHooks.js";
import { useMutation, useQueryClient } from "react-query";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorDialog from "components/Utilities/ErrorDialog";
import { Outlined_btn } from "components/Theme/Styled";
import clone from "just-clone";

export const PopoverButtons = (props) => {
  // const [isDuplicating, setIsDuplicating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const queryClient = useQueryClient();
  const [isExporting, setIsExporting] = useState(false);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleCloseDialog = () => {
      setDialogOpen(false);
  };

  const { mutate: exportItem } = useMutation(exportCatalog, {
    onSettled: (data) => {
      setIsExporting(false);
      props.handleClose();
    },
    onError: (error) => {
      setIsExporting(false);
      setErrorMessage(error.message || 'An error occurred');
      setDialogOpen(true);
    },
  });

  const { mutate: duplicateItem } = useMutation(duplicateCatalog, {
    onSettled: (data) => {
      queryClient.refetchQueries(["allCatalogs"]).then(() => {
        setIsDuplicating(false);
        props.handleClose();
      });
    },
    onError: (error) => {
      setIsDuplicating(false);
      setErrorMessage(error.message || 'An error occurred');
      setDialogOpen(true);
    },
  });

  const { mutate: deleteItem } = useMutation(deleteCatalog, {
    onSettled: (data) => {
      queryClient
        .refetchQueries(["allCatalogs"])
        .then(() => {
          setIsDeleting(false);
        })
        .then(() => {
          props.handleClose();
        })
        .catch((error) => {
          setIsDeleting(false);
          setErrorMessage(error.message || 'An error occurred');
          setDialogOpen(true);
        });
    },
    onError: (error) => {
      setIsDeleting(false);
      setErrorMessage(error.message || 'An error occurred');
      setDialogOpen(true);
    },
  });

  const handleDuplicate = (e) => {
    e.stopPropagation();
    setIsDuplicating(true);
    const payload = {};
    payload.name = `${props.catalogName} (copy)`;
    payload.source = props.catalogId;
    duplicateItem(payload);
  };
  const handleDelete = (e) => {
    e.stopPropagation();
    setIsDeleting(true);
    deleteItem(props.catalogId);
  };

  const handleConfigure = async (e) => {
    e.stopPropagation();
    props.setIsConfiguring(true);
    props.setCatalogName(props.catalogName)
    let items = await getCatalogItems(props.catalog.id);
    let object = clone(props.catalog);
    if(items.data){
      let cat_items = items.data;
      object['items'] = cat_items;
    }
    props.setSelectedCatalog(object);
  }

  const handleExport = (e) => {
    e.stopPropagation();
    setIsExporting(true);
    let catalogId = props.catalogId;
    let catalogName = props.catalogName.split(" ").join("");
    let data = { catalogId, catalogName}
    exportItem(data);
  };

  const handleRenameClick = (e) => {
    setCatalogIdForRenameForm(props.catalogId);
    openRenameCatalogForm();
    props.handleClose();
  };

  return (
    <>
      <ButtonGroup
        orientation="vertical"
        aria-label="vertical outlined button group"
      >
        <Outlined_btn
          //color="secondary"
          onClick={(e) => handleConfigure(e)}
        >
          Configure
        </Outlined_btn>
        <Outlined_btn onClick={(e) => handleExport(e)} key="export">
          Export
          {isExporting ? (
            <CircularProgress sx={styles.marginLeft} size={12} />
          ) : null}
        </Outlined_btn>
        <Outlined_btn
          //color="secondary"
          //variant="outlined"
          onClick={(e) => {
            handleRenameClick(e)
          }}
        >
          Rename
        </Outlined_btn>
        <Outlined_btn
          //color="secondary"
          //variant="outlined"
          onClick={(e) => handleDuplicate(e)}
        >
          Duplicate
          {isDuplicating ? (
            <CircularProgress sx={styles.marginLeft} size={12} />
          ) : null}
        </Outlined_btn>
        <Outlined_btn
          //color="secondary"
          onClick={(e) => handleDelete(e)}
        >
          Delete
          {isDeleting ? (
            <CircularProgress sx={styles.marginLeft} color="secondary" size={12} />
          ) : null}
        </Outlined_btn>
      </ButtonGroup>
      <ErrorDialog open={dialogOpen} handleClose={handleCloseDialog} errorMessage={errorMessage} />

      </>
  );
};

const styles = {
  marginLeft: { marginLeft: "1rem" },
};
