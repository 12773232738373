import{
    Box,
    Checkbox,
    MenuItem,
    Select
} from '@mui/material'
import {Lock, LockOpen} from '@mui/icons-material'
import React, {useEffect, useState} from "react";

import {styled} from "@mui/material/styles";

export const ServiceConfigurationPartDropdownOption = (props) => {
    const [option, setOption] = useState(props.option)
    const [optionValue, setOptionValue] = useState(props.option.PossibleValues[0])
    const [isLocked, setIsLocked] = useState(props.option.IsLocked);
    const [dimension, setDimension] = useState(props.dimension)
    const [optionIndex, setOptionIndex] = useState(props.optionIndex)
    
    useEffect(() => {
        setDimension(props.dimension)
    }, [props.dimension])
    const checkSendProp = () =>{
        if(isLocked){
            setIsLocked(false);
            option.IsLocked = false;
            props.checkOption(props.optionIndex, false)
        }else{
            setIsLocked(true);
            option.IsLocked = true;
            props.checkOption(props.optionIndex, true)
        }
    }
    const handleChange = (event)=> {
        const { myValue, myIndex } = event.currentTarget.dataset;
        setOptionValue(myValue);
        option.SelectedOption = myIndex;
        props.handleOptionChange(props.optionIndex, option);
    };

    return(
        <Box>
        {option !== undefined && optionValue !== undefined ? (
            <Box 
                key={option.fabId} 
                className="card" 
                sx={styles.div[1]}
            >
                <Box sx={styles.div[2]}>
                    <Box sx={styles.div[3]}>
                        <P_>{option.Name}</P_>
                    </Box>
                    <Box sx={styles.div[4]}>
                        <Select_  
                            size='small'
                            sx={styles.select}
                            labelId="demo-simple-select-label" 
                            id="demo-simple-select" 
                            defaultValue={optionValue} 
                            value={optionValue} 
                        >
                            {option.PossibleValues.map((value, index) => {
                            return <MenuItem 
                                    sx={{display: 'flex !important', flexDirection: 'column !important', paddingLeft: "4px !important", paddingRight: "4px !important"}} 
                                    onClick={handleChange} 
                                    key={index} 
                                    data-my-index={index} 
                                    value={value}
                                    data-my-value={value}>
                                        {value}
                                    </MenuItem>
                        })}</Select_>
                    </Box>
                    <Box sx={styles.div[5]}>
                        <Checkbox
                            checked={isLocked}
                            onChange={checkSendProp}
                            inputProps={{ 'aria-label': 'controlled'}}
                            checkedIcon={<Lock/>}
                            icon={<LockOpen color="secondary"/>}
                        />   
                    </Box>
                </Box>
            </Box>
        ): null
    }
    </Box>
    )
}

const styles = {
    div: {
        1: {
            backgroundColor: 'white', 
            width: 290, 
            WebkitBorderRadius: '10px 10px 10px 10px', 
            MozBorderRadius: '10px 10px 10px 10px', 
            borderRadius: '10px 10px 10px 10px'
        },
        2: {display: 'flex', paddingLeft: '10px'},
        3: {display: "inline-block", width: '80px !important'},
        4: {display: "inline-block", width: '148px', paddingTop: '10px'},
        5: {display: "inline-block", paddingLeft: "5px", paddingTop: '16px'},
        6: {
            backgroundColor: 'white', 
            width: 290, 
            WebkitBorderRadius: '10px 10px 10px 10px', 
            MozBorderRadius: '10px 10px 10px 10px', 
            borderRadius: '10px 10px 10px 10px'
        },
        7: {display: 'flex', paddingLeft: '10px'} ,
        8: {display: "inline-block", width: '20px'},
        9: {display: "inline-block", width: '80px !important'},
        10: {display: "inline-block", paddingTop: '10px'},
        11: {display: "inline-block", marginLeft: '6px !important', paddingRight: '5px', paddingTop: '16px'},
    },
    p: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'},
    select: {width: '150px !important', backgroundColor: 'white !important'}
};

const P_ = styled('p')`
    white-space: nowrap !important; 
    overflow: hidden !important; 
    text-overflow: ellipsis !important;
    padding-top: 4px;
`;

const Select_ = styled(Select)`
    width: 150px !important;
    background: white !important;
    margin-right: 2px !important;
    //padding-left: .5rem;
`;