import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  LinearProgress,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { servicesImportIncrement, servicesImportProgress } from "Context/componentStates";
import { useQuery, useQueryClient } from "react-query";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import { ImportServiceDialog } from "./ImportServiceDialog";
import { Outlined_btn } from "components/Theme/Styled";
import { RenameForm } from "./RenameForm";
import { ServiceRow } from "./ServiceRow";
import { ServicesRefreshDialog } from "./ServicesRefreshDialog";
import { ServicesTable } from "./ServicesTable";
import clone from "just-clone";
import { newGetServicesWithGroups } from "../../../data/queries/queryAPI";
import { styled } from "@mui/material/styles";
import { typography } from "@mui/system";

export const ServicesList = (props) => {
  const [isConfiguring, setIsConfiguring] = useState(false);
  const [ids, setIds] = useState([]);
  const [importing, setImporting] = useState(false);
  const [importDialogOpen, setImportDialogOpen] = useState(false);
  const [renameFormOpen, setRenameFormOpen] = useState(false);
  const [serviceId, setServiceId] = useState('None');
  const [selectedService, setSelectedService] = useState(undefined);
  const queryClient = useQueryClient();
  const [dataForDisplay, setDataForDisplay] = useState(props.services);

  useEffect(() => {
    setDataForDisplay(props.services)
  }, [props.services])

  const handleImportService = (e) => {
    setImportDialogOpen(true);
  };

  const resetImport = () => {
    servicesImportIncrement.set(null); 
    servicesImportProgress.set(null); 
    setImporting(false)
  }


  return (
    <>
          <Grid direction="row" container paddingTop="1rem" paddingLeft="2rem">
          { !isConfiguring ? <Typography sx={styles.typography[1]} variant="h5" > Services </Typography> : null}
            { !importing && !isConfiguring ?
              (
                <AddCircleIcon
                  onClick={(e) => handleImportService()}
                  sx={styles.addCircleIcon}
                  color="secondary"
                />
              )
              : importing && !isConfiguring ?
              (
                <>
                  <Box width="25%" marginTop=".85rem" marginRight=".5rem">
                    <LinearProgress variant="determinate" value={servicesImportProgress.get()} color="secondary" sx={{border: "1px solid orange"}}/>
                  </Box>
                  {
                    servicesImportProgress.get() <= 99 ?
                    <>
                      <CircularProgress
                        sx={styles.circularProgress}
                        color="secondary"
                        size={12}
                      />
                      <Typography
                        variant="overline"
                        color="secondary"
                        sx={{marginTop: ".05rem !important"}}
                      >
                        Importing...
                      </Typography>
                    </>
                    :
                    <>
                      <Typography
                        variant="overline"
                        color="secondary"
                        sx={{marginTop: ".05rem !important"}}
                      >
                        Completed
                      </Typography>
                      <CancelPresentationIcon_
                        fontSize="small"
                        onClick={ () => { resetImport() } }
                      />
                    </>
                  }

                </>

              ) : null
            }
        </Grid>
          {dataForDisplay === undefined ? (
            <Box sx={styles.color.gray} open={true}>
              <Box sx={styles.box[3]}>
                <Typography variant="h3">Building Services Table...</Typography>
                <CircularProgress color="inherit" />
              </Box>
            </Box>
          ): dataForDisplay === "no services to fetch" ?
          (
            <>
              <Divider sx={styles.divider} />
              <Box sx={styles.color.gray} open={true}>
                <Box sx={styles.box[3]}>
                  <Typography variant="h3">No Services</Typography>
                </Box>
              </Box>
            </>
          )

        :

        (Array.isArray(dataForDisplay) && dataForDisplay?.length > 0) ? (

          <Box sx={styles.box.table}>
            <ServicesTable
              dataForDisplay={dataForDisplay}
              serviceId={serviceId}
              setServiceId={setServiceId}
              selectedService={selectedService}
              setSelectedService={setSelectedService}
              setRenameFormOpen={setRenameFormOpen}
              importing={importing}
              setImporting={setImporting}
              isConfiguring={isConfiguring}
              setIsConfiguring={setIsConfiguring}
              companyId={props.companyId}
              serviceGeneratorVersion={props.serviceGeneratorVersion}
            />
          </Box>
        )

        : (dataForDisplay?.length === 0) && dataForDisplay === "no services to fetch" ? (
          <>
              <Divider sx={styles.divider} />
              <Box sx={styles.color.gray} open={true}>
                <Box sx={styles.box[3]}>
                  <Typography variant="h3">No Services</Typography>
                </Box>
              </Box>
            </>
        )
        : null
      }

      <ImportServiceDialog
        open={importDialogOpen}
        setOpen={setImportDialogOpen}
        setImporting={setImporting}
        ids={ids}
      />
      <RenameForm
        renameFormOpen={renameFormOpen}
        setRenameFormOpen={setRenameFormOpen}
        serviceId={serviceId}
      />
    </>
  );
};

const styles = {
  color: {
    gray: { color: "gray" },
    orange: {color: "#F15A29"},
    white: {color: "#fff"}
  },
  box: {
    1: {
      paddingTop: "1rem",
      paddingLeft: "2rem",
      display: "flex",
      //flexDirection: "row",
    },
    2: {
      display: "flex",
      flexDirection: "row",
      marginLeft: "2rem",
      alignItems: "center",
    },
    3: { display: "flex", flexDirection: "col" },
    table: {paddingLeft: "25px", paddingRight: "30px", paddingTop: "20px"}
  },
  addCircleIcon: {
    marginTop: "auto",
    marginBottom: "auto",
    //marginLeft: "1rem",
  },
  circularProgress: { marginTop: ".6rem", marginRight: ".25rem" },
  typography: {
    1: { marginRight: "1rem !important" },
    2: { marginLeft: "2rem" },
  },
  divider: { marginTop: "1rem", marginBottom: "1rem" },
};

const CancelPresentationIcon_ = styled(CancelPresentationIcon)`
  color: rgba(0,0,0, .2);
  margin-left: .5rem;
  :hover {
    color: #F15A29;
  }
`;
