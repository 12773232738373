import { Box, Button, Popover } from "@mui/material";
import React, { useEffect, useState } from "react";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMore from "@mui/icons-material/ExpandMore";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { PopoverButtons } from "./PopoverButtons";
import {ServiceGroup} from  "./ServiceGroup";

const ServiceTable_renderCell = (props)=> {

    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpenPopover = async (event) => {
      event.stopPropagation();
      const action = async () => { setAnchorEl(event.currentTarget); };
      await action();
    };
    
    const handleClose = () => {
      setAnchorEl(null);
    };
    const popoverOpen = Boolean(anchorEl);
    const id = popoverOpen ? "simple-popover" : undefined;
    return(
    <div>
        <Button onClick={(e) => handleOpenPopover(e)}>
          {
            (props.variant === "menu" || props.variant === "Menu") ?
            <MoreHorizIcon color="secondary"/>
            :
            (props.variant === "group" || props.variant === "Group") ?
              <>
                { popoverOpen ?
                  <ExpandMore/>
                  :
                  <ChevronRightIcon/>
                }
              </>      
            :
            null
          }
          
        </Button>
     
        <Popover
          id={props.params.id}
          open={popoverOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
            {
              (props.variant === "menu" || props.variant === "Menu") ?
              <PopoverButtons
                elevation={0}
                handleClose={ () => setAnchorEl(null) }
                serviceId={props.serviceId}
                setServiceId={props.setServiceId}
                serviceData={props.serviceData}
                setSelectedService={props.setSelectedService}
                setRenameFormOpen={props.setRenameFormOpen}
                setIsConfiguring={props.setIsConfiguring}
                setErrorMessage={props.setErrorMessage}
                setDialogOpen={props.setDialogOpen}
              />
              :
              // <span>{props.params.row.children[1].name}</span>
              <Box sx={{padding: "20px"}}>
                {
                  props.params.row.children.map( (child) => (
                      <ServiceGroup
                        key={child.id}
                        node={child}
                        conditions={props.params.row.conditions}
                        metric={props.params.row.metric}
                        serviceId={props.params.row.id}
                      />
                  ))
                }
              </Box>
            }
            
        </Popover>  

    </div>
  );
};

export default ServiceTable_renderCell;