import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  assignUserDivision,
  getUsersWithCognitoData,
  unassignUserDivision,
} from "data/queries/queryAPI";
import {
  closeDivisionUsersDialog,
  divisionUsersDialogOpen,
  openDivisionUsersDialog,
} from "Context/DrawerStates";
import { useQuery, useQueryClient } from "react-query";

import { Outlined_btn } from "components/Theme/Styled";
import {styled} from "@mui/material/styles";

export const EditUsersDialog = (props) => {
  const [checked, setChecked] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const queryClient = useQueryClient();

  const Box1_= styled(Box)`
  margin-right: auto;
  `;

  const MenuItem_ = styled(MenuItem)`
  display: flex !important;
  flex-direction: column !important;
`;

  const usersQuery = useQuery(["allDetailedUsers"], getUsersWithCognitoData, {
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (props.divisionForDialog && usersQuery.data) {
      setAllUsers(usersQuery.data.filter(e => e));
      setChecked(props.divisionForDialog.users);
    }
  }, [props.divisionForDialog, usersQuery.data]);

  const handleAddUser = (user) => {
    const payload = {};
    payload.userId = user.id;
    payload.divisionId = props.divisionForDialog.id;
    return assignUserDivision(payload);
  };

  const handleRemoveUser = (user) => {
    const payload = {};
    payload.userId = user.id;
    payload.divisionId = props.divisionForDialog.id;
    return unassignUserDivision(payload);
  };

  const handleToggle = (value) => () => {
    const isDivisionUser = (user) => user.id === value.id;
    const currentIndex = checked.findIndex(isDivisionUser);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    handleUpdateChecked(newChecked);
  };

  const handleUpdateChecked = (checked) => {
    setIsUpdating(true);
    const promiseArray = [];
    const usersToAdd = checked.filter(
      (user) => !props.divisionForDialog.users.includes(user)
    );
    const usersToRemove = props.divisionForDialog.users.filter(
      (user) => !checked.includes(user)
    );

    if (usersToAdd.length > 0) {
      usersToAdd.forEach((user) => {
        promiseArray.push(handleAddUser(user));
      });
    }
    if (usersToRemove.length > 0) {
      usersToRemove.forEach((user) => {
        promiseArray.push(handleRemoveUser(user));
      });
    }

    Promise.all(promiseArray)
      .then(async () => {
        await queryClient.refetchQueries(["allDivisions"]);
      })
      .then(() => {
        setIsUpdating(false);
        closeDivisionUsersDialog();
      })
      .catch((error) =>
        console.error("Error updating division users list: ", error)
      );
  };

  const handleUpdate = () => {
    setIsUpdating(true);
    const promiseArray = [];
    const usersToAdd = checked.filter(
      (user) => !props.divisionForDialog.users.includes(user)
    );
    const usersToRemove = props.divisionForDialog.users.filter(
      (user) => !checked.includes(user)
    );

    if (usersToAdd.length > 0) {
      usersToAdd.forEach((user) => {
        promiseArray.push(handleAddUser(user));
      });
    }
    if (usersToRemove.length > 0) {
      usersToRemove.forEach((user) => {
        promiseArray.push(handleRemoveUser(user));
      });
    }

    Promise.all(promiseArray)
      .then(async () => {
        await queryClient.refetchQueries(["allDivisions"]);
      })
      .then(() => {
        setIsUpdating(false);
        closeDivisionUsersDialog();
      })
      .catch((error) =>
        console.error("Error updating division users list: ", error)
      );
  };

  return (
    <div>
        <Dialog
          disableEscapeKeyDown
          open={divisionUsersDialogOpen.use()}
          onClose={closeDivisionUsersDialog}
        >
            <Box sx={styles.box[1]}>
              <Typography variant="h6" color="#fff">
                Edit Current User(s)
              </Typography>
            </Box>
            <Box sx={styles.box[2]}></Box>
            <div>
              <FormControl sx={styles.formControl}>
                <InputLabel id="multiple-checkbox-label">
                  Assign Users
                </InputLabel>
                <Select
                  disabled={isUpdating}
                  labelId="multiple-checkbox-label"
                  id="multiple-checkbox"
                  multiple
                  value={checked?.map((user) => user?.name)}
                  input={<OutlinedInput label="Assign Users" />}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {allUsers.map((user) => (

                    <MenuItem_ key={user.id} value={user.name}>
                      <Box1_ sx={styles.row} onClick={handleToggle(user)}>
                        <Checkbox
                          edge="start"
                          checked={checked?.some(
                              (checkedUser) => checkedUser.id === user.id
                            )}
                            tabIndex={-1}
                            color="secondary"
                            disableRipple
                            // inputProps={{ "aria-labelledby": labelId }}
                          />
                        <ListItemText primary={user.name} />
                      </Box1_>

                    </MenuItem_>
                  ))}

                  
                </Select>
              </FormControl>
            </div>
            <Box
              sx={styles.box[3]}
            >
              <DialogActions sx={styles.div}>

                <Outlined_btn
                  onClick={closeDivisionUsersDialog}
                >
                  {isUpdating ? (
                    <CircularProgress color="secondary" size={24} />
                  ) : (
                  <span>Close</span>
                  )}
                </Outlined_btn>
                </DialogActions>
            </Box>
        </Dialog>
    </div>
  );
};

const styles = {
  list: { width: "100%", maxWidth: 360 },
  btn: {borderColor: "#F15A29", color: "#F15A29", alignSelf: "center"},
  div: { display: "flex", alignItems: "center", justifyContent: "center" },
  box: {
    1: { backgroundColor: "#F15A29", padding: "1rem" },
    2: { display: "flex", flexDirection: "row" },
    3: { display: "flex", flexDirection: "row", padding: "1rem" }
  },
  formControl: { m: 1, width: 300 },
  row: {display: "flex", flexDirection: "row"}

};
