import React, {useEffect, useRef, useState} from 'react';
import {
  useAPICallback,
  useAPIData,
  useAPIEffect,
} from "./data/hooks/customHooks";

import Loading from "./Loading";
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select';
import StyledSelect from './StyledSelect';
import {styled} from "@mui/material/styles";

//STILL NEED TO IMPLEMENT
const ConfigurationSelector = (props) => {
    const {service, configurations, setSelectedConfig, setSelectedItem, setServiceParts} = props;
    const [selected, setSelected] = useState('none');    
    const onSelect = (event) =>{
        setSelected(event.target.value);
        configurations.data.map((config, index) => {
          if(event.target.value === config.name){
            return setSelectedConfig(config)
          }
        })
        setSelectedItem([])
    }
    

    useRef(React.createRef())
    useEffect(() => {
        setSelected('none')
        setSelectedConfig([])
        setServiceParts({isLoading: false, isError: false, data: []})
    }, [configurations]);
    

    return (
        <div>
          { 
            
            (configurations.data && !configurations.isLoading && !configurations.isError) ?
            <div ref={React.createRef()}>
              <div className="flex-h aligned centered" ref={React.createRef()}>
                <label>Configuration</label>
                <Select_
                  sx={styles.select}
                  ref={React.createRef()}
                  variant='outlined' 
                  value={selected} 
                  onChange={onSelect} 
                  defaultValue={'Select a configuration'}>
                    <MenuItem ref={React.createRef()} value='none' disabled> {'Select a configuration'} </MenuItem>
                      {
                        configurations.data.map(option =>
                          <MenuItem ref={React.createRef()} key={option.id} value={option.id}> {option.name} </MenuItem>
                        )
                      }
                </Select_>
              </div>
            </div> : <div ref={React.createRef()}>
              <div className="flex-h aligned centered" ref={React.createRef()}>
                <Loading message="Loading configurations..."/>
              </div>
            </div>
          }
        </div>
      );
     
};

const Select_ = styled(Select)`
  width: 220px;
`;

const styles = {
  select: {width: '220px'}
};

export default ConfigurationSelector;