import React from "react";
import { AmplifyAuthenticator, withAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";
import { Box, Dialog, Grid, Paper } from '@mui/material';
import PilotSystem from "./Pilot/PilotSystem";
export const Login = props => {


  return (
    <Paper sx={styles.img}>

      <Box sx={ styles.panelBox }> 
    
        <Grid 
          container 
          direction='column' 
          sx={ styles.grid }
          wrap="nowrap"
        >

          <Grid item sx={{maxHeight: 530 }}>

            <div authState={ props.authState } authData={props.user} >
              <AmplifyAuthenticator>
                <AmplifySignIn
                  slot="sign-in"
                  style={{alignSelf: 'self-start'}}
                >
                  <div slot="secondary-footer-content"></div>

                </AmplifySignIn>

              </AmplifyAuthenticator>
            </div>

          </Grid>

          <Grid item >
            <PilotSystem/>
          </Grid>  

        </Grid>
      </Box>
    </Paper>
  );
};

export default withAuthenticator(Login);




const bgImg = `./assets/LoginBackground.png`

const styles = {
  img: {
    backgroundImage: `url(${bgImg})`,
    backgroundPositions: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    textAlign: 'center',
    overflow: 'auto',
    width: '100vw',
    height: '100vh'
  },

  grid: {display: 'inline-flex', marginTop: '33%'},

  amplifySignIn: {
    display: 'inline-flex',
    flexDirection: 'column',
  },

  panelBox: {
    display: 'inline-flex',
  }

};