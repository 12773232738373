import React, {useEffect, useRef, useState} from 'react';
import { getService, getServiceConfigurations } from "./data/queries/queryAPI.js";
import {
  useAPICallback,
  useAPIData,
  useAPIEffect,
} from "./data/hooks/customHooks";

import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select';
import StyledSelect from './StyledSelect';

const ServiceSelector = (props) => {
    const {services, setSelectedService, setSelectedServiceFile, setSelectedConfiguration} = props;
    const [selected, setSelected] = useState('none');
    const options = useRef(services)
    const [
      serviceFileLoading,
      serviceFileError,
      getServiceFile,
    ] = useAPIEffect(async (serviceId) => {
      const resp = await getService(serviceId);
      setSelectedServiceFile({
        isLoading: serviceFileLoading,
        isError: serviceFileError,
        data: resp.data,
      });
      return resp;
    });

    const [
      configurationsLoading,
      configurationsError,
      getServiceConfiguration,
    ] = useAPIEffect(async (serviceId) => {
      const resp = await getServiceConfigurations(serviceId);
      setSelectedConfiguration({
        isLoading: configurationsLoading,
        isError: configurationsError,
        data: resp,
      });
      return resp;
    });
    const onSelect = (event) =>{
        localStorage.setItem("LAST_SELECTED_DUCT_SERVICE", event.target.value);
        setSelected(event.target.value);
        setSelectedService(event.target.value);
        if(event.target.value !== 'none'){
          setSelectedServiceFile(getServiceFile(event.target.value));
          setSelectedConfiguration(getServiceConfiguration(event.target.value));
        }
    }
    


    useEffect(() => {
        const serviceId = localStorage.getItem("LAST_SELECTED_DUCT_SERVICE") != null ? localStorage.getItem("LAST_SELECTED_DUCT_SERVICE") : 'none';
        
        setSelected(serviceId);
        setSelectedService(serviceId);  
        if(serviceId !== 'none'){
          setSelectedServiceFile(getServiceFile(serviceId)); 
          setSelectedConfiguration(getServiceConfiguration(serviceId));
        }
        
    }, [setSelectedService]);
    

    return (
        <div>
          { 
            (options.current !== null) &&
            <div ref={React.createRef()}>
              <div className="flex-h aligned centered" ref={React.createRef()}>
                <label>Service</label>
                
                <Select
                  sx={styles.select}
                  ref={React.createRef()}
                  variant='outlined' 
                  value={selected} 
                  onChange={onSelect} 
                  defaultValue={'Select a service'}
                >
                    <MenuItem 
                      ref={React.createRef()} 
                      value='none' disabled
                    > 
                      {'Select a service'} 
                    </MenuItem>
                      {
                        options.current.filter(d=>!d?.isDeleted).map(option =>
                          <MenuItem 
                            ref={React.createRef()} 
                            key={option.id} 
                            value={option.id}
                          > 
                            {option.name} 
                          </MenuItem>
                        )
                      }
                </Select>
              </div>
            </div>
          }
        </div>
      );    
};

const styles = {
  select: {width: '220px !important'}
};

export default ServiceSelector;