import { Box, Paper } from "@mui/material";
import React, {
  useEffect,
  useState,
} from "react";
import { useQuery, useQueryClient } from "react-query";

import { CatalogsList } from "./components/CatalogsList";
import { PersistentNavButtons } from "../Navigation/PersistentNavButtons";
import { getCatalogs } from "../../data/queries/queryAPI";

export const Catalogs = (props) => {

  const queryClient = useQueryClient();
  //CLOSE ALL OTHER PAGE'S DRAWERS ON INITIAL LOAD
  useEffect(() => {
    props.setProjectServiceDialogOpen(false);
    props.setProjectUserDialogOpen(false);
    props.handleCloseOrdersDrawer();
    props.closeServicesDrawer();
    props.closeCreateUserDrawer();
    props.closeUsersDrawer();
    props.closeProjectsDrawer();
  });

  const [catalogs, setCatalogs] = useState(undefined);

  const catalogsQuery = useQuery(["allCatalogs"], getCatalogs, {
    staleTime: Infinity,
    refetchOnMount: 'always',
    refetchOnWindowFocus: false,
  })


  useEffect(() => {
    if(Array.isArray(catalogsQuery?.data?.data)){
      setCatalogs(catalogsQuery?.data?.data)
    }
  }, [catalogsQuery])

 /*  const flipSwitch = () => {
    setCardsView(!cardsView);
  }; */
  const styles = {
    paper: {
      height: "auto",
      width: "100%",
      marginTop: "80px",
      backgroundColor: "#fff",
      flex: 1,
      flexDirection: "column",
      paddingBottom: "20px",
    }
  };
  return (
    <>
        <Box
          sx={styles.paper}
        >
          <CatalogsList
            //cardsView={cardsView}
            catalogs={catalogs}
          />
        </Box>
    </>
  );
};
