import * as THREE from "three";

import {Canvas, extend} from "@react-three/fiber"
import {
    Card,
    CardContent
} from "@mui/material"
import {OrbitControls, Stars} from "@react-three/drei"
import React, {useEffect, useMemo, useState} from "react";

import Alata from "../../../../fonts/Alata_Regular.json";
import  {FontLoader} from 'three/examples/jsm/loaders/FontLoader'
import {TextGeometry} from 'three/examples/jsm/geometries/TextGeometry'

extend({TextGeometry})

export const ServiceConfigurationPartPreviewer = (props) => {
    const [meshData, setMeshData] = useState(props.meshData);
    const [selectedPart, setSelectedPart] = useState(props.selectedPart);
    const [meshGeometry, setMeshGeometry] = useState([]);
    const [meshAnnotations, setMeshAnnotations] = useState([]);
    const [meshVertices, setMeshVertices] = useState([]);
    const [meshTriangles, setMeshTriangles] = useState([]);
    const [meshNormals, setMeshNormals] = useState([]);
    const [meshConnectors, setMeshConnectors] = useState([]);
    const [meshInsulationVertices, setMeshInsulationVertices] = useState([]);
    const [meshInsulationTriangles, setMeshInsulationTriangles] = useState([]);
    const [meshInsulationNormals, setMeshInsulationNormals] = useState([]);
    const [meshDimensions, setMeshDimensions] = useState([]);
    const [currentVertices, setCurrentVertices] = useState([]);
    const [currentTriangles, setCurrentTriangles] = useState([]);
    const [currentNormals, setCurrentNormals] = useState([]);
    const [currentInsulationVertices, setCurrentInsulationVertices] = useState([]);
    const [currentInsulationTriangles, setCurrentInsulationTriangles] = useState([]);
    const [currentInsulationNormals, setCurrentInsulationNormals] = useState([]);
    const [currentColors, setCurrentColors] = useState([]);

    useEffect(() => {
      setMeshData(props.meshData)
    }, [props.meshData])


    var cam = {};

    const GenerateMesh = (meshVertices, meshTriangles, meshNormals, insulationVertices, insulationTriangles, insulationNormals, annotations, connectors, dimensions) => {
        let vertices = [];
        let triangles = [];
        let normals = [];
        let colors = [];

        let insVertices = [];
        let insTriangles = [];
        let insNormals = [];
        if(meshVertices !== undefined && Array.isArray(meshVertices)) {
            setMeshVertices(meshVertices)
            meshVertices.map((vert) => {
                vertices.push(vert.X, vert.Y, vert.Z);
                let color = {r: 1, g: 1, b: 1}
                colors.push(color.r, color.g, color.b);
            })
            setCurrentVertices(vertices);
            setCurrentColors(colors);            
        }
        if(meshTriangles!== undefined && Array.isArray(meshTriangles)) {
            setMeshTriangles(meshTriangles)
            meshTriangles.map((triangle) => {
                triangles.push(triangle.Index1, triangle.Index2, triangle.Index3);
            })
            setCurrentTriangles(triangles);
        }
        if(meshNormals!== undefined && Array.isArray(meshNormals)) {
            setMeshNormals(meshNormals)
            meshNormals.map((normal) => {
                normals.push(normal.X, normal.Y, normal.Z);
            })
            setCurrentNormals(normals);
        }
        if(insulationVertices !== undefined && Array.isArray(insulationVertices)){
            setMeshInsulationVertices(insulationVertices)
            insVertices.map((vert) => {
                insVertices.push(vert.X, vert.Y, vert.Z);
            })
            setCurrentInsulationVertices(insVertices)
        }
        if(insulationTriangles !== undefined && Array.isArray(insulationTriangles)){
            setMeshInsulationTriangles(insulationTriangles)
            insTriangles.map((triangle) => {
                insTriangles.push(triangle.Index1, triangle.Index2, triangle.Index3);
            })
            setCurrentInsulationTriangles(insTriangles)
        }
        if(insulationNormals !== undefined && Array.isArray(insNormals)){
            setMeshInsulationNormals(insulationNormals)
            insNormals.map((normal) => {
                insNormals.push(normal.X, normal.Y, normal.Z);
            })
            setCurrentInsulationNormals(insNormals)
        }
        if(annotations !== undefined && Array.isArray(annotations)){
            setMeshAnnotations(annotations)
        }
        if(dimensions !== undefined && Array.isArray(dimensions)){
            setMeshDimensions(dimensions)
            props.setDimensions(dimensions)
        }
        if(connectors !== undefined && Array.isArray(connectors)){
            setMeshConnectors(connectors)
        }
    }

    const ConstructMeshData = (meshGeometry) => {
        let verts = [];
        let tris = [];
        let norms = [];
        let annots = [];
        let dims = [];
        let conns = [];
        let insVerts = [];
        let insTris = [];
        let insNorms = [];
        if(meshGeometry !== undefined){
            if(meshGeometry.Shell !== undefined){
                if(meshGeometry.Shell.Annotations){
                    annots = meshGeometry.Shell.Annotations;
                }
                if(meshGeometry.Shell.Body !== undefined){
                    if(meshGeometry.Shell.Body.Vertices !== undefined){
                        verts = meshGeometry.Shell.Body.Vertices;
                    }
                    if(meshGeometry.Shell.Body.Triangles !== undefined){
                        tris = meshGeometry.Shell.Body.Triangles;
                    }
                    if(meshGeometry.Shell.Body.Normals !== undefined){
                        norms = meshGeometry.Shell.Body.Normals;
                    }
                }
                if(meshGeometry.Shell.Connectors !== undefined){
                    conns = meshGeometry.Shell.Body.Connectors;
                }
                if(meshGeometry.Shell.Dimensions !== undefined){
                    dims = meshGeometry.Shell.Dimensions;
                }
                if(meshGeometry.Shell.Insulation !== undefined){
                    if(meshGeometry.Shell.Insulation.Vertices !== undefined){
                        insVerts = meshGeometry.Shell.Insulation.Vertices;
                    }
                    if(meshGeometry.Shell.Insulation.Triangles !== undefined){
                        insTris = meshGeometry.Shell.Insulation.Triangles;
                    }
                    if(meshGeometry.Shell.Insulation.Normals !== undefined){
                        insNorms = meshGeometry.Shell.Insulation.Normals
                    }
                }
            }
            GenerateMesh(verts, tris, norms, insVerts, insTris, insNorms, annots, conns, dims)
        }
    }

    useEffect(() => {
        ConstructMeshData(meshGeometry)
    }, [meshGeometry])

    useEffect(() =>{
        if(meshData.data){
          if(meshData.data.processedGeometry){
            setMeshGeometry(JSON.parse(JSON.parse(JSON.stringify(meshData.data.processedGeometry)))[0])
          }else{
            setMeshGeometry([]);
            setCurrentVertices([])
            setCurrentTriangles([])
            setCurrentNormals([])
            setCurrentInsulationVertices([])
            setCurrentInsulationNormals([]);
            setCurrentInsulationTriangles([]);
            setMeshAnnotations([])
            setMeshDimensions([])
            setMeshVertices([])
            setMeshTriangles([])
            setMeshNormals([])
          }
        }
      }, [meshData])

      useEffect(() => {
        setMeshGeometry([]);
        setCurrentVertices([])
        setCurrentTriangles([])
        setCurrentNormals([])
        setCurrentInsulationVertices([])
        setCurrentInsulationNormals([]);
        setCurrentInsulationTriangles([]);
        setMeshAnnotations([])
        setMeshDimensions([])
        setMeshVertices([])
        setMeshTriangles([])
        setMeshNormals([])
      }, [props.selectedPart])

      const getPointInBetween = (pointA, pointB, percentage) => {
        var dir = pointB.clone().sub(pointA);
        var len = dir.length();
        dir = dir.normalize().multiplyScalar(len * percentage);
        return pointA.clone().add(dir);
      }

      const PartPreview = (props) => {
        const font = new FontLoader().parse(Alata)
        const memoVerts = useMemo(() => { return new Float32Array(props.verts)}, [props.verts])
        const memoTris = useMemo(() => {return new Uint32Array(props.tris)}, [props.tris])
        const memoNorms = useMemo(() => {return new Float32Array(props.norms)}, [props.norms])
        const memoInsVerts = useMemo(() => { return new Float32Array(props.insVerts)}, [props.insVerts])
        const memoInsTris = useMemo(() => {return new Uint32Array(props.insTris)}, [props.insTris])
        const memoInsNorms = useMemo(() => {return new Float32Array(props.insNorms)}, [props.insNorms])
        const memoAnnotations = useMemo(() => {return props.annotations}, [props.annotations])
        const memoDimensions = useMemo(() => {return props.dimensions}, [props.dimensions])
        const item = useMemo(() => {return props.selectedPart}, [props.selectedPart])

        let textOptions = {
            font,
            size: 2, 
            height: 1
        }
        if(item !== undefined && item?.connectors !== undefined && Array.isArray(item?.connectors) && item?.connectors.length > 0){
          if(item?.connectors[0]?.WidthOrDiameter !== undefined){
              textOptions = {
                  font,
                  size: item?.connectors[0]?.WidthOrDiameter * 0.25,
                  height: item?.connectors[0]?.WidthOrDiameter * 0.25
              }
          }
      }

        return (
            <mesh {...props} >
              <bufferGeometry attach="geometry">
                <bufferAttribute
                  attachObject={["attributes", "position"]}
                  array={memoVerts}
                  itemSize={3}
                  count={memoVerts.length / 3}
                  needsUpdate={true}
                />
                <bufferAttribute 
                  attach="index"
                  array={memoTris}
                  count={memoTris.length}
                  itemSize={1}
                  needsUpdate={true}
                />
                <bufferAttribute
                  attachObject={["attributes", "normal"]}
                  count={memoNorms.length}
                  array={memoNorms}
                  itemSize={3}
                  needsUpdate={true}
                />
              </bufferGeometry>
              {
                memoInsVerts.length !== 0 && memoInsTris.length !== 0 && memoInsNorms !== 0 ? (
                    <bufferGeometry attach="geometry">
                      <bufferAttribute
                        attachObject={["attributes", "position"]}
                        array={memoInsVerts}
                        itemSize={3}
                        count={memoInsVerts.length / 3}
                        needsUpdate={true}
                      />
                      <bufferAttribute 
                        attach="index"
                        array={memoInsTris}
                        count={memoInsTris.length}
                        itemSize={1}
                        needsUpdate={true}
                      />
                      <bufferAttribute
                        attachObject={["attributes", "normal"]}
                        count={memoInsNorms.length}
                        array={memoInsNorms}
                        itemSize={3}
                        needsUpdate={true}
                      />
                    </bufferGeometry>
                ): null
              }  
              <meshPhongMaterial attach="material" side={THREE.DoubleSide} color="gray" />
              {memoAnnotations.length !== 0 && memoAnnotations.map((e, index) => {
                let linePoints = [];
                let point1 = new THREE.Vector3(e.Pt1.X, e.Pt1.Y, e.Pt1.Z)
                let point2 = new THREE.Vector3(e.Pt2.X, e.Pt2.Y, e.Pt2.Z)
                let midPoint = getPointInBetween(point1, point2, 0.25)
                linePoints.push(point1)
                
                if(e.Prefix.includes("C")){
                  midPoint = getPointInBetween(point1, point2, 0.025)
                  linePoints.push(midPoint)
                  let lineGeometry = new THREE.BufferGeometry().setFromPoints(linePoints)
                  return (
                    <mesh key={index}>
                      <line geometry={lineGeometry}>
                        <lineBasicMaterial attach="material" color={'blue'} linewidth={50} linecap={'round'} linejoin={'round'} />
                      </line>
                      <mesh  position={midPoint} rotation={props.camera.rotation}>
                          <textGeometry attach='geometry' args={[e.Prefix, textOptions]} />
                          <meshStandardMaterial attach='material' color={'blue'}/>
                      </mesh>
                    </mesh>
                    
                  )
                }else{
                  linePoints.push(point2)
                  let lineGeometry = new THREE.BufferGeometry().setFromPoints(linePoints)
                  return (
                    <mesh key={index}>
                      <line geometry={lineGeometry}>
                        <lineBasicMaterial attach="material" color={'blue'} linewidth={50} linecap={'round'} linejoin={'round'} />
                      </line>
                      <mesh  position={[e.Position.X, e.Position.Y, e.Position.Z]} rotation={props.camera.rotation}>
                          <textGeometry attach='geometry' args={[e.Prefix, textOptions]} />
                          <meshStandardMaterial attach='material' color={'blue'}/>
                      </mesh>
                    </mesh>
                    
                  )
                }
              })}
              {memoDimensions.length !== 0 && memoDimensions.map((e, index) =>{
                let linePoints = []
                let point1 = new THREE.Vector3(e.From.X, e.From.Y, e.From.Z);
                let point2 = new THREE.Vector3(e.To.X, e.To.Y, e.To.Z)
                let midPoint = getPointInBetween(point1, point2, 0.5)
                linePoints.push(point1)
                linePoints.push(point2)
                let lineGeometry = new THREE.BufferGeometry().setFromPoints(linePoints);
                return (
                  <mesh key={index}>
                    <mesh position={midPoint} rotation={props.camera.rotation}>
                        <textGeometry attach='geometry' args={[e.Prefix, textOptions]} />
                        <meshStandardMaterial attach='material' color={'orange'}/>
                    
                    </mesh>
                    <line geometry={lineGeometry}>
                      <lineBasicMaterial attach="material" color={'orange'} linewidth={50} linecap={'round'} linejoin={'round'} />
                    </line>
                  </mesh> 
                )
              })}
            </mesh>
          );
      }
      return(
        <Card variant="outlined" sx={styles.card}>
              
              <CardContent sx={styles.cardContent}>
              <Canvas onCreated={({camera})=> {
                cam = camera
              }}>
                  <ambientLight intensity={0.5}/>
                  <spotLight position={[40, 50, 40]} angle={0.9}/>
                  <Stars/>
                  <OrbitControls/>
                  {
                    currentVertices !== [] && currentTriangles !== [] && currentNormals !== [] &&
                    <PartPreview 
                        verts={currentVertices} 
                        tris={currentTriangles} 
                        norms={currentNormals} 
                        annotations={meshAnnotations} 
                        dimensions={meshDimensions} 
                        camera={cam} 
                        selectedPart={selectedPart}
                        insVerts={currentInsulationVertices}
                        insTris={currentInsulationTriangles}
                        insNorms={currentInsulationNormals} 
                    />
                  }                
              </Canvas>
                  {props.buttons}
              </CardContent>
        </Card>   
      ); 

}

const styles = {
    div: {
      1: {width: 310, height: 260, border: '4px solid #003E70'}
    },
    card: {width: 310, height: 260, overflowY: 'auto', border: '1px solid #F15A29 !important', marginBottom: "1rem"},
    cardContent: {width: 280, height: 200}
  };