export const buttons = {
    variants: [

      {props: {variant: 'bordered'},
      style: {
        //border: "2px solid #F15A29",
        color: "#F15A29 !important",
        borderColor: "#F15A29 !important",
        borderWidth: "1px !important",
        borderStyle: "solid !important",
      }},

      {props: {variant: 'filled'},
      style: {
        backgroundColor: "#F15A29 !important",
        color: "#fff !important",
        boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2) !important"
      }},

      {props: {variant: 'lettered'},
      style: {
        color: "#F15A29 !important",
      }},

    ]
};



