import { Button, Chip, Divider, Typography } from "@mui/material";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";

import CustomDataGrid from "./CustomDataGrid"
import { styled } from "@mui/material/styles";
import { useState } from 'react';

export const Service_Tabs_Table = (props) => {

    const [selectionModel, setSelectionModel] = useState([])
    const columns = [
        { field: "id", headerName: "ID", width: 150 },
        { field: "name", headerName: "Name", width: 150 },
    ];
    return(
        <>
            <Divider sx={styles.margin_bottom}><Typography color="secondary">Tabs</Typography></Divider>
            <CustomDataGrid
                sx={styles.margin_bottom}
                rows={props.rows}
                columns={columns}
                autoHeight={true}
                hideFooter={true}
                disableSelectionOnClick={true}
                onSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel);
                    props.setSelectionTabModel(newSelectionModel);
                  }}
                  selectionModel={selectionModel}
                  checkboxSelection
                disableColumnMenu={true}
                components={{
                    Toolbar: GridToolbar,
                  }}
                variant="elevation"
                
            />
        </>
    );

};


const DataGridPro_ = styled(DataGridPro)`
  .MuiDataGrid-columnHeaderTitle {
    color: #F15A29;
  };
`;

const styles = {
    margin_bottom: {marginBottom: "20px"}
}