import { Box, Container } from "@mui/material";
import React, { useEffect, useState, } from "react";
import { TextField, Typography } from "@mui/material";
import { useMutation, useQueryClient } from "react-query";

import {
    CircularProgress,
} from '@mui/material';
import LogoSelector from './LogoSelector';
import { styled } from '@mui/material/styles';
import { updateCompany } from "../../../data/queries/queryAPI.js";
import { withStyles } from "@mui/styles";

const toBase64 = file => new Promise((resolve, reject) => {
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    }
  });

  const CssTextField = styled(TextField)(({theme}) => ({
    "&.MuiTextField-root": {
      "& label": {
        color: "#F15A29",
        fontSize: "1rem",
      },
      "& label.Mui-focused": {
        fontSize: "1rem",
        color: "#F15A29"
      },
      "& .MuiOutlinedInput-root": {
      backgroundColor: 'white',
        borderRadius: 4,
        color: "#F15A29",
        width: "30rem",
        fontSize: "1rem",
        "& fieldset": {
          borderColor: "#F15A29",
          borderWidth: 2,
        },
        "&:hover fieldset": {
          borderColor: "#F15A29",
          borderWidth: 2,
        },
        "&.Mui-focused fieldset": {
          borderColor: "#F15A29",
          borderWidth: 2,
        },
      },
    },
   }));

export default function NameAndThumbnail(props){

    const companyId = props.companyId;
    const companyData = props.companyData;
    let companyName = companyData?.data?.data?.name === undefined ? " " : companyData?.data?.data?.name
    const companyLoadingStatus = companyData?.status;
    const [logo, setLogo] = useState(companyData?.data?.data?.logo);
    // const setCompany = props.setCompany;
    //const CssTextField = props.CssTextField;

    const [nameInput, setNameInput] = useState(""); //consider changing to companyName
    const [originalName, setOriginalName] = useState(""); //consider changing to companyName
    //const [originalPhoto, setOriginalPhoto] = useState("")
    const [nameChanged, setNameChanged] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [waitingOnName, setWaitingOnName] = useState(false);
    const [waitingOnLogoSave, setWaitingOnLogoSave] = useState(false);
    const [waitingOnLogoDelete, setWaitingOnLogoDelete] = useState(false);
    const queryClient = useQueryClient();

    const payload = {
        data: undefined,
        companyId: undefined
      };

    const [formIcon, setFormIcon] = useState(payload?.data?.logo || '');

    useEffect(() => {
        setNameInput(companyName);
        setOriginalName(companyName);
        setFormIcon(companyName ? logo : "");
      }, [companyName]);

      const { mutate: setCompany, refetch} = useMutation(
        (payload)=> updateCompany(payload), {

        onSuccess: (data) => {
          queryClient.refetchQueries(["getOneCompany"]).then(() => {
            setWaitingOnName(false);
            setUpdating(false);
            setWaitingOnLogoSave(false);
            setWaitingOnLogoDelete(false);
            
          })
          // if (data.status === 200) {
          //   setWaitingOnName(false);
          //   setUpdating(false);
          //   setWaitingOnLogoSave(false);
          //   setWaitingOnLogoDelete(false);
          // }
        },
        onError: (error) => {
          //console.error("error mutating data:", error);
          alert("There was an error: ", error);
        },
      })

      function saveIcon() {
        if (companyLoadingStatus !== 'loading' && companyId !== undefined) {
          const payload = {};
          payload.data = { logo: formIcon };
          payload.companyId = companyId;
          setCompany(payload);
          setWaitingOnLogoSave(true);
        }
      }

      function removeIcon() {
        if (companyLoadingStatus !== 'loading' && companyId !== undefined) {
            const payload = {};
            payload.data = { logo: "" };
            payload.companyId = companyId;
            setCompany(payload);
            setFormIcon(payload.data.logo);
            setWaitingOnLogoDelete(true);
        }
      }


      const handleNameClick = () => {
        const payload = {};
        payload.data = {name: nameInput};
        payload.companyId = companyId;
        companyName = nameInput;
        setCompany(payload);
        setWaitingOnName(true);
      }

      useEffect(()=>{
        if(formIcon){
          saveIcon()
        }
      }, [formIcon])

    const styles = {
      header: {
        margintop: "25px",
        marginbottom: "40px",
        width: "100%",
        height: "100%",
      },
    }

    const Div1 = styled('div')`
      max-width: 30rem;
      display: flex;
      flex-direction: column;
      border: 1.5px solid #dadada;
      border-radius: 4px;
    `;
    const Div2 = styled('div')`
      justify-content: space-evenly;
      display: flex;
      flex-direction: column;
    `;
    const Div3 = styled('div')`
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
    `;
    
    const Button1_ = styled('button')`
      display: flex;
      border-color: #F15A29;
      background: #F15A29;
      color: white;
      padding-left: 20px;
      padding-right: 20px;
      border-radius: 4px;
      font-weight: 400;
      text-align: center;
      cursor: pointer;
      margin: 20px;
      margin-top: 9px;
      margin-bottom: 10px;
      font-size: 14px;
      height: 40px;
      width: 80px;
    `;
    const Button2 = styled('button')`
      margin-left:0;
      border-color: #F15A29;
      background: #white;
      color: #F15A29;
      padding-left: 20px;
      padding-right: 20px;
      border-radius: 4px;
      font-weight: 400;
      text-align: center;
      cursor: pointer;
    `;
    const Span_fontsize = styled('span')`
      font-size: 14;
    `;
    const Span_align = styled('span')`
      margin-top: 10px;
      text-align: center;
    `;
    
    return (
        <div>
            <Box display="flex">
                <CssTextField
                  label="Name"
                  placeholdertext={nameInput}
                  value={nameInput}
                  onChange={(e)=>
                  setNameInput(e.target.value)
                }
                />
                {
                  (nameInput !== originalName) &&
                   <Button1_
                      type="button"
                      onClick={handleNameClick}
                    >
                      {
                        !waitingOnName ?
                          (
                            
                            <Span_align>Save</Span_align>
                            
                          ) :
                          (
                            <Span_align>
                              <CircularProgress
                                  size={"1rem"}
                                  color="inherit"
                              />
                            </Span_align>
                          )
                      }
                   </Button1_>
                 }
            </Box>
        <br/>
        <Typography sx={styles.header} variant="h5">Logo</Typography>
        <div>
          <Div1
          >
            <Div2>
              {/* <h3 id="transition-modal-title">Set Company Logo (.png) </h3> */}

              <Div3>
                <LogoSelector
                  labelId='menu-icon-selector'
                  displayIcon={formIcon}
                  onChange={async file => setFormIcon(await toBase64(file))}
                  width={200}
                  height={100}
                />
              </Div3>

            </Div2>
          </Div1>
          <br />

          <Button2
            onClick={()=>removeIcon()}
          >
            {
              waitingOnLogoDelete
              ? <CircularProgress size={16} />
              : <Span_fontsize>DELETE</Span_fontsize>
            }
          </Button2>
        </div>
      </div>
    )
}
