import { useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    DialogActions
} from "@mui/material";

import {styled} from "@mui/material/styles";
import { Text_btn } from "components/Theme/Styled";

import{
    closeFailedUserDialog,
    failedUserDialogOpen
} from "../../../Context/DrawerStates";

export const FailedUserDialog = (props) => {

    const [open, setOpen] = useState(false);

    const handleClose = (e) => {
        closeFailedUserDialog();
        setOpen(false);
    }

    return (
        <>
            <Dialog open={failedUserDialogOpen.use()}>
                <DialogTitle color="secondary">USERNAME ALREADY EXISTS</DialogTitle>
                <DialogContent>
                    <Typography_>The username that you have selected already exists. Please select another username.</Typography_>
                </DialogContent>
                <DialogActions>
                    <Text_btn onClick={(e) => handleClose(e)}>OK</Text_btn>
                </DialogActions>
            </Dialog>
        </>
    )
}

const Typography_ = styled(Typography)`
  //width: 75% !important;
  flex-shrink: 0 !important;
`;
