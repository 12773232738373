import React, { useEffect, useRef, useState } from 'react';

import {Box} from "@mui/material";
import Card from '@mui/material/Card';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {ServiceConfigurationPartConnectorSelector} from './ServiceConfigurationPartConnectorSelector'

export const ServiceConfigurationPartConnectors = (props) =>{

    const [selectedPart, setSelectedPart] = useState(props.selectedPart)
    const [selectedConnectionTypes, setSelectedConnectionTypes] = useState([...props.selectedConnectionTypes, {Connectivity: "", Domain: 0, FabId: -1, Group: "Blank", Name: "Blank", Profile: "", Sex: 2, Shape: 0}])
    //console.log("Selected Connection Types: ", selectedConnectionTypes)
    useRef(React.createRef());

    useEffect(() => {
        setSelectedPart(props.selectedPart);
    }, [props.selectedPart])

    useEffect(() => {
        setSelectedConnectionTypes([...props.selectedConnectionTypes, {Connectivity: "", Domain: 0, FabId: -1, Group: "Blank", Name: "Blank", Profile: "", Sex: 2, Shape: 0}]);
    }, [props.selectedConnectionTypes])

    return(
        <Box>  
            {
                (selectedPart.connections !== undefined && Array.isArray(selectedPart.connections) && selectedConnectionTypes !== undefined && Array.isArray(selectedConnectionTypes)) ?

                <Card variant="outlined" sx={{ alignItems: 'center', marginTop: "1rem", width: 310, border: " 1px solid #F15A29 !important", paddingTop: "1rem", paddingBottom: "1.5rem", marginBottom: "1.5rem" }}>
                    {selectedPart.connections.map((e, index) => { 
                        let value = ""

                        selectedConnectionTypes.map((conn => {
                            if(conn.FabId === e.fabId){
                                value = (conn.Name) 
                            }
                        }))
                        return(
                            <ServiceConfigurationPartConnectorSelector 
                                key={index} 
                                selectedPart={selectedPart}
                                setSelectedPart={props.setSelectedPart}
                                setPropertiesChanged={props.setPropertiesChanged} 
                                value={value} 
                                connection={e} 
                                connectionIndex={index} 
                                selectedConnectionTypes={selectedConnectionTypes}
                            />
                            
                        )
                    })}
                </Card>    
                : (
                    <Card variant="outlined" sx={styles.card2}>
                        
                    </Card>
                )
            }
        </Box>
    )
}

const styles = {
    div: {
        1: { 
            width: 250,
            height: 140, 
            overflowX: 'hidden', 
            overflowY: 'auto', 
            border: '4px solid #003E70'
        },
        2: {
            display: 'block', 
            width: 250, 
            height: 140, 
            overflowX: 'hidden', 
            overflowY: 'hidden', 
            border: '4px solid #003E70', 
            justifyContent: 'center'
        }
    },

    card: {
        width: 310,
        height: 260,  
        overflowX: 'hidden', 
        overflowY: 'auto', 
        border: "1px solid #F15A29 !important",
    },
    card2: {
        display: 'block', 
        width: 310, 
        height: 140, 
        overflowX: 'hidden', 
        overflowY: 'hidden', 
        border: "1px solid #F15A29 !important", 
        justifyContent: 'center'
    }
};