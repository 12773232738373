import { 

    Box, 
    Dialog, 
    DialogTitle,
    Divider, 
    Grid, 
    TextField, 
    Typography, 
    
} from "@mui/material"
import { styled } from "@mui/material/styles";

/** Notification modal for after application is sent.
 * 
 * @param {*} props 
 * @returns 
 */
const PilotNoteDialog = props => {

    const Title = `Thank you!`;
    const Caption = `We're currently reviewing your Application and will Email you 
    with a determination by next business day. If you have questions or would like
    to purchase the full version of FieldOrderz, please contact us at: fieldorderz@gogtp.com
    `;

    const ok = () => {

        props.setIsOpen(false);
    };

    return(
        <Dialog
            open={ props.isOpen }
            onClose={ () => props.setIsOpen(false) }
        >
            <DialogTitle> {Title} </DialogTitle>

            <Box sx={{paddingLeft: 3, paddingRight: 3, paddingBottom: 3}} >


                <Box sx={{textAlign: 'justify', width: '75%'}} >
                    <Typography variant='caption'> {Caption} </Typography>
                </Box>   
                    

            </Box>

            <div 
                onClick={ ok } 
                style={ styles.signUpbtn }
            >
                <Typography variant='button'>Okay</Typography>
            </div>

        </Dialog>
    )
}

const styles = {

    signUpbtn: {

        backgroundColor:'#FF9900', 
        padding: 12, 
        color: '#fff', 
        width: 153,
        maxWidth: 153,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '1rem',
        marginTop: '.5rem',
        textAlign: 'center'
    }
}



export default PilotNoteDialog;
