import { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    CircularProgress,
    Typography,

  } from "@mui/material";
import { Outlined_btn, Contained_btn } from "components/Theme/Styled";

import {styled} from "@mui/material/styles";
import { Text_btn } from "components/Theme/Styled";

import { postDeleteProject } from "data/queries/queryAPI";
// TODO :: CREATE DRAWERSTATES FOR HARD DELETE OF APPLICABLE PAGE
import {
closeProjectSoftDeleteDialog,
projectSoftDeleteDialogOpen,

} from "../../../Context/DrawerStates";

  export const SoftDeleteDialog = (props) => {
    const queryClient = useQueryClient();
    const [open, setOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const projectId = props?.project?.id;

    const handleClose = (e) => {
      //TODO:: CHANGE TO APPLICABLE DRAWER STATE
      closeProjectSoftDeleteDialog();
      setOpen(false);
    };

    const { mutate: postDeleteItem } = useMutation(postDeleteProject, {
        onSettled: (data) => {

          queryClient
            .refetchQueries(["allProjects"])
            .then(() => {
              setIsDeleting(false);
            })
            .then(() => {
              handleClose();
            })
            .catch((error) => {
              console.error("error deleting division", error);
            });
        },
        onError: (error) => {
          console.error("error deleting division", error);
          alert("An error occurred while deleting a division: ", error);
        },
      });


      const handleDelete = () => {
          setIsDeleting(true);
          postDeleteItem(projectId);

        };

      const Bold = styled('span')`
        font-weight: bold;
      `;



        return (
            <>
            {/* CHANGE DIALOG OPEN PROP*/}
            <Dialog open={projectSoftDeleteDialogOpen.use()} onClose={handleClose}>
              <DialogTitle color="secondary" ><Bold>WARNING</Bold></DialogTitle>
              <DialogContent>
                {/* TODO::: SWITCH TO TYPOGRAPHY COMPONENT ? */}
                <Typography_>
                  <Bold>WARNING:</Bold>
                  Deleting this project will delete all associated packages. Restore the project to restore the packages.
                </Typography_>
              </DialogContent>
              <DialogActions>
                <Outlined_btn
                  onClick={(e) => handleClose(e)}
                >
                  Cancel
                </Outlined_btn>
                <Contained_btn
                  onClick={handleDelete}
                >
                  Delete
                  {isDeleting ? <Progress_ size={12} /> : null}
                </Contained_btn>
              </DialogActions>
            </Dialog>
        </>
        );
  };

  const Progress_ = styled(CircularProgress)`
    margin-left: 6px;
    color: white !important;
    `;

    const Content_ = styled('p')`
    font-size: 18px;
    `;

    const Typography_ = styled(Typography)`
      //width: 75% !important;
      flex-shrink: 0 !important;
    `;
