import {Box, MenuItem, TextField} from '@mui/material'
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import React, { useState } from 'react';

import {styled} from "@mui/material/styles";

export const ServiceConfigurationDrawerNavBar = (props) => {

    const openTab = (e) => {
        props.setSelectedTab(e.target.outerText)
    }
    const CustomListItem = styled(ListItem)`
    :hover { background: transparent; };
    color: #F15A29 !important;
    text-decoration: none;
    border: 1px solid #FFA988;
    border-color: #FFA988 !important;
    background: #fff;
    text-align: center !important;
    maxWidth: 130px !important;
    height: 40px !important;
    `;

    const CurrentCustomListItem = styled(ListItem)`
      :hover { background: #F15A29 !important; };
      color: #fff !important;
      text-decoration: none;
      border: 1px solid #FFA988;
      border-color: #FFA988 !important;
      background: #F15A29 !important;
      text-align: center !important;
      maxWidth: 130px !important;
      height: 40px !important;
    `;

    const MenuItem_ = styled(MenuItem)`
      display: flex !important;
      flex-direction: column !important;
    `;


    const StyledTextField = styled(TextField)(({theme}) => ({
      "&.MuiTextField-root": {
        padding: ".25rem !importantƒ",
        height: "55.5px",
        "& label": {
          fontSize: "1rem",
        },
        "& label.Mui-focused": {
          fontSize: "1rem",
        },
        "& .MuiOutlinedInput-root": {
          backgroundColor: 'white',
          borderRadius: 4,
          height: "55.5px",

          fontSize: "1rem",
          "& fieldset": {
            borderWidth: 2,
          },
          "&:hover fieldset": {
            borderWidth: 2,
          },
          "&.Mui-focused fieldset": {
            borderWidth: 2,
          },
        },
      },
    }));

    const styles = {
        box: { paddingTop: "1rem" },
        drawer: {
            drawerHeader: {
              backgroundColor: "#000",
              color: "#fff",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }
          },
        iconButton: { color: "#fff", textAnchor: "right" },
        btn: { paddingLeft: "2rem", paddingRight: "2rem" },
        cancelIcon: { color: "#fff", textAlign: "right" },
        row: {display: "flex", flexDirection: "row"},
        tenrem: { width: "64px", height: "64px"},
    };
    return(
        <Box id="DrawerNavBar"
              sx={[styles.box, {maxWidth: 310} ]}
              display="flex"
              flexDirection="row"
              alignItems="center"
              alignSelf="center"
              mb={2}
            >
              <Box sx={{flex: 1, border: "1px solid #FFA988"}} >
                { props.selectedTab === 'Main' ?
                  <CurrentCustomListItem button key="Main" id='Main' onClick={(e) => openTab(e)}>
                    <ListItemText primary="Main"/>
                  </CurrentCustomListItem>
                  :
                  <CustomListItem button key="Main" id='Main' onClick={(e) => openTab(e)}>
                      <ListItemText primary="Main" />
                  </CustomListItem>
                }
              </Box>
              {
                props.productLists !== undefined && Array.isArray(props.productLists) && props.productLists.length <= 0 ? (
                    <Box sx={{ flex: 1, border: "1px solid #FFA988"}}>
                      { props.selectedTab === 'Dimensions' ?
                        <CurrentCustomListItem button key="Dimensions" id='Dimensions' onClick={(e) => openTab(e)}>
                            <ListItemText primary="Dimensions" />
                        </CurrentCustomListItem>
                        :
                        <CustomListItem button key="Dimensions" id='Dimensions' onClick={(e) => openTab(e)}>
                            <ListItemText primary="Dimensions" />
                        </CustomListItem>
                      }
                    </Box>
                ): null
              }
              {
                props.productLists !== undefined && Array.isArray(props.productLists) && props.productLists.length > 0 ? (
                    <Box sx={{ flex: 1, border: "1px solid #FFA988"}}>
                      { props.selectedTab === 'Connectors' ?
                        <CurrentCustomListItem button key="Connectors" id='Connectors' onClick={(e) => openTab(e)}>
                            <ListItemText primary="Connectors" />
                        </CurrentCustomListItem>
                        :
                        <CustomListItem button key="Connectors" id='Connectors' onClick={(e) => openTab(e)}>
                            <ListItemText primary="Connectors" />
                        </CustomListItem>
                      }
                    </Box>
                ): null
              }
              {
                props.itemOptions !== undefined && Array.isArray(props.itemOptions) && props.itemOptions.length > 0 ? (
                    <Box sx={{ flex: 1, border: "1px solid #FFA988"}}>
                      { props.selectedTab === 'Options' ?
                        <CurrentCustomListItem button key="Options" id='Options' onClick={(e) => openTab(e)}>
                            <ListItemText primary="Options" />
                        </CurrentCustomListItem>
                        :
                        <CustomListItem button key="Options" id='Options' onClick={(e) => openTab(e)}>
                            <ListItemText primary="Options" />
                        </CustomListItem>
                      }
                    </Box>
                ): null
              }
              
            </Box>
    )
}