import {Box, MenuItem, TextField} from '@mui/material'
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import React, { useEffect, useState } from 'react';

import {styled} from "@mui/material/styles";

export const ServiceConfigurationNavBar = (props) => {

    const [selectedService, setSelectedService] = useState(props.selectedService);
    const ductServiceTypeFabIds = [1, 2, 3, 30, 32, 33, 34, 35, 36, 56];

    useEffect(() => {
      setSelectedService(props.selectedService);
    }, [props.selectedService])

    const openTab = (e) => {
        props.setSelectedTab(e.target.outerText)
    }
    const CustomListItem = styled(ListItem)`
    :hover { background: transparent; };
    color: #F15A29 !important;
    text-decoration: none;
    border: 1px solid #FFA988;
    border-color: #FFA988 !important;
    background: #fff;
    text-align: center !important;
    width: 130px !important;
    height: 40px !important;
    `;

    const CurrentCustomListItem = styled(ListItem)`
      :hover { background: #F15A29 !important; };
      color: #fff !important;
      text-decoration: none;
      border: 1px solid #FFA988;
      border-color: #FFA988 !important;
      background: #F15A29 !important;
      text-align: center !important;
      width: 130px !important;
      height: 40px !important;
    `;

    const MenuItem_ = styled(MenuItem)`
      display: flex !important;
      flex-direction: column !important;
    `;


    const StyledTextField = styled(TextField)(({theme}) => ({
      "&.MuiTextField-root": {
        padding: ".25rem !importantƒ",
        height: "55.5px",
        "& label": {
          fontSize: "1rem",
        },
        "& label.Mui-focused": {
          fontSize: "1rem",
        },
        "& .MuiOutlinedInput-root": {
          backgroundColor: 'white',
          borderRadius: 4,
          height: "55.5px",

          fontSize: "1rem",
          "& fieldset": {
            borderWidth: 2,
          },
          "&:hover fieldset": {
            borderWidth: 2,
          },
          "&.Mui-focused fieldset": {
            borderWidth: 2,
          },
        },
      },
    }));

    const styles = {
        box: { paddingTop: "1rem" },
        drawer: {
            drawerHeader: {
              backgroundColor: "#000",
              color: "#fff",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }
          },
        iconButton: { color: "#fff", textAnchor: "right" },
        btn: { paddingLeft: "2rem", paddingRight: "2rem" },
        cancelIcon: { color: "#fff", textAlign: "right" },
        row: {display: "flex", flexDirection: "row"},
        tenrem: { width: "64px", height: "64px"},
    };
    return(
        <Box id="DrawerNavBar"
              sx={[styles.box]}
              display="flex"
              flexDirection="row"
              alignItems="center"
              alignSelf="center"
              mb={2}
            >
              <Box sx={{border: "1px solid #FFA988"}} >
                { !ductServiceTypeFabIds.includes(selectedService?.serviceType) ? (
                  props.selectedTab === 'Pipes' ?
                  <CurrentCustomListItem button key="Pipes" id='Pipes' onClick={(e) => openTab(e)}>
                    <ListItemText primary="Pipes"/>
                  </CurrentCustomListItem>
                  :
                  <CustomListItem button key="Pipes" id='Pipes' onClick={(e) => openTab(e)}>
                      <ListItemText primary="Pipes" />
                  </CustomListItem>
                )
                : props.selectedTab === 'Ducts' ?
                  <CurrentCustomListItem button key="Ducts" id='Ducts' onClick={(e) => openTab(e)}>
                    <ListItemText primary="Ducts"/>
                  </CurrentCustomListItem>
                  :
                  <CustomListItem button key="Ducts" id='Ducts' onClick={(e) => openTab(e)}>
                      <ListItemText primary="Ducts" />
                  </CustomListItem>
                }
              </Box>
              <Box sx={{border: "1px solid #FFA988"}}>
                { props.selectedTab === 'Parts' ?
                  <CurrentCustomListItem button key="Parts" id='Parts' onClick={(e) => openTab(e)}>
                      <ListItemText primary="Parts" />
                  </CurrentCustomListItem>
                  :
                  <CustomListItem button key="Parts" id='Parts' onClick={(e) => openTab(e)}>
                      <ListItemText primary="Parts" />
                  </CustomListItem>
                }
              </Box>
              <Box sx={{border: "1px solid #FFA988"}}>
                { props.selectedTab === 'Connectors' ?
                  <CurrentCustomListItem button key="Connectors" id='Connectors' onClick={(e) => openTab(e)}>
                      <ListItemText primary="Connectors" />
                  </CurrentCustomListItem>
                  :
                  <CustomListItem button key="Connectors" id='Connectors' onClick={(e) => openTab(e)}>
                      <ListItemText primary="Connectors" />
                  </CustomListItem>
                }
              </Box>
              {
                (ductServiceTypeFabIds.includes(selectedService?.serviceType)) ? (
                  <Box sx={{border: "1px solid #FFA988"}}>
                  { props.selectedTab === 'Specs/Liner' ?
                    <CurrentCustomListItem button key="Specs/Liner" id='Specs/Liner' onClick={(e) => openTab(e)}>
                        <ListItemText primary="Specs/Liner" />
                    </CurrentCustomListItem>
                    :
                    <CustomListItem button key="Specs/Liner" id='Specs/Liner' onClick={(e) => openTab(e)}>
                        <ListItemText primary="Specs/Liner" />
                    </CustomListItem>
                  }
                </Box>
                ): null
              }
              <Box sx={{border: "1px solid #FFA988"}}>
                { props.selectedTab === 'Insulation' ?
                  <CurrentCustomListItem button key="Insulation" id='Insulation' onClick={(e) => openTab(e)}>
                      <ListItemText primary="Insulation" />
                  </CurrentCustomListItem>
                  :
                  <CustomListItem button key="Insulation" id='Insulation' onClick={(e) => openTab(e)}>
                      <ListItemText primary="Insulation" />
                  </CustomListItem>
                }
              </Box>
              {
                (ductServiceTypeFabIds.includes(selectedService?.serviceType)) ? (
                  <Box sx={{border: "1px solid #FFA988"}}>
                  { props.selectedTab === 'Materials' ?
                    <CurrentCustomListItem button key="Materials" id='Materials' onClick={(e) => openTab(e)}>
                        <ListItemText primary="Materials" />
                    </CurrentCustomListItem>
                    :
                    <CustomListItem button key="Materials" id='Materials' onClick={(e) => openTab(e)}>
                        <ListItemText primary="Materials" />
                    </CustomListItem>
                  }
                </Box>
                ): null
              }
            </Box>
    )
}