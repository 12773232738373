import {
    Box,
    Checkbox,
    TextField
} from "@mui/material"
import { Lock, LockOpen } from "@mui/icons-material"
import React, {useEffect, useState} from "react";

import {styled} from "@mui/material/styles";

export const ServiceConfigurationPartValueProperty = (props) => {
    const [property, setProperty] = useState(props.property)
    const [propertyValue, setPropertyValue] = useState(props.property.dimensionValue.Value)
    const [isLocked, setIsLocked] = useState(props.property.isLocked);
    const [dimension, setDimension] = useState(props.dimension);
    const [propertyIndex, setPropertyIndex] = useState(props.propertyIndex)


    useEffect(() => {
        setDimension(props.dimension)
    }, [props.dimension])
    
    const checkSendProp = () =>{
        if(isLocked){
            setIsLocked(false);
            property.isLocked = false;
            props.checkProperty(props.propertyIndex, false)
        }else{
            setIsLocked(true);
            property.isLocked = true;
            props.checkProperty(props.propertyIndex, true)
        }
    }
    const handleChange = (event) =>{
        const myValue = event.target.value;
        setPropertyValue(myValue);
        property.dimensionValue.Value = Number(myValue);
        props.handlePropertyChange(props.propertyIndex, property);
    };

    return (
        <Box>
        { dimension === undefined && property !== undefined && propertyValue !== undefined ? (
            <Box
                key={property.fabId} 
                className="card" 
                sx={styles.div[1]}
            >
                <Box sx={styles.div[2]}>
                    <Box sx={styles.div[3]}>
                        <P_>
                            {property?.name}
                        </P_>
                    </Box>

                    <Box sx={styles.div[4]}>
                        <TextField
                            size="small"
                            sx={styles.textField}
                            my-data-index={propertyIndex}
                            value={propertyValue}
                            onChange={handleChange}
                            className="inline"
                            type="text"
                            placeholder={propertyValue.toString()}
                        />
                    </Box>
                    <Box sx={styles.div[5]}>
                        <Checkbox
                            checked={isLocked}
                            onChange={checkSendProp}
                            inputProps={{ 'aria-label': 'controlled'}}
                            checkedIcon={<Lock/>}
                            icon={<LockOpen color="secondary"/>}
                            
                        />
                    </Box>
                </Box>
            </Box>
        ) : null
        }
        {
            dimension !== undefined && property !== undefined && propertyValue !== undefined ? (
                <Box
                    key={property.fabId} 
                    className="card" 
                    sx={styles.div[6]}
                >
                    <Box sx={styles.div[2]}>
                        <Box sx={styles.div[8]}>
                            <P_>
                               {dimension.Prefix} | {property?.name}
                            </P_>
                        </Box>
    
                        <Box sx={styles.div[4]}>
                            <TextField
                                size="small"
                                sx={styles.textField}
                                my-data-index={propertyIndex}
                                value={propertyValue}
                                onChange={handleChange}
                                className="inline"
                                type="text"
                                placeholder={propertyValue.toString()}
                            />
                        </Box>
                        <Box sx={styles.div[9]}>
                            <Checkbox
                                checked={isLocked}
                                onChange={checkSendProp}
                                inputProps={{ 'aria-label': 'controlled'}}
                                checkedIcon={<Lock/>}
                                icon={<LockOpen color="secondary"/>}
                                
                            />
                        </Box>
                    </Box>
                </Box>
            ) : null
        }
        </Box>
    )
}

const styles = {
    div: {
        1: {
            backgroundColor: 'white', 
            width: 290, 
            WebkitBorderRadius: '10px 10px 10px 10px', 
            MozBorderRadius: '10px 10px 10px 10px', 
            borderRadius: '10px 10px 10px 10px',
        },
        2: {display: 'flex', paddingLeft: '10px'},
        3: {display: "inline-block", width: '80px !important'},
        4: {display: "inline-block", width: '148px', paddingTop: '10px'},
        5: {display: "inline-block", marginLeft: '5px !important', paddingRight: '5px', paddingTop: '16px'},
        6: {
            backgroundColor: 'white', 
            width: 290, 
            WebkitBorderRadius: '10px 10px 10px 10px', 
            MozBorderRadius: '10px 10px 10px 10px', 
            borderRadius: '10px 10px 10px 10px',
        },
        7: { display: "inline-block", width: '20px' },
        8: { display: "inline-block", width: '80px !important' },
        9: {display: "inline-block", marginLeft: '10px !important', paddingRight: '5px', paddingTop: '16px'},

    },
    p: {
        whiteSpace: 'nowrap', 
        overflow: 'hidden', 
        textOverflow: 'ellipsis'
    },
    textField: { width: '150px !important', backgroundColor: 'white !important'}
};

const P_ = styled('p')`
    white-space: nowrap !important;
    overflow: hidden !important; 
    text-overflow: ellipsis !important;
    padding-top: 4px;
`;

