import PilotDialog from "./PilotDialog";
import PilotPanel from "./PilotPanel";
import PilotNoteDialog from "./PilotNoteDialog";
import { useReducer, useState } from "react";

/** System for Pilot Program ( free trial )
 * 
 * @param {*} props 
 * @returns 
 */
const PilotSystem = props => {


    const [ isOpen, setIsOpen ] = useState(false);
    const [ hasApplied, setHasApplied] = useState(false);

    const applied = res => {

        switch (res) {

            case 200:
                setIsOpen(false);
                setHasApplied(true);
                break;

            default:
                alert('Error: Make sure all text fields are filled in correctly, then try again.')
                //setIsOpen(false);
                //setHasApplied(true);
        }

    };


    const reducer = (state, action) => {

        switch (action.type) {

            case 'set-email':
                
                return state.set('email', action.value);

            case 'set-phone-number':

                return state.set('phoneNumber', action.value);

            case 'set-company-name':

                return state.set('companyName', action.value);

            case 'set-company-address':

                return state.set('companyAddress', action.value);

            case 'set-company-employee-count':
        
                return state.set('companyEmployeeCount', action.value);

            case 'set-license-count':

                return state.set('licenseCount', action.value);

            case 'set-referral':

                return state.set('referral', action.value);

            default:

                throw new Error(` 
                🚨🚨🚨 Invalid dispatch type. 🚨🚨🚨 \n
                📝 Check your spelling on the dispatch function and reducer hook 🪝
                `
                );

        }
    };


    const obj = {
        companyName: null,
        companyAddress: null,
        companyEmployeeCount: null,
        email: null,
        phoneNumber: null,
        licenseCount: null,
        referral: null
    };

    const [state, dispatch] = useReducer(reducer, new Map(Object.entries(obj)));


    return(
        <>
            <PilotPanel
                setIsOpen={ value => setIsOpen(value) }
            />
            <PilotDialog
                setIsOpen={ value => setIsOpen(value) }
                isOpen={isOpen}
                dispatch={ dispatch }
                state={state}
                applied={applied}
            />
            <PilotNoteDialog
                isOpen={hasApplied}
                setIsOpen={setHasApplied}
            />
        </>
    )
};

export default PilotSystem;