import "./Company.css";

import { MenuItem } from "@material-ui/core";
import { Paper } from "@mui/material";
import React from "react";
import Select from "@mui/material/Select";
import { black } from "color-name";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";

//import {ReactComponent as DropdownIcon} from './assets/IconDropdown.svg'

const CssSelect = styled(Select)(({theme}) => ({
    color: "#000000",
    minWidth: "20em",
    height: "2.5em",
    padding: 10,
    borderRadius: 5,
    background: "#ffffff",
    fontSize: "1rem",
    fontWeight: 700,
    // borderColor: "#F15A29 important!",
    "&.Mui-focused fieldset": {
      outline: "none important!",
      border: "none important!",
    },
    // outline: "#F15A29"
    //outline: "none important!"
  // select:focus: {

  // },
  // selectIcon: {
  //   width: "2em",
  //   height: "2em",
  //   paddingRight: 0,
  //   top: "auto",
  // },
  // fieldset: {
  //   outline: "none important!",
  //   border: "none important!"

  // }

  // paper: {
  //   backgroundColor: theme.palette.background.paper,
  //   border: '2px solid #000',
  //   borderRadius: 5,
  //   boxShadow: theme.shadows[5],
  //   padding: theme.spacing(2, 4, 3),
  //   outline: 0,
  // },
}));

export default function StyledSelect(props) {
  const { selected, onSelect, placeholderText, options, style } = props;

  return (
    <>
        <CssSelect
          //style={style}
          variant="outlined"
          value={selected}
          onChange={onSelect}
          defaultValue={
            placeholderText + <i className="fas fa-chevron-circle-down"></i>
          }
        >
          {/* Here we are utilizing the MenuItem to either display the placeholder text or the option name depending on the stratusStatus */}
          <MenuItem value="none" disabled>
            {" "}
            {placeholderText}{" "}
          </MenuItem>
          {options?.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {" "}
              {option.name}{" "}
            </MenuItem>
          ))}
        </CssSelect>
    </>
  );
}
