import {
    Card,
    Grid,
} from '@mui/material'
import React, {useEffect, useState} from 'react';

import {ServiceConfigurationPartDropdownProperty} from './ServiceConfigurationPartDropdownProperty'
import {ServiceConfigurationPartValueProperty} from './ServiceConfigurationPartValueProperty'

export const ServiceConfigurationPartDimensions = (props) => {
    const [properties, setProperties] = useState(props.properties);
    const [dimensions, setDimensions] = useState(props.dimensions);
    const [selectedPart, setSelectedPart] = useState(props.selectedPart);
    useEffect(() => {
        setProperties(props.properties)
    }, [props.properties])

    useEffect(() => {
        setDimensions(props.dimensions)
    }, [props.dimensions])

    useEffect(() => {
        setSelectedPart(props.selectedPart);
    }, [props.selectedPart])

    const changeCheckedState = (index, value) => {
        selectedPart.properties[index].isLocked = value;
        props.setSelectedPart(selectedPart)
        props.setPropertiesChanged(true)

    }

    const handlePropertyChange = (index, property) => {
        selectedPart.properties[index] = property
        props.setSelectedPart(selectedPart)
        props.setPropertiesChanged(true)
    }

    return (
        <Card variant="outlined" sx={{ alignItems: 'center', marginTop: "1rem", width: 310, border: " 1px solid #F15A29 !important", paddingTop: "1rem", paddingBottom: "1.5rem", marginBottom: "1.5rem" }}>
            <Grid
            container
            direction="column"
            sx={{
                width: 400,
                marginLeft: '1px'
            }}
            >
                {
                    properties !== undefined && Array.isArray(properties) && dimensions === undefined ? (
                            properties.map((property, index) => {
                                if(property.possibleValues.length > 0){
                                    return (
                                        <ServiceConfigurationPartDropdownProperty
                                        key={index} 
                                        propertyIndex={index} 
                                        property={property} 
                                        handlePropertyChange={handlePropertyChange} 
                                        checkProperty={changeCheckedState}
                                        />
                                    )
                                }else{
                                    return (
                                        <ServiceConfigurationPartValueProperty
                                        key={index} 
                                        propertyIndex={index} 
                                        property={property}
                                        handlePropertyChange={handlePropertyChange}
                                        checkProperty={changeCheckedState} 
                                        />
                                    )
                                }
                            }
                        )
                    ) : null
                }
                {
                    properties !== undefined && Array.isArray(properties) && dimensions !== undefined && Array.isArray(dimensions) ? (
                            properties.map((property, index) => {
                                if(property.possibleValues.length > 0){
                                    return (
                                        <ServiceConfigurationPartDropdownProperty
                                        key={index} 
                                        propertyIndex={index} 
                                        property={property}
                                        dimension={dimensions[index]}
                                        handlePropertyChange={handlePropertyChange}
                                        checkProperty={changeCheckedState} 
                                        />
                                    )
                                }else{
                                    return (
                                        <ServiceConfigurationPartValueProperty
                                        key={index} 
                                        propertyIndex={index} 
                                        property={property}
                                        dimension={dimensions[index]}
                                        handlePropertyChange={handlePropertyChange}
                                        checkProperty={changeCheckedState} 
                                        />
                                    )
                                }
                            }
                        )
                    ) : null
                }
            </Grid>
        </Card>
    )
}