import {
  Box,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';
import {
    DataGridPro,
    GridToolbar,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
    LicenseInfo,
    useGridApiRef
} from "@mui/x-data-grid-pro";
import React, { useState } from "react";
import { servicesImportIncrement, servicesImportProgress } from "../../../Context/componentStates";

import Delete from "@mui/icons-material/Delete";
import DeleteForever from "@mui/icons-material/DeleteForever"
import DeleteOutline from "@mui/icons-material/DeleteOutline"
import DoneIcon from '@mui/icons-material/Done';
import LinearProgress from "@mui/material/LinearProgress";
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import SyncIcon from "@mui/icons-material/Sync";
import { openServicesRefreshDialog } from "Context/DrawerStates";
import { useContext } from "react";

const CustomToolbar = props => {


    return (
        <GridToolbarContainer sx={{width: "100%"}}>
          <GridToolbarExport/>
          <GridToolbarColumnsButton/>
          <GridToolbarFilterButton/>
          <GridToolbarDensitySelector/>
        </GridToolbarContainer>
    );
  }

  const styles = {
    syncIcon: {color:"#F15A29" },
  }

  export default CustomToolbar;
