import { entity } from "simpler-state";

const userPermissions_key = "JxXSoSKKP^N9tot9x4qDEGZZ%L2t^vYS";


export const currentUser = entity(null);
export const setCurrentUser = (obj) => {
  currentUser.set(obj);
};
export const getCurrentUser = () => {
  return currentUser.get();
}

const currentUser_viewOnlyProjects = entity([]);
export const set_currentUser_viewOnlyProjects = array => {
  currentUser_viewOnlyProjects.set(array);
};
export const get_currentUser_viewOnlyProjects = () => {
  return currentUser_viewOnlyProjects.get();
};
export const use_currentUser_viewOnlyProjects = () => {
  return currentUser_viewOnlyProjects.use();
}

const userPermissions = entity(null);
const getUserPermissions_keyAuth = key => {
  if( key == userPermissions_key ) {
    return userPermissions.get();
  }
};

export const getUserPermissions = key => {
  return getUserPermissions_keyAuth(key);
};
export const initUserPermissions = (key, value) => {
  if( key == userPermissions_key ) {
    userPermissions.set(value);
  }
};

const attachmentDownloads = entity(true);
export const setAttachmentDownloadSetting = (value) => {
  attachmentDownloads.set(value);
}
export const getAttachmentDownloadSetting = () => {
  return attachmentDownloads.get();
}