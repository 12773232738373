import { ListItem, ListItemText } from "@material-ui/core";

import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import React from 'react'
import { getUserPermissions } from "Context/currentUser";
import { styled } from '@mui/material/styles';
import { useLocation } from "react-router-dom";
import { useState } from "react";

//import {ListItem, ListItemText} from "@mui/material";

export const FOAppBarNavButtons = () => {

  const userPermissions_key = "JxXSoSKKP^N9tot9x4qDEGZZ%L2t^vYS";
  const userPermissions = getUserPermissions(userPermissions_key);
  const [admin, isAdmin] = useState(userPermissions.includes("AdminUsers"));
  const [servicePermissions, isServicePermissions] = useState(userPermissions.includes("ServiceCatalogEditors"));
  const [projectEditor, isProjectEditor] = useState(userPermissions.includes("ProjectEditors"));

  const CustomNavLink = ({ children, to, matchPaths, ...props }) => {
    const location = useLocation();
  
    const isMatch = matchPaths
      ? matchPaths.some((path) => location.pathname.startsWith(path))
      : location.pathname.startsWith(to);
  
    return (
      <StyledLink
        to={to}
        sx={{
          color: "#fff",
          backgroundColor: isMatch ? "#F15A29" : "#000000",
          textDecoration: "none",
          background: isMatch ? "#F15A29" : "#000000",
          height: '64px',

        }}
        {...props}
      >
        {children}
      </StyledLink>
    );
  };

  const StyledLink = styled(Link)`
    &:hover {
      text-decoration: none;
    }
  `;

  const CustomListItem = styled(ListItem)`
    :hover { background: transparent; };
  `;

  return (
      <Box
        sx={styles.box}
        display="flex"
        flexDirection="row"
        alignItems="center"
      >

        { admin ?
          <CustomNavLink to="/company/general" matchPaths={['/company/general', '/company/settings', '/company/divisions']}>
            <CustomListItem button key="company" sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
              <ListItemText primary="Company" />
            </CustomListItem>
          </CustomNavLink>
          : null
        }
        { admin ?
          <>
            <CustomNavLink to="/users">
              <CustomListItem button key="users" sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
                <ListItemText primary="Users" />
              </CustomListItem>
            </CustomNavLink>

          </>
          : null
        }
        { servicePermissions ?
          <>
            <CustomNavLink to="/services">
              <CustomListItem button key="services" sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
                <ListItemText primary="Services" />
              </CustomListItem>
            </CustomNavLink>
          </>
          : null
        }
        { servicePermissions ?
          <>
            <CustomNavLink to="/catalogs">
              <CustomListItem button key="catalogs" sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
                <ListItemText primary="Catalogs" />
              </CustomListItem>
            </CustomNavLink>
          </>
          : null
        }



        {/* ALWAYS AVAILABLE */}

        { projectEditor ?
          <>
            <CustomNavLink to="/projects">
              <CustomListItem button key="projects" sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
                <ListItemText primary="Projects" />
              </CustomListItem>
            </CustomNavLink>
          </>
          : null
        }

        <CustomNavLink to="/packages" matchPaths={['/packages']}>
          <CustomListItem button key="packages" sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
            <ListItemText primary="Packages" />
          </CustomListItem>
        </CustomNavLink>
      </Box>
  );
};

const styles = {
  box: {  marginLeft: "1rem"}
};
