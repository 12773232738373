import { Auth, onAuthUIStateChange } from "aws-amplify";
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { Box } from "@mui/system";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import DesignServicesOutlinedIcon from "@mui/icons-material/DesignServicesOutlined";
import { DrawerHeader } from "../MUIstyled.js";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import PermMediaOutlinedIcon from "@mui/icons-material/PermMediaOutlined";
import React from "react";
import { styled } from '@mui/material/styles';

export const FONavDrawer = (props) => {
  const drawerWidth = 240;

  const handleLogout = () => {
    props.setAccount(null);
    try {
      Auth.signOut({global: false});
    } catch (error) {
      console.log('error signing out: ', error);
    }
    window.location.assign("/");//.reload();
  };

  const MatchedLink = styled(Link)`
    color: #F15A29;
    text-decoration: none;
  `;
  const UnmatchedLink = styled(Link)`
    color: white;
    text-decoration: none;
  `;
  const Spacer = styled(Box)`
    margin: 1rem;
  `;

  const CustomNavLink = ({ children, to, ...props }) => {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });

    if(match) {
      return (
        <div>
          <MatchedLink
            to={to}
            {...props}
          >
            {children}
          </MatchedLink>
        </div>
      );
    }
    else {
      return (
        <div>
          <UnmatchedLink
            to={to}
            {...props}
          >
            {children}
          </UnmatchedLink>
        </div>
      );
    }
  };



  return (
      <Drawer
        color="primary"
        sx={styles.drawer}
        anchor="left"
        open={props.open}
        onClose={(e, reason) => {
          props.handleDrawerClose();
        }}
      >
        <DrawerHeader sx={styles.BGcolor.black}
        >
          <IconButton onClick={props.handleDrawerClose} color="white">
            <ChevronRightIcon />
          </IconButton>
        </DrawerHeader>
        <List sx={styles.list[1]}>

          { props.admin ?
            <>
              <CustomNavLink to="/company">
                <ListItem button onClick={props.handleDrawerClose} key="company">
                  <ListItemIcon sx={styles.color.white}>
                    <BusinessOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Company" />
                </ListItem>
              </CustomNavLink>
            </>
            : null
          }

          {/* <List sx={[styles.list[2]]}> */}

            { props.admin ?
              <>

                <Spacer/>
                <CustomNavLink to="/users">
                  <ListItem button onClick={props.handleDrawersCloseToUsers} key="users">
                    <ListItemIcon sx={styles.color.white}>
                      <GroupOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Users" />
                  </ListItem>
                </CustomNavLink>


                <Spacer/>
                <CustomNavLink to="/divisions">
                  <ListItem
                    button
                    onClick={props.handleDrawersCloseToDivision}
                    key="divisions"
                  >
                    <ListItemIcon sx={styles.color.white}>
                      <GroupsOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Divisions" />
                  </ListItem>
                </CustomNavLink>
              </>
              : null
            }

            { props.servicePermissions ?
              <>
                <Spacer/>
                <CustomNavLink to="/services">
                  <ListItem button onClick={props.handleDrawerClose} key="services">
                    <ListItemIcon sx={styles.color.white}>
                      <DesignServicesOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Services" />
                  </ListItem>
                </CustomNavLink>
              </>
              : null
            }

            { props.servicePermissions ?
              <>
                <Spacer/>
                <CustomNavLink to="/catalogs">
                  <ListItem button onClick={props.handleDrawerClose} key="catalogs">
                    <ListItemIcon sx={styles.color.white}>
                      <LibraryBooksOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Catalogs" />
                  </ListItem>
                </CustomNavLink>
              </>
              : null
            }

            <Spacer/>

              {/* <CustomNavLink to="/configure">
                <ListItem button onClick={props.handleDrawerClose} key="services">
                  <ListItemIcon sx={styles.color.white}>
                    <DesignServicesOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Service Configuration" />
                </ListItem>
              </CustomNavLink> */}
          {/* </List> */}

          { props.projectEditor ?
            <>
              <Spacer/>
              <CustomNavLink to="/projects">
                <ListItem button onClick={props.handleDrawerClose} key="projects">
                  <ListItemIcon sx={styles.color.white}>
                    <PermMediaOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Projects" />
                </ListItem>
              </CustomNavLink>
            </>

            : null
          }

          <Spacer/>
          <CustomNavLink to="/packages">
            <ListItem button onClick={props.handleDrawerClose} key="packages">
              <ListItemIcon sx={styles.color.white}>
                <DescriptionOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Packages" />
            </ListItem>
          </CustomNavLink>

          {
            props.superAdmin &&
            <>
              <Spacer/>
              <CustomNavLink to="/adminPanel">
                <ListItem button onClick={props.handleDrawerClose} key="adminPanel">
                  <ListItemIcon sx={styles.color.white}>
                    <AdminPanelSettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Admin Panel" />
                </ListItem>
              </CustomNavLink>
            </>
          }


        </List>

        <Divider />
        <List sx={styles.list[3]}>
          <ListItem button onClick={handleLogout} key="logout">
            <ListItemIcon sx={styles.color.white}>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              window
                .open("https://www.fieldorderz.com/support", "_blank")
                .focus();
              props.handleDrawerClose();
            }}
            key="help"
          >
            <ListItemIcon sx={styles.color.white}>
              <HelpOutlineOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Help" />
          </ListItem>
        </List>
      </Drawer>
  );
};

const styles = {
  color: {
    white: { color: "#fff"}
  },
  BGcolor: {
    black: {backgroundColor: "#000000"}
  },
  list: {
    1: { backgroundColor: "#000000", flex: 1, paddingLeft: "1rem" },
    2: { marginLeft: "2rem" },
    3: { backgroundColor: "#000000", color: "#fff", paddingLeft: "1rem" }
  },
  drawer: {
    backgroundColor: "primary",
    width: 240,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      width: 240,
      boxSizing: "border-box",
    },
    "& .MuiBackdrop-root": {
      backgroundColor: "transparent"
    }
  }
};
