import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from "@mui/material";
import { Contained_btn, Outlined_btn } from "components/Theme/Styled";
import { assignUserDivision, createDivision } from "../../../data/queries/queryAPI";
import { useMutation, useQueryClient } from "react-query";

import ErrorDialog from "components/Utilities/ErrorDialog";
import React from 'react'
import { useState } from "react";

export const CreateDivisionDialog = (props) => {
  const [newDivisionName, setNewDivisionName] = useState("");
  const [isCreating, setIsCreating] = useState(false);
  const queryClient = useQueryClient();
  const users = queryClient.getQueryData(['allDetailedUsers']);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleCloseDialog = () => {
      setDialogOpen(false);
  };

  const { mutate: createNewDivision } = useMutation(createDivision, {
    onSettled: async (data) => {
      await users.forEach((user) => {
        let payload = {};
        payload['divisionId'] = data.id;
        payload['userId'] = user.id;
        addUserToDivision(payload);
      })
      queryClient.refetchQueries(["allDivisions"]).then(() => {
        setNewDivisionName("");
        setIsCreating(false);
        props.setOpen(false);
      });
    },
    onError: (error) => {
      setIsCreating(false);
      setErrorMessage(error.message || 'An error occurred');
      setDialogOpen(true);
    },
  });

  const { mutate: addUserToDivision } = useMutation(assignUserDivision, {
    onSettled: (data) => {
      if (data.status === 200) {
        queryClient
          .refetchQueries(["allDivisions"])
          .catch((error) => {
            setIsCreating(false);
            setErrorMessage(error.message || 'An error occurred');
            setDialogOpen(true);
          });
      } else {
        setIsCreating(false);
        setErrorMessage('An error occurred');
        setDialogOpen(true);
        }
    },
    onError: (error) => {
      setIsCreating(false);
      setErrorMessage(error.message || 'An error occurred');
      setDialogOpen(true);
    },
  });

  const handleCreateDivision = () => {
    const payload = {};
    payload.name = newDivisionName;
    createNewDivision(payload);
    setIsCreating(true);
  };

  return (
    <>
        <Dialog open={props.open} onClose={(e) => props.setOpen(false)}>
          <Box sx={{backgroundColor: '#F15A29', color: '#fff', height: '560x'}}>
            <DialogTitle>Add Division</DialogTitle>
          </Box>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="New Division Name"
              type="text"
              sx={{width: '300px'}}
              variant="outlined"
              value={newDivisionName}
              onChange={(e) => setNewDivisionName(e.target.value)}
            />
          </DialogContent>
          <Box sx={{paddingLeft: '3.25rem', paddingBottom: '1rem'}}>
            <Outlined_btn 
            sx={{marginRight: '1rem'}}
            onClick={(e) => {
              props.setOpen(false)
              setNewDivisionName(" ")
            }}>
              Cancel
            </Outlined_btn>
            {
              isCreating ?
                <CircularProgress
                  sx={styles.circularProgress}
                  size={24}
                />
                :
                <Contained_btn onClick={handleCreateDivision}>
                  Create Division
                </Contained_btn>
            }

          </Box>
        </Dialog>
        <ErrorDialog open={dialogOpen} handleClose={handleCloseDialog} errorMessage={errorMessage} />
    </>

  );
};

const styles = {
  box: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  circularProgress: { color: "secondary"},
  div: {alignSelf: "center"}
}
