import React, { useEffect, useMemo } from "react";
import { getCatalogs, getServiceGeneratorVersion, getServices } from "data/queries/queryAPI";
import { useQuery, useQueryClient } from "react-query";

import { Box } from "@mui/material";
import { ServicesList } from "./components/ServicesList";

export const Services = (props) => {
  //CLOSE ALL OTHER PAGE'S DRAWERS ON INITIAL LOAD
  const queryClient = useQueryClient();

  useEffect(() => {
    props.setCatalogBrowserDialogOpen(false);
    props.setCatalogDrawerOpen(false);
    props.setProjectServiceDialogOpen(false);
    props.setProjectUserDialogOpen(false);
    props.closeCreateCatalogPartDrawer(false);
    props.closeCatalogImportDialog();
    props.handleCloseOrdersDrawer();
    props.closeCreateUserDrawer();
    props.closeUsersDrawer();
    props.closeProjectsDrawer();
  });

  const generatorVersionQuery = useQuery(['serviceGeneratorVersion'], getServiceGeneratorVersion, {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: "always",
  })

  const servicesQuery = useQuery(["allServices"], getServices, {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: "always",
  });

  const catalogsQuery = useQuery(["allCatalogs"], getCatalogs, {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: "always"
  });

  useEffect(() => {
    if (Array.isArray(servicesQuery?.data?.data)) {
      servicesQuery?.data?.data?.forEach((service) => {
        let service_id = service.id;
        //delete service.id
        queryClient.setQueryData(['serviceData', service_id], service)
      })
    }
  }, [servicesQuery?.data?.data]);

  const services = useMemo(() => {
    
    if(servicesQuery?.isLoading || catalogsQuery?.isLoading) {
      return undefined;
    }else{
      if (Array.isArray(servicesQuery?.data?.data) && Array.isArray(catalogsQuery?.data?.data)) {
        return servicesQuery.data.data.map(service => {
          const isCatalog = catalogsQuery.data.data.some(catalog => catalog.id === service.id);
          return {...service, isCatalog};
        });
      }else{
        return []
      }
    }
    

  }, [servicesQuery?.data?.data, catalogsQuery?.data?.data]);

  const serviceGeneratorVersion = generatorVersionQuery.data || 1.0;

  return (
    <Box
      sx={styles.paper}
    >
      <ServicesList
        companyId={props.companyId}
        services={services}
        serviceGeneratorVersion={serviceGeneratorVersion}
      />
    </Box>
  );
};

const styles = {
  paper: {
    height: "auto",
    width: "100%",
    marginTop: "80px",
    backgroundColor: "#fff",
    flex: 1,
    flexDirection: "column",
    paddingBottom: "20px",
  }
};
