import {
    Box,
    Checkbox,
    TextField,
} from "@mui/material";
import React, {useEffect, useState} from 'react';

import { styled } from "@mui/material/styles";

//import CheckBox from "@material-ui/core/CheckBox";


const PartPropertyValue = (props) =>{
    const [property, setProperty] = useState({fabId: 0, name: "name", dimensionValue: {Value: 0}});
    const [propertyValue, setPropertyValue] = useState(props.property.dimensionValue.Value);
    const [sendProperty, setSendProperty] = useState(!props.property.isLocked);
    const [dimension, setDimension] = useState(undefined)
    useEffect(() => {
        setProperty(props.property);
    }, [props.property])

    useEffect(() => {
        setDimension(props.dimension)
    }, [props.dimension])

    useEffect(() =>{
        setPropertyValue(property.dimensionValue.Value);
    }, [property])
    const handleChange = (event) =>{
        const myValue = event.target.value;
        setPropertyValue(myValue);
        property.dimensionValue.Value = Number(myValue);
        props.handlePropertyChange(props.propertyIndex, property);
    };

    const checkSendProp = () =>{
        if(sendProperty){
            setSendProperty(false);
            props.sendPropertyChange(props.propertyIndex, false)
        }else{
            setSendProperty(true);
            props.sendPropertyChange(props.propertyIndex, true)
        }
    }

    const render = () =>{
        if(dimension === undefined && propertyValue !== undefined){
            return(
                <Box 
                    key={property.fabId} 
                    className="card" 
                    sx={styles.div[1]}
                >
                    <Box sx={styles.div[2]}>
                        
                        <Box sx={styles.div[3]}>
                            <P_>
                                {property.name}
                            </P_>
                        </Box>
                        
                        <Box sx={styles.div[4]}>
                            <TextField 
                                size='small'
                                sx={styles.textField} 
                                my-data-index={props.propertyIndex} 
                                value={propertyValue} 
                                onChange={handleChange} 
                                className="inline" 
                                type='text' 
                                placeholder={property.dimensionValue.Value.toString()}
                            />
                        </Box>
                        <Box sx={styles.div[5]}>
                            <Checkbox  
                                    checked={sendProperty}
                                    onChange={checkSendProp}
                                    inputProps={{ 'aria-label': 'controlled' }}/>
                        </Box>  
                    </Box>
                
                </Box>
            )
        }else if(dimension !== undefined && propertyValue !== undefined){
            return (
                <Box 
                    key={property.fabId} 
                    className="card" 
                    sx={styles.div[6]}
                >
                    <Box sx={styles.div[2]}>
                        {/* <Box sx={styles.div[7]}>
                            <P_>
                                {dimension.Prefix} |
                            </P_>
                        </Box> */}
                        <Box sx={styles.div[8]}>
                            <P_>
                                {property.name}
                            </P_>
                        </Box>
                        <Box sx={styles.div[4]}>
                            <TextField 
                                size='small'
                                sx={styles.textField} 
                                my-data-index={props.propertyIndex} 
                                value={propertyValue} 
                                onChange={handleChange} 
                                className="inline" 
                                type='text' 
                                placeholder={property.dimensionValue.Value.toString()}
                            />
                        </Box>
                        <Box sx={styles.div[5]}>
                            <Checkbox  
                                checked={sendProperty}
                                onChange={checkSendProp}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </Box>  
                    </Box>
            
                </Box> 
            );
        }
        else {
            return <div>ERROR!</div> 
        };
    };
    return(
        render()
    );
};

const styles = {
    div: {
        1: {
            backgroundColor: 'white', 
            width: 290, 
            WebkitBorderRadius: '10px 10px 10px 10px', 
            MozBorderRadius: '10px 10px 10px 10px', 
            borderRadius: '10px 10px 10px 10px',
        },
        2: {display: 'flex', paddingLeft: '10px'},
        3: {display: "inline-block", width: '80px !important'},
        4: {display: "inline-block", width: '148px', paddingTop: '10px'},
        5: {display: "inline-block", marginLeft: '5px !important', paddingRight: '5px', paddingTop: '16px'},
        6: {
            backgroundColor: 'white', 
            width: 290, 
            WebkitBorderRadius: '10px 10px 10px 10px', 
            MozBorderRadius: '10px 10px 10px 10px', 
            borderRadius: '10px 10px 10px 10px',
        },
        7: { display: "inline-block", width: '20px' },
        8: { display: "inline-block", width: '80px !important' },
    },
    p: {
        whiteSpace: 'nowrap', 
        overflow: 'hidden', 
        textOverflow: 'ellipsis'
    },
    textField: { width: '150px !important', backgroundColor: 'white !important'}
};

const P_ = styled('p')`
    white-space: nowrap !important;
    overflow: hidden !important; 
    text-overflow: ellipsis !important;
    padding-top: 4px;
`;

export default PartPropertyValue;