import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Radio,
  RadioGroup
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ShowDeletedSwitch, ViewSwitch } from "components/Packages/components/ViewSwitch";
import {
  convertServiceToCatalog,
  importStratusServicesArray,
  stratusGetFileGroups,
  stratusGetProfilesForFileGroup
} from "data/queries/queryAPI";
import { servicesImportIncrement, servicesImportProgress } from "Context/componentStates";
import { useMutation, useQuery, useQueryClient } from "react-query";

import ErrorDialog from "components/Utilities/ErrorDialog";
import { ImportServiceTable } from "./ImportServiceTable";
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select';
import { Typography } from "@material-ui/core";
import clone from "just-clone"

// import { importStratusService } from "data/queries/queryAPI";
//
export const ImportServicePageOne = (props) => {
  const [selectedFileGroupId, setSelectedFileGroupId] = useState("");
  const [selectedProfile, setSelectedProfile] = useState("Global");
  const [selectedProfileHasSeparateItems, setSelectedProfileHasSeparateItems] = useState(false)
  const [fileGroups, setFileGroups] = useState([]);
  const [profiles, setProfiles] = useState(null);
  const [servicesData, setServicesData] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [value, setValue] = useState("imperial");
  const queryClient = useQueryClient();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const integratedFileGroups = [294, 271, 229, 345, 200, 1117857577]
  const [showIntegrated, setShowIntegrated] = useState(false);
  const [prevShowIntegrated, setPrevShowIntegrated] = useState(showIntegrated);

useEffect(() => {
    setPrevShowIntegrated(showIntegrated);
}, [showIntegrated]);


  const handleCloseDialog = () => {
      setDialogOpen(false);
  };

  const progress = servicesImportProgress.use();
  const increment = servicesImportIncrement.use();

  const [importCatalog, setImportCatalog] = useState(false);
  const [isConverting, setIsConverting] = useState(false);
  let importedServices = [];

  const fileGroupsQuery = useQuery(
    ["companyFileGroups"],
    () => stratusGetFileGroups(),
    { staleTime: Infinity, refetchOnWindowFocus: false, refetchOnMount: 'always', keepPreviousData: false }
  );

  const profilesForFileGroupQuery = useQuery(
    [
      "servicesForFileGroup",
      selectedFileGroupId,
    ],
    () =>
      stratusGetProfilesForFileGroup({
        fileGroupId: selectedFileGroupId,
      }),
    { staleTime: Infinity, refetchOnWindowFocus: false, refetchOnMount: 'always', keepPreviousData: false }
  );


  const { mutate: importArrayFromStratus } = useMutation(
    importStratusServicesArray,
    {
      onSettled: (data) => {
        if (data.every((el) => el === "SUCCESS")) {
          queryClient.refetchQueries(["allServices"]).then( ()=>{
            if(importCatalog){
              let allServices = queryClient.getQueryData(['allServices']);
              let servicesToConvert = []
              if(allServices.data){
                allServices.data.forEach((service) => {
                  importedServices.forEach((imported) => {
                    if(String(service.templateId) === imported.id && String(service.fabId) === imported.fabId && service.fileGroupId === imported.fileGroupId){
                      servicesToConvert.push(service);
                    }
                  })
                })
              
                servicesToConvert.forEach((service, index) => {
                      convertItem(service.id)                    
                  })
                }
              }
              
          })
          
        }
        if (data.some((el) => el === "ERROR")) {
          props.setImporting(false);
          setErrorMessage("There was an error importing a service. Services that were successfully imported will be shown on the screen.");
          setDialogOpen(true);
        }
        if (data.some((el) => el === "TIMEOUT")) {
          props.setImporting(false);
          setErrorMessage("The import timed out. Services that were successfully imported before the timeout will be shown on the screen.");
          setDialogOpen(true);
        }
      },
      onError: (error) => {
        props.setImporting(false);
        setErrorMessage(error.message || 'An error occurred');
        setDialogOpen(true);
      },
    }
  );

  const { mutate: convertItem } = useMutation(convertServiceToCatalog, {
    onSettled: (data) => {
      queryClient.refetchQueries(['allOnlyCatalogs']).then(()=> {
        setIsConverting(false);
      })
    },
    onError: (error) => {
      setIsConverting(false);
      setErrorMessage(error.message || 'An error occurred');
      setDialogOpen(true);
    },
  });

  useEffect(() => {
    if (fileGroupsQuery.data) {
      setFileGroups(fileGroupsQuery.data.data);
    }
  }, [fileGroupsQuery.data]);

  useEffect(() => {
    if (profilesForFileGroupQuery.data){
      setProfiles(profilesForFileGroupQuery.data.Data);
    }
  }, [profilesForFileGroupQuery.data])

  useEffect(() => {
    if (showIntegrated !== prevShowIntegrated) {
        const filteredGroups = fileGroups.filter(group => 
            showIntegrated ? integratedFileGroups.includes(group.FileGroupId) : !integratedFileGroups.includes(group.FileGroupId)
        );
        
        if (filteredGroups.length > 0) {
            setSelectedFileGroupId(filteredGroups[0].FileGroupId);
        }
    }
}, [fileGroups, showIntegrated, integratedFileGroups]);




  const getFabId = (serviceTemplateId) => {
    const serviceToFind = servicesData.find(
      (serviceItem) => serviceItem.ServiceTemplateId === serviceTemplateId
    );
    return serviceToFind.FabId;
  };

  const requestImport = () => {
    const servicesToImport = [];
    selectionModel.forEach((service) => {
      
      const uid = service.split("+")[1];
      const fid = service.split("+")[0];

      const serviceToImport = {};
      serviceToImport.id = uid;
      serviceToImport.fabId = fid;
      serviceToImport.profile = {'Name': selectedProfile, 'HasSeparateItems' : selectedProfileHasSeparateItems};
      serviceToImport.fileGroupId = selectedFileGroupId;
      serviceToImport.serviceColor = "#C47C25";
      serviceToImport.metric = value === "imperial" ? false : true;
      servicesToImport.push(serviceToImport);
    });
    props.setImporting(true);
    props.setOpen(false);
    servicesImportProgress.set(0);
    servicesImportIncrement.set( Math.ceil( 100 / servicesToImport.length ) );
    importedServices = clone(servicesToImport);
    importArrayFromStratus(servicesToImport);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleImportChange = () => {
    if(importCatalog){
      setImportCatalog(false)
    }else{
      setImportCatalog(true)
    }
  }

  const handleProfileSelection = (e) => {

    if(e.target.value === "Global"){
      setSelectedProfile("Global")
      setSelectedProfileHasSeparateItems(false);
    }
    if(Array.isArray(profiles) && profiles.length > 0){
      profiles.forEach((prof) => {
          if(prof.Name === e.target.value){
            setSelectedProfile(prof.Name);
            setSelectedProfileHasSeparateItems(prof.HasSeparateItems);
          }
      })
    }
  }
  return (

      <Box sx={styles.box[1]}>
        <Box
          sx={styles.box[2]}
        >
          <FormControl fullWidth>
            <InputLabel id="assigned-to-label">Select File Group:</InputLabel>
            <br />
            <Select
              labelId="file-group-label"
              id="file-group-label"
              variant='outlined'
              defaultValue="Select File Group"
              label="Select File Group"
              placeholdertext={"Select File Group"}
              value={selectedFileGroupId}
              onChange={(e) => {
                console.log("E Value: ", e.target.value)
                setSelectedFileGroupId(e.target.value)
              }}
              sx={styles.select.sx}
            >
              {(Array.isArray(fileGroups) && fileGroups.length > 0 ) ? fileGroups?.map((group) => {
                if(showIntegrated){
                  if(integratedFileGroups.includes(group.FileGroupId)){
                    return (
                                        <MenuItem key={group.FileGroupId} value={group.FileGroupId}>{group.FileGroupName}</MenuItem>
                                    )
                  }
                }else{
                  if(!integratedFileGroups.includes(group.FileGroupId)){
                    return (
                                        <MenuItem key={group.FileGroupId} value={group.FileGroupId}>{group.FileGroupName}</MenuItem>
                                    )
                  }
                }
                
              }) : <CircularProgress color="secondary" sx={{marginLeft: "8px"}} />}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="assigned-to-label">Select Profile:</InputLabel>
            <br />
            <Select
              ref={React.useRef(React.createRef())}
              labelId="Profile-label"
              id="Profile-label"
              variant='outlined'
              defaultValue="Select Profile"
              label="Select Profile"
              placeholdertext={"Select Profile"}
              value={selectedProfile}
              onChange={(e) => handleProfileSelection(e)}
              sx={styles.select.sx}
            >
              <MenuItem key='Global'value='Global'>Global</MenuItem>
              {(Array.isArray(profiles) && profiles.length > 0 ) ? profiles?.map((group) => {
                return (

                    <MenuItem key={group.Name} value={group.Name}>{group.Name}</MenuItem>
                )
              }) : (Array.isArray(profiles)) ? null :  <CircularProgress color="secondary" sx={{marginLeft: "8px"}}  />}
            </Select>
          </FormControl>
          <Box display='flex' sx={{paddingLeft: "1rem", paddingTop: "1.65rem"}}>
            <Box sx={{paddingLeft: "1rem", paddingTop: ".5rem", width: 60}}>
            <Typography>FO DBs:</Typography>
            </Box>
            <ShowDeletedSwitch
              value={showIntegrated}
              onChange={() => setShowIntegrated(!showIntegrated)}
            />
          </Box>
          <Box sx={styles.box[3]}>
            <Box sx={{paddingTop: "1.35rem"}}>
              <FormControl>
                <RadioGroup
                  aria-label="units"
                  defaultValue="imperial"
                  name="radio-buttons-group"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="imperial"
                    control={<Radio />}
                    label="Imperial"
                  />
                  <FormControlLabel
                    value="metric"
                    control={<Radio />}
                    label="Metric"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
        </Box>
        <Box sx={{ marginTop: "2rem" }}>
          <ImportServiceTable
            selectedFileGroupId={selectedFileGroupId}
            selectedProfile={selectedProfile}
            selectionModel={selectionModel}
            setSelectionModel={setSelectionModel}
            servicesData={servicesData}
            setServicesData={setServicesData}
            ids={props.ids}
          />
        </Box>
        <Box sx={styles.box[4]}>
          <Button
            sx={styles.style}
            type="submit"
            disabled={selectionModel.length === 0}
            onClick={requestImport}
            variant="contained"
            key="nav-next"
          >
            Import Selected
          </Button>
          <Box sx={{marginLeft: "2rem", marginTop: ".5rem"}}>
          <FormGroup>
            <FormControlLabel control={<Checkbox checked={importCatalog}
                                                  onChange={handleImportChange}
                                                   />
                                      }
                              label="Import as Catalog" />
          </FormGroup>
          </Box>
        </Box>
        <ErrorDialog open={dialogOpen} handleClose={handleCloseDialog} errorMessage={errorMessage} />

      </Box>
  );
};

const styles = {
  style: {
    "&.MuiButton-root": {
      backgroundColor:"#F15A29"
    },
    "&.MuiButton-text": {
      color: "white"
    },
    "&.Mui-disabled": {
      color: "gray",
      backgroundColor:"light-gray"
    },
    '&.MuiButton-root:hover':{
      backgroundColor: "orange"
    }
  },
  box: {
    1: { paddingLeft: "2rem", paddingRight: "2rem"},
    2: { display: "flex", flexDirection: "row"},
    3: { marginLeft: "2rem" },
    4: { marginTop: "2rem", marginBottom: "2rem", display: "flex"}
  },
  select: {
    style: { paddingLeft: "1rem",borderRadius: '5px'},
    sx: {height: "50px", paddingLeft: "1rem",borderRadius: '5px'}
  }
  

};
