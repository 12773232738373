import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  LinearProgress,
  Snackbar,
} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import { getCatalogs, getCatalogsWithItems } from "../../../data/queries/queryAPI";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box } from "@mui/material";
import { CatalogImportDialog } from "./CatalogImportDialog";
import { CatalogsTable } from "./CatalogsTable";
import Divider from "@mui/material/Divider";
import { RenameCatalogForm } from "./RenameCatalogForm";
import Typography from "@material-ui/core/Typography";
import { openCatalogImportDialog } from "Context/DrawerStates";
import { useQuery } from "react-query";

export const CatalogsList = (props) => {
  const [catalogs, setCatalogs] = useState(props.catalogs);
  const [catalogId, setCatalogId] = useState('None');
  const [renameFormOpen, setRenameFormOpen] = useState(false);
  const [isConfiguring, setIsConfiguring] = useState(false);
  const [importing, setImporting] = useState(false);
  const [selectedCatalog, setSelectedCatalog] = useState(undefined);

  useEffect(() => {
    setCatalogs(props.catalogs)
  }, [props.catalogs])

  const handleImportCatalog = (e) => {
    openCatalogImportDialog();
  };


  const styles = {
    color: {
      gray: { color: "gray" },
      orange: {color: "#F15A29"},
      white: {color: "#fff"}
    },
    box: {
      1: {
        paddingTop: "1rem",
        paddingLeft: "2rem",
        display: "flex",
        //flexDirection: "row",
      },
      2: {
        display: "flex",
        flexDirection: "row",
        marginLeft: "2rem",
        alignItems: "center",
      },
      3: { display: "flex", flexDirection: "col" },
      table: {paddingLeft: "25px", paddingRight: "30px", paddingTop: "20px"}
    },
    addCircleIcon: {
      marginTop: "auto",
      marginBottom: "auto",
      marginLeft: "1rem",
    },
    circularProgress: { marginTop: ".6rem", marginRight: ".25rem" },
    typography: {
      1: { marginRight: "1rem !important", paddingRight: "1rem !important"},
      2: { marginLeft: "2rem" },
    },
    divider: { marginTop: "1rem", marginBottom: "1rem" },
  };
  
  return (
      <>
            <Grid direction="row" container paddingTop="1rem" paddingLeft="2rem">
            { !isConfiguring ? <Typography sx={styles.typography[1]} variant="h5" > Catalogs </Typography> : null}
              { !importing && !isConfiguring ?
                (
                  <AddCircleIcon
                    onClick={(e) => handleImportCatalog()}
                    sx={styles.addCircleIcon}
                    color="secondary"
                  />
                )
                : null
              }
          </Grid>
            {catalogs === undefined ? (
              <Box sx={styles.color.gray} open={true}>
                <Box sx={styles.box[3]}>
                  <Typography variant="h3">Building Catalogs Table...</Typography>
                  <CircularProgress color="inherit" />
                </Box>
              </Box>
            ): catalogs === [] ?
            (
              <>
                <Divider sx={styles.divider} />
                <Box sx={styles.color.gray} open={true}>
                  <Box sx={styles.box[3]}>
                    <Typography variant="h3">No Catalogs</Typography>
                  </Box>
                </Box>
              </>
            )
  
          :
  
          (Array.isArray(catalogs) && catalogs?.length > 0) ? (
  
            <Box sx={styles.box.table}>
              <CatalogsTable
                dataForDisplay={catalogs}
                companyId={props.companyId}
                importing={importing}
                selectedCatalog={selectedCatalog}
                setSelectedCatalog={setSelectedCatalog}
                setImporting={setImporting}
                isConfiguring={isConfiguring}
                setIsConfiguring={setIsConfiguring}
              />
            </Box>
          )
  
          : (catalogs?.length === 0) && catalogs === [] ? (
            <>
                <Divider sx={styles.divider} />
                <Box sx={styles.color.gray} open={true}>
                  <Box sx={styles.box[3]}>
                    <Typography variant="h3">No Catalogs</Typography>
                  </Box>
                </Box>
              </>
          )
          : null
        }
        <CatalogImportDialog
        
        />
        <RenameCatalogForm
          renameFormOpen={renameFormOpen}
          setRenameFormOpen={setRenameFormOpen}
          catalogId={catalogId}
        />
      </>
    );
};
