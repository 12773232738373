import {
    Box,
    Card,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    TextField,
} from '@mui/material'
import React, { useEffect, useState } from 'react';

import {styled} from "@mui/material/styles";

export const ServiceConfigurationPartMain = (props) => {
    const [selectedPart, setSelectedPart] = useState(props.selectedPart);
    const [selectedService, setSelectedService] = useState(props.selectedService);
    const [itemName, setItemName] = useState(props.selectedPart?.description);
    const itemStatuses = ["Auto Fill", "Excluded", "Supported", "Unsupported"]
    const decodeStatus = (selectedPart) => {
        if(selectedPart !== undefined){
            if(selectedPart.supported && selectedPart.isExcluded){
                return (
                    "Excluded"
                )
              }else if(selectedPart.supported && !selectedPart.isExcluded){
                  if(selectedPart.auto){
                      return (
                          "Auto Fill"
                      )
                  }else{
                      return(
                          "Supported"
                      )
                  }
              }else if(!selectedPart.supported){
                  return(
                      "Unsupported"
                  )
              }
        }
    }
    
    const [selectedPartStatus, setSelectedPartStatus] = useState(decodeStatus(props.selectedPart));
    const updateItemStatus = (event) => {
        props.setPropertiesChanged(true);
        if(event.target.value === "Excluded"){
          selectedPart.supported = true
          selectedPart.isExcluded = true;
          props.setSelectedPart(selectedPart)
        }else if(event.target.value === "Auto Fill"){
          selectedPart.auto = true;
          selectedPart.supported = true;
          selectedPart.isExcluded = false;
          props.setSelectedPart(selectedPart);
        }else if (event.target.value === "Supported"){
          selectedPart.auto = false;
          selectedPart.supported = true;
          selectedPart.isExcluded = false;
          props.setSelectedPart(selectedPart);
        }else if (event.target.value === "Unsupported"){
          selectedPart.auto = false;
          selectedPart.supported = false;
          selectedPart.isExcluded = true;
          props.setSelectedPart(selectedPart);
        }
        setSelectedPartStatus(event.target.value);
    }
    const decodeConditionMax = () => {
        if(selectedPart?.conditionId !== undefined){
            if(selectedService?.conditions){
                let condition = selectedService?.conditions?.find(i => i.conditionId === selectedPart.conditionId);
                if(condition?.max_val){
                    if(condition.max_val === -1){
                        return(
                          `None`
                        )
                    }else{
                        return(
                          `${condition.max_val}`
                        )
                    }
                }   
            } 
        }
      }
      const decodeConditionMin = () => {
        if(selectedPart?.conditionId !== undefined){
            if(selectedService?.conditions){
                let condition = selectedService?.conditions?.find(i => i.conditionId === selectedPart.conditionId);
                if(condition?.min_val){
                    if(condition.min_val === -1){
                      return(
                        `None`
                      )
                    }else{
                        return(
                          `${condition.min_val}`
                        )
                    }
                }
            }
        }
      }
    
      




    return (
        < Card
          variant="outlined"
          raised={false}
          sx={{ alignItems: 'center', marginTop: "1rem", width: 310, border: " 1px solid #F15A29 !important", paddingTop: "1rem", marginBottom: "1rem" }}
        >
          <Box sx={{marginLeft: ".5rem", marginRight: ".5rem"}}>
            <StyledTextField
              fullwidth
              disabled
              id="name"
              label="Name"
              variant="outlined"
              defaultValue={itemName}
              //onChange={handleNameChange}
            />
          </Box>
          <Box sx={{marginTop: "1rem", display: "flex", marginLeft: ".5rem", marginRight: ".5rem"}}>
            <Box sx={{width: "112.5px"}}>
              <StyledTextField
                fullwidth
                disabled
                id="constraint-min"
                label="Min"
                variant="outlined"
                defaultValue={decodeConditionMin()}
              />
            </Box>
            <Box sx={{width: "112.5px", marginLeft: ".5rem", marginRight: ".5rem"}}>
              <StyledTextField
                fullwidth
                disabled
                id="constraint-max"
                label="Max"
                variant="outlined"
                defaultValue={decodeConditionMax()}
              />
            </Box>
          </Box>
          <Box sx={{marginTop: "1rem", marginBottom: ".75rem", marginLeft: ".5rem", marginRight: ".5rem"}}>
            <FormControl sx={{ minWidth: "225px" }}>
              <InputLabel id="status-label">Status</InputLabel>
                <Select
                  sx={{width: "225px", height: "55px"}}
                  labelId="status-label"
                  id="status"
                  label="Status"
                  value={selectedPartStatus}
                  onChange={updateItemStatus}
                >
                  {itemStatuses.map((status) => (
                    <MenuItem_ key={status} value={status}>
                      <Box sx={styles.row}>
                        <ListItemText primary={status} />
                      </Box>

                    </MenuItem_>
                  ))}
                </Select>
              </FormControl>
          </Box>
        </Card>
    )
}

const StyledTextField = styled(TextField)(({theme}) => ({
    "&.MuiTextField-root": {
      padding: ".25rem !importantƒ",
      height: "55.5px",
      "& label": {
        fontSize: "1rem",
      },
      "& label.Mui-focused": {
        fontSize: "1rem",
      },
      "& .MuiOutlinedInput-root": {
        backgroundColor: 'white',
        borderRadius: 4,
        height: "55.5px",
  
        fontSize: "1rem",
        "& fieldset": {
          borderWidth: 2,
        },
        "&:hover fieldset": {
          borderWidth: 2,
        },
        "&.Mui-focused fieldset": {
          borderWidth: 2,
        },
      },
    },
   }));

   const MenuItem_ = styled(MenuItem)`
   display: flex !important;
   flex-direction: column !important;
 `;

 const styles = {
    box: { paddingTop: "1rem" },
    drawer: {
        drawerHeader: {
          backgroundColor: "#000",
          color: "#fff",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }
      },
    iconButton: { color: "#fff", textAnchor: "right" },
    btn: { paddingLeft: "2rem", paddingRight: "2rem" },
    cancelIcon: { color: "#fff", textAlign: "right" },
    row: {display: "flex", flexDirection: "row"},
    tenrem: { width: "64px", height: "64px"},
};
