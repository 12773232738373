import Box from "@mui/material/Box";
import CancelIcon from "@mui/icons-material/Cancel";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { ImportServicePageOne } from "./ImportServicePageOne";
import React from "react";

export const ImportServiceDialog = (props) => {
  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={props.open}
      onClose={(e) => {
        props.setOpen(!props.open);
      }}
    >
      <Box sx={styles.display}>
        <DialogTitle>Service Importer</DialogTitle>
        <IconButton
            sx={styles.iconButton}
            onClick={()=>{props.setOpen(!props.open);}}
        >
            <CancelIcon sx={styles.cancelIcon} />
          </IconButton>
          
      </Box>
      <ImportServicePageOne
        setImporting={props.setImporting}
        setOpen={props.setOpen}
        ids={props.ids}
      />
    </Dialog>
  );
};

const styles = {
  display: {display: "flex"},
  iconButton: { color: "#000000", textAnchor: "right", },
  cancelIcon: { color: "#000000", textAlign: "right" }
};