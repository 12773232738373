import React, { useState } from "react";
import {
  openServicesDrawer,
  setItemsGroupParentService,
  setServiceGroupToEdit,
} from "Context/DrawerStates";

import { Box } from "@mui/system";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Collapse from "@mui/material/Collapse";
import { EditItemsGroupDialog } from "./EditItemsGroupDialog";
import ExpandMore from "@mui/icons-material/ExpandMore";
import HelpIcon from "@mui/icons-material/Help";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Typography } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { styled } from "@mui/material/styles"

export const ItemsGroup = (props) => {
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [groupToEdit, setGroupToEdit] = useState({});

  const handleClick = () => {
    setOpen(!open);
  };

  const handleEditItemsGroup = (e) => {
    e.stopPropagation();
    setItemsGroupParentService(props.serviceId);
    setServiceGroupToEdit(props.node);
    openServicesDrawer();
  };

  const ListItemButton_ = styled(ListItemButton)`
  justify-content: left !important;
  width: 100%;
  `;

  return (
    <div>
      <ListItemButton_ onClick={handleClick} //style={styles.listItemButton}
      >
        {open ? <ExpandMore /> : <ChevronRightIcon/>}
        <Box
          sx={styles.box[1]}
        >

          <Box sx={styles.box[2]}>
              <Box
                sx={styles.box[3]}
                
              >
                {props?.node?.items?.some((item) => item.supported === false) ? (
                  <WarningAmberIcon
                    color="secondary"
                    sx={styles.warningAmberIcon}
                  />
                ) : null}
                <Typography sx={styles.typography}>
                  {props?.node?.name} ({props?.node?.items?.length})
                </Typography>
                <MoreHorizIcon
                  color="secondary"
                  onClick={(e) => handleEditItemsGroup(e)}
                  sx={styles.marginLeft}
                />
              </Box>
          </Box>
          <Box sx={styles.box[4]}>
            <ListItemText
              primary={
                props?.node?.menuCategoryOverride?.length > 0
                  ? props?.node?.menuCategoryOverride
                  : props?.node?.menuCategory
              }
            />
          </Box>
          <Box sx={styles.box[5]}>
            {props.node.menuIcon.length > 50 ? (
              <IMG_
                src={
                  props?.node?.menuIconOverride?.length > 0
                    ? props?.node?.menuIconOverride
                    : props?.node?.menuIcon
                }
                alt={`${props.node.name}`}
              />
            ) : (
              <HelpIcon />
            )}
          </Box>
        </Box>
      </ListItemButton_>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Box
            sx={styles.box[6]}
          >
            <Box>
              <Typography variant="button" color="secondary">
                Item
              </Typography>
            </Box>
            <Box>
              <Typography variant="button" color="secondary">
                Connector
              </Typography>
            </Box>
          </Box>
          {props?.node?.children?.map((node) => {
            const nodeCondition = props?.conditions?.find((condition) => {
              let name = "";
              if (condition.conditionId === node.conditionId) {
                name = condition?.name;
              }
              return name;
            });
            return (
              <Box key={node.id} sx={styles.paddingLeft}>
                <ListItem key={node?.id}>
                    <Box
                      sx={styles.box[7]}
                    >
                      {node?.supported ? null : (
                        <WarningAmberIcon color="primary" />
                      )}
                      <Box sx={styles.width[75]}>
                        <ListItemText primary={node?.name} />
                      </Box>
                      <Box sx={styles.width[25]}>
                        <ListItemText
                          primary={nodeCondition.name.split(":")[0]}
                        />
                      </Box>
                    </Box>
                </ListItem>
              </Box>
            );
          })}
        </List>
      </Collapse>
      <EditItemsGroupDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        groupToEdit={groupToEdit}
      />
    </div>
  );
};

const styles = {
  listItemButton: {justifyContent: 'left', width: '100%'},
  box: {
    1: {
      paddingLeft: "2rem",
      width: "77%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    2: { display: "flex", flex: 1 },
    3: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    4: { display: "flex", flex: 2, textAlign: "center" },
    5: { display: "flex", flex: 1, justifyContent: "center" },
    6: {
      paddingLeft: "5rem",
      width: "50%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    7: {
      paddingLeft: "2rem",
      width: "60%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    }
  },
  warningAmberIcon: { marginRight: ".5rem" },
  typography: {width: 150},
  color: {
    flame: {color: "#F15A29"}
  },
  marginLeft: { marginLeft: "1rem"},
  img: { width: "40px", height: "40px" },
  paddingLeft: { paddingLeft: "2rem" },
  width: {
    25: { width: "25%" },
    75: { width: "75%" }
  }
};


const IMG_ = styled('img')`
  width: 40px; 
  height: 40px;
`;