import {ThemeProvider} from "@mui/material/styles";

import { withStyles } from '@mui/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
//import { styled } from '@mui/material/styles';
import styled from "styled-components";
//import {theme} from "components/Theme/Theme.js";

//import { colors } from './styles';

const styledTooltip = ({ className, ...props }) => (
  <Tooltip {...props} classes={{ tooltip: className }} />
);

export const InfoTooltip = styled(styledTooltip)(({ theme }) => ({
  backgroundColor: '#adafbc',
  color: 'rgba(0, 0, 0, 0.87)',
  display: 'block',
  //boxShadow: theme.shadows[1],
  fontSize: '1rem',
}));

export const ErrorTooltip = styled(styledTooltip)(({ theme }) => ({
  backgroundColor: 'rgba(240, 52, 52, 1)',
  color: 'rgba(0, 0, 0, 0.87)',
  display: 'block',
  //boxShadow: theme.shadows[1],
  fontSize: '1rem',
}));

export const SuccessTooltip = styled(styledTooltip)(({ theme }) => ({
  backgroundColor: 'forestgreen',
  color: 'rgba(255, 255, 255, 0.87)',
  //boxShadow: theme.shadows[1],
  fontSize: '1rem',
}));

