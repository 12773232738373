import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  assignUserProject,
  assignViewOnlyUser,
  getUsers,
  getUsersWithCognitoData,
  unassignUserProject,
  unassignViewOnlyUser,
} from "../../../data/queries/queryAPI";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { Error } from "@mui/icons-material";
import ErrorDialog from "components/Utilities/ErrorDialog";
import { Outlined_btn } from "components/Theme/Styled";
import {styled} from "@mui/material/styles";

export const ProjectUserDialog = (props) => {
  const [allUsers, setAllUsers] = useState([]);
  const [eligibleUsers, setEligibleUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const queryClient = useQueryClient();
  const project = props.project;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const usersQuery = useQuery(["allDetailedUsers"], () => getUsersWithCognitoData(), {//getUsers(), {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  useEffect( async () => {
    if (usersQuery.data) {
      const alphabetized = usersQuery.data.sort((a, b) => {
        if (a.name.toUpperCase() === b.name.toUpperCase()) {
          return 0;
        }
        return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1;
      });
      setAllUsers(alphabetized);
    }
  }, [usersQuery.data]);

  useEffect( () => {

    if( props.projectViewOnlyUser ) {

      const inProjectDivision = (divisionId) =>
        divisionId === props.project.divisionId;
      const collector = allUsers // eslint-disable-next-line array-callback-return
        .map((user) => {
          if (user?.divisions && user?.divisions.some(inProjectDivision) && user?.enabled ) {
            return user;
          }
        })
        .filter((user) => user !== undefined && !user.projects.includes(project.id) );
      setEligibleUsers(collector);
    } else {
      const inProjectDivision = (divisionId) =>
        divisionId === props.project.divisionId;
      const collector = allUsers // eslint-disable-next-line array-callback-return
        .map((user) => {
          if (user?.divisions && user?.divisions.some(inProjectDivision) && user?.enabled ) {
            return user;
          }
        })
        .filter((user) => user !== undefined );
      setEligibleUsers(collector);
    }

  }, [allUsers, props.project?.divisionId, props.projectViewOnlyUser]);

  useEffect(() => {

    if( props.projectViewOnlyUser ) {

      const collector = props.project?.viewOnly?.map( user => {
        // eslint-disable-next-line array-callback-return
        return allUsers.find( userObj => {
          if (userObj.id === user) {
            return userObj;
          }
        });
      });
      setSelectedUsers(collector);

    } else {

      const collector = props.project?.users?.map((user) => {
        // eslint-disable-next-line array-callback-return
        return allUsers.find((userObj) => {
          if (userObj.id === user) {
            return userObj;
          }
        });
      });
      setSelectedUsers(collector);
    }

  }, [allUsers, props.project?.users, props.project?.viewOnly]);

  const { mutate: addUserToProject } = useMutation(assignUserProject, {
    onSettled: (data) => {
      if (data.status === 200) {
        queryClient
          .refetchQueries(["allProjects"])
          .then(() => {
            setIsUpdating(false);
          })
          .catch((err) => console.error(err));
      }
    },
    onError: (error) => {
      console.error("error mutating data:", error);
      alert("There was an error: ", error);
    },
  });

  const { mutate: addViewOnlyUserToProject } = useMutation(assignViewOnlyUser, {
    onSettled: (data) => {
      if (data.status === 200) {
        queryClient
          .refetchQueries(["allProjects"])
          .then(() => {
            setIsUpdating(false);
          })
          .catch((error) => {
            setIsUpdating(false);
            setErrorMessage(error.message || 'An error occurred');
            setDialogOpen(true);
        });
      }
    },
    onError: (error) => {
      setIsUpdating(false);
      setErrorMessage(error.message || 'An error occurred');
      setDialogOpen(true);
    },
  });

  const { mutate: removeUserFromProject } = useMutation(unassignUserProject, {
    onSettled: (data) => {
      if (data.status === 200) {
        queryClient
          .refetchQueries(["allProjects"])
          .then(() => {
            setIsUpdating(false);
          })
          .catch((error) => {
            setIsUpdating(false);
            setErrorMessage(error.message || 'An error occurred');
            setDialogOpen(true);
          });
      }
    },
    onError: (error) => {
      setIsUpdating(false);
      setErrorMessage(error.message || 'An error occurred');
      setDialogOpen(true);
    },
  });

  const { mutate: removeViewOnlyUserFromProject } = useMutation(unassignViewOnlyUser, {
    onSettled: (data) => {
      if (data.status === 200) {
        queryClient
          .refetchQueries(["allProjects"])
          .then(() => {
            setIsUpdating(false);
          })
          .catch((error) => {
            setIsUpdating(false);
            setErrorMessage(error.message || 'An error occurred');
            setDialogOpen(true);
          });
      }
    },
    onError: (error) => {
      setIsUpdating(false);
      setErrorMessage(error.message || 'An error occurred');
      setDialogOpen(true);
    },
  });

  const handleClose = () => {
    props.setProjectUserDialogOpen(false)
    props.setProjectViewOnlyUser(false);
  }

  const handleChange = (event) => {
    queryClient.invalidateQueries(eligibleUsers);
    // VALUE IS AN ARRAY OF ALL CURRENTLY SELECTED NAMES
    const {
      target: { value },
    } = event;

    // COLLECTOR IS AN ARRAY OF THE FULL USER OBJECTS THAT ARE CURRENTLY SELECTED
    const collector = value.map((name) => {
      return usersQuery.data.find((userObj) => {
        return userObj.name === name;
      });
    });

    //IF THE NEW ARRAY IS LONGER WE NEED TO ADD A USER
    if (collector.length > selectedUsers.length) {
      //FIND THE ADDED USER
      const userToAdd = collector.filter(
        (user) => !selectedUsers.includes(user)
      );

      // RUN THE MUTATION
      setIsUpdating(true);
      const payload = {};
      payload.projectId = project.id;
      payload.userId = userToAdd[0].id;
      // CHECK IF IN VIEW ONLY MODE
      if( props.projectViewOnlyUser ) {
        addViewOnlyUserToProject(payload);
      } else addUserToProject(payload);
      

      // UPDATE LOCAL STATE
      setSelectedUsers(collector);
    }

    //IF THE NEW ARRAY IS SHORTER WE NEED TO REMOVE A USER
    if (value.length < selectedUsers.length) {
      //FIND THE REMOVED USER
      const userToRemove = selectedUsers.filter(
        (user) => !collector.includes(user)
      );

      // RUN THE MUTATION
      setIsUpdating(true);
      const payload = {};
      payload.projectId = project.id;
      payload.userId = userToRemove[0].id;

      if( props.projectViewOnlyUser ) {
        removeViewOnlyUserFromProject(payload);
      } else removeUserFromProject(payload);
      
      // UPDATE LOCAL STATE
      setSelectedUsers(collector);
    }
  };

  const Box1_ = styled(Box)`
  margin-right: auto;
  `;

  const Header = () => {

    return (
      
      <Box sx={styles.box[1]}>
        { props.projectViewOnlyUser ?
            <Typography variant="h6" color="#fff">
              Add View Only User(s)
            </Typography>
            :
            <Typography variant="h6" color="#fff">
              Add User(s)
            </Typography>
        }
      </Box>

    );
  };

  return (
    <>
        <Dialog open={props.projectUserDialogOpen}>
          <Box>
            {/* DIALOG HEADER */}

            <Header/>

            {/* DIALOG BODY */}
            <Box sx={styles.box[2]}/>
            <div>
              <FormControl sx={styles.formControl}>
                <InputLabel id="multiple-checkbox-label">
                  Assign Users
                </InputLabel>
                <Select
                  onOpen={() => {
                    setTimeout(() => {
                      const menuElement = document.querySelector('.MuiMenu-paper');
                      if (menuElement) {
                        menuElement.scrollTop = 0;
                      }
                    }, 0);
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 45 * 4.5,
                        width: 250,
                      },
                    },
                    getContentAnchorEl: null,
                  }}
                  disabled={isUpdating}
                  labelId="multiple-checkbox-label"
                  id="multiple-checkbox"
                  multiple
                  value={selectedUsers?.map((user) => user?.name)}
                  onChange={handleChange}
                  input={<OutlinedInput label="Assign Users" />}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {eligibleUsers.map((user) => (
                    <MenuItem_ key={user.id} value={user.name}>
                      <Box1_ sx={styles.row}>
                        <Checkbox checked={selectedUsers.indexOf(user) > -1} />
                        <ListItemText primary={user.name} />
                      </Box1_>

                    </MenuItem_>
                  ))}
                </Select>
              </FormControl>
            </div>
            <Box
              sx={styles.box[3]}
            >
              <Outlined_btn
                onClick={ handleClose }
              >
                Close
              </Outlined_btn>
              {isUpdating ? (
                <Box sx={styles.box[2]}>
                  <Typography
                    sx={styles.typography}
                    variant="button"
                    color="primary"
                  >
                    Updating...
                  </Typography>
                  <CircularProgress size={18} />
                </Box>
              ) : null}
            </Box>
          </Box>
        </Dialog>
        <ErrorDialog open={dialogOpen} handleClose={handleCloseDialog} errorMessage={errorMessage} />
    </>
  );
};

const styles = {
  box: {
    1: { backgroundColor: "#F15A29", padding: "1rem" },
    2: { display: "flex", flexDirection: "row" },
    3: { display: "flex", flexDirection: "row", padding: "1rem" }
  },
  formControl: { m: 1, width: 300 },
  typography: { marginLeft: "1rem" },
  column: {display: "flex", flexDirection: "column"},
  row: {display: "flex", flexDirection: "row"}
};

const MenuItem_ = styled(MenuItem)`
  display: flex !important;
  flex-direction: column !important;
`;
