import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@mui/material";
import { Contained_btn, Outlined_btn, Text_btn } from "components/Theme/Styled";
import {
  closeDivisionHardDeleteDialog,
  divisionHardDeleteDialogOpen
} from "../../../Context/DrawerStates";
import { useMutation, useQueryClient } from "react-query";

import React from 'react'
import { hardDeleteDivision } from "data/queries/queryAPI";
import { styled } from "@mui/material/styles";
import { useState } from "react";

// TODO :: CREATE DRAWERSTATES FOR HARD DELETE OF APPLICABLE PAGE



export const HardDeleteDialog = (props) => {

  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { mutate: deleteItem } = useMutation(hardDeleteDivision, {
    onSettled: (data) => {
      queryClient
        .refetchQueries(["allDivisions"])
        .then(() => {
          setIsDeleting(false);
        })
        .then(() => {
          handleClose();
        })
        .catch((error) => {
          console.error("error deleting division", error);
        });
    },
    onError: (error) => {
      console.error("error deleting division", error);
      alert("An error occurred while deleting a division: ", error);
    },
  });



  const handleClose = (e) => {
    //TODO:: CHANGE TO APPLICABLE DRAWER STATE
    closeDivisionHardDeleteDialog();
    setOpen(false);
  };

  const handleDelete = () => {
    //TODO:: CHANGE TO APPROPRIATE PROP.ID
    setIsDeleting(true);
    deleteItem(props.division.id);
  };

  return (
    <>
        {/* CHANGE DIALOG OPEN PROP*/}
        <Dialog open={divisionHardDeleteDialogOpen.use()} onClose={handleClose}>
          <Box sx={{backgroundColor: '#F15A19', color: '#fff', display: 'flex', justifyContent: 'left', alignItems: 'center', height: '60px'}}>
            <Box sx={{marginLeft: '2rem'}}>
              <Typography variant="h5" >Delete Division</Typography>
            </Box>
          </Box>
          <DialogContent>
            <Typography_>Are you sure you want to permanently delete this Division?</Typography_>
            <br/>
            <Typography_>This will also remove all Projects and Packages associated with the Division.</Typography_>
          </DialogContent>
          <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '1rem'}}>
            <Box sx={{marginRight: '1rem'}}>
              <Outlined_btn
                onClick={(e) => handleClose(e)}
              >
                Cancel
              </Outlined_btn>
            </Box>
            <Box sx={{marginLeft: '1rem'}}>

            <Contained_btn
              onClick={handleDelete}
            >
              Delete
              {isDeleting ? <Progress_ size={12} color='white'/> : null}
            </Contained_btn>
            </Box>

          </Box>
        </Dialog>
    </>
  );
};

const Progress_ = styled(CircularProgress)`
  margin-left: 6px;
`;
const Content_ = styled('p')`
  font-size: 18px;
`;

const styles = {
  card: {
    width: 310,
    minHeight: 260,
    maxHeight: 500,
    maxWidth: 310,
    overflowX: "hidden",
    overflowY: "scroll",
    border: "1px solid #F15A29 !important"
  },
  typography: { width: '75%', flexShrink: 0 },
};

const Typography_ = styled(Typography)`
  //width: 75% !important;
  flex-shrink: 0 !important;
`;
