import { Box, Button, FormControl, FormControlLabel, FormGroup, FormLabel, Menu, Switch, TextField } from '@mui/material';
import React, { useState } from 'react';
import { ShowDeletedSwitch, Switch_v5 } from './ViewSwitch';

import SortIcon from '@mui/icons-material/Sort';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  menuPaper: {
    '& ul': {
      flexDirection: 'column !important',
    },
  },
  sortSection: {
    padding: '10px',
    border: '1px solid',
    borderColor: "#F15A29",
    borderRadius: '4px',
    margin: '10px',
  },
  filterSection: {
    padding: '5px',
    borderRadius: '4px',
    margin: '5px',
  },
});

const CustomToolbarCards = ({ columns, onSort, onFilter }) => {
  const [anchorElSort, setAnchorElSort] = useState(null);
  const [sortField, setSortField] = useState(columns[0].field);
  const [sortOrder, setSortOrder] = useState(true); // true for ascending, false for descending
  const [filterTerm, setFilterTerm] = useState("");
  const classes = useStyles();

  const handleSortClick = (event) => {
    setAnchorElSort(event.currentTarget);
  };

  const handleCloseSort = () => {
    setAnchorElSort(null);
  };

  const handleSortFieldChange = (event) => {
    setSortField(event.target.name);
    onSort(event.target.name, sortOrder);
  };

  const handleSortOrderChange = (event) => {
    setSortOrder(event.target.checked);
    onSort(sortField, event.target.checked);
  };

  const handleFilterChange = (event) => {
    setFilterTerm(event.target.value);
    onFilter(sortField, event.target.value); // use the sortField for filtering
  };

  const openSort = Boolean(anchorElSort);
  const idSort = openSort ? 'simple-sort' : undefined;

  return (
    <Box sx={{ paddingLeft: '2rem', paddingTop: '1rem', paddingRight: '1rem', paddingBottom: '1rem' }}>
      
      <Button
        aria-describedby={idSort}
        variant="text"
        onClick={handleSortClick}
        startIcon={<SortIcon />}
      >
        Sort
      </Button>
      <Menu
        anchorEl={anchorElSort}
        open={openSort}
        onClose={handleCloseSort}
        classes={{ paper: classes.menuPaper }}
      >
        <div className={classes.filterSection}>
          <TextField
            label="Filter"
            value={filterTerm}
            onChange={handleFilterChange}
            variant="outlined"
          />
        </div>
        <div className={classes.sortSection}>
          <FormLabel component="legend">Sort By</FormLabel>
          <FormGroup>
            {columns.map((column) => (
              <FormControlLabel
                control={
                  <Switch_v5
                    checked={sortField === column.field}
                    onChange={handleSortFieldChange}
                    name={column.field}
                  />
                }
                label={column.headerName || column.field}
                key={column.field}
              />
            ))}
          </FormGroup>
        </div>
        <div className={classes.sortSection}>
          <FormLabel component="legend">Sort Direction</FormLabel>
          <FormControlLabel
            control={
              <Switch_v5
                checked={sortOrder}
                onChange={handleSortOrderChange}
                name="sortOrder"
              />
            }
            label={sortOrder ? "Descending" : "Ascending"}
          />
        </div>
      </Menu>
    </Box>
  );
};

export default CustomToolbarCards;
