import { Box, Paper } from "@mui/material";
import React, { useEffect } from "react";
import { openUsersDrawer, usersDrawerOpen } from "Context/DrawerStates";

import { FailedUserDialog } from "./components/FailedUserDialog";
import { NoFileSelected } from "./components/NoFileSelected";
import { PersistentNavButtons } from "../Navigation/PersistentNavButtons";
import { UsersList } from "./components/UsersList";

export const Users = (props) => {
  //CLOSE ALL OTHER PAGE'S DRAWERS ON INITIAL LOAD
  useEffect(() => {
    props.setCatalogBrowserDialogOpen(false);
    props.setCatalogDrawerOpen(false);
    props.setProjectServiceDialogOpen(false);
    props.setProjectUserDialogOpen(false);
    props.closeCreateCatalogPartDrawer(false);
    props.closeCatalogImportDialog();
    props.handleCloseOrdersDrawer();
    props.closeServicesDrawer();
    props.closeProjectsDrawer();
  });

  const openDrawer = (userId) => {
    props.setUserForDrawer(userId);
    const key = userId;
    props.setDrawerKey(key);
    openUsersDrawer();
  };

  return (
    <Box
      sx={styles.paper}
    >
      <UsersList companyId={props.companyId} openDrawer={openDrawer} isOpen={usersDrawerOpen} />
      <NoFileSelected />
      <FailedUserDialog />
    </Box>
  );
};

const styles = {
  paper: {
    height: "auto",
    width: "100%",
    marginTop: "80px",
    backgroundColor: "#fff",
    flex: 1,
    flexDirection: "column",
  },
};
