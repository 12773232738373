import {
    Box,
    Checkbox,
    TextField
} from "@mui/material"
import { ConstructionOutlined, Lock, LockOpen } from "@mui/icons-material"
import React, {useEffect, useState} from "react";

import {styled} from "@mui/material/styles";

export const ServiceConfigurationPartValueOption = (props) => {
    const [option, setOption] = useState(props.option)
    const [optionValue, setOptionValue] = useState(props.option.OptionValue.Value)
    const [isLocked, setIsLocked] = useState(props.option.IsLocked);
    const [optionIndex, setOptionIndex] = useState(props.optionIndex)

    const checkWithinConstraints = (option)=> {
        if(option.Lowest === -1 && option.Highest === -1){
            return true;
        }else if(option.Lowest === -1 && option.Highest !== -1){
            if(Number(option.OptionValue.Value) <= option.Highest){
                return true;
            }else{
                return false;
            }
        }else if(option.Lowest !== -1 && option.Highest === -1){
            if(Number(option.OptionValue.Value) >= option.Lowest){
                return true;
            }else{
                return false;
            }
        }else{
            if(Number(option.OptionValue.Value) >= option.Lowest && Number(option.OptionValue.Value) <= option.Highest){
                return true;
            }else{
                return false;
            }
        }
    }

    const withinConstraints = checkWithinConstraints(option);
    
    const checkSendProp = () =>{
        if(isLocked){
            setIsLocked(false);
            option.IsLocked = false;
            props.checkOption(props.optionIndex, false)
        }else{
            setIsLocked(true);
            option.IsLocked = true;
            props.checkOption(props.optionIndex, true)
        }
    }
    const handleChange = (event) =>{
        const myValue = event.target.value;
        setOptionValue(myValue);
        option.OptionValue.Value = Number(myValue);
        props.handleOptionChange(props.optionIndex, option);
    };

    useEffect(() => {

    }, [withinConstraints])

    const styles = {
        div: {
            1: {
                backgroundColor: 'white', 
                width: 290, 
                WebkitBorderRadius: '10px 10px 10px 10px', 
                MozBorderRadius: '10px 10px 10px 10px', 
                borderRadius: '10px 10px 10px 10px',
            },
            2: {display: 'flex', paddingLeft: '10px'},
            3: {display: "inline-block", width: '80px !important'},
            4: {display: "inline-block", width: '148px', paddingTop: '10px'},
            5: {display: "inline-block", marginLeft: '5px !important', paddingRight: '5px', paddingTop: '16px'},
            6: {
                backgroundColor: 'white', 
                width: 290, 
                WebkitBorderRadius: '10px 10px 10px 10px', 
                MozBorderRadius: '10px 10px 10px 10px', 
                borderRadius: '10px 10px 10px 10px',
            },
            7: { display: "inline-block", width: '20px' },
            8: { display: "inline-block", width: '80px !important' },
            9: {display: "inline-block", marginLeft: '10px !important', paddingRight: '5px', paddingTop: '16px'},
    
        },
        p: {
            whiteSpace: 'nowrap', 
            overflow: 'hidden', 
            textOverflow: 'ellipsis'
        },
        textField: { width: '150px !important', backgroundColor: (withinConstraints) ? 'white !important' : 'red !important'}
    };

    return (
        <Box>
        {option !== undefined && optionValue !== undefined ? (
            <Box
                key={option.fabId} 
                className="card" 
                sx={styles.div[1]}
            >
                <Box sx={styles.div[2]}>
                    <Box sx={styles.div[3]}>
                        <P_>
                            {option?.Name}
                        </P_>
                    </Box>

                    <Box sx={styles.div[4]}>
                        <TextField
                            size="small"
                            sx={styles.textField}
                            my-data-index={optionIndex}
                            value={optionValue}
                            onChange={handleChange}
                            className="inline"
                            type="text"
                            placeholder={optionValue.toString()}
                        />
                    </Box>
                    <Box sx={styles.div[5]}>
                        <Checkbox
                            checked={isLocked}
                            onChange={checkSendProp}
                            inputProps={{ 'aria-label': 'controlled'}}
                            checkedIcon={<Lock/>}
                            icon={<LockOpen color="secondary"/>}
                            
                        />
                    </Box>
                </Box>
            </Box>
        ) : null
        }
        </Box>
    )
    
}



const P_ = styled('p')`
    white-space: nowrap !important;
    overflow: hidden !important; 
    text-overflow: ellipsis !important;
    padding-top: 4px;
`;

