import {
    Box,
    Typography,
} from '@mui/material'
import {
    DataGridPro,
    GridToolbar,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
    LicenseInfo,
    gridDateComparator,
    useGridApiRef
} from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from 'react';

import { ActionBar } from "./ActionBar";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import CustomDataGrid from "./CustomDataGrid";
import EditIcon from "@mui/icons-material/Edit";
import LinkIcon from "@mui/icons-material/Link";
import LockIcon from "@mui/icons-material/Lock";
import {OrdersDrawer} from './OrdersDrawer'
import clone from 'just-clone'
import { getAttachmentDownloadSetting } from 'Context/currentUser';
import { getUserPermissions } from "Context/currentUser";
import { updateOrder } from 'data/queries/queryAPI';
import { useMutation } from "react-query";

export const PackageTable = (props) => {
    const userPermissions_key = "JxXSoSKKP^N9tot9x4qDEGZZ%L2t^vYS";
    const userPermissions = getUserPermissions(userPermissions_key);
    const [admin, isAdmin] = useState(userPermissions.includes("AdminUsers"));
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [activeOrders, setActiveOrders] = useState(props.orders);
    const [users, setUsers] = useState(props.users);
    const [division, setDivision] = useState(props.division);
    const [project, setProject] = useState(props.project);
    const [selectionModel, setSelectionModel] = useState([]);
    const [packagesForDrawer, setPackagesForDrawer] = useState([]);
    const [checkingIn, setCheckingIn] = useState(false);
    const [assignableViewers, setAssignableViewers] = useState([]);
    const [assignedViewers, setAssignedViewers] = useState([]);

    useEffect(() => {
        if(props.clearSelectionFlag){
            setSelectionModel([]);
            setPackagesForDrawer([]);
            props.setPackagesForDrawer([]);
            props.setClearSelectionFlag(!props.clearSelectionFlag);
        }
    }, [props.clearSelectionFlag]);
    

    useEffect(() => {
        setActiveOrders(props.orders)
    }, [props.orders])

    useEffect(() => {
        setUsers(props.users)
    }, [props.users])

    useEffect(() => {
        if(project !== undefined && users !== undefined){
            const assignable = project.users.filter((user) => !project.viewOnly.includes(user));
            const assigned = project.users.filter((user) => project.viewOnly.includes(user));
            const assignableUsers = assignable.map((user) => {
                const userObject = users.find((item) => item.id === user)
                return userObject;
            })
            const assignedUsers = assigned.map((user) => {
                const assignedUserObject = users.find((item) => item.id === user)
                return assignedUserObject;
            })
            setAssignableViewers(assignableUsers);
            setAssignedViewers(assignedUsers);
        }
    }, [project, users])

    useEffect(() => {
        setDivision(props.division)
    }, [props.division])

    useEffect(() => {
        setProject(props.project)
        setSelectionModel([]);
    }, [props.project])

    useEffect(() => {
        const packagesArray = selectionModel?.map((id) => {
            const order = activeOrders.find((order) => order.id === id);
            return order;
        })
        setPackagesForDrawer(packagesArray)
        props.setPackagesForDrawer(packagesArray)
    }, [selectionModel])

    const handleCheckIn = async () => {
        setCheckingIn(true)
        let clonedOrders = clone(activeOrders);
        for(let i = 0; i < packagesForDrawer.length; i ++){
            let payload = {}
            payload.updateObject = {assignedTo: ""}
            payload.projectId = project?.id;
            payload.packageId = packagesForDrawer[i]?.id;
            let data = await updateOrder(payload);
            if(!data.error){
                let index = clonedOrders.findIndex((packageItem) => packageItem?.id === data?.id);
                clonedOrders[index] = data;
            }
        }
        setCheckingIn(false);
        setActiveOrders(clonedOrders);
        props.setOrders(clonedOrders);
    }

    const columns = [
        {
            field: "stratusId",
            headerName: "Lnk",
            sortable: true,
            width: 75,
            disableClickEventBubbling: true,
            delete: true,
            renderCell: (params) => {
                if (params.row?.orderData?.stratusId){
                    return(
                            <LinkIcon
                              color="secondary"
                              //sx={styles.color.flame}
                            />
                    );
                } else if((users.find((user) => {return user.id === params.row?.orderData?.assignedTo}) === undefined)){
                    return (
                            <EditIcon
                              // onClick={(e) => editSinglePackage(params.row.id)}
                              color="secondary"
                              //sx={styles.color.flame}
                            />
                    );
                } else if((users.find((user) => {return user.id === params.row?.orderData?.assignedTo}) !== undefined)) {
                  return (
                          <LockIcon
                          color="secondary"
                          // onClick={(e) => editSinglePackage(params.row.id)}
                          //sx={styles.color.flame}
                          />
                )
              }
            },
            valueGetter: (params) => {
                if (params.row?.orderData?.stratusId){
                    return(
                            0
                    );
                } else if((users.find((user) => {return user.id === params.row?.orderData?.assignedTo}) === undefined)){
                    return (
                            1
                    );
                } else if((users.find((user) => {return user.id === params.row?.orderData?.assignedTo}) !== undefined)) {
                  return (
                          2
                )
              }
            }
        },
        { 
          field: "targetDate", 
          headerName: "On Site", 
          width: 150, 
          valueGetter: params => {
            // CONVERTS TO UNIX EPOCH SO THE MUI NUMBER COMPARATOR CAN HANDLE SORTING
            var nums = params.row?.orderData?.targetDate.split('/');
            var dateParsed = new Date( Number(nums[2]), Number(nums[0]) - 1, Number(nums[1]) ).getTime();
            return dateParsed;
          },
          valueFormatter: params => {
            var str = new Date( params.value ).toLocaleDateString();
            return str;
          }
        },
        { field: "name", headerName: "Sketch/Package Name", width: 250, valueGetter: (params) => {
            return params.row?.orderData?.name 
        }, },
        { field: "package", headerName: "Order Name", width: 250, valueGetter: (params) => {
            return params.row?.orderData?.package
        }},
        { field: "status", headerName: "Status", width: 150, valueGetter: (params) => {
            return params.row?.orderData?.status
        } },
        { field: "createdBy", headerName: "Created By", width: 175, valueGetter: (params) => {
            return users.find((user) => user.id === params.row?.orderData?.authorId)?.name
        } },
        { 
          field: "lastModified", 
          headerName: "Last Modified", 
          width: 175, 
          valueGetter: params => {
  
            // CONVERTS TO UNIX EPOCH SO THE MUI NUMBER COMPARATOR CAN HANDLE SORTING
            return new Date(params.row?.orderData?.lastModified).getTime();
          },
          valueFormatter: params => {
  
            var str = new Date( params.value ).toLocaleString();
            return str;
          }        
        },
        { field: "checkedOutBy", headerName: "In/Out", width: 250, valueGetter: (params) => {
            if((users.find((user) => {return user.id === params.row?.orderData?.assignedTo}) === undefined)){
                return (
                        "Checked In"
                );
            } else if((users.find((user) => {return user.id === params.row?.orderData?.assignedTo}) !== undefined)) {
              return (
                      users.find((user) => {return user.id === params.row?.orderData?.assignedTo})?.name
            )
          }
        }, },
    ]
    function CustomToolbar() {
        return (
            <Box bgcolor="white">
            <GridToolbarContainer>
              <GridToolbarColumnsButton/>
              <GridToolbarFilterButton/>
              <GridToolbarDensitySelector/>
              <GridToolbarExport/>
            </GridToolbarContainer>
            </Box>
        );
      }

    if (activeOrders !== undefined && activeOrders.length === 0) {
        return <Box sx={{display: 'flex', color: "gray", marginLeft: "1.9rem"}}>
                    <Typography variant="h4">No Packages</Typography>
                </Box>;
    }
    return (
        <Box>
        {
            (project !== undefined && (activeOrders === undefined)) ? (
                <Box sx={{display: 'flex', color: "gray", marginLeft: "1.9rem"}}>
                    <Typography variant="h4">Loading Packages...</Typography>
                    <CircularProgress color="inherit" />
                </Box>
            ): null
        }
        {
            selectionModel?.length && (getAttachmentDownloadSetting() || admin) > 0 ? (
                (users?.find((user) => {return user.id === packagesForDrawer[0]?.orderData?.assignedTo}) === undefined) ? (
                    <Box sx={{marginLeft: '1.9rem'}}>
                    <ActionBar
                        openDrawer={props.openDrawer}
                        projectId={project.id}
                        isStratusProject={(project?.stratusModelId) ? true : false}
                        packages={packagesForDrawer}
                        assignedViewers={assignedViewers}
                        companyId={props.companyId}
                        assignableViewers={assignableViewers}
                        packageUrl
                        selectionModel={selectionModel}
                        setOrders={props.setOrders}
                        orders={activeOrders}
                    />
                    </Box>
                ) : (
                    !checkingIn ? (
                        <Box sx={{marginLeft: '1.9rem', marginBottom: '1rem'}}>
                            <Button
                              size="large"
                              variant="contained"
                              color="secondary"
                              //create an onClick that changes checkedOutBy to "Checked In"
                              onClick={() => {
                              handleCheckIn()
                            }}
                            >
                                <span>
                                  Convert to Checked In
                                </span>
                            </Button>
                        </Box>
                    ) : (
                        <Box sx={{marginLeft: '1.9rem'}}>
                            <CircularProgress size={24} />
                        </Box>)
                )
            ) : null
        }
        {

            (project !== undefined && activeOrders !== undefined && Array.isArray(activeOrders) && activeOrders.length > 0 && users !== undefined) ? (
                <Box sx={{width: '77.5%', marginLeft: '1.75rem', boxShadow: '0 0 5px 2px lightgray'}}> 
                    <CustomDataGrid
                        editMode="row"
                        columns={columns}
                        rows={activeOrders}
                        checkboxSelection={true}
                        pagination={true}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[10, 20, 30, 40, 50]}
                        onPageChange={(newPage) => setPage(newPage)}
                        isRowSelectable={(params) => {
                            if(selectionModel?.length === 0){
                                packagesForDrawer.pop()
                            }
                            return (packagesForDrawer?.length !== 0) ? (
                                ((users.find((user) => {return user.id === packagesForDrawer[0]?.orderData?.assignedTo}) === undefined)) ?
                                (users.find((user) => {return user.id === params.row?.orderData?.assignedTo}) === undefined):
                                (users.find((user) => {return user.id === params.row?.orderData?.assignedTo}) !== undefined)
                            ) : true
                        }}
                        onSelectionModelChange={(newSelectionModel) => {
                            setSelectionModel(newSelectionModel);
                        }}
                        selectionModel={selectionModel}
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                        autoHeight={true}
                    />
                </Box>
            ) : null
        }
        
        </Box>
    )
}
