import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from 'react';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Loading from './Loading';
import { styled } from "@mui/material/styles";

const ConnectorsContainer = (props) =>{

    const groupBy =  (xs, key) => {
      return xs.reduce((rv, x) => {
        if(x[key] === 0 || x[key] === 3 || x[key] === 4){
          (rv[0] = rv[0] || []).push(x);
        }else{
          (rv[x[key]] = rv[x[key]] || []).push(x);
        }
        return rv;
      }, {});
    };
    const {connectionTypes, serviceParts, currentConnectionTypes} = props;

    const [dataForDisplay, setDataForDisplay] = useState(null);
    var checkedStateShape = useRef([])
    const [expanded, setExpanded] = React.useState(false);
    const [shapeExpanded, setShapeExpanded] = React.useState(false);
    const [selectedConnectionTypes, setSelectedConnectionTypes] = useState({connTypes: [{Connectivity: "", Domain: 0, FabId: -1, Group: "Blank", Name: "Blank", Profile: "", Sex: 2, Shape: 0}],});
    useEffect(() =>{
        let pp = connectionTypes.data.connectionTypes.filter((ele, ind) => ind === connectionTypes.data.connectionTypes.findIndex(elem => elem.Name === ele.Name && elem.Group === ele.Group))
        let newArray = []
        let groupedByDomain = groupBy(pp, 'Domain')
        let groupedByShape = []

        let checkedStateShapeTemp = []
        Object.keys(groupedByDomain).map((domain, index) =>{
          let array = groupBy(groupedByDomain[domain], 'Shape');
          checkedStateShapeTemp.push({[domain]: []})
          groupedByShape.push({[domain]: array})
          Object.keys(array).map((group, ind) =>{
             checkedStateShapeTemp[index][domain].push({[group]:new Array(array[group].length).fill(false)}) 
          })
          
        })


        setDataForDisplay(groupedByShape)

        checkedStateShape.current = checkedStateShapeTemp
    }, [props.connectionTypes, props.config, serviceParts, currentConnectionTypes])

    useEffect(() => {
      let existingConnectionTypes = [];
      let existingConnectionTypesFromFile = [];
      if(Array.isArray(currentConnectionTypes.data) && existingConnectionTypesFromFile.length === 0){
        currentConnectionTypes.data.map((type) => {
          existingConnectionTypesFromFile.push((type.FabId));
        })
      }

      if(serviceParts.data && Array.isArray(serviceParts.data)){
          serviceParts.data.map((part, index) => {
          part.connections.map((connection, ind) => { 
            if(existingConnectionTypes.indexOf(connection.fabId) < 0)
              existingConnectionTypes.push(connection.fabId);  
          })
        })
        let existingConnectionTypes2 = Array.from(new Set(existingConnectionTypes))
        if(Array.isArray(dataForDisplay)){
          dataForDisplay.map((group, ind) => {
          Object.keys(group).map((domain, index) =>{
            Object.keys(group[domain]).map((shape,idx) =>{
              group[domain][shape].map((type, indexx) => {
                existingConnectionTypes2.map(conn => {
                  if(type.FabId === conn){
                    if(selectedConnectionTypes.connTypes.indexOf(type.FabId) > 0){
                      if(existingConnectionTypesFromFile !== []){
                        if(existingConnectionTypesFromFile.indexOf(type.FabId) < 0){
                          existingConnectionTypesFromFile.push(type.FabId)
                        }
                      }
                    }
                  }
                })
                if(existingConnectionTypesFromFile !== []){
                  if(existingConnectionTypesFromFile.indexOf(type.FabId) >= 0){
                    checkConnectionType(ind,domain, idx, shape, indexx)
                  }
                }
              })
            })
          })
        })
        }
        
        checkedStateShape.current = checkedStateShape.current
      }
    }, [dataForDisplay])

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    const handleShapeChange = (panel) => (event, isExpanded) => {
      setShapeExpanded(isExpanded ? panel : false);
    }

    const checkConnectionType = (domainIndex, domain, shapeIndex, shape, index) => {
      if(checkedStateShape.current[domainIndex][domain][shapeIndex][shape][index] !== undefined){
        checkedStateShape.current[domainIndex][domain][shapeIndex][shape][index] = !checkedStateShape.current[domainIndex][domain][shapeIndex][shape][index]
        if(checkedStateShape.current[domainIndex][domain][shapeIndex][shape][index]){
          addSelectedConnectionType(domainIndex, domain, shapeIndex, shape, index);
        }else{
          removeSelectedConnectionType(domainIndex, domain, shapeIndex, shape, index);
        }
      }
    }

    const addSelectedConnectionType = (domainIndex, domain, shapeIndex, shape, index) => {
      let connTypes = selectedConnectionTypes.connTypes
      if(connTypes.indexOf(JSON.parse(JSON.stringify(dataForDisplay[domainIndex][domain][shape][index]))) < 0){
        connTypes.push(dataForDisplay[domainIndex][domain][shape][index])
      }
      setSelectedConnectionTypes({connTypes: Array.from(new Set(connTypes))})
      props.setSelectedConnectionTypesMain({connTypes: Array.from(new Set(connTypes))})
    }

    const removeSelectedConnectionType = (domainIndex, domain, shapeIndex, shape, index)  =>{
      let connTypes = selectedConnectionTypes.connTypes
      let typeToRemove = connTypes.indexOf(dataForDisplay[domainIndex][domain][shape][index])
      connTypes.splice(typeToRemove, 1);
      setSelectedConnectionTypes({connTypes: Array.from(new Set(connTypes))})
      props.setSelectedConnectionTypesMain({connTypes: Array.from(new Set(connTypes))})
    }

    const getDomainName = (domain) =>{
      if(Number(domain) === 0){
        return "Pipe"
      }else if(Number(domain) === 1){
        return "Duct"
      }else if(Number(domain) === 3){
        return "Pipe"
      }else if(Number(domain) === 4){
        return "Pipe"
      }
    }

    const getShapeName = (shape) =>{
      if(Number(shape) === 1){
        return "Rectangular"
      }else if (Number(shape) === 2){
        return "Round"
      }else if (Number(shape) === 3){
        return "Oval"
      }else{
        return "NotSet"
      }
    }


    return(
            <Box >

                {
                  !connectionTypes.isLoading  && connectionTypes.data !== [] && (dataForDisplay !== undefined && Array.isArray(dataForDisplay)) ? <Box>
                    {
                      dataForDisplay.map((group, ind) =>{
                        return Object.keys(group).map((domain, index) =>{
                          return (
                            <Accordion expanded={expanded === ind} onChange={handleChange(ind)} elevation={false} sx={{marginLeft: "1rem !important"}} >
                              <AccordionSummary
                                ref={React.createRef()}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                              >
                                <Typography_ 
                                  noWrap={true}
                                  ref={React.createRef()} 
                                  //sx={styles.typography}
                                >
                                  {getDomainName(domain)}
                                </Typography_>
                              </AccordionSummary>
                              <AccordionDetails>
                                {
                                  Object.keys(group[domain]).map((shape,idx) =>{
                                    return(
                                      <Accordion expanded={shapeExpanded === idx} onChange={handleShapeChange(idx)} elevation={false} >
                                        <AccordionSummary
                                          ref={React.createRef()}
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                        >
                                          <Typography_ 
                                            noWrap={true}
                                            ref={React.createRef()} 
                                            //sx={styles.typography}
                                          >
                                            {getShapeName(shape)}
                                          </Typography_>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        <List> 
                                          {
                                            group[domain][shape].map((type, indexx) => {
                                              //const index = Object.keys(group[domain]).indexOf(shape);
                                                return (
                                                  <ListItem key={indexx}>
                                                    <ListItemText key={indexx} primary={type.Name}/>
                                                    <ListItemIcon>
                                                      <Checkbox 
                                                        checked={checkedStateShape.current[ind][domain][idx][shape][indexx]}
                                                        onChange={() => checkConnectionType(ind, domain, idx, shape, indexx)}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                      />
                                                    </ListItemIcon>
                                                  </ListItem>
                                                )
                                              
                                            })
                                          }
                                        </List>
                                        </AccordionDetails>
                                      </Accordion>

                                    )
                                  })
                                }
                            </AccordionDetails>
                          </Accordion>
                          )
                        })
                      }) 
                    }
                  </Box>
                   : <Loading msg="Loading Connection Types"></Loading>
                }    
          </ Box >
    )
};

const styles = {
  card: {
    width: 310,
    minHeight: 260,
    maxHeight: 500,
    maxWidth: 310,
    overflowX: "hidden",
    overflowY: "scroll",
    border: "1px solid #F15A29 !important"
  },
  typography: { width: '75%', flexShrink: 0 },
};

const Typography_ = styled(Typography)`
  //width: 75% !important; 
  flex-shrink: 0 !important;
`;

export default ConnectorsContainer;