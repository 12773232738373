import { entity } from "simpler-state";

// CATALOGS
export const catalogIdForDrawer = entity("");
export const setCatalogIdForDrawer = (id) => catalogIdForDrawer.set(id);
export const catalogPartDrawerOpen = entity(false);
export const openCatalogPartDrawer = () => {
  catalogPartDrawerOpen.set(true);
};
export const closeCatalogPartDrawer = () => {
  catalogPartDrawerOpen.set(false);
};
export const createCatalogPartDrawerOpen = entity(false);
export const openCreateCatalogPartDrawer = () => {
  createCatalogPartDrawerOpen.set(true);
};
export const closeCreateCatalogPartDrawer = () => {
  createCatalogPartDrawerOpen.set(false);
};

export const configureConnectionTypeDialogOpen = entity(false);
export const openConfigureConnectionTypeDialog = () => {
  configureConnectionTypeDialogOpen.set(true);
}

export const closeConfigureConnectionTypeDialog = () => {
  configureConnectionTypeDialogOpen.set(false);
}

export const configurePartDialogOpen = entity(false);
export const openConfigurePartDialogOpen = () => {
  configurePartDialogOpen.set(true);
}
export const closeConfigurePartDialogOpen = () => {
  configurePartDialogOpen.set(false);
}
export const catalogImportDialogOpen = entity(false);
export const openCatalogImportDialog = () => {
  catalogImportDialogOpen.set(true);
};
export const closeCatalogImportDialog = () => {
  catalogImportDialogOpen.set(false);
};
export const renameCatalogFormOpen = entity(false);
export const openRenameCatalogForm = () => {
  renameCatalogFormOpen.set(true);
};
export const closeRenameCatalogForm = () => {
  renameCatalogFormOpen.set(false);
};
export const catalogIdForRenameForm = entity("");
export const setCatalogIdForRenameForm = (id) => {
  catalogIdForRenameForm.set(id);
};

//DIVISIONS
export const divisionsDrawerOpen = entity(false);
export const openDivisionsDrawer = () => {
  divisionsDrawerOpen.set(true);
};
export const closeDivisionsDrawer = () => {
  divisionsDrawerOpen.set(false);
};
export const divisionUsersDialogOpen = entity(false);
export const divisionRenameDialogOpen = entity(false);
export const divisionHardDeleteDialogOpen = entity(false);
//export const divisionSoftDeleteDialogOpen = entity(false);

export const openDivisionHardDeleteDialog = ()=> {
  divisionHardDeleteDialogOpen.set(true);
};
export const closeDivisionHardDeleteDialog = ()=> {
  divisionHardDeleteDialogOpen.set(false);
};
// export const openDivisionSoftDeleteDialog = ()=> {
//   divisionHardDeleteDialogOpen.set(true);
// };
// export const closeDivisionSoftDeleteDialog = ()=> {
//   divisionHardDeleteDialogOpen.set(false);
// };
export const packageDeleteDialogOpen = entity(false);
export const openPackageDeleteDialog = ()=> {
  packageDeleteDialogOpen.set(true);
}
export const closePackageDeleteDialog = ()=> {
  packageDeleteDialogOpen.set(false);
}
export const openDivisionUsersDialog = () => {
  divisionUsersDialogOpen.set(true);
};
export const closeDivisionUsersDialog = () => {
  divisionUsersDialogOpen.set(false);
};
export const openDivisionRenameDialog = () =>{
  divisionRenameDialogOpen.set(true);
}
export const closeDivisionRenameDialog = ()=>{
  divisionRenameDialogOpen.set(false)
}

// ORDERS
export const ordersDrawerOpen = entity(false);
export const openOrdersDrawer = () => {
  ordersDrawerOpen.set(true);
};
export const closeOrdersDrawer = () => {
  ordersDrawerOpen.set(false);
};
export const ordersSelectionModel = entity([]);
export const setOrdersSelectionModel = (arr) => {
  ordersSelectionModel.set(arr);
};

//PROJECTS
export const projectsDrawerOpen = entity(false);
export const openProjectsDrawer = () => {
  projectsDrawerOpen.set(true);
};
export const closeProjectsDrawer = () => {
  projectsDrawerOpen.set(false);
};
export const projectRenameDialogOpen = entity(false);
export const projectHardDeleteDialogOpen = entity(false);
export const projectSoftDeleteDialogOpen = entity(false);

export const openProjectRenameDialog = () => {
  projectRenameDialogOpen.set(true);
};
export const openProjectHardDeleteDialog = () => {
  projectHardDeleteDialogOpen.set(true);
};
export const openProjectSoftDeleteDialog = () => {
  projectSoftDeleteDialogOpen.set(true);
};
export const closeProjectRenameDialog = () => {
  projectRenameDialogOpen.set(false);
};
export const closeProjectHardDeleteDialog = () => {
  projectHardDeleteDialogOpen.set(false);
};
export const closeProjectSoftDeleteDialog = () => {
  projectSoftDeleteDialogOpen.set(false);
};

//SERVICES
export const servicesConfigDrawerOpen = entity(false);
export const servicesRefreshDialogOpen = entity(false);
export const openServicesConfigDrawer = () => {
  servicesConfigDrawerOpen.set(true);
};
export const closeServicesConfigDrawer = () => {
  servicesConfigDrawerOpen.set(false);
};

export const openServicesRefreshDialog = () => {
  servicesRefreshDialogOpen.set(true);
}

export const closeServicesRefreshDialog = () => {
  servicesRefreshDialogOpen.set(false);
}

export const sizesConfigDrawerOpen = entity(false);

export const openSizesServicesConfigDrawer = () => {
  sizesConfigDrawerOpen.set(true);
};
export const closeSizesServicesConfigDrawer = () => {
  sizesConfigDrawerOpen.set(false);
};

export const connectorsConfigDrawerOpen = entity(false);

export const openConnectorsServicesConfigDrawer = () => {
  connectorsConfigDrawerOpen.set(true);
};
export const closeConnectorsServicesConfigDrawer = () => {
  connectorsConfigDrawerOpen.set(false);
};

export const servicesDrawerOpen = entity(false);
export const openServicesDrawer = () => {
  servicesDrawerOpen.set(true);
};
export const closeServicesDrawer = () => {
  servicesDrawerOpen.set(false);
};
export const serviceGroupToEdit = entity(null);
export const setServiceGroupToEdit = (group) => {
  serviceGroupToEdit.set(group);
};
export const itemsGroupParentService = entity(null);
export const setItemsGroupParentService = (serviceId) => {
  itemsGroupParentService.set(serviceId);
};

export const createStatusDialogOpen = entity(false);

export const openCreateStatusDialog = () => {
  createStatusDialogOpen.set(true);
}

export const closeCreateStatusDialog = () => {
  createStatusDialogOpen.set(false);
}

//CREATE USER DRAWER
export const createUserDrawerOpen = entity(false);
export const userNoFileSelectedOpen = entity(false);
export const failedUserDialogOpen = entity(false);


export const openCreateUserDrawer = () => {
  createUserDrawerOpen.set(true);
  usersDrawerOpen.set(false);
};
export const closeCreateUserDrawer = () => {
  createUserDrawerOpen.set(false);
};

export const openUserNoFileSelectedDialog = () => {
  userNoFileSelectedOpen.set(true);
}

export const closeUserNoFileSelectedDialog = () => {
  userNoFileSelectedOpen.set(false);
}

export const openFailedUserDialog = () => {
  failedUserDialogOpen.set(true);
}

export const closeFailedUserDialog = () => {
  failedUserDialogOpen.set(false);
}

//USERS
export const usersDrawerOpen = entity(false);
export const openUsersDrawer = () => {
  usersDrawerOpen.set(true);
  createUserDrawerOpen.set(false);
};
export const closeUsersDrawer = () => {
  usersDrawerOpen.set(false);
};

//COMPANY
export const companyId = entity("");
export const setCompanyId = (id) => companyId.set(id);
