import React, { useEffect, useReducer, useRef, useState } from "react";
import { convertConfigurationToCatalog, createServiceConfigurations, deleteServiceConfigurations, getFabGeometry, getServiceConfigurations, getServiceConnectionTypes, getServices, postFabGeometry, updateServiceConfigurations } from "./data/queries/queryAPI.js";
import {
  useAPICallback,
  useAPIData,
  useAPIEffect,
} from "./data/hooks/customHooks";
import { useDispatch, useSelector } from "react-redux";

import {Box} from "@mui/material";
import {Button} from "@mui/material";
import Card from '@mui/material/Card';
import ConfigurationSelector from "./ConfigurationSelector";
import ConnectorsContainer from './ConnectorsContainer';
import Loading from './Loading';
import MeshViewer from './MeshViewer';
import ModifiedParts from "./ModifiedParts";
import { Paper } from "@mui/material";
import PartContainer from './PartContainer';
import { PersistentNavButtons } from "../src/components/Navigation/PersistentNavButtons";
import PropertyContainer from './PropertyContainer';
import SelectedPartContainer from './SelectedPartContainer';
import ServiceSelector from './ServiceSelector';
import TextField from '@material-ui/core/TextField';

const initialState = {
  mouseX: null,
  mouseY: null,
};
export const DuctConfig = (props) => {
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState('none');
  const [connectionTypes, setConnectionTypes] = useState({isLoading: true, isError: false, data: {connectionTypes: []}});
  const [selectedServiceFile, setSelectedServiceFile] = useState([]);
  const [serviceParts, setServiceParts] = useState([]);
  const [selectedItem, setSelectedItem] = useState('none');
  const [selectedProperties, setSelectedProperties] = useState('none');
  const [selectedConnectionTypes, setSelectedConnectionTypes] = useState({connTypes: [],});
  const [selectedConfigurations, setSelectedConfigurations] = useState([]);
  const [newConfigName, setNewConfigName] = useState('none');
  const [newConfiguration, setNewConfiguration] = useState([]);
  const [selectedConfig, setSelectedConfig] = useState([]);
  const [isPlaceholder, setPlaceholder] = useState(false);
  const [modifiedParts, setModifiedParts] = useState({parts: []})
  const [selectedItemMeshData, setSelectedItemMeshData] = useState([]);
  const [meshId, setMeshId] = useState({isLoading: false,isError: false, data: []})
  const [meshData, setMeshData] = useState([]);
  const [meshDimensions, setDimensions] = useState([])
  const [bool, setBool] = useState(false);
  const [servicesFetchLoading, servicesFetchError, getServicesFetch] = useAPIEffect(
    async (data) => {
      const resp = await getServices();
      const newData = [...resp.data];
      const dataSorted = newData.sort((a, b) => (a.name > b.name ? 1 : -1));
      setServices({
        isLoading: servicesFetchLoading,
        isError: servicesFetchError,
        data: dataSorted,
      });
      return resp;
    }
  );
  const [
    connectionTypesLoading,
    connectionTypesError,
    getConnectionTypes,
  ] = useAPIEffect(async (serviceId) => {
    const resp = await getServiceConnectionTypes(serviceId);
    setConnectionTypes({
      isLoading: connectionTypesLoading,
      isError: connectionTypesError,
      data: resp,
    });
    return resp;
  });

  const [filesUploading, fileUploadError, uploadFiles] = useAPIEffect(async (serviceId) => {
    const resp = await createServiceConfigurations(serviceId, {selectedConfig})
    return resp
  })

  const [createConfigUploading, createConfigError, createConfig] = useAPIEffect(async (serviceId) => {
    if(serviceId !== 'none' && newConfiguration !== []){
      const resp = await createServiceConfigurations(serviceId, {newConfiguration})
      return resp
    }
    
  })

  const [serviceConverting, serviceConvertError, convertService] = useAPIEffect(
    async (serviceId) => {
      const resp = await convertConfigurationToCatalog(serviceId, {selectedConfig});
      return resp;
    }
  );

  const [deleteConfigLoading, deleteConfigError, deleteConfig] = useAPIEffect(async (serviceId) => {
    const resp = await deleteServiceConfigurations(serviceId, {selectedConfig});
    const newArray = [...services.data]; // Use state variable for items being displayed
    const finalArray = newArray.filter((service) => {
      return service.id !== serviceId;
    });
    const dataSorted = finalArray.sort((a, b) => (a.name > b.name ? 1 : -1));
    return resp;
  });

  const [postMeshLoading, postMeshError, postMesh] = useAPIEffect(async (serviceId) => {
    const resp = await postFabGeometry(serviceId, {selectedItemMeshData});
    setMeshId({
      isLoading: postMeshLoading,
      isError: postMeshError,
      data: resp,
    })
    return resp;
  })

  const [getMeshLoading, getMeshError, getMesh] = useAPIEffect(async (serviceId) => {
    const resp = await getFabGeometry(serviceId, {meshId});
    setMeshData({
      isLoading: getMeshLoading,
      isError: getMeshError,
      data: resp.data,
    });
    let geo = JSON.parse(JSON.parse(JSON.stringify(resp.data.processedGeometry)))[0]
    setDimensions(geo.Shell.Dimensions);
    setBool(true)
    return resp;
  })

  const generateMesh = () => {
    if(selectedItemMeshData !== []){
      PostMesh()
    }
  }

  const PostMesh = () =>{
    if(selectedItemMeshData !== []){
      postMesh(selectedService)
    }
  }

  const GetMesh = () =>{
    if(meshId.data !== [] && meshId.data.length !== 0){
      getMesh(selectedService)
    }
  }

  const updateItemInService = () =>{
    let alreadyExists = false;
    if(serviceParts.data){
      if(selectedItem !== undefined && selectedProperties !== undefined){
        serviceParts.data.map((part, index) => {
          if(part.partNumber === selectedItem.partNumber){
            if(selectedConfig.connectors !== []){
              selectedConfig.connectors.map((modded, index) => {
                if(modded.partNumber === selectedItem.partNumber){
                  modded = JSON.parse(JSON.stringify(selectedItem));
                  modded.properties = JSON.parse(JSON.stringify(selectedProperties))
                  alreadyExists = true;
                  selectedConfig.connectors[index] = modded;
                  return alreadyExists;
                }
              })

              if(!alreadyExists){
                let isSameConnectionTypes = false;
                let isSameProperties = false;
                if(JSON.stringify(part.properties) === JSON.stringify(selectedProperties)){
                  isSameProperties = true;
                }
                if(JSON.stringify(part.connections) === JSON.stringify(selectedItem.connections)){
                  isSameConnectionTypes = true
                }
                if(!isSameConnectionTypes || !isSameProperties){
                  part = JSON.parse(JSON.stringify(selectedItem));
                  part.properties = JSON.parse(JSON.stringify(selectedProperties));
                  return selectedConfig.connectors.push(part);
                }else{
                  return selectedConfig.connectors;
                }

              }else{
                return selectedConfig.connectors;
              }

            }

          }
        })
        updateConfig()
      }
    }
  }

  const writeItemtoFile = () =>{
    convertService(selectedService)
  }

  const createNewConfig = (name) => {
    let newConfig = {name: selectedServiceFile.data.name + '-' + name, id: selectedServiceFile.data.name + '-' + name, connectionTypes: [], connectors: []}
    let alreadyExists = false
    selectedConfigurations.data.map(config => {
      if(config.name === selectedServiceFile.data.name + '-' + name){
        return alreadyExists = true
      }
    })
    if(!alreadyExists){
      selectedConfigurations.data.push(newConfig)
      setNewConfiguration(newConfig);

    }else{
      console.log("This configuration already exists")
    }
  }

  useEffect(() => {
    if(newConfiguration !== [] && selectedService){
      createConfig(selectedService)
    }
  }, [newConfiguration])

  const updateConfig = () => {
    console.log("Updating configuration")
    uploadFiles(selectedService)
  }

  const deleteConfiguration = (name) => {
    if(selectedConfig){
      console.log("Deleting configuration: ", selectedConfig)
      setSelectedConfigurations({isLoading: false, isError: false, data: selectedConfigurations.data.filter(config => config.name !== selectedConfig.name)})
      deleteConfig(selectedService)
      setSelectedConfig([])
    }

  }

  const UpdateNewConfigName = (event) => {
      setNewConfigName(event.target.value)
  }

  useEffect(() => {

    getServicesFetch();
  }, []);

  useEffect(() => {
    if(selectedService !== 'none')
      setConnectionTypes(getConnectionTypes(selectedService))

  }, [selectedService])

  useEffect(() => {
    if(meshId){
      GetMesh()
    }
  }, [meshId])

  useEffect(() => {
    setDimensions([])
    setSelectedItemMeshData({Name: selectedItem.description,
      PatternNumber: selectedItem.cid,
      DatabaseId: selectedItem.databaseId,
      ItemPath: selectedItem.itemPath,
      Dimensions: selectedItem.properties,
      Connectors: selectedItem.connectors,
      Seams: selectedItem.seams,
      InletConnector: selectedItem.inletIndex,
      OutletConnector: selectedItem.outletIndex,
      InsulationSpecFabId: selectedItem.InsulationSpecFabId,
      MaterialFabId: selectedItem.MaterialFabId,
      MaterialGaugeFabId: selectedItem.MaterialGaugeFabId,
      ServiceTypeFabId: selectedItem.ServiceTypeFabId,
      SpecFabId: selectedItem.SpecFabId,
      Order: "",
      ItemNumber: "",
      Notes: "",
      Quantity: 1})
  }, [selectedItem])

  useRef(React.createRef());

  return (
    <Paper
    elevation={3}
    sx={styles.paper}
    >
      <div>
        <PersistentNavButtons />
      </div>
      <Box sx={styles.div[1]} ref={React.createRef()} >
          {
            services.data &&
            <div ref={React.createRef()} >
              <Box sx={styles.div[2]} ref={React.createRef()} >
                <div ref={React.createRef()} >
                  <ServiceSelector
                  ref={React.createRef()}
                  services={services.data}
                  setSelectedService={setSelectedService}
                  setSelectedServiceFile={setSelectedServiceFile}
                  setSelectedConfiguration={setSelectedConfigurations}
                />
                </div>
                <Box sx={styles.div[3]}>
                  <ConfigurationSelector
                  ref={React.createRef()}
                  setServiceParts={setServiceParts}
                  service={selectedService}
                  configurations={selectedConfigurations}
                  setSelectedItem={setSelectedItem}
                  setSelectedConfig={setSelectedConfig}
                  />
                </Box>
                <Box sx={styles.div[4]}>
                  <Box sx={styles.div[5]}>
                    <label>Create Configuration: </label>
                  </Box>
                  <div>
                    <TextField id="outlined-basic" label="Configuration Suffix" variant="outlined" onChange={UpdateNewConfigName}/>
                  </div>
                  <Box sx={styles.div[6]}>
                    <Button variant="contained" color="secondary" onClick={() => createNewConfig(newConfigName)}>Create Configuration</Button>
                  </Box>
                  <Box sx={styles.div[7]}>
                    <Button variant="contained" color="secondary" onClick={() => deleteConfiguration(newConfigName)}>Delete Configuration</Button>
                  </Box>
                </Box>
              </Box>
              <br/>
              <Box sx={styles.div[2]}>
                <Box sx={styles.div[8]}>
                  <h3>Service Parts: </h3>
                  <PartContainer 
                    service={selectedService} 
                    config={selectedConfig} 
                    setSelectedItem={setSelectedItem} 
                    setServiceParts={setServiceParts} 
                    serviceParts={serviceParts} 
                  />
                </Box>
                <Box 
                  sx={styles.div[9]}
                >
                  <SelectedPartContainer item={selectedItem} setPlaceholder={setPlaceholder}/>
                </Box>
                <Box 
                  sx={styles.div[10]}
                >
                  <h3>Part Properties: </h3>
                  <PropertyContainer item={selectedItem} setSelectedProperties={setSelectedProperties} dimensions={meshDimensions} />
                </Box>
                <Box 
                  sx={styles.div[11]}
                >
                    <Box 
                      sx={styles.div[12]}
                    >
                    <h3>Mesh Preview:</h3>
                      <MeshViewer config={selectedConfig} meshData={meshData} item={selectedItem}/>
                    </Box>
                  <Box 
                    sx={styles.div[12]}
                  >
                    <Box 
                      sx={styles.div[13]}
                    >
                      <Button variant="contained" color="secondary" onClick={generateMesh}>Generate Mesh</Button> 
                    </Box>
                    <Box 
                      sx={styles.div[13]}
                    >
                      <Button variant="contained" color="secondary" onClick={writeItemtoFile}>Send to Catalog</Button> 
                    </Box>  

                  </Box>
                  <Box 
                    sx={styles.div[14]}
                  >
                    <Box 
                      sx={styles.div[15]}
                    >
                        <Button variant="contained" color="secondary" onClick={updateItemInService}>Save Item</Button> 
                    </Box>
                  </Box>
                  <br/>


                </Box>
              </Box>
              <br/>
              <Box sx={styles.div[2]}>
              {
                !connectionTypes.isLoading && !connectionTypes.isError && connectionTypes.data !== undefined && connectionTypes.data.connectionTypes !== [] ?
                (<Box sx={styles.div[16]}>
                  <h3>Service Connection Types: </h3>
                  <ConnectorsContainer config={selectedConfig} connectionTypes={connectionTypes} serviceParts={serviceParts} setSelectedConnectionTypesMain={setSelectedConnectionTypes}/>
                </Box>): <Box sx={styles.div[16]}>
                          <h3>Service Connection Types: </h3>
                            <Card sx={styles.connectionTypes_card} variant="outlined" color="secondary">
                              <Loading message="Loading Connection Types..."></Loading> 
                            </Card>
                        </Box>
              }
                <Box sx={styles.div[18]}>
                  <h3>Modified Parts: </h3>
                  <ModifiedParts modifiedParts={selectedConfig} setSelectedItem={setSelectedItem}/>
                </Box>
              </Box>
            </div>
          }


      </Box>
    </Paper>
  );
};

export default DuctConfig;

const styles = {
  paper: {
    height: "auto",
    width: "100%",
    marginTop: "80px",
    backgroundColor: "#fff",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
  },
  div: {
    1: { paddingLeft: "2rem" , paddingTop: "2rem", paddingBottom: "2rem"},
    2: {display: 'flex'},
    3: {paddingLeft: '10px'},
    4: {display: 'flex', paddingLeft: '10px'},
    5: {justifyContent: 'center', paddingTop: '15px'},
    6: {paddingLeft: '10px', justifyContent: 'center', paddingTop: '10px'},
    7: {paddingLeft: '10px', justifyContent: 'center', paddingTop: '10px'},
    8: {display: 'inline-block', justifyContent: 'left', paddingRight: '10px'},
    9: {
      display: 'inline-block', 
      justifyContent: 'center', 
      paddingLeft: '10px', 
      paddingRight:'10px', 
      paddingTop: '200px'
    },
    10: {
      display: 'inline-block', 
      justifyContent: 'right', 
      paddingLeft: '10px'
    },
    11: {
      display: 'inline-block', 
      paddingLeft: '20px',
      alignItems: "center"
    },
    12: {
      display: 'inline-block', 
      paddingRight: '10px'
    },
    13: {
      display: 'inline-block', 
      paddingRight: '10px', 
      paddingTop: '10px'
    },
    14: {
      display: 'inline-block', 
      paddingLeft: '2px', 
      justifyContent: 'center'
    },
    15: {
      display: 'inline-block', 
      paddingLeft: '2px', 
      paddingTop: '10px', 
      justifyContent: 'right'
    },
    16: {display: 'inline-block', justifyContent: 'left'},
    17: {width: 512, height: 512, border: '4px solid #F15A29', overflowY: 'auto'},
    18: {display: 'inline-block', paddingLeft: '227px'}

  },
  connectionTypes_card: {width: 512, height: 512, overflowY: 'auto', border: '2px solid #F15A29 !important'},

  BGcolor: {
    flame: {backgroundColor: "#F15A29"}
  },
  color: {
    white: {color: "white"},
    flame: {color: "#F15A29"}
  },

  btn: {
    contained: {color: "white", backgroundColor: "#F15A29"},
    outlined: {color: "#F15A29", borderColor: "#F15A29"}
  }
};
