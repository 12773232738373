import { Box, Button, Paper, Typography } from "@mui/material"
import { Route, Routes } from "react-router-dom";
import { pilotApproval, pilotDecline } from "data/queries/queryAPI";

import AdminNavButtons from "./components/AdminNavButtons";
import PilotRequests from "./pages/PilotRequests";
import { useQueryClient } from "react-query"
import { useState } from "react"

const SuperAdminPanel = (props) => {

    const [selectionModel, setSelectionModel] = useState([])
    const [selectedObjects, setSelectedObjects] = useState([])
    const queryClient = useQueryClient();
    return (
        <Box
            sx={styles.paper}
        >
            <Typography variant='h5' sx={ styles.title } >Super Admin Panel</Typography> 
            <br/>
            {selectionModel.length > 0 && selectedObjects.length > 0 ? (
            <Box sx={{marginLeft: "2rem"}}>
                <Button variant='outlined' color="secondary" onClick={() => pilotApproval(selectedObjects).then(() => {queryClient.refetchQueries(['pilotApplicationQueue'])})}>APPROVE</Button>
                <Button variant='outlined' color="secondary" onClick={() => pilotDecline(selectedObjects).then(() => {queryClient.refetchQueries(['pilotApplicationQueue'])})}>DECLINE</Button>
            </Box>
            ): null}
            <PilotRequests sx={styles.pilotRequests} setSelectionModel={setSelectionModel} setSelectedObjects={setSelectedObjects}/>

        </Box>
    )
};

const styles = {
    paper: {
        height: "auto",
        width: "100%",
        marginTop: "80px",
        backgroundColor: "#fff",
        flex: 1,
        flexDirection: "column",

    },
    title: {
        marginLeft: '2rem !important',
        marginTop: '1rem !important'
    },
    pilotRequests: {
        paddingLeft: '1rem !important',
        paddingRight: '2rem',
        marginTop: '2rem'
    }

}

export default SuperAdminPanel;