import { createTheme } from "@mui/material/styles";
import {buttons} from "./themes/buttons";


export const theme = createTheme({
    components: {
        MuiButton: buttons,
    },
    palette: {
        primary: {
          main: "#F15A29 !important",
        },
        secondary:{
          main: "#F15A29 !important",
        },
        white:{
          main: "#ffffff !important",
        },
        black:{
          main: "#000 !important",
          contrastText: '#fff',
        },
        action: {
          disabledBackground: 'lightgray !important',
          disabled: 'darkgray !important'
        }
    },
  });