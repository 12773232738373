import{
    Box,
    Checkbox,
    MenuItem,
    Select
} from '@mui/material'
import {Lock, LockOpen} from '@mui/icons-material'
import React, {useEffect, useState} from "react";

import {styled} from "@mui/material/styles";

export const ServiceConfigurationPartDropdownProperty = (props) => {
    const [property, setProperty] = useState(props.property)
    const [propertyValue, setPropertyValue] = useState(props.property.possibleValues[0])
    const [isLocked, setIsLocked] = useState(props.property.isLocked);
    const [dimension, setDimension] = useState(props.dimension)
    const [propertyIndex, setPropertyIndex] = useState(props.propertyIndex)
    
    useEffect(() => {
        setDimension(props.dimension)
    }, [props.dimension])
    const checkSendProp = () =>{
        if(isLocked){
            setIsLocked(false);
            property.isLocked = false;
            props.checkProperty(props.propertyIndex, false)
        }else{
            setIsLocked(true);
            property.isLocked = true;
            props.checkProperty(props.propertyIndex, true)
        }
    }
    const handleChange = (event)=> {
        const { myValue, myIndex } = event.currentTarget.dataset;
        setPropertyValue(myValue);
        property.selectedOption = myIndex;
        props.handlePropertyChange(props.propertyIndex, property);
    };

    return(
        <Box>
        {dimension === undefined && property !== undefined && propertyValue !== undefined ? (
            <Box 
                key={property.fabId} 
                className="card" 
                sx={styles.div[1]}
            >
                <Box sx={styles.div[2]}>
                    <Box sx={styles.div[3]}>
                        <P_>{property.name}</P_>
                    </Box>
                    <Box sx={styles.div[4]}>
                        <Select_  
                            size='small'
                            sx={styles.select}
                            labelId="demo-simple-select-label" 
                            id="demo-simple-select" 
                            defaultValue={propertyValue} 
                            value={propertyValue} 
                        >
                            {property.possibleValues.map((value, index) => {
                            return <MenuItem 
                                    sx={{display: 'flex !important', flexDirection: 'column !important', paddingLeft: "4px !important", paddingRight: "4px !important"}} 
                                    onClick={handleChange} 
                                    key={index} 
                                    data-my-index={index} 
                                    value={value}
                                    data-my-value={value}>
                                        {value}
                                    </MenuItem>
                        })}</Select_>
                    </Box>
                    <Box sx={styles.div[5]}>
                        <Checkbox
                            checked={isLocked}
                            onChange={checkSendProp}
                            inputProps={{ 'aria-label': 'controlled'}}
                            checkedIcon={<Lock/>}
                            icon={<LockOpen color="secondary"/>}
                        />   
                    </Box>
                </Box>
            </Box>
        ): null
    }
    { dimension !== undefined && property !== undefined && propertyValue !== undefined ? (
        <Box 
        key={property.fabId} 
        className="card" 
        sx={styles.div[6]}
        >
            <Box sx={styles.div[7]}>
                <Box sx={styles.div[9]}>
                    <P_>{dimension.Prefix} | {property.name}</P_>
                </Box>
                <Box sx={styles.div[10]}>
                    <Select_  
                        size='small'
                        sx={styles.select} 
                        labelId="demo-simple-select-label" 
                        id="demo-simple-select" 
                        defaultValue={propertyValue} 
                        value={propertyValue} 
                    >
                        {
                            property.possibleValues.map((value, index) => {
                                return <MenuItem  
                                    sx={{ paddingLeft: "4px !important", paddingRight: "4px !important" }} 
                                    onClick={handleChange} 
                                    key={index} 
                                    data-my-index={index} 
                                    value={value} 
                                    data-my-value={value}>
                                        {value}
                                    </MenuItem>
                            })
                        }
                    </Select_>
                </Box>
                <Box sx={styles.div[11]}>
                    <Checkbox
                        checked={isLocked}
                        onChange={checkSendProp}
                        inputProps={{ 'aria-label': 'controlled'}}
                        checkedIcon={<Lock/>}
                        icon={<LockOpen color="secondary"/>}
                    />     
                </Box>      
            </Box>
        </Box> 
    ): null
    }
    </Box>
    )
}

const styles = {
    div: {
        1: {
            backgroundColor: 'white', 
            width: 290, 
            WebkitBorderRadius: '10px 10px 10px 10px', 
            MozBorderRadius: '10px 10px 10px 10px', 
            borderRadius: '10px 10px 10px 10px'
        },
        2: {display: 'flex', paddingLeft: '10px'},
        3: {display: "inline-block", width: '80px !important'},
        4: {display: "inline-block", width: '148px', paddingTop: '10px'},
        5: {display: "inline-block", paddingLeft: "5px", paddingTop: '16px'},
        6: {
            backgroundColor: 'white', 
            width: 290, 
            WebkitBorderRadius: '10px 10px 10px 10px', 
            MozBorderRadius: '10px 10px 10px 10px', 
            borderRadius: '10px 10px 10px 10px'
        },
        7: {display: 'flex', paddingLeft: '10px'} ,
        8: {display: "inline-block", width: '20px'},
        9: {display: "inline-block", width: '80px !important'},
        10: {display: "inline-block", paddingTop: '10px'},
        11: {display: "inline-block", marginLeft: '6px !important', paddingRight: '5px', paddingTop: '16px'},
    },
    p: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'},
    select: {width: '150px !important', backgroundColor: 'white !important'}
};

const P_ = styled('p')`
    white-space: nowrap !important; 
    overflow: hidden !important; 
    text-overflow: ellipsis !important;
    padding-top: 4px;
`;

const Select_ = styled(Select)`
    width: 150px !important;
    background: white !important;
    margin-right: 2px !important;
    //padding-left: .5rem;
`;