import { Box, IconButton, Toolbar, Typography } from "@mui/material";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import React, {useEffect, useState} from 'react'
import {
  catalogPartDrawerOpen,
  connectorsConfigDrawerOpen,
  createCatalogPartDrawerOpen,
  createUserDrawerOpen,
  divisionsDrawerOpen,
  ordersDrawerOpen,
  projectsDrawerOpen,
  servicesConfigDrawerOpen,
  servicesDrawerOpen,
  sizesConfigDrawerOpen,
  usersDrawerOpen,
} from 'Context/DrawerStates'
import { getCurrentUser, getUserPermissions } from "Context/currentUser";

import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { Auth } from "aws-amplify";
import { FOAppBarNavButtons } from "./FOAppBarNavButtons";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import { StyledAppBar } from "../MUIstyled";
import { styled } from '@mui/material/styles';
import { useEntity } from "simpler-state";
import { useNavigate } from 'react-router-dom';

// Inside your component

export const FOAppBar = (props) => {
  const userPermissions_key = "JxXSoSKKP^N9tot9x4qDEGZZ%L2t^vYS";
  const userPermissions = getUserPermissions(userPermissions_key);
  const user = getCurrentUser();
  const [admin, isAdmin] = useState(userPermissions.includes("AdminUsers"));
  const [superAdmin, setSuperAdmin] = useState(userPermissions.includes("SuperAdmins"));
  const isCatalogPartDrawerOpen = useEntity(catalogPartDrawerOpen);
  const isCreateCatalogPartDrawerOpen = useEntity(createCatalogPartDrawerOpen);
  const isDivisionsDrawerOpen = useEntity(divisionsDrawerOpen);
  const isOrdersDrawerOpen = useEntity(ordersDrawerOpen);
  const isProjectsDrawerOpen = useEntity(projectsDrawerOpen);
  const isServicesConfigDrawerOpen = useEntity(servicesConfigDrawerOpen);
  const isSizesConfigDrawerOpen = useEntity(sizesConfigDrawerOpen);
  const isConnectorsConfigDrawerOpen = useEntity(connectorsConfigDrawerOpen);
  const isServicesDrawerOpen = useEntity(servicesDrawerOpen);
  const isCreateUserDrawerOpen = useEntity(createUserDrawerOpen);
  const isUsersDrawerOpen = useEntity(usersDrawerOpen);

  const isAnyDrawerOpen = () => {
    return (
      isCatalogPartDrawerOpen ||
      isCreateCatalogPartDrawerOpen ||
      isDivisionsDrawerOpen ||
      isOrdersDrawerOpen ||
      isProjectsDrawerOpen ||
      isServicesConfigDrawerOpen ||
      isSizesConfigDrawerOpen ||
      isConnectorsConfigDrawerOpen ||
      isServicesDrawerOpen ||
      isCreateUserDrawerOpen ||
      isUsersDrawerOpen
    );
  };

  const open = props.open;
  const navigate = useNavigate();
  const MatchedLink = styled(Link)`
    color: #F15A29;
    text-decoration: none;
  `;
  const UnmatchedLink = styled(Link)`
    color: white;
    text-decoration: none;
  `;
  const CustomNavLink = ({ children, to, ...props }) => {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });

    if(match) {
      return (
        <div>
          <MatchedLink
            to={to}
            {...props}
          >
            {children}
          </MatchedLink>
        </div>
      );
    }
    else {
      return (
        <div>
          <UnmatchedLink
            to={to}
            {...props}
          >
            {children}
          </UnmatchedLink>
        </div>
      );
    }
  };
  const styles = {
    box: {
      1: { display: 'flex', flex: 1 }
    },
    color: {
      white: { color: "#fff"}
    },
  };
  const handleLogout = () => {
    props.setAccount(null);
    try {
      Auth.signOut({global: false});
    } catch (error) {
      console.log('error signing out: ', error);
    }
    window.location.assign("/");//.reload();
  };
  
  return (
    <StyledAppBar position="fixed" open={open}>
      <Toolbar>
        <Box sx={styles.box[1]}>
          <Box sx={{display: 'flex'}}>
            <Box sx={{paddingTop: '.25rem'}}>
            <img src="../assets/FieldOrderzLogo.png" alt="home button" />
            </Box>
            <FOAppBarNavButtons/>
          </Box>
          
        </Box>
        {
          <Box sx={{display: 'flex', marginRight: isAnyDrawerOpen() ? "380px !important" : "0px !important" }}>
            {user?.attributes?.name ? (
              <Box sx={{paddingTop:".75rem", paddingRight: "1rem"}}>
                <Typography color="white !important">{user.attributes.name}</Typography>
              </Box>
            ): null}
            {superAdmin ? (
              <IconButton
                onClick={() => {
                  navigate("/adminPanel")
                }}
                key="adminPanel"
                color="inherit"
                aria-label="open admin panel"
                edge="start"
                sx={{ mr: 2, ...(open && { display: "none" }) }}
              >
                <AdminPanelSettingsIcon color="white"/>
              </IconButton>
              ) : null
            }
            
            <IconButton 
              onClick={() => {
                window
                  .open("https://www.fieldorderz.com/support", "_blank")
                  .focus();
              }}
              key="help"
              color="white"
              aria-label="open drawer"
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}>
              <HelpOutlineOutlinedIcon />
            </IconButton>
            <IconButton 
              onClick={handleLogout}
              color="white"
              aria-label="open drawer"
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}>
              <LogoutIcon />
            </IconButton>
          </Box>
        }
        
      </Toolbar>
    </StyledAppBar>
  );
};
