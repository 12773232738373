import { ErrorTooltip, InfoTooltip } from "../../../StyleToolTips.js";
import React, { useState } from "react";

import { CircularProgress } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { importProjectsFromStratus } from "../../../data/queries/queryAPI.js";
import { styled } from "@mui/material/styles";
import { useMutation } from "react-query";
import { useQueryClient } from "react-query";

export default function ImportProjects(props) {
  const [projectsLoading, setProjectsLoading] = useState(false);
  const [projectsError, setProjectsError] = useState(false);
  const queryClient = useQueryClient();
  
  const { mutate: importProjects } = useMutation(
    () => importProjectsFromStratus(),
    {
      onSettled: (data) => {
        //setProjectsLoading(true);
        if (data.status === 200) {
          queryClient.refetchQueries(['allProjects']).then(() => {
            setProjectsLoading(false);
            setProjectsError(false);
          })
        }
      },
      onError: (error) => {
        //console.error("error mutating data:", error);
        alert("There was an error: ", error);
        setProjectsError(true);
        setProjectsLoading(false);
      },
    }
  );


  const handleImportingProjects = () => {
    if (!projectsLoading) {
      importProjects();
      setProjectsLoading(true);
    }
  };

  const Button_ = styled('button')`
    cursor: pointer;
    border-color: #F15A29;
    background: #EFEFEF;
    color: #F15A29;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 4px;
    font-weight: 400;
    text-align: center;
  `;
  const ErrorIcon_ = styled(ErrorIcon)`
    position: absolute;
    top: -.25rem;
    right: -.25rem;
    color: #ec5747;
  `;

  return (
    <>
      <div>
        <InfoTooltip title="Projects will be imported into the STRATUS Division">
          <Button_
            onClick={handleImportingProjects}
          >
            {!projectsLoading ? (
              <>
                <span>IMPORT</span>
                {projectsError && (
                  <ErrorTooltip title="An error occurred during import, please try again">
                    <ErrorIcon_/>
                  </ErrorTooltip>
                )}
              </>
            ) : (
              <CircularProgress size={"1rem"} color="inherit" />
            )}
          </Button_>
        </InfoTooltip>
      </div>
    </>
  );
}
