import React, { useState, useEffect } from "react";

// AWS IMPORTS
import { awsConfig } from "./data/constants";
import Amplify, { Auth } from 'aws-amplify';
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import "@aws-amplify/ui/dist/style.css";

// DEV ENV ONLY
import { ReactQueryDevtools } from "react-query/devtools";

// ROUTING
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// COMPONENTS TO RENDER
import { Main } from "./components/Main";
import { Login } from "components/Login/Login";

const AppWithAuthenticator = () => {
  Amplify.configure(awsConfig);
  const [authState, setAuthState] = useState();
  const [user, setUser] = useState();

  useEffect(() => {
    if (authState === undefined) {
      Auth.currentAuthenticatedUser().then((authData) => {
        setAuthState(AuthState.SignedIn);
        setUser(authData);
      });
    }

    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
      console.log("authData", authData);
    });
  }, [authState]);

  if (authState === AuthState.resettingpassword) {
    return <Login authState={authState} authData={user} />;
  }
  return authState === "signedin" && user ? (
    <Router>
      <Routes>
        <Route
          path="/*"
          element={<Main authState={authState} cognitoUser={user} />}
        />
      </Routes>
      <ReactQueryDevtools position="bottom-right" />
    </Router>
  ) : (
    <Login authState={authState} authData={user} />
  );
};

export default AppWithAuthenticator;
