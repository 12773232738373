import * as XLSX from "xlsx";

import { servicesImportIncrement, servicesImportProgress } from "Context/componentStates";

import { Auth } from "aws-amplify";
import Qs from "qs";
import axios from "axios";
import clone from "just-clone";
import fetch from "node-fetch";
import { saveAs } from "file-saver";

const API_URL = process.env.REACT_APP_API_URL; // The API Gateway base URL
axios.defaults.baseURL = API_URL;

const downloadBlob = (response, filename) => {
  saveAs(response.data, filename);
};

/** **Triggers download upon receiving presignedURL/s.**
 * 
 * 
 * @param {*} response *response from request*
 */
const saveFiletoDisk = response => {

  const urls = [...response.data.url];

  const links = [];

  urls.forEach( url => {
    let link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    links.push(link);
  })


  const popThenClick = () => {
    let link = links.pop();
    link.click();
    document.body.removeChild(link);
    if( links.length != 0 ) {
      setTimeout( () => popThenClick() , 500);
    }
  };

  popThenClick();

}

const downloadBlobCSV = (response, filename) => {
  saveAs(new Blob([response]), filename);
};
const downloadBlobXLSX = (response, filename) => {
  var data = clone(response);
  response.forEach((item, index) => {
    if(item.iconImage !== null && item.iconImage !== undefined) {
      if(String(item.iconImage).includes('data:image/png;base64,')){
        data[index].iconImage = String(item.iconImage).split(',')[1];
      }
      if(String(data[index].iconImage).length > 32000){
        data[index].iconImage = 'Image data too large'
      }
    }else{
      data[index].iconImage = 'No Image assigned'
    }
    if(item.companyId){
      delete data[index].companyId;
    }
    if(item.id){
      delete data[index].id;
    }
    if(item.properties){
      delete data[index].properties;
    }
    if(item.specification){
      delete data[index].specification;
    }
    if(item.connections){
      delete data[index].connections;
    }
    if(item.connectors){
      delete data[index].connectors;
    }
    if(item.specFabId){
      delete data[index].specFabId;
    }
    if(item.materialFabId){
      delete data[index].materialFabId;
    }
    if(item.serviceTypeFabId){
      delete data[index].serviceTypeFabId;
    }
    if(item.insulationSpecFabId){
      delete data[index].insulationSpecFabId;
    }
    if(item.materialGaugeFabId){
      delete data[index].materialGaugeFabId;
    }
    if(item.inletConnector){
      delete data[index].inletConnector;
    }
    if(item.outletConnector){
      delete data[index].outletConnector;
    }
    if(item.Insulation){
      delete data[index].Insulation;
    }
    if(item.Airturns){
      delete data[index].Airturns;
    }
    if(item.Splitters){
      delete data[index].Splitters;
    }
    if(item.Stiffeners){
      delete data[index].Stiffeners;
    }
    if(item.ProductLists){
      delete data[index].ProductLists;
    }
    if(item.manufacturer === ""){
      data[index].manufacturer = "None assigned"
    }
    if(item.material === ""){
      data[index].material = "None assigned"
    }
    if(item.packageQuantity === ""){
      data[index].packageQuantity = "1"
    }
    if(item.sizes.length <= 0){
      data[index].sizes = "No sizes"
    }else{
      data[index].sizes = data[index].sizes.join(", ");
    }
    if(item.partNumber === ""){
      data[index].partNumber = "No part number"
    }
    if(item.tab === ""){
      data[index].tab = "None assigned"
    }
    if(item.sizeUnits === ""){
      data[index].sizeUnits = "N/A"
    }

  })
  var ws = XLSX.utils.json_to_sheet(data);

  var wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Data");
  XLSX.writeFile(wb,filename);
};



///////////////////////////////////////////////////////////////////////////////
//  GENERICS
///////////////////////////////////////////////////////////////////////////////
const getAuthToken = async () => {
  try {
    const session = await Auth.currentSession();
    if (!session.isValid()) {
      console.warn("Tokens expired, session no longer valid");
    }
    return session.getIdToken().getJwtToken();
  } catch (error) {
    console.error("Could not retrieve session", error);
  }
};

///////////////////////////////////////////////////////////////////////////////
// PILOT RELATED
///////////////////////////////////////////////////////////////////////////////

export const testSES = async payload => {

  const headers = {
    Authorization: await getAuthToken(),
    "Content-Type": "application/json"
  };

  return axios.post(`/pilot/test`, {
    headers,
    //responseType: "json",
    data: JSON.stringify(payload)
  }).then( response => {
  });
};

/** Sends an Application for the Pilot Program
 * 
 * @param {*} payload 
 * @returns 
 */
export const pilotApply = async payload => {

  const headers = {
    Authorization: "NONE",
    "Content-Type": "application/json"
  };

  return axios.post(`/pilot/apply`, {
    headers,
    data: JSON.stringify(payload)
  })
};

/** Submits a determination. 
 * 
 * @param {*} payload 
 * {
 *  approval: true or false
 * }
 * @returns 
 */
export const pilotApproval = async payload => {
  const token = await getAuthToken();

  const putString = `/pilot/approve`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
    body: JSON.stringify(payload),
  });
};

export const pilotDecline = async payload => {
  const token = await getAuthToken();

  const putString = `/pilot/decline`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
    body: JSON.stringify(payload),
  });
};

/** Returns pending requests for the Pilot Trial Program
 * 
 * @param {*} payload 
 * @returns 
 */
export const getPilotQueue = async payload => {

  const headers = {
    Authorization: await getAuthToken(),
    "Content-Type": "application/json"
  };

  return axios.get(`/pilot/getApplicants`, {
    headers,
    data: JSON.stringify(payload)
  })
};


///////////////////////////////////////////////////////////////////////////////
// FILE RELATED
////////////////////?//?///////////////////////////////////////////////////////

// const getPresignedBlob = (url) => {
//   return axios.get(url, {
//     responseType: "blob",
//     paramsSerializer: (params) =>
//       Qs.stringify(params, { arrayFormat: "repeat" }),
//   });
// };

const getBlob = async (url, params, mime) => {
  const headers = {
    Authorization: await getAuthToken(),
    Accept: mime ? mime : "*/*",
  };
  return axios.get(url, {
    params,
    headers,
    responseType: "blob",
    paramsSerializer: (params) =>
      Qs.stringify(params, { arrayFormat: "repeat" }),
  });
};

async function getPickList(projectId, orderIds, orderNames) {
  return getBlob(`/projects/${projectId}/reports/pick`, {
    orderIds: orderIds,
  }).then((response) => {
    if(orderIds.length > 1){
      downloadBlob(response, "Multi-Package-picks.csv");
    }else{
      downloadBlob(response, orderNames[0] + "-picks.csv");
    }
  });
}
async function getEstimatingBOM(projectId, orderIds, orderNames){
  return getBlob(`/projects/${projectId}/reports/estimating-bom`, {
    orderIds: orderIds,
  }).then((response) => {
    if(orderIds.length > 1){
      downloadBlob(response, "Multi-Package-estimating-bom.csv");
    }else{
      downloadBlob(response, orderNames[0] + "-estimating-bom.csv");
    }
  })
}
async function getSketchBOM(projectId, orderIds, orderNames) {
  return getBlob(`/projects/${projectId}/reports/sketch-bom`, {
    orderIds: orderIds,
  }).then((response) => {
    if(orderIds.length > 1){
      downloadBlob(response, "Multi-Package-sketch-bom.csv");
    }else{
      downloadBlob(response, orderNames[0] + "-sketch-bom.csv");
    }
  });
}
async function getOverallBOM(projectId, orderIds, orderNames) {
  return getBlob(`/projects/${projectId}/reports/overall-bom`, {
    orderIds: orderIds,
  }).then((response) => {
    if(orderIds.length > 1){
      downloadBlob(response, "Multi-Package-overall-bom.csv");
    }else{
      downloadBlob(response, orderNames[0] + "-overall-bom.csv");
    }
  });
}

/** Gets presigned-URL from S3 then downloads MAJ file to client disk
 * 
 * @param {*} projectId 
 * @param {*} orderIds 
 * @returns MAJ file to client disk
 */
async function getSketchMAJ(projectId, orderIds) {

  const token = await getAuthToken();

  const getURL = async (url, params, mime) => {

    const headers = { Authorization: token };

    return axios.get(url, {
      headers,
      responseType: "json",
    });
  };

  return getURL(`/projects/${projectId}/orders/${orderIds}/sketch-maj`)
    .then( response => {
      saveFiletoDisk(response);
    });
}

/** Gets presigned-URL from S3 then downloads PCF file to client to disk
 * 
 * @param {*} projectId 
 * @param {*} orderIds 
 * @returns PCF file to client disk
 */
async function getSketchPCF(projectId, orderIds) {

  const token = await getAuthToken();
  const getURL = async (url, params, mime) => {
    const headers = {
      Authorization: token,
    };

    return axios.get(url, {
      headers,
      responseType: "json",
    });
  };
  return getURL(`/projects/${projectId}/orders/${orderIds}/sketch-pcf`)
    .then( response => {
      saveFiletoDisk(response);
    })
}

async function getCutList(projectId, orderIds, orderNames) {
  return getBlob(`/projects/${projectId}/reports/cut`, {
    orderIds: orderIds,
  }).then((response) => {
    if(orderIds.length > 1){
      downloadBlob(response, "Multi-Package-cuts.csv");
    }else{
      downloadBlob(response, orderNames[0] + "-cuts.csv");
    }
  });
}
async function getPDFExport(projectId, orderIds, orderNames) {
  return getBlob(
    `/projects/${projectId}/reports/pdf`,
    {
      orderIds: orderIds,
    },
    "application/pdf"
  ).then((response) => {
    if(orderIds.length > 1){
      downloadBlob(response, "Multi-Package.pdf");
    }else{
      downloadBlob(response, orderNames[0] + ".pdf");
    }
  });
}

///////////////////////////////////////////////////////////////////////////////
//  DIVISION RELATED
///////////////////////////////////////////////////////////////////////////////

async function getDivision(divisionId) {
  const params = {};
  const token = await getAuthToken();
  const headers = {
    Authorization: token,
  };
  return axios
    .get(`/divisions/${divisionId}`, { params, headers })
    .catch((err) => {
      throw err;
    });
}

async function getDivisions() {
  const params = {};
  const token = await getAuthToken();
  const headers = {
    Authorization: token,
  };
  return axios.get("/divisions", { params, headers }).catch((err) => {
    throw err;
  });
}
async function createDivision(payload) {
  const token = await getAuthToken();

  const putString = "divisions";
  const newDivision = await fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
    body: JSON.stringify(payload),
  }).then((res) => {
    const data = res.json();
    return data;
  })
  // .then((data) => {
  //   return data
  // })
  .catch((err) => {
    console.error(err);
    throw err;
  });
  return newDivision;
}

async function updateDivision(payload) {
  const token = await getAuthToken();

  const putString = `divisions/${payload.divisionId}`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
    body: JSON.stringify(payload.data),
  });
}

async function softDeleteDivision(divisionId) {
  const token = await getAuthToken();

  const putString = `divisions/${divisionId}/delete`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
  });
}

async function hardDeleteDivision(divisionId) {
  const token = await getAuthToken();
  const putString = `divisions/${divisionId}`;
    return fetch(API_URL + putString, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      method: "DELETE",
    });
}

async function restoreDivision(divisionId) {
  const token = await getAuthToken();

  const putString = `divisions/${divisionId}/restore`;
  //return this.post(`/divisions/${divisionId}/restore`);
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST"
  });
};

async function assignUserDivision(payload) {
  const token = await getAuthToken();
  const putString = `/mappings/user-division-map?userId=${payload.userId}&divisionId=${payload.divisionId}`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "text/plain",
      Authorization: token,
    },
    method: "PUT",
  });
}

async function unassignUserDivision(payload) {
  const token = await getAuthToken();

  const putString = `/mappings/user-division-map?userId=${payload.userId}&divisionId=${payload.divisionId}`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "DELETE",
  });
}
///////////////////////////////////////////////////////////////////////////////
//  CATALOG RELATED
///////////////////////////////////////////////////////////////////////////////

async function getCatalogs() {
  const params = {};
  const token = await getAuthToken();
  const headers = {
    Authorization: token,
  };
  return axios.get("/catalogs", { params, headers }).catch((err) => {
    throw err;
  });
}

async function getCatalogItems(catalogId) {
  const params = {};
  const token = await getAuthToken();
  const headers = {
    Authorization: token,
  };
  return axios
    .get(`/catalogs/${catalogId}/items`, { params, headers })
    .catch((err) => {
      throw err;
    });
}

const getCatalogsWithItems = async () => {
  const catalogs = await getCatalogs();
  // return await Promise.all(
  //   catalogs.data.map(async (catalog) => {
  //     const catalogItems = await getCatalogItems(catalog.id);
  //     const catalogWithItems = catalog;
  //     catalogWithItems.items = catalogItems.data;
  //     return catalogWithItems;
  //   })
  // );
  const catalogsWithItems = await Promise.all(
    catalogs.data.map(async (catalog) => {
      const catalogItems = await getCatalogItems(catalog.id);
      const catalogWithItems = catalog;
      catalogWithItems.itemDetails = catalogItems.data;

      return catalogWithItems;
    })
  );

  return catalogsWithItems;
};

async function getCatalogsAndIcons() {
  return getCatalogs().then(async (resp) => {
    const catalogs = resp.data;
    const imageFetches = [];
    catalogs.forEach((catalog) => {
      catalog.items.forEach(async (item) => {
        if (item.iconImage) {
          imageFetches.push(
            this.getPresignedBlob(item.iconImage).then(
              (successfulResp) =>
                (item.iconImage = URL.createObjectURL(successfulResp.data)),
              (failureReason) => (item.iconImage = null)
            )
          );
        }
      });
    });
    await Promise.all(imageFetches);
    return resp;
  });
}

async function createCatalogItem(payload) {
  const token = await getAuthToken();

  const putString = `catalogs/${payload.catalogId}/items/`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
    body: JSON.stringify(payload.data),
  });
}

async function updateCatalogItem(payload) {
  const token = await getAuthToken();

  const putString = `catalogs/${payload.catalogId}/items/${payload.itemId}`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
    body: JSON.stringify(payload.data),
  });
}

async function renameCatalog(payload) {
  const token = await getAuthToken();

  const putString = `catalogs/${payload.catalogId}`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
    body: JSON.stringify(payload.data),
  });
}

async function exportCatalog({catalogId, catalogName}) {
  const token = await getAuthToken();
  const getBlob = async (url, params, mime) => {
    const headers = {
      Authorization: token,
    };

    return axios.get(url, {
      // params,
      headers,
      //responseType: "blob",
      // paramsSerializer: (params) =>
      //   Qs.stringify(params, { arrayFormat: "repeat" }),
    });
  };

  return getBlob(`/catalogs/${catalogId}/export`, {}).then((resp) => {
    downloadBlobCSV(resp.data['csv'], `${catalogName}.csv`);
    downloadBlobXLSX(resp.data['items'], `${catalogName}.xlsx`)
  });
}

async function duplicateCatalog(payload) {
  const token = await getAuthToken();

  const putString = "catalogs/";
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
    body: JSON.stringify(payload),
  });
}

async function deleteCatalog(catalogId) {
  const token = await getAuthToken();

  const putString = `catalogs/${catalogId}`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "DELETE",
  });
}

async function deleteCatalogItem(payload){
  const token = await getAuthToken();
  const putString = `catalogs/${payload.catalogId}/items/${payload.itemId}`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "DELETE",
  });
}

async function getCatalogImportStatus(catalogId) {
  const params = {};
  const token = await getAuthToken();
  const headers = {
    Authorization: token,
  };

  return axios
    .get(`/imports/catalogs/${catalogId}`, { params, headers })
    .catch((err) => {
      throw err;
    });
}

async function importCatalog(payload) {
  const token = await getAuthToken();
  const timeAtFirstRun = Date.now();

  //POLLING FUNCTION
  const promisePoll = (promiseFunction, { pollIntervalMs = 10000 } = {}) => {
    const startPoll = async (resolve) => {
      const startTime = new Date();
      const result = await promiseFunction();

      // HANDLE THE API RESPONSE
      if (result.status === 200 && result.data.status === "success")
        return resolve("SUCCESS");
      if (result.data.status === "error") return resolve("ERROR");
      if (Date.now() > timeAtFirstRun + 600000) {
        return resolve("TIMEOUT");
      }

      const timeUntilNext = Math.max(
        pollIntervalMs - (new Date() - startTime),
        0
      );

      setTimeout(() => startPoll(resolve), timeUntilNext);
    };

    return new Promise(startPoll);
  };

  const resp = await fetch(API_URL + "catalogs", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
    body: JSON.stringify({
      name: payload.catalogName,
      catalog_type: payload.catalogType,
      source: "import",
    }),
  }).then(async (res) => {
    const data = await res.json();
    return data;
  });

  // Expect a presigned s3 PUT URL in successful response; upload zip to the URL
  await fetch(resp.importURL, {
    headers: {
      Accept: "application/json",
      "Content-Type": payload.zippedSource.type,
    },
    method: "PUT",
    body: payload.zippedSource,
  });

  const importId = resp.id;

  const finalStatus = await promisePoll(() => getCatalogImportStatus(importId));

  return finalStatus;
}

//   getCatalog = (catalogId) => {
//     return this.get(`/catalogs/${catalogId}`, {});
//   };

//   updateCatalog = (catalogId, data) => {
//     return this.post(`/catalogs/${catalogId}`, { ...data });
//   };

///////////////////////////////////////////////////////////////////////////////
//  PROJECT RELATED
///////////////////////////////////////////////////////////////////////////////

async function createProject(payload) {
  const token = await getAuthToken();

  const putString = "projects/";
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
    body: JSON.stringify(payload),
  }).then(response => {
    if (!response.ok) {
      throw new Error('Error creating new project');
    }
    return response.json();
  });;
}

async function getProject(projectId) {
  if (!projectId) {
    return {};
  }
  const params = {};
  const token = await getAuthToken();
  const headers = {
    Authorization: token,
  };
  return axios
    .get(`/projects/${projectId}`, { params, headers })
    .catch((err) => {
      throw err;
    });
}

async function getProjects() {
  const params = {};
  const token = await getAuthToken();
  const headers = {
    Authorization: token,
  };
  return axios.get("/projects", { params, headers }).catch((err) => {
    throw err;
  });
}

async function renameProject(payload) {
  const token = await getAuthToken();

  const putString = `projects/${payload.projectId}`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
    body: JSON.stringify(payload.data),
  });
}

async function updateProject(payload) {
  const token = await getAuthToken();

  const putString = `projects/${payload.id}`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
    body: JSON.stringify(payload),
  });
}

async function deleteProject(projectId) {
  //HARD DELETE
  const token = await getAuthToken();

  const putString = `projects/${projectId}`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "DELETE",
  });
}

async function postDeleteProject(projectId) {
  //SOFT DELETE
  const token = await getAuthToken();

  const putString = `projects/${projectId}/delete`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
  });
};

async function restoreProject(projectId) {
  const token = await getAuthToken();
  const putString = `projects/${projectId}/restore`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
  });
}

async function assignCatalogProject(payload) {
  const token = await getAuthToken();

  const putString = `mappings/catalog-project-map?catalogId=${payload.catalogId}&projectId=${payload.projectId}`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "text/plain",
      Authorization: token,
    },
    method: "PUT",
  });
}

async function unassignCatalogProject(payload) {
  const token = await getAuthToken();

  const putString = `mappings/catalog-project-map?catalogId=${payload.catalogId}&projectId=${payload.projectId}`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "text/plain",
      Authorization: token,
    },
    method: "DELETE",
  });
}

async function assignUserProject(payload) {
  const token = await getAuthToken();

  const putString = `mappings/user-project-map?userId=${payload.userId}&projectId=${payload.projectId}`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "text/plain",
      Authorization: token,
    },
    method: "PUT",
  });
}

async function unassignUserProject(payload) {
  const token = await getAuthToken();

  const putString = `mappings/user-project-map?userId=${payload.userId}&projectId=${payload.projectId}`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "text/plain",
      Authorization: token,
    },
    method: "DELETE",
  });
}

async function assignServiceProject(payload) {
  const token = await getAuthToken();

  const putString = `mappings/service-project-map?serviceId=${payload.serviceId}&projectId=${payload.projectId}`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "text/plain",
      Authorization: token,
    },
    method: "PUT",
  });
}

async function unassignServiceProject(payload) {
  const token = await getAuthToken();

  const putString = `mappings/service-project-map?serviceId=${payload.serviceId}&projectId=${payload.projectId}`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "text/plain",
      Authorization: token,
    },
    method: "DELETE",
  });
}

///////////////////////////////////////////////////////////////////////////////
//  PACKAGE VIEWER RELATED
///////////////////////////////////////////////////////////////////////////////

async function getPackageViewers(projectId, orderId) {
  if(projectId !== undefined && orderId !== undefined) {
    const params = {};
    const token = await getAuthToken();
    const headers = {
      Authorization: token,
    };

      return axios
      .get(`/projects/${projectId}/orders/${orderId}/viewers`, {
        params,
        headers,
      })
      .catch((err) => {
        console.error("Error getting packageViewers", err);
        throw err;
      });
  }else{
    return console.log("Project Id or Order Id is missing")
  }


}

async function createUserPackageViewer(payload) {
  const token = await getAuthToken();

  const putString = `/mappings/user-viewer-map?userId=${payload.userId}&projectId=${payload.projectId}&packageId=${payload.packageId}`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
  });
}

async function deleteUserPackageViewer(payload) {
  const token = await getAuthToken();

  const putString = `mappings/user-viewer-map?userId=${payload.userId}&packageId=${payload.packageId}`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "DELETE",
  });
}

async function assignViewOnlyUser(payload) {
  const token = await getAuthToken();

  const putString = `/mappings/user-view-only-map?userId=${payload.userId}&projectId=${payload.projectId}`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
  });
}

async function unassignViewOnlyUser(payload) {
  const token = await getAuthToken();

  const putString = `mappings/user-view-only-map?userId=${payload.userId}&projectId=${payload.projectId}`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "DELETE",
  });
}
// async function importProjectsFromStratus() {
//   const token = await getAuthToken();
//   const postString = `stratus/imports/projects`;
//   const request = await fetch(API_URL + postString, {
//     method: "POST",
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json",
//       Authorization: token,
//     },
//     type: "text/plain",
//   });
//   return request;
// }

async function updateOrder(payload) {
  const token = await getAuthToken();

  const putString = `/projects/${payload.projectId}/orders/${payload.packageId}`;
  const request = await fetch(API_URL + putString, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(payload.updateObject),
  }).then((res) => {
    const data = res.json();
    return data;
  })
  .catch((err) => {
    console.error(err);
    throw err;
  });
  return request;
}

///////////////////////////////////////////////////////////////////////////////
//  USER RELATED
///////////////////////////////////////////////////////////////////////////////

// getUsers Object
//  {
//    id: "",
//    divisions: [],
//    projects: [],
//    name: "",
//    username: "",
//    companyId: ""
//  }
async function getUsers() {
  const params = {};
  const token = await getAuthToken();
  const headers = {
    Authorization: token,
  };
  return axios.get("/users", { params, headers }).catch((err) => {
    throw err;
  });
}

// getCognitoUsers Object
//  {
//    id: "",
//    username: "",
//    email: "",
//    name: "",
//    enabled: bool,
//    status: "",
//    groups: "",
//    accesses: {}
//  }
async function getCognitoUsers() {
  const params = {};
  const token = await getAuthToken();
  const headers = {
    Authorization: token,
  };
  return axios.get("cognito/users", { params, headers }).catch((err) => {
    throw err;
  });
}

//  From getUsers
//    id: "",
//    divisions: [],
//    projects: [],
//    companyId: "",
//    name: "",
//  From getCognitoUsers
//    enabled: bool,
//    status: "",
//    groups: "",
//    accesses: {},
//    username: "",
//    email: "",
//  }
const getUsersWithCognitoData = async () => {
  const users = await getUsers();
  const cognitoUsers = await getCognitoUsers();
  const newUsers = users.data.map((user) => {
    const cognitoUser = cognitoUsers?.data?.find(
      (cognitoUser) => cognitoUser.id === user.id
    );
    if(cognitoUser){
      const newUser = {
        id: user.id,
        divisions: user.divisions,
        projects: user.projects,
        viewOnly: [...user.viewOnly],
        companyId: user.companyId,
        name: user.name,
        enabled: cognitoUser?.enabled,
        status: cognitoUser?.status,
        groups: cognitoUser?.groups,
        accesses: cognitoUser?.accesses,
        username: cognitoUser?.username,
        email: cognitoUser?.email,
      };
      return newUser;
    }

  });

  return newUsers;
};

const createUser = async (payload) => {
  const token = await getAuthToken();
  const putString = `cognito/users/`;

  const newUser = await fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
    body: JSON.stringify(payload),
  })
    .then((res) => {
      const data = res.json();
      return data;
    })
    // .then((data) => {
    //   return data
    // })
    .catch((err) => {
      console.error(err);
      throw err;
    });

  return newUser;
};

const createMultiUser = async (payload) => {
  const token = await getAuthToken();
  const putString = `cognito/multi-users/`;

  const newUser = await fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
    body: JSON.stringify(payload),
  })
    .then((res) => {
      const data = res.json();
      return data;
    })
    // .then((data) => {
    //   return data
    // })
    .catch((err) => {
      console.error(err);
      throw err;
    });

  return newUser;
};

const updateLocalUser = async (payload) => {
  const token = await getAuthToken();
  const putString = `/users/${payload.id}`;
  const user = await fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
    body: JSON.stringify(payload),
  }).then((res) => {
    const data = res.json();
    return data;
  })
  // .then((data) => {
  //   return data
  // })
  .catch((err) => {
    console.error(err);
    throw err;
  });
  return user;
};

const updateCognitoUser = async (payload) => {
  const token = await getAuthToken();
  const putString = `/cognito/users/${payload.id}`;
  const user = await fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
    body: JSON.stringify(payload),
  }).then((res) => {
    const data = res.json();
    return data;
  })
  // .then((data) => {
  //   return data
  // })
  .catch((err) => {
    console.error(err);
    throw err;
  });
  return user;
};

const updateUser = async (payload) => {
  const localUserBody = {};
  localUserBody.id = payload.id;
  localUserBody.username = payload.username;
  localUserBody.email = payload.email;
  localUserBody.name = payload.name;
  localUserBody.enabled = payload.enabled;
  localUserBody.status = payload.status;
  localUserBody.groups = payload.groups;
  localUserBody.accesses = payload.accesses;

  const cognitoUserBody = {};
  cognitoUserBody.id = payload.id;
  cognitoUserBody.username = payload.username;
  cognitoUserBody.email = payload.email;
  cognitoUserBody.name = payload.name;
  cognitoUserBody.enabled = payload.enabled;
  cognitoUserBody.status = payload.status;
  cognitoUserBody.groups = payload.groups;
  cognitoUserBody.accesses = payload.accesses;
  const promiseArray = [
    updateLocalUser(localUserBody),
    updateCognitoUser(cognitoUserBody),
  ];
  const results = await Promise.all(promiseArray);

  const returnArray = results.map((res) => {
    return res.status;
  });
  return returnArray;
};

async function reinviteCognitoUser(userId) {
  const token = await getAuthToken();

  const putString = `cognito/users/${userId}/reinvite`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
  });
}

async function setCognitoUserStatus(payload) {
  const token = await getAuthToken();

  const putString = `cognito/users/${payload.userId}`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
    body: JSON.stringify({ enabled: payload.enabled }),
  });
}

async function deleteCognitoUser(userId) {
  const token = await getAuthToken();

  const putString = `cognito/users/${userId}`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "DELETE",
  });
}

///////////////////////////////////////////////////////////////////////////////
//  COMPANY RELATED
///////////////////////////////////////////////////////////////////////////////
async function getStatuses(companyId) {
  const params = {};
  const token = await getAuthToken();
  const headers = {
    Authorization: token,
  };
  return axios
    .get(`companies/${companyId}/statuses`, { params, headers })
    .catch((err) => {
      throw err;
    });
}






///////////////////////////////////////////////////////////////////////////////
//  ORDER RELATED
///////////////////////////////////////////////////////////////////////////////

async function getOrder(payload){
  const params = {
    archived: payload.archived,
  };
  const token = await getAuthToken();
  const headers = {
    Authorization: token,
  };
  return axios
    .get(`/projects/${payload.projectId}/orders/${payload.orderId}`, {
      params,
      headers,
    })
    .catch((err) => {
      throw err;
    })
}

async function getOrdersFromProject(projectId, qs) {
  const params = {};
  const token = await getAuthToken();
  const headers = {
    Authorization: token,
  };
  return axios
    .get(`/projects/${projectId}/orders`, {
      params,
      headers,
    })
    .catch((err) => {
      throw err;
    });
}

async function archivePackage(payload){
  const token = await getAuthToken();
  const putString = `projects/${payload.projectId}/orders/${payload.orderId}/archive`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
    body: JSON.stringify(payload),
  });
}

async function unarchivePackage(payload){
  const token = await getAuthToken();
  const putString = `projects/${payload.projectId}/orders/${payload.orderId}/unarchive`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
    body: JSON.stringify(payload),
  });
}



async function deletePackage(payload){
  const token = await getAuthToken();
  const putString = `projects/${payload.projectId}/orders/${payload.orderId}`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "DELETE",
    body: JSON.stringify(payload),
  });
};

async function softDeletePackage(payload){
  const token = await getAuthToken();
  const putString = `projects/${payload.projectId}/orders/${payload.packageId}/delete`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
  });
};

async function restorePackage(payload){
  const token = await getAuthToken();
  const putString = `projects/${payload.projectId}/orders/${payload.packageId}/restore`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
  });
};


async function getCompanies() {
  const token = await getAuthToken();
  const headers = {
    Authorization: token,
  };
  return axios.get("/companies", { headers }).catch((err) => {
    throw err;
  });
}

async function getCompany(companyId) {
  if (!companyId) {
    return {};
  }
  const params = {};
  const token = await getAuthToken();
  const headers = {
    Authorization: token,
  };
  return axios
    .get(`/companies/${companyId}`, { params, headers })
    .catch((err) => {
      throw err;
    });
}

async function updateCompany(payload) {
  const token = await getAuthToken();
  const putString = `companies/${payload.companyId}`;
  return fetch(API_URL + putString, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(payload.data),
  });
}

async function getStratusStatuses() {
  const token = await getAuthToken();
  const headers = {
    Authorization: token,
  };
  return axios.get(`/stratus/tracking-statuses`, { headers }).catch((err) => {
    throw err;
  });
}
async function importStratusStatuses() {
  const token = await getAuthToken();
  const postString = `stratus/imports/statuses`;
  const request = await fetch(API_URL + postString, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    type: "text/plain",
  });
  return request;
}

async function getCompanyStatuses(){
  const token = await getAuthToken();
  const headers = {
    Authorization: token,
  };
  return axios.get(`/statuses`, { headers }).catch((err) => {
    throw err;
  });
}

async function getCompanyExpirationDate(companyId){
  if (!companyId) {
    return {};
  }
  const params = {};
  const token = await getAuthToken();
  const headers = {
    Authorization: token,
  };
  return axios
    .get(`/companies/${companyId}/expiration`, { params, headers })
    .catch((err) => {
      throw err;
    });
}

async function getCompanySettings(companyId){
  if (!companyId) {
    return {};
  }
  const params = {};
  const token = await getAuthToken();
  const headers = {
    Authorization: token,
  };
  return axios
    .get(`/companies/${companyId}/settings`, { params, headers })
    .catch((err) => {
      throw err;
    });
}

async function updateCompanySettings(payload){
  const token = await getAuthToken();
  const postString = `/companies/${payload.companyId}/settings`
  const request = await fetch(API_URL + postString,{
    method: "POST",
    headers:{
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    type: "text/plain",
    body: JSON.stringify(payload)
  })
  return request;
}

async function createCompanyStatus(payload){
  const token = await getAuthToken();
  const headers = {
    Authorization: token,
  };
  const postString = `/statuses`
  const request = await fetch(API_URL + postString,{
    method: "POST",
    headers:{
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    type: "text/plain",
    body: JSON.stringify(payload)
  })
  return request;
}

async function updateCompanyStatuses(payload){
  const token = await getAuthToken();
  const putString = `/updateStatuses`
  const request = await fetch(API_URL + putString, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    type: "text/plain",
    body: JSON.stringify(payload)
  })
  return request;
}

async function moveCompanyStatuses(payload){
  const token = await getAuthToken();
  const putString = `/moveStatus`
  const request = await fetch(API_URL + putString, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    type: "text/plain",
    body: JSON.stringify(payload)
  })
  return request;
}


async function deleteCompanyStatus(payload){
  const token = await getAuthToken();
  const deleteString = `/statuses`
  const request = await fetch(API_URL + deleteString, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    type: "text/plain",
    body: JSON.stringify(payload)
  })
  return request;
}

async function testStratusStatusConnection() {
  const token = await getAuthToken();
  const putString = `/stratus/stratus-connection-status`;
  const response = await fetch(API_URL + putString, {
    headers: {
      Authorization: token,
    },
  });
  return {status: response.status};
}

async function stratusSetUploadStatus(payload) {
  const token = await getAuthToken();
  const putString = `/stratus/tracking-statuses/default-upload-status?statusId=${payload.data.statusId}`;
  const response = await fetch(API_URL + putString, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    type: "text/plain",
    //body: JSON.stringify(payload.data),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
}

async function importProjectsFromStratus() {
  const token = await getAuthToken();
  const postString = `stratus/imports/projects`;
  const request = await fetch(API_URL + postString, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    type: "text/plain",
  });
  return request;
}

async function updateUploadKey(payload) {
  const token = await getAuthToken();
  const putString = `/stratus/app-key`;
  const request = await fetch(API_URL + putString, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      Authorization: token,
    },
    body: JSON.stringify(payload.data),
  });

  return request;
}



const getOrders = async () => {
  const divisions = await getDivisions();
  const projects = await getProjects();
  //COLLECT THE API DATA
  const externalCollector = [];
  const divisionProjects = await divisions.data.map(async (division) => {
    const activeProjectsInDivision = projects.data.filter(
      (proj) => proj.divisionId === division.id && !proj?.deleteTime
    );
    const projectsCollector = [];
    for (let project of activeProjectsInDivision) {
      const packagesQuery = await getOrdersFromProject(project.id);
      let packageItemsArray = [];
      Object.keys(packagesQuery.data).forEach((key) => {
        return packageItemsArray.push(packagesQuery.data[key]);
      });
      // Resolve projects' userIds into user objects from the project's parent division fetch
      project = {
        ...project,
        packageItems: packageItemsArray,
        users: project.users
          .map((userId) => division.users.find((user) => user.id === userId))
          .filter((user) => user && !user?.deleteTime),
      };
      projectsCollector.push(project);
    }
    const divisionProjects = {
      name: division.name,
      id: division.id,
      children: projectsCollector,
    };
    externalCollector.push(divisionProjects);
  });

  //ITERATE OVER THE API DATA TO BUILD THE DIVISIONS ARRAY OF PROJECTS WITH A DIVISIONS PROPERTY
  return Promise.all(divisionProjects).then(() => {
    //division project orders(divisionPos)
    Object.keys(externalCollector).forEach((divisionPos) => {
      const divisionProjects = externalCollector[divisionPos].children;
      // MAP OVER THE PROJECTS IN THE DIVISION TO CREATE AN ARRAY OF ORDERS TO SET AS CHILDREN
      const projectWithOrders = divisionProjects.map((project) => {
        //SET THE EMPTY OBJECT
        const projectOrdersObj = {};
        //ITERATE OVER THE PACKAGE ITEMS TO REBUILD THE ARRAY WITH APPROPRIATE NAMING CONVENTIONS
        project.packageItems.forEach((packageItem) => {
          if (projectOrdersObj[packageItem.orderData.package]) {
            projectOrdersObj[packageItem.orderData.package].push(packageItem);
          } else {
            projectOrdersObj[packageItem.orderData.package] = [packageItem];
          }
        });
        const projectOrdersArray = [];
        for (const [key, value] of Object.entries(projectOrdersObj)) {
          let orderPackagesArray = value.map((packageItem) => {
            return {
              name: packageItem.orderData.name,
              id: packageItem.id,
              data: packageItem,
              isStratusPackage: project.stratusModelId ? true : false,
            };
          });
          orderPackagesArray.push("packages");
          let orderObj = {};
          orderObj.name = key;
          orderObj.id = project.id + "," + key;
          orderObj.children = orderPackagesArray;
          orderObj.parentProject = project;
          orderObj.isStratusOrder = project.stratusModelId ? true : false;
          projectOrdersArray.push(orderObj);
        }
        project.children = projectOrdersArray;
        return project;
      });
      externalCollector[divisionPos].children = projectWithOrders;
    });
    return externalCollector;
  });
};

///////////////////////////////////////////////////////////////////////////////
//  SERVICE RELATED
///////////////////////////////////////////////////////////////////////////////

async function getService(serviceId) {
  const params = {};
  const token = await getAuthToken();
  const headers = {
    Authorization: token,
  };
  return axios
    .get(`/services/${serviceId}`, { params, headers })
    .catch((err) => {
      throw err;
    });
}
async function getServices() {
  const params = {};
  const token = await getAuthToken();
  const headers = {
    Authorization: token,
  };
  return axios.get("/services", { params, headers }).catch((err) => {
    throw err;
  });
}

async function getServicesWithGroups() {
  const token = await getAuthToken();
  const headers = {
    Authorization: token,
  };
  return axios.get("/services-with-groups", { headers }).catch((err) => {
    throw err;
  });
}

async function newGetServicesWithGroups(serviceIdsArray) {
  const token = await getAuthToken();
  const headers = {
    Authorization: token,
  };
  if (serviceIdsArray?.length > 0) {
    const promiseArray = serviceIdsArray.map((serviceId) => {
      return axios.get(`/services-with-groups/${serviceId}`, { headers });
    });

    const ret = await Promise.all(promiseArray)
      .then((results) => {
        const collector = results.map((result) => {
          return result.data;
        });
        return collector;
      })
      .catch((err) =>
        console.error("error when fetching multiple services: ", err)
      );
    return ret;
  } else {
    return "no services to fetch";
  }
}

async function stratusGetFileGroups() {
  const token = await getAuthToken();
  const headers = {
    Authorization: token,
  };

  return axios.get("/stratus/filegroups", { headers });
}

async function stratusGetProfilesForFileGroup(payload){
  if(payload.fileGroupId){
    const token = await getAuthToken();
    const getString = "stratus/profiles";
    var url = new URL(API_URL + getString);

    Object.keys(payload).forEach((key) =>{
      url.searchParams.append(key, payload[key])
    });

    const res = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    const resAsJson = await res.json();
    return resAsJson;
  }else{
    return console.log("[ERROR] File Group is null, select a database");
  }
}

async function stratusGetServicesForFileGroup(payload) {
  if(payload.fileGroupId){
    const token = await getAuthToken();
    const getString = "/stratus/services";
    var url = new URL(API_URL + getString);

    Object.keys(payload).forEach((key) =>
      url.searchParams.append(key, payload[key])
    );

    const res = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    const resAsJson = await res.json();
    return resAsJson;
  }else{
    return console.log("[ERROR] File Group is null, select a database");
  }
}

async function getServiceImportStatus(serviceId) {
  const token = await getAuthToken();
  const headers = {
    Authorization: token,
  };
  const res = await axios
    .get(`/imports/services/${serviceId}`, { headers })
    .catch((err) => {
      console.error("error fetching import status of service", serviceId);
    });
  return res;
}

async function importStratusService(serviceObj) {
  const token = await getAuthToken();
  const timeAtFirstRun = Date.now();

  //POLLING FUNCTION
  const promisePoll = (promiseFunction, { pollIntervalMs = 10000 } = {}) => {
    const startPoll = async (resolve) => {
      const startTime = new Date();
      const result = await promiseFunction();

      // HANDLE THE API RESPONSE
      if (result?.status === 200 && result?.data?.status === "success") {
        servicesImportProgress.set( servicesImportProgress.get() + servicesImportIncrement.get() );
        return resolve("SUCCESS");
      }
      if (result?.data?.status === "error") return resolve("ERROR");
      if (Date.now() > timeAtFirstRun + 600000) {
        return resolve("TIMEOUT");
      }

      const timeUntilNext = Math.max(
        pollIntervalMs - (new Date() - startTime),
        0
      );

      setTimeout(() => startPoll(resolve), timeUntilNext);
    };

    return new Promise(startPoll);
  };

  const putString = "imports/services";
  return fetch(API_URL + putString, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(serviceObj),
  })
    .then((res) => {
      return res.json();
    })
    .then(async (data) => {
      const finalStatus = await promisePoll(() =>
        getServiceImportStatus(data.id)
      );
      return finalStatus;
    })
    .catch((err) => {
      console.error("error:", err);
    });
}

async function importStratusServicesArray(servicesArray) {
  if(servicesArray.length > 0) {
      const promiseArray = servicesArray.map((serviceObj) => {
      return importStratusService(serviceObj);
    });

    const results = await Promise.all(promiseArray)
      .then((results) => {
        const collector = results.map((result) => {
          return result;
        });
        return collector;
      })
      .catch((err) => console.error("error when importing services: ", err));
    return results;
  }else{
    console.log("No Services to Import")
  }

}

async function convertServiceToCatalog(serviceId) {
  const token = await getAuthToken();

  const putString = `services/${serviceId}/convert`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
  });
}

async function deleteService(serviceId) {
  const token = await getAuthToken();

  const putString = `services/${serviceId}`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "DELETE",
  });
}

function duplicateService(serviceId, data) {
  return this.post(`/services`, { ...data, source: serviceId });
}

async function renameService(payload) {
  const token = await getAuthToken();

  const putString = `services/${payload.serviceId}`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
    body: JSON.stringify(payload.data),
  });
}

async function updateServiceAccessory(payload) {
  const token = await getAuthToken();

  const putString = `services/${payload.serviceId}/groups/${payload.groupId}`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
    body: JSON.stringify(payload.data),
  });
}

async function updateServiceConnectionType(payload){
  const token = await getAuthToken();
  
  const putString = `services/${payload.id}/alias`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type" : "application/json",
      Authorization: token,
    },
    method: "POST",
    body: JSON.stringify(payload.data)
  })
}

async function updateServiceDrawable(payload){
  const token = await getAuthToken();
  const putString = `/services/${payload.id}/drawable`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type" : "application/json",
      Authorization: token,
    },
    method: "POST",
    body: JSON.stringify(payload.data)
  })
}

///////////////////////////////////////////////////////////////////////////////
//  STRATUS RELATED
///////////////////////////////////////////////////////////////////////////////

async function stratusSyncPackage(payload) {
  const token = await getAuthToken();

  const putString = `projects/${payload.projectId}/orders/${payload.packageId}/stratus-pull`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
    //body: JSON.stringify(payload),
  }).then((res) => {
    const data = res.json();
    return data;
  })
  .catch((err) => {
    console.error(err);
    throw err;
  });
}

async function stratusUploadPackage(payload) {
  const token = await getAuthToken();

  const putString = `projects/${payload.projectId}/orders/${payload.packageId}/stratus-commit`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
    // body: JSON.stringify(payload),
  });
}

async function StratusPackageUpload(payload) {
  const token = await getAuthToken();

  const putString = `projects/${payload.projectId}/orders/${payload.packageId}/stratus-commit-package`;
  const response = await fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
  });

  if (!response.ok) {
    throw new Error(`Stratus package upload failed: ${response.status}`);
  }

  const responseBody = await response.json();
  return responseBody;
}

async function StratusPackagePDFUpload(payload) {
  const token = await getAuthToken();

  const putString = `projects/${payload.projectId}/orders/${payload.packageId}/stratus-commit-pdf`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
    // body: JSON.stringify(payload),
  });
}

async function StratusPackageMAJUpload(payload) {
  const token = await getAuthToken();

  const putString = `projects/${payload.projectId}/orders/${payload.packageId}/stratus-commit-maj`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
    // body: JSON.stringify(payload),
  });
}

async function StratusPackagePCFUpload(payload) {
  const token = await getAuthToken();

  const putString = `projects/${payload.projectId}/orders/${payload.packageId}/stratus-commit-pcf`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
    // body: JSON.stringify(payload),
  });
}

async function StratusPackageBOMUpload(payload) {
  const token = await getAuthToken();

  const putString = `projects/${payload.projectId}/orders/${payload.packageId}/stratus-commit-bom`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
    // body: JSON.stringify(payload),
  });
}

async function StratusPackageCUTUpload(payload) {
  const token = await getAuthToken();

  const putString = `projects/${payload.projectId}/orders/${payload.packageId}/stratus-commit-cut`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
    // body: JSON.stringify(payload),
  });
}

async function StratusPackageTakeoffMAJUpload(payload) {
  const token = await getAuthToken();

  const putString = `projects/${payload.projectId}/orders/${payload.packageId}/stratus-commit-maj-takeoff`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
    body: JSON.stringify(payload.data),
  });
}

async function GetPackageGeometryFiles(payload){
  const token = await getAuthToken();

  const putString = `projects/${payload.projectId}/orders/${payload.packageId}/geometry-files`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "GET"
  }).then(response => {
    if(!response.ok){
      throw new Error(response.statusText);
    }
    return response.json()
  }).then(data => {return data});
}

async function StratusPackageGeometryUpload(payload) {
  const token = await getAuthToken();

  const putString = `projects/${payload.projectId}/orders/${payload.packageId}/stratus-commit-geometry`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
    // body: JSON.stringify(payload),
  });
}

async function getServiceConnectionTypes(serviceId) {
    if (serviceId !== 'none') {
        const token = await getAuthToken();
        const urlString = `/services/${serviceId}/connectors`;

        const response = await fetch(API_URL + urlString, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: token,
            },
            method: "GET",
        });

        if (!response.ok) {
            throw new Error(response.statusText);
        }

        return await response.json();
    } else {
        console.log("ID was none");
        return null;
    }
}


async function getServiceSpecifications(serviceId) {
    if (serviceId !== 'none') {
        const token = await getAuthToken();
        const urlString = `/services/${serviceId}/specifications`;

        const response = await fetch(API_URL + urlString, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: token,
            },
            method: "GET",
        });

        if (!response.ok) {
            throw new Error(response.statusText);
        }

        return await response.json();
    } else {
        console.log("ID was none");
        return null;
    }
}


async function getServiceMaterials(serviceId) {
    if (serviceId !== 'none') {
        const token = await getAuthToken();
        const urlString = `/services/${serviceId}/materials`;

        const response = await fetch(API_URL + urlString, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: token,
            },
            method: "GET",
        });

        if (!response.ok) {
            throw new Error(response.statusText);
        }

        return await response.json();
    } else {
        console.log("ID was none");
        return null;
    }
}

async function getServiceConfigurations(serviceId){
  const token = await getAuthToken();

  const putString = `/services/${serviceId}/configurations`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "GET",
    // body: JSON.stringify(payload),
  }).then(response => {
    if(!response.ok){
      throw new Error(response.statusText)
    }
    return response.json()
  }).then(data => {return data})
}

async function getServiceGeneratorVersion(serviceId){
  const token = await getAuthToken();

  const getString = `generator/version`

  return fetch(API_URL + getString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "GET",
  }).then (response => {
    if(!response.ok){
      throw new Error(response.statusText)
    }
    return response.json()
  }).then(data => {return data})
}

async function deleteServiceConfigurations(serviceId, data){
  const token = await getAuthToken();

  const putString = `/services/${serviceId}/configurations/${data.selectedConfig.id}`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "DELETE",
    // body: JSON.stringify(payload),
  });

}



async function updateServiceConfigurations(serviceId, data, data2){
  //return this.post(`/services/${serviceId}/configurations/${data.selectedConfig.id}`, {...data2});
  const token = await getAuthToken();

  const putString = `/services/${serviceId}/configurations/${data.selectedConfig.id}`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "PUT",
    body: JSON.stringify(data2),
  });
}

async function createServiceConfigurations(serviceId, data){
  //return this.post(`/services/${serviceId}/configurations`, {...data})
  const token = await getAuthToken();
  const putString = `/services/${serviceId}/configurations`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
    body: JSON.stringify(data),
  }).then((res) => {
    const data = res.json();
    return data;
  })
  // .then((data) => {
  //   return data
  // })
  .catch((err) => {
    console.error(err);
    throw err;
  });;
}

async function convertConfigurationToCatalog(serviceId, data){
  //return this.post(`/services/${serviceId}/convert/${data.selectedConfig.id}`);
  const token = await getAuthToken();

  const putString = `/services/${serviceId}/convert/${data.selectedConfig.id}`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
    body: JSON.stringify(data),
  });
}
async function postFabGeometry(payload){
  //return this.post(`/services/${serviceId}/geometry`, {data})
  const token = await getAuthToken();

  const putString = `/services/${payload.id}/geometry`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
    body: JSON.stringify(payload.data),
  }).then(response => {
    if(!response.ok){
      throw new Error(response.statusText)
    }
    return response.json()
  }).then(data => {return data});

}

async function getFabGeometry(payload){
  const token = await getAuthToken();
  const putString = `/services/${payload.id}/geometry/${payload.meshId}`;
  return fetch(API_URL + putString, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "GET",

  }).then(response => {
    if(!response.ok){
      throw new Error(response.statusText)
    }
    return response.json()
  }).then(data => {
    return {data}});
}
async function getServiceItems(serviceId) {
    if(serviceId !== 'none' && serviceId !== '' && serviceId !== 'undefined'){
        const params = {};
        const token = await getAuthToken();
        const headers = {
            Authorization: token,
        };

        try {
            const fetch = await axios.get(`/services/${serviceId}/items`, {
                params,
                headers,
            });
            return fetch;
        } catch (error) {
            // If the server sends back a structured error message
            if (error.response && error.response.data) {
                // Throw that to the caller for further handling
                throw error.response.data;
            } else {
                // If it's a more general error (like network issues), throw the error as is
                throw error;
            }
        }
    } else {
        return console.log("ID was none");
    }
}


async function getServiceGroups(serviceId) {
    const params = {};
    const token = await getAuthToken();
    const headers = {
        Authorization: token,
    };

    try {
        const fetch = await axios.get(`/services/${serviceId}/groups`, {
            params,
            headers,
        });
        return fetch;
    } catch (error) {
        if (error.response && error.response.data) {
            throw error.response.data;
        } else {
            throw error;
        }
    }
}


  async function updateServiceItems(payload) {
    if (payload.id !== 'none'){
    const token = await getAuthToken();
    const putString = `services/${payload.id}/items`;
    return fetch(API_URL + putString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({'items': payload.items, 'connectionTypes': payload.connectionTypes}),
    });
  }else{
    return console.log("ID was none")
  }
  }

  async function updateServiceConnectionTypes(payload) {
    if (payload.id !== 'none'){
      const token = await getAuthToken();
      const putString = `services/${payload.id}/connectionTypes`;
      return fetch(API_URL + putString, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(payload),
      });
    }else{
      return console.log("ID was none")
    }

  }

  async function updateServiceMaterials(payload) {
    if (payload.id !== 'none'){
      const token = await getAuthToken();
      const putString = `services/${payload.id}/materials`;
      return fetch(API_URL + putString, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(payload),
      });
    }else{
      return console.log("ID was none")
    }

  }

  async function updateServiceSpecifications(payload) {
    if (payload.id !== 'none'){
      const token = await getAuthToken();
      const putString = `services/${payload.id}/specifications`;
      return fetch(API_URL + putString, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(payload),
      });
    }else{
      return console.log("ID was none")
    }

  }

  async function getStratusPackage(payload) {
    const token = await getAuthToken();
    const headers = {
      Authorization: token,
    };

    const getString = `projects/${payload.projectId}/orders/${payload.packageId}`;
    return fetch(API_URL + getString, { headers }).catch((err) => {
      console.error(err);
    });
  }
const APIGW = {
  // async function getServiceGroups(serviceId) {
  //   const params = {};
  //   const token = await getAuthToken();
  //   const headers = {
  //     Authorization: token,
  //   };
  //   const fetch = await axios.get(`/services/${serviceId}/groups`, {
  //     params,
  //     headers,
  //   });
  //   return fetch;
  // }
  // async function getServiceItems(serviceId) {
  //   const params = {};
  //   const token = await getAuthToken();
  //   const headers = {
  //     Authorization: token,
  //   };
  //   const fetch = await axios.get(`/services/${serviceId}/items`, {
  //     params,
  //     headers,
  //   });
  //   return fetch;
  // }
  // getServicesAndIcons = async () => {
  //   return this.getServices().then(async (resp) => {
  //     const services = resp.data;
  //     const imageFetches = [];
  //     services.forEach((service) => {
  //       service.accessories.forEach(async (accessoryId) => {
  //         if (accessoryId.menuIcon) {
  //           const resolvedAcc = this.getServiceAccessory(
  //             service.id,
  //             accessoryId
  //           ).then(
  //             (resp) => (resp.data.menuIcon = URL.createObjectURL(resp.data))
  //           );
  //           imageFetches.push(resolvedAcc);
  //         }
  //       });
  //     });
  //     await Promise.all(imageFetches);
  //     return resp;
  //   });
  // };
  //   stratusGetStatuses = async () => {
  //     return this.get("/stratus/tracking-statuses");
  //   };
  //   stratusUpdateStatuses = async () => {
  //     return this.post("/stratus/tracking-statuses/pull");
  //   };
  //   stratusSetUploadStatus = async (statusId) => {
  //     return this.put(
  //       `/stratus/tracking-statuses/default-upload-status?statusId=${statusId}`,
  //       { type: "text/plain" }
  //     );
  //   };
  //   softDeletePackageItem = async (projectId, orderId) => {
  //     return this.post(`/projects/${projectId}/orders/${orderId}/delete`);
  //   };
  //   restorePackageItem = async (projectId, orderId) => {
  //     return this.post(`/projects/${projectId}/orders/${orderId}/restore`);
  //   };
  //   uploadImage = async (serviceId, partNumber, image, imageName) => {
  //     if (!image) return;
  //     return this.put(
  //       `/services/${serviceId}/groups/${partNumber}/${imageName}`,
  //       image
  //     );
  //   };
  //   getImageFromPresignedURL = async (presignedURL) => {
  //     return this.get(presignedURL);
  //   };
  //   stratusGetServicesForFileGroup = async ({ page, pageSize, fileGroupId }) => {
  //     return this.get(`/stratus/services`, {
  //       page,
  //       pageSize,
  //       fileGroupId,
  //     });
  //   };
  //   getCatalogImportStatus = async (catalogId) => {
  //     return this.get(`/imports/catalogs/${catalogId}`);
  //   };
  //   importStratusService = (jobData) => {
  //     return this.post(`/imports/services`, jobData);
  //   };
  //   getServiceAccessory = (serviceId, accessoryId) => {
  //     if (serviceId && accessoryId) {
  //       return this.get(`/services/${serviceId}/groups/${accessoryId}`, {});
  //     }
  //     return { data: [] };
  //   };
  //   updateServiceAccessory = (serviceId, partNumber, data) => {
  //     return this.post(
  //       `/services/${serviceId}/groups/${encodeURIComponent(partNumber)}`,
  //       { ...data }
  //     );
  //   };
  //   importProjectsFromStratus = () => {
  //     return this.post(`/stratus/imports/projects`, {});
  //   };
  //   // Update an existing project with new data
  //   deleteProject = (projectId) => {
  //     return this.post(`/projects/${projectId}/delete`);
  //   };
  //   restoreProject = (projectId) => {
  //     return this.post(`/projects/${projectId}/restore`);
  //   };
  //     // Expect a presigned s3 PUT URL in successful response; upload zip to the URL
  //     await this.put(resp.data.importURL, zippedSource, false);
  //     return resp;import { getOrdersFromProject } from './queryAPI';
  //   };import { log } from 'console';
  //   getCompany = async (companyId) => {
  //     return this.get(`/companies/${companyId}`, {});
  //   };
  //   updateCompany = async (companyId, data) => {
  //     return this.post(`/companies/${companyId}`, {
  //       ...data,
  //     });
  //   };
  //   getCompanyStatuses = async (companyId) => {
  //     return this.get(`/companies/${companyId}/statuses`, {});
  //   };
  //   getCompanyStatus = async (companyId, statusId) => {
  //     return this.get(`/companies/${companyId}/statuses/${statusId}`, {});
  //   };
  //   getUser = (userId) => {
  //     return this.get(`/users/${userId}`, {});
  //   };
  //   getCognitoUser = (userId) => {
  //     return this.get(`/cognito/users/${userId}`);
  //   };
  //   updateUser = (userId, data) => {
  //     return this.post(`/users/${userId}`, { ...data });
  //   };
};

// export const queryAPI = new APIGW();

// export const APIGW;
export {
  assignCatalogProject,
  assignServiceProject,
  assignUserDivision,
  assignUserProject,
  assignViewOnlyUser,
  convertServiceToCatalog,
  createCatalogItem,
  createDivision,
  createProject,
  createUserPackageViewer,
  deleteCatalog,
  deleteCatalogItem,
  softDeleteDivision,
  deletePackage,
  deleteProject,
  deleteService,
  deleteUserPackageViewer,
  duplicateService,
  exportCatalog,
  getCatalogs,
  getCatalogsAndIcons,
  getCatalogItems,
  getCognitoUsers,
  deleteCognitoUser,
  duplicateCatalog,
  createUser,
  createMultiUser,
  updateUser,
  getDivision,
  getDivisions,
  getCatalogsWithItems,
  getOrder,
  getOrdersFromProject,
  getOrders,
  getProject,
  getPackageViewers,
  getProjects,
  getUsers,
  getUsersWithCognitoData,
  getPickList,
  getService,
  getServices,
  getServicesWithGroups,
  newGetServicesWithGroups,
  getSketchBOM,
  getStratusPackage,
  getStratusStatuses,
  getStatuses,
  getCompanyStatuses,
  getOverallBOM,
  getCutList,
  getPDFExport,
  getSketchMAJ,
  getSketchPCF,
  hardDeleteDivision,
  importCatalog,
  importStratusService,
  importStratusServicesArray,
  renameCatalog,
  renameProject,
  renameService,
  reinviteCognitoUser,
  restoreProject,
  restoreDivision,
  restorePackage,
  setCognitoUserStatus,
  stratusGetFileGroups,
  stratusGetServicesForFileGroup,
  stratusGetProfilesForFileGroup,
  stratusSyncPackage,
  stratusUploadPackage,
  unassignCatalogProject,
  unassignServiceProject,
  unassignUserProject,
  unassignViewOnlyUser,
  updateOrder,
  updateProject,
  updateDivision,
  getCompany,
  getCompanies,
  importProjectsFromStratus,
  softDeletePackage,
  stratusSetUploadStatus,
  testStratusStatusConnection,
  unassignUserDivision,
  updateCatalogItem,
  updateUploadKey,
  updateCompany,
  getServiceConnectionTypes,
  getServiceConfigurations,
  createServiceConfigurations,
  updateServiceConfigurations,
  deleteServiceConfigurations,
  convertConfigurationToCatalog,
  postFabGeometry,
  postDeleteProject,
  getFabGeometry,
  getServiceItems,
  updateServiceItems,
  updateServiceAccessory,
  importStratusStatuses,
  updateServiceConnectionTypes,
  updateServiceMaterials,
  updateServiceSpecifications,
  getServiceGroups,
  getServiceSpecifications,
  getServiceMaterials,
  StratusPackageUpload,
  StratusPackagePCFUpload,
  StratusPackageMAJUpload,
  StratusPackagePDFUpload,
  StratusPackageCUTUpload,
  StratusPackageGeometryUpload,
  StratusPackageBOMUpload,
  createCompanyStatus,
  deleteCompanyStatus,
  updateCompanyStatuses,
  moveCompanyStatuses,
  getCompanyExpirationDate,
  GetPackageGeometryFiles,
  StratusPackageTakeoffMAJUpload,
  updateServiceConnectionType,
  getServiceGeneratorVersion,
  updateCognitoUser,
  updateCompanySettings,
  getCompanySettings,
  updateLocalUser,
  getEstimatingBOM,
  updateServiceDrawable,
  archivePackage,
  unarchivePackage,
  APIGW,
};
