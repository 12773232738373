import {
    Box,
    Breadcrumbs,
    Button,
    CircularProgress,
    Grid,
    Link,
    Typography,
} from '@mui/material'
import React, {useEffect, useState} from 'react'

import AddCircleIcon from '@mui/icons-material/AddCircle'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import {CardSwitch} from './CardSwitch'
import {CatalogConfigurationCreateDrawer} from './CatalogConfigurationCreateDrawer'
import {CatalogConfigurationDrawer} from './CatalogConfigurationDrawer'
import {CatalogConfigurationPartsCards} from './CatalogConfigurationPartsCards'
import {CatalogConfigurationPartsTable} from './CatalogConfigurationPartsTable'
import clone from 'just-clone'
import {styled} from '@mui/material/styles'

export const CatalogConfiguration = (props) => {
    const [selectedCatalog, setSelectedCatalog] = useState(props.selectedCatalog);
    const [selectedPart, setSelectedPart] = useState(undefined);
    const [cardsView, setCardsView] = useState(false);
    const switchView = () => {
      setCardsView(!cardsView);
    }
    useEffect(() => {
        setSelectedCatalog(props.selectedCatalog)
    }, [props.selectedCatalog])

    return (
        <>
        {
            selectedCatalog !== undefined ?
            (   
                <>
                <CardSwitch onChange={switchView}/>
                <Box>
                    { !cardsView ? (
                      <CatalogConfigurationPartsTable parts={selectedCatalog?.items} setSelectedPart={setSelectedPart}/>
                    ): <CatalogConfigurationPartsCards parts={selectedCatalog?.items} setSelectedPart={setSelectedPart}/>
                    }
                    <CatalogConfigurationDrawer companyId={props.companyId} selectedPart={selectedPart} setSelectedPart={setSelectedPart} selectedCatalog={selectedCatalog} setSelectedCatalog={props.setSelectedCatalog}/>
                    <CatalogConfigurationCreateDrawer companyId={props.companyId} selectedCatalog={selectedCatalog} setSelectedCatalog={props.setSelectedCatalog}/>
                </Box>
                </>
            ): (<p>Undefined</p>)
        }
        </>
        
    )
}