import { useCallback, useContext, useEffect, useRef, useState } from "react";

import { AccountInfoContext } from "../../Context/AccountContext";
import axios from "axios";

const useFetch = (initialUrl, initialData) => {
  const [data, setData] = useState(initialData);
  const [url, setUrl] = useState(initialUrl);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);

      try {
        const result = await axios(url);

        setData(result.data);
      } catch (error) {
        console.error(error);
        setIsError(true);
      }

      setIsLoading(false);
    };

    fetchData();
  }, [url]);

  return [{ data, isLoading, isError }, setUrl];
};

/*
 * Wraps the useEffect react hook with loading and error status information
 * Ensure that staticFunc is the same function every render to prevent unnecessary calls
 */
const useEffectWithStatus = (staticFunc, params) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);
      try {
        const result = await staticFunc(params);

        setData(result.data);
      } catch (error) {
        console.error(error);
        setIsError(true);
      }

      setIsLoading(false);
    };

    fetchData();
  }, [staticFunc, params]);

  return [{ data, isLoading, isError }, setData];
};

// Wraps an async api call
const useAPIData = (initialData, func, initialParams) => {
  const [data, setData] = useState(initialData);
  const [params, setParams] = useState(initialParams);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);
      try {
        const result = await func(params);

        setData(result.data);
      } catch (error) {
        console.error(error);
        setIsError(true);
      }

      setIsLoading(false);
    };

    fetchData();
  }, [func, params]);

  return [{ data, isLoading, isError }, setParams, setData];
};

/*
 *
 */
const useAPICallback = (initialData, func) => {
  const [data, setData] = useState(initialData);
  // const [params, setParams] = useState(initialParams);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const apiCall = useCallback(
    async (inputs) => {
      const fetchData = async () => {
        setIsError(false);
        setIsLoading(true);

        try {
          const result = await func(inputs);

          setData(result.data);
        } catch (error) {
          console.error(error);
          setIsError(true);
        }

        setIsLoading(false);
      };

      fetchData();
    },
    [func]
  );

  return [{ data, setData, isLoading, isError }, apiCall];
};

/*
 * Use to call API with side effects that doesn't return anything
 */
const useAPIEffect = (func) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const apiCall = useCallback(
    async (inputs) => {
      const funcCall = async () => {
        setIsError(false);
        setIsLoading(true);

        try {
          await func(inputs);
        } catch (error) {
          console.error(error);
          setIsError(true);
        }
        setIsLoading(false);
      };

      funcCall();
    },
    [func]
  );

  return [isLoading, isError, apiCall];
};

/*
 * React Hook version of setInterval()
 */
function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export {
  useFetch,
  useAPIData,
  useAPICallback,
  useAPIEffect,
  useEffectWithStatus,
  useInterval,
};
