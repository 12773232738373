import {
    Box,
    MenuItem,
    Select,
    Switch,
    Typography
} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {getOrder, stratusSyncPackage} from '../../../data/queries/queryAPI'

import { CardSwitch } from './ViewSwitch';
import CircularProgress from "@mui/material/CircularProgress";
import SyncIcon from "@mui/icons-material/Sync";
import clone from 'just-clone'
import { getCurrentUser } from 'Context/currentUser';
import {styled} from '@mui/material/styles'
import {useMutation} from 'react-query'

export const DivisionProjectSelection = (props) => {
    const [selectedDivision, setSelectedDivision] = useState(props.selectedDivision);
    const [selectedProject, setSelectedProject] = useState(props.selectedProject);
    const [savedProject, setSavedProject] = useState(props.savedProject);
    const [savedDivision, setSavedDivision] = useState(props.savedDivision);
    const [syncing, setSyncing] = useState(false);
    const [divisions, setDivisions] = useState(props.divisions)
    const [projects, setProjects] = useState(props.projects)
    const [activePackages, setActivePackages] = useState(undefined);
    const [archivedPackages, setArchivedPackages] = useState(undefined);
    const currentUser = getCurrentUser();
    const [userId, setUserId] = useState(currentUser?.attributes?.sub);
    useEffect(() => {
        if(currentUser?.attributes?.sub){
            setUserId(currentUser?.attributes?.sub)
        }
    }, [currentUser])
    useEffect(() => {
        setDivisions(props.divisions)
    }, [props.divisions])
    useEffect(() => {
        setProjects(props.projects)
    }, [props.projects])
    useEffect(() => {
        setSelectedDivision(props.selectedDivision)
    }, [props.selectedDivision])
    useEffect(() => {
        setSelectedProject(props.selectedProject)
    }, [props.selectedProject])
    useEffect(() => {
        setSavedProject(props.savedProject);
    }, [props.savedProject])

    useEffect(() => {
        setSavedDivision(props.savedDivision)
    }, [props.savedDivision])

    useEffect(() => {
        if(savedProject && savedDivision && projects && divisions){
            setSelectedDivision(divisions.find((division) => division.name === savedDivision));
            setSelectedProject(projects.find((project) => project.name === savedProject));
            props.setSelectedDivision(divisions.find((division) => division.name === savedDivision));
            props.setSelectedProject(projects.find((project) => project.name === savedProject));

        }
    }, [savedProject, savedDivision, divisions, projects])

    useEffect(() => {
        if(activePackages === undefined && selectedProject && selectedDivision){
            getOrders(selectedProject)
        }
        if(archivedPackages === undefined && selectedProject && selectedDivision){
            getArchivedPackages(selectedProject)
        }
    }, [selectedDivision, selectedProject])
    
    const getOrders = async (project) => {
        if(Array.isArray(project?.packageItems)){
            let payloads = [];
            project.packageItems.forEach(async (order) => {
                let payload = {};
                payload['projectId'] = project.id
                payload['orderId'] = order;
                payloads.push(payload);
            })
            const promises = payloads.map(async (payload) => {
                try {
                  const response = await getOrder(payload);
                  return response;
                } catch (error) {
                  console.error(error);
                  return Promise.resolve(undefined);
                }
            });
              
            const responses = await Promise.all(promises);
            const successfulResponses = responses.filter(response => response !== undefined);
            const data = await Promise.all(successfulResponses.map((response) => response.data));
            let newOrders = [];
            data.forEach((orderData) => {
                newOrders.push(orderData);
            })
            setActivePackages(newOrders);
            props.setActivePackages(newOrders);
        }
    }

    const getArchivedPackages = async (project) => {
        if(Array.isArray(project?.archivedPackages)){
            let payloads = [];
            project.archivedPackages.forEach(async (order) => {
                let payload = {};
                payload['projectId'] = project.id;
                payload['orderId'] = order;
                payload['archived'] = true;
                payloads.push(payload);
            })

            const promises = payloads.map(async (payload) => {
                try{
                    const response = await getOrder(payload);
                    return response;
                }catch(error){
                    console.error(error);
                    return Promise.resolve(undefined)
                }
            });

            const responses = await Promise.all(promises);
            const successfulResponses = responses.filter(response => response !== undefined);
            const data = await Promise.all(successfulResponses.map((response) => response.data));
            let newOrders = [];
            data.forEach((orderData) => {
                newOrders.push(orderData);
            })
            setArchivedPackages(newOrders);
            props.setArchivedPackages(newOrders);
        }
    }

    const setDivision = (value) => {
        setSelectedDivision(value);
        setSelectedProject(undefined);
        setActivePackages(undefined);
        setArchivedPackages(undefined);
        props.setSelectedDivision(value)
        props.setSelectedProject(undefined)
        props.setActivePackages(undefined);
        props.setArchivedPackages(undefined);
    }
    const setProject = async (value) => {
        setSelectedProject(value);
        setActivePackages(undefined);
        props.setSelectedProject(value);
        props.setActivePackages(undefined);
        if(Array.isArray(value.packageItems)){
            let payloads = []
            value.packageItems.forEach(async (order) => {
                let payload = {};
                payload['projectId'] = value.id
                payload['orderId'] = order;
                payloads.push(payload);
            })
            const promises = payloads.map((payload) => getOrder(payload));
            const responses = await Promise.all(promises);
            const data = await Promise.all(responses.map((response) => response.data));
            let newOrders = [];
            data.forEach((orderData) => {
                if(orderData !== "Order was NoneType"){
                    newOrders.push(orderData);
                }
            })
            setActivePackages(newOrders);
            props.setActivePackages(newOrders);
        }

        if(Array.isArray(value.archivedPackages)){
            let payloads = []
            value.archivedPackages.forEach(async (order) => {
                let payload = {};
                payload['projectId'] = value.id
                payload['orderId'] = order;
                payloads.push(payload);
            })
            const promises = payloads.map((payload) => getOrder(payload));
            const responses = await Promise.all(promises);
            const data = await Promise.all(responses.map((response) => response.data));
            let newOrders = [];
            data.forEach((orderData) => {
                if(orderData !== "Order was NoneType"){
                    newOrders.push(orderData);
                }
            })
            setArchivedPackages(newOrders);
            props.setArchivedPackages(newOrders);
        }
    }

    const handleSyncClick = async () => {
        setSyncing(true);
        let clonedOrders = clone(activePackages);
        if (clonedOrders !== undefined && selectedProject !== undefined){
            for(let i = 0; i < clonedOrders.length; i++){
                let payload = {}
                payload.projectId = selectedProject.id;
                payload.packageId = clonedOrders[i].id;
                if(clonedOrders[i]?.orderData?.stratusId.length > 0){
                    let data = await stratusSyncPackage(payload);
                    if(!data.error){
                        clonedOrders[i] = data;
                    }
                }
            }
            setSyncing(false);
            setActivePackages(clonedOrders);
            props.setActivePackages(clonedOrders);
        }
    }
    return (
        <>
        {
            divisions !== undefined && projects !== undefined ? (
                
                <Box sx={{display: 'flex', alignItems: 'center', margin: "1.9rem"}}>
                <Box sx={{marginBottom: '16px'}}>
                    <CardSwitch onChange={() => {props.flipSwitch()}}/>
                </Box>
                <Box sx={{backgroundColor: 'lightgray', marginBottom: "16px", height: '45px', justifyContent: 'center', borderTopLeftRadius: '10%', borderBottomLeftRadius: '10%'}}>
                <Typography sx={{paddingLeft: '5px', paddingRight: "5px", paddingTop: '10px'}}> Division: </Typography>
                </Box>                
                <Select
                sx={{width: "15%", marginRight: "1rem", marginBottom: "1rem",  height: '45px', paddingTop: "5px"}}
                value={selectedDivision?.name || ''}
                onChange={(event) => {setDivision(divisions?.find((division) => {
                    if (division.name === event.target.value){
                        return division
                    }else{
                        return undefined;
                    }
                }))}}
                >
                    {
                        divisions?.map((division) => {
                            return <MenuItem key={division.id} sx={{display: 'flex !important', flexDirection: 'column !important'}} value={division.name}>{division.name}</MenuItem>
                        })
                    }
                </Select>
                <Box sx={{backgroundColor: 'lightgray', marginBottom: "16px", height: '45px', justifyContent: 'center', borderTopLeftRadius: '10%', borderBottomLeftRadius: '10%'}}>
                <Typography sx={{paddingLeft: '5px', paddingRight: "5px", paddingTop: '10px'}}> Project: </Typography>
                </Box>
                <Select
                sx={{width: "15%", marginBottom: "1rem", height: '45px', paddingTop: "5px"}}
                value={selectedProject?.name || ''}
                placholderText="Project"
                onChange={(event) => {setProject(projects?.find((project) => {
                    if (project.name === event.target.value){
                        return project
                    }else{
                        return undefined;
                    }
                }))}}
                >
                    {
                        projects?.map((project) => {
                            if(project.divisionId === selectedDivision?.id && project.users.find((user) => user === userId)){
                                return <MenuItem key={project.id} sx={{display: 'flex !important', flexDirection: 'column !important'}} value={project.name}>{project.name}</MenuItem>
                            }
                        })
                    }
                </Select>
                {
                    (selectedDivision?.id === "stratus0-0000-0000-0000-000000000000" && selectedProject !== undefined) ? (
                        <Box sx={{marginBottom: '10px', marginLeft: '5px'}}>
                            {
                                !syncing ? (
                                    <SyncIcon
                                        fontSize="large"
                                        color="secondary"
                                        onClick={() => {
                                          handleSyncClick();
                                        }}
                                    />
                                ): <CircularProgress color="secondary" variant="indeterminate" size='1rem' />
                            }
                            
                        </Box>
                    ): null
                }
                <Box sx={{marginBottom: ".75rem"}}>
                    <ShowArchivedSwitch
                        checked={props.showArchived}
                        onChange={() => {props.setShowArchived(!props.showArchived)}}
                    />
                </Box>
            </Box>
            ) : (
                <Box sx={{display: 'flex', color: "gray", marginLeft: "1.9rem"}}>
                    <Typography variant="h4">Loading Division and Project Data...</Typography>
                    <CircularProgress color="inherit" />
                </Box>
                )
                
            
        }
        </>
    )
}

const ShowArchivedSwitch = styled(Switch)( ({theme}) => ({

    '& .MuiSwitch-switchBase':{
      marginTop: '9px',
      marginLeft: '9px',
      transition: 'translateX(10px)',
      transitionDuration: '300 ms',
  
      '& .MuiSwitch-thumb':{
        color: 'black',
        transitionDuration: '300 ms',
  
      },
      '&.Mui-checked': {
        '& .MuiSwitch-thumb' : {
          color: '#F15A29'
        },
        '& ~ .MuiSwitch-track':{
          backgroundColor: '#F15A29',
  
        }
      },
      '& ~ .MuiSwitch-track':{
        backgroundColor: 'black',
  
      }
    },
  }));
