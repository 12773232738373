import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { ListItem, ListItemText } from "@material-ui/core";

import { Box } from "@mui/system";
import React from 'react'
import { getUserPermissions } from "Context/currentUser";
import { styled } from '@mui/material/styles';
import { useState } from "react";

//import {ListItem, ListItemText} from "@mui/material";

export const CompanyNavButtons = () => {

  const userPermissions_key = "JxXSoSKKP^N9tot9x4qDEGZZ%L2t^vYS";
  const userPermissions = getUserPermissions(userPermissions_key);
  const [admin, isAdmin] = useState(userPermissions.includes("AdminUsers"));
  const [servicePermissions, isServicePermissions] = useState(userPermissions.includes("ServiceCatalogEditors"));
  const [projectEditor, isProjectEditor] = useState(userPermissions.includes("ProjectEditors"));

  const CustomNavLink = ({ children, to, ...props }) => {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: false });
    if (match) {
      return (
        <MatchLink to={to} {...props}>
          {children}
        </MatchLink>
      );
    } else {
      return (
        <UnmatchedLink to={to} {...props}>
          {children}
        </UnmatchedLink>
      );
    }
  };

  const MatchLink = styled(Link)`
    color: #fff;
    text-decoration: none;
    background: #F15A29;
  `;
  const UnmatchedLink = styled(Link)`
    color: #F15A29 !important;
    text-decoration: none;
    background: #fff;
  `;

  const CustomListItem = styled(ListItem)`
    :hover { background: transparent; };
  `;

  return (
    <Box
      sx={styles.box}
      display="flex"
      flexDirection="row"
      alignItems="center"
      mb={2}
    >
      {admin ? (
        <Box sx={{display: 'flex'}}>
          <CustomNavLink to="/company/general">
            <CustomListItem button key="general">
              <ListItemText primary="General" />
            </CustomListItem>
          </CustomNavLink>
          <CustomNavLink to="/company/divisions">
            <CustomListItem button key="divisions">
              <ListItemText primary="Divisions" />
            </CustomListItem>
          </CustomNavLink>
          {/* <CustomNavLink to="/company/settings">
            <CustomListItem button key="settings">
              <ListItemText primary="Settings" />
            </CustomListItem>
          </CustomNavLink> */}
        </Box>
      ) : null}
    </Box>
  );
};

const styles = {
  box: { paddingTop: "2rem"}
};
