import { CircularProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from "react";
import {
  getCompanyStatuses,
  stratusSetUploadStatus,
} from "../../../data/queries/queryAPI.js";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { Box } from '@material-ui/core';
import StyledSelect from "./StyledSelect.jsx";
import { styled } from '@mui/material/styles';

export default function UploadStatus(props) {
  const [companyId, setCompanyId] = useState(props.companyId);
  const [companyData, setCompanyData] = useState(props.companyData);
  const [defaultUploadStatus, setDefaultUploadStatus] = useState(companyData?.data?.data?.defaultUploadStatus);
  const [isUpdating, setIsUpdating] = useState(false);
  const queryClient = useQueryClient();
  const [selectedStatus, setSelectedStatus] = useState();

  useEffect(() => {
    setCompanyId(props.companyId)
  }, [props.companyId])

  useEffect(() => {
    setCompanyData(props.companyData)
  }, [props.companyData])

  useEffect(() => {
    setDefaultUploadStatus(companyData?.data?.data?.defaultUploadStatus)
  }, [companyData?.data?.data?.defaultUploadStatus])

  useEffect(() => {
    setSelectedStatus(props.selectedStatus)
  }, [props.selectedStatus])

  //get all companyStatuses
  const companyStatuses = useQuery(["companyStatuses"], getCompanyStatuses, {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (companyStatuses.isSuccess) {
        const defaultStatus = companyStatuses?.data?.data?.statuses?.find((status) => status.id === companyData?.data?.data?.defaultUploadStatus)?.id
        setSelectedStatus(defaultStatus);
    }
  }, [companyStatuses.isSuccess, companyStatuses.data, companyData?.data?.data?.defaultUploadStatus]);

  const defaultDescription = companyStatuses?.data?.data?.statuses?.find((status) => {
    return status.id === defaultUploadStatus;
  })?.description || "Select an Upload Status...";

  //mutate the original stratusStatus
  const { mutate: uploadStratusStatus } = useMutation(stratusSetUploadStatus, {
    onSettled: (data) => {
      setIsUpdating(false);
    },
    onError: (error) => {
      console.error("error mutating data:", error);
      alert("There was an error: ", error);
    },
  });

  const OnSelectStatus = (event) => {
    setIsUpdating(true);
    const selectedStatusId = event.target.value;
    const payload = { data: { statusId: selectedStatusId }};
    uploadStratusStatus(payload);
    setSelectedStatus(selectedStatusId);
  };

  const styles = {
    header: {
      margintop: "25px",
      marginbottom: "40px",
      width: "100%",
      height: "100%",
    },
  }

  return (
    <>
    <br/>
    { companyStatuses !== undefined && companyStatuses?.data !== undefined? (
      <Box>
          <Typography variant="h5">Upload Status</Typography>
          <Box display='flex'>
          <StyledSelect
            selected={selectedStatus || ""}
            onSelect={OnSelectStatus}
            placeholderText={
              companyStatuses?.isLoading
                ? "Loading..."
                : defaultUploadStatus === undefined
                ? "Select an Upload Status..."
                : defaultDescription
            }
            options={
              companyStatuses?.data
                ? companyStatuses?.data?.data?.statuses?.map((status, index) => ({
                    ...status,
                    id: status.id || index,
                    name: status.name,
                  }))
                : []
            }
          />
          {
            isUpdating ? <Box sx={{display: 'flex', marginLeft: '0.75rem', marginTop: '0.2rem'}}><CircularProgress color="secondary" size={32}/></Box> : null
          }
          </Box>
      </Box>
    ):null}
      
    </>
  );
}
