import React, { useEffect, useState } from "react";

import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Collapse from "@mui/material/Collapse";
import { CollapseCatalogs } from "./CollapseCatalogs";
import { CollapseDivisions } from "./CollapseDivisions";
import { CollapseServices } from "./CollapseServices";
import { CollapseUsers } from "./CollapseUsers";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LinkIcon from "@mui/icons-material/Link";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Popover from "@mui/material/Popover";
import { PopoverButtons } from "./PopoverButtons";
import Typography from "@mui/material/Typography";
import { getServices } from "../../../data/queries/queryAPI";
import { useQuery } from "react-query";

export const ProjectRow = (props) => {
  const project = props.project;
  const [open, setOpen] = useState(false);
  const [catalogsOpen, setCatalogsOpen] = useState(false);
  const [divisionsOpen, setDivisionsOpen] = useState(false);
  const [servicesOpen, setServicesOpen] = useState(false);
  const [usersOpen, setUsersOpen] = useState(false);
  const handleProjectClick = () => {
    setOpen(!open);
  };

  //HANDLING POPOVER
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpenPopover = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const popoverOpen = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  //PRE-LOADING DATA
  const servicesBasicQuery = useQuery(["allServices"], () => getServices(), {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  const [servicesList, setServicesList] = useState([]);

  useEffect(() => {
    const collector = project.services.map((serviceId) => {
      return servicesBasicQuery.data?.data.find((serviceObj) => {
        return serviceObj.id === serviceId;
      });
    });

    const alphabetized = collector.sort((a, b) => {
      if (a.name.toUpperCase() === b.name.toUpperCase()) {
        return 0;
      }
      return a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1;
    });

    setServicesList(alphabetized);
  }, [servicesBasicQuery.data, project.services]);
  if (servicesBasicQuery.isLoading) return <div>Loading...</div>;
  return (
    <>
      <ListItemButton
        onClick={handleProjectClick}
        sx={styles.listItemButton}
      >
        {open ? (
          <ExpandMore sx={styles.paddingTop} />
        ) : (
          <ChevronRightIcon sx={styles.paddingTop} />
        )}
        <Typography variant="h6">{project.name}</Typography>
          {project.stratusModelId ? (
            <LinkIcon
              sx={styles.linkIcon}
              color="secondary"
            />
          ) : null}
          <Button
            sx={styles.btn}
            //color="secondary"
            onClick={(e) => handleOpenPopover(e)}
          >
            <MoreHorizIcon color="secondary" />
          </Button>
        <Popover
          id={id}
          open={popoverOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <PopoverButtons
            openDrawer={props.openDrawer}
            handleClose={handleClose}
            projects={props.projects}
            projectId={props.project.id}
            isStratus={props.project.stratusModelId ? true : false}
            setProjectForDialog={props.setProjectForDialog}
            project={props.project}
          />
        </Popover>
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box sx={styles.box[1]}>
          <Box>
            <ListItemButton
              onClick={(e) => {
                setCatalogsOpen(!catalogsOpen);
              }}
            >
              {catalogsOpen ? <ExpandMore /> : <ChevronRightIcon />}
              <ListItemText primary={`Catalogs (${project.catalogs.length})`} />
            </ListItemButton>
            <CollapseCatalogs
              catalogs={project.catalogs}
              catalogsOpen={catalogsOpen}
            />
          </Box>
          <Box>
            <ListItemButton
              onClick={(e) => {
                setDivisionsOpen(!divisionsOpen);
              }}
            >
              {divisionsOpen ? <ExpandMore /> : <ChevronRightIcon />}
              <ListItemText primary={"Division (1)"} />
            </ListItemButton>
            <CollapseDivisions
              divisionId={project.divisionId}
              divisionsOpen={divisionsOpen}
            />
          </Box>
          <Box>
            <ListItemButton
              onClick={(e) => {
                setUsersOpen(!usersOpen);
              }}
            >
              {usersOpen ? <ExpandMore /> : <ChevronRightIcon />}
              <ListItemText primary={`Users (${project.users.length})`} />
            </ListItemButton>
            <CollapseUsers users={project.users} usersOpen={usersOpen} />
          </Box>
          <Box>
            <ListItemButton
              onClick={(e) => {
                setServicesOpen(!servicesOpen);
              }}
            >
              {servicesOpen ? <ExpandMore /> : <ChevronRightIcon />}
              <ListItemText primary={`Services (${servicesList.filter(service => service).length})`} />
            </ListItemButton>
            <CollapseServices
              services={servicesList}
              servicesOpen={servicesOpen}
            />
          </Box>
          <CollapseServices services={project.services} />
        </Box>
      </Collapse>
    </>
  );
};

const styles = {
  listItemButton: { display: "flex", flexDirection: "row" },
  paddingTop: { paddingTop: ".25rem" },
  linkIcon: { marginLeft: "1rem" },
  btn: {
    paddingLeft: "0rem",
    marginLeft: "0rem",
  },
  box: {
    1: { paddingLeft: "2rem" }
  }
};