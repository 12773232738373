import {Box, Button, Typography} from '@mui/material'
import React, {useEffect, useState} from 'react';
import {convertServiceToCatalog, updateServiceSpecifications} from "../../../../data/queries/queryAPI"
import {useMutation, useQueryClient} from "react-query"

import CircularProgress from '@material-ui/core/CircularProgress'
import CustomDataGrid from './../CustomDataGrid';
import ErrorDialog from 'components/Utilities/ErrorDialog';
import clone from 'just-clone'

export const ServiceConfigurationSpecifications = (props) => {
    const [selectedService, setSelectedService] = useState(props.selectedService)
    const [specifications, setSpecifications] = useState(props.specifications);
    const [selectionModel, setSelectionModel] = useState([]);
    const [needsUpdate, setNeedsUpdate] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [pageSize, setPageSize] = useState(25);
    const queryClient = useQueryClient();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    
    const { mutate: updateSpecifications } = useMutation(updateServiceSpecifications, {
        onSettled: (data) => {
            convertItem(selectedService.id);
        },
        onError: (error) => {
            setIsUpdating(false);
            setErrorMessage(error.message || 'An error occurred');
            setDialogOpen(true);
        },
    });

    const { mutate: convertItem } = useMutation(convertServiceToCatalog, {
        onSettled: (data) => {
          queryClient.refetchQueries(['allOnlyCatalogs']).then(()=> {
            setIsUpdating(false);
            setNeedsUpdate(false);
          })
        },
        onError: (error) => {
            setIsUpdating(false);
            setErrorMessage(error.message || 'An error occurred');
            setDialogOpen(true);
        },
    });

    const updateServiceSpecs = () => {
        setIsUpdating(true);
        let newSpecifications = clone(specifications);
        if(selectionModel !== undefined && Array.isArray(selectionModel)
         && specifications !== undefined && Array.isArray(specifications)){
            newSpecifications.forEach((specification, index) => {
                if(selectionModel.find(fabId => fabId === specification.FabId) !== undefined){
                    newSpecifications[index].isApp = true;
                }else{
                    newSpecifications[index].isApp = false;
                }
            })
        }
        let payload = {
            id: selectedService.id,
            setInsulation: false,
            data: {specifications: newSpecifications}
        }
        updateSpecifications(payload)
        setSpecifications(newSpecifications)
        let updatedObject = selectedService;
        if(updatedObject!== undefined){
            updatedObject['specifications'] = [...updatedObject['specifications'].filter((spec) => spec.Type === 9), ...newSpecifications];
            props.setSelectedService(updatedObject)
        }
    }

    useEffect(() => {
        setSpecifications(props.specifications)
    }, [props.specifications])

    useEffect(() => {
        setSelectedService(props.selectedService)
    }, [props.selectedService])

    useEffect(() => {
        if(Array.isArray(specifications)){
            let existingSpecifications = []
            specifications.forEach((specification) => {
                if(specification?.isApp)
                    existingSpecifications.push(specification.FabId)
            })
            setSelectionModel(existingSpecifications)
        }
    }, [specifications])


    const columns = [
        { field: "Name", headerName: "Name", width: 250, renderCell: (params) => {
            if(params.value.includes("?")){
                return(
                    params.value.toString().replace("?", "\u00B0")
                )
            }
        } },
        { field: "FabId", hide: true},
        { field: "Group", headerName: "Group", width: 150},
        { field: "Type", headerName: "Type", width: 150, renderCell: (params) => {
            return(
                "Pipe and Duct"
            )
        }},
       
    ];
    return (
        <Box>
            {
                specifications === undefined ? (
                    <Typography>Loading...</Typography>
                ) : null
            }
            {
                needsUpdate ? (
                    <Button variant="contained" color="secondary" onClick={updateServiceSpecs}>{isUpdating ? (<CircularProgress color="white"/>): "Update Specifications"}</Button>
                ): null
            }
            {
                specifications !== undefined && Array.isArray(specifications) && specifications.length > 0 ?(
                    <CustomDataGrid
                    columns={columns}
                    rows={specifications}
                    autoHeight={true}
                    getRowId={(row) => row.FabId}
                    selectionModel={selectionModel}
                    pagination={true}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[25, 100, 250, 500]}
                    checkboxSelection
                    onSelectionModelChange={(newSelectionModel => {
                        if(newSelectionModel !== selectionModel){
                            setNeedsUpdate(true);
                        }else{
                            setNeedsUpdate(false)
                        }
                        setSelectionModel(newSelectionModel)
                    })}
                    />
                ): null
                
            }
            <ErrorDialog open={dialogOpen} handleClose={handleCloseDialog} errorMessage={errorMessage} />
        </Box>
    )
}