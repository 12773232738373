import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Paper,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import React, { createContext, useContext, useEffect, useReducer, useState } from "react";
import { alpha, styled } from '@mui/material/styles';
import { closeOrdersDrawer, openOrdersDrawer, ordersDrawerOpen } from "Context/DrawerStates";
import {
        getCompanyStatuses,
        getDivisions,
        getProjects
} from "data/queries/queryAPI";
import { useQuery, useQueryClient } from "react-query";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import { CardsGrid } from "./components/CardsGrid";
import { CreateOrderDialog } from "./components/CreateOrderDialog";
import { DivisionProjectSelection } from "./components/DivisionProjectSelection";
import { OrdersDrawer } from "./components/OrdersDrawer";
import {PackageTable} from "./components/PackageTable";
import { PersistentNavButtons } from "../Navigation/PersistentNavButtons";
import SearchIcon from "@mui/icons-material/Search";
import { ordersShowDeleted } from "Context/componentStates";

export const Orders = (props) => {
    const queryClient = useQueryClient();
    const [showCardView, setShowCardView] = useState(false);
    const [savedProject, setSavedProject] = useState((localStorage.getItem('project')) ? localStorage.getItem('project') : undefined);
    const [savedDivision, setSavedDivision] = useState((localStorage.getItem('division')) ? localStorage.getItem('division') : undefined);
    const [selectedDivision, setSelectedDivision] = useState(undefined);
    const [selectedProject, setSelectedProject] = useState(undefined);
    const [divisions, setDivisions] = useState(undefined)
    const [projects, setProjects] = useState(undefined)
    const [orders, setOrders] = useState(undefined);
    const [archivedPackages, setArchivedPackages] = useState(undefined);
    const [activePackages, setActivePackages] = useState(undefined);
    const [packagesForDrawer, setPackagesForDrawer] = useState([]);
    const [showArchived, setShowArchived] = useState(false);
    const [statuses, setStatuses] = useState(undefined);
    const [clearSelectionFlag, setClearSelectionFlag] = useState(false);
    

    const flipSwitch = () => {
      setShowCardView(!showCardView);
    };

    useEffect(() => {
      if(selectedDivision){
        localStorage.setItem('division', selectedDivision?.name)
        setSavedDivision(selectedDivision?.name);
      }
    }, [selectedDivision])

    useEffect(() => {
      if(selectedProject){
        localStorage.setItem('project', selectedProject?.name)
        setSavedProject(selectedProject?.name)
      }
    }, [selectedProject])

    // //FETCH DATA FROM PROJECTS
    const projectsQuery = useQuery(["allProjects"], () => getProjects(), {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
    });
    //each project has a divisionId: match proj_divId with id on division

    // //FETCH DATA FROM DIVISIONS
    const divisionsQuery = useQuery(["allDivisions"], () => getDivisions(), {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
    });
    const users = queryClient.getQueryData(['allUsers'])?.data

    const statusesQuery = useQuery(
      ["companyStatuses"],
      () => getCompanyStatuses(props.companyId),
      { staleTime: Infinity, refetchOnWindowFocus: false }
    );

    useEffect(() => {
      setDivisions(divisionsQuery?.data?.data)
    }, [divisionsQuery?.data?.data])

    useEffect(() => {
      setProjects(projectsQuery?.data?.data)
    }, [projectsQuery?.data?.data])

    useEffect(() => {
      setStatuses(statusesQuery?.data?.data?.statuses)
    }, [statusesQuery?.data?.data])

    useEffect(() => {
      if(packagesForDrawer.length > 0){
        if(!ordersDrawerOpen.get()){
          if(packagesForDrawer.some((pack) => users.find((user) => user.id === pack.orderData.assignedTo) === undefined)){
            openOrdersDrawer();
          }
        }
      }else if (packagesForDrawer.length === 0){
        if(ordersDrawerOpen.get()){
          closeOrdersDrawer();
        }
      }
    }, [packagesForDrawer])



    useEffect(() => {
      if(showArchived){
        setOrders(archivedPackages);
      }else{
        setOrders(activePackages)
      }
    }, [showArchived, archivedPackages, activePackages])
    
    return (
      <>

        <Box
          sx={styles.paper}
        >
        <DivisionProjectSelection 
        setSelectedDivision={setSelectedDivision}
        setSelectedProject={setSelectedProject}
        savedProject={savedProject}
        savedDivision={savedDivision}
        showArchived={showArchived}
        setShowArchived={setShowArchived}
        setActivePackages={setActivePackages}
        setArchivedPackages={setArchivedPackages}
        divisions={divisions}
        projects={projects}
        flipSwitch={flipSwitch}
        />
        {
          !showCardView ? (
            <PackageTable
            division={selectedDivision}
            project={selectedProject}
            orders={orders}
            archivedPackages={archivedPackages}
            showArchived={showArchived}
            users={users}
            clearSelectionFlag={clearSelectionFlag}
            setClearSelectionFlag={setClearSelectionFlag}
            setPackagesForDrawer={setPackagesForDrawer}
            setOrders={setOrders}
            setArchivedPackages={setArchivedPackages}
            />
          ) : <CardsGrid
              division={selectedDivision}
              project={selectedProject}
              orders={orders}
              archivedPackages={archivedPackages}
              showArchived={showArchived}
              users={users}
              statuses={statuses}
              clearSelectionFlag={clearSelectionFlag}
              setClearSelectionFlag={setClearSelectionFlag}
              setPackagesForDrawer={setPackagesForDrawer}
              setOrders={setOrders}
              setArchivedPackages={setArchivedPackages}
            />
        }
        
        <OrdersDrawer
          packages={packagesForDrawer}
          companyId={props.companyId}
          project={selectedProject}
          projects={projects}
          division={selectedDivision}
          users={users}
          orders={orders}
          clearSelectionFlag={clearSelectionFlag}
          setClearSelectionFlag={setClearSelectionFlag}
          activePackages={activePackages}
          archivedPackages={archivedPackages}
          showArchived={showArchived}
          setArchivedPackages={setArchivedPackages}
          setOrders={setOrders}
          setActivePackages={setActivePackages}
          statuses={statuses}
        />
      </Box>
      </>
    )
};

const styles = {
  divider: { marginTop: "1rem", marginBottom: "1rem" },
  cardSwitch: {marginTop: 1.5},
  color: {
    gray: { color: "gray" },
    orange: {color: "#F15A29"}
  },
  paper: {
    height: "auto",
    width: "100%",
    marginTop: "80px",
    backgroundColor: "#fff",
    flex: 1,
    flexDirection: "column",
    paddingBottom: "20px",
  },
  box: {
    1: {
      display: "flex",
      flexDirection: "row",
      marginTop: "1rem",
      justifyContent: "space-between",
      alignItems: "center",
    },
    2: { display: "flex", flexDirection: "row" },
    3: { display: "flex", flexDirection: "row", alignItems: "center" },
    4: { display: "flex", flexDirection: "col" }
  },
  typography: {marginTop: "1rem"},
  marginRight: {marginRight: "1rem"}

};

const H5_ = styled(Typography)`
  margin-top: 1rem !important;
`;

const LoadingOrders = styled(Typography)`
  color: white;
`;
