import {
  Box,
  Button,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  assignCatalogProject,
  getCatalogs,
  unassignCatalogProject,
} from "../../../data/queries/queryAPI";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { CircularProgress } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import ErrorDialog from "components/Utilities/ErrorDialog";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Outlined_btn } from "components/Theme/Styled";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import {styled} from "@mui/material/styles";

export const ProjectCatalogDialog = (props) => {
  const [allCatalogs, setAllCatalogs] = useState([]);
  const [selectedCatalogs, setSelectedCatalogs] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const queryClient = useQueryClient();
  const project = props.project;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleCloseDialog = () => {
      setDialogOpen(false);
  };

  const catalogsQuery = useQuery(["allCatalogs"], () => getCatalogs(), {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (catalogsQuery?.data) {
      if(Array.isArray(catalogsQuery?.data?.data)){
        const alphabetized = catalogsQuery?.data?.data?.sort((a, b) => {
        if (a.name.toUpperCase() === b.name.toUpperCase()) {
          return 0;
        }
        return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1;
      });
      setAllCatalogs(alphabetized);
      }
      
    }
  }, [catalogsQuery.data]);

  useEffect(() => {
    const collector = props.project?.catalogs?.map((service) => {
      // eslint-disable-next-line array-callback-return
      return allCatalogs.find((serviceObj) => {
        if (serviceObj.id === service) {
          return serviceObj;
        }
      });
    });
    setSelectedCatalogs(collector);
  }, [allCatalogs, props.project?.catalogs]);

  const { mutate: addCatalogToProject } = useMutation(assignCatalogProject, {
    onSettled: (data) => {
      if (data.status === 200) {
        queryClient
          .refetchQueries(["allProjects"])
          .then(() => {
            setIsUpdating(false);
          })
          .catch((error) => {
            setIsUpdating(false);
            setErrorMessage(error.message || 'An error occurred');
            setDialogOpen(true);
          });
      }
    },
    onError: (error) => {
      setIsUpdating(false);
      setErrorMessage(error.message || 'An error occurred');
      setDialogOpen(true);
    },
  });

  const { mutate: removeCatalogFromProject } = useMutation(
    unassignCatalogProject,
    {
      onSettled: (data) => {
        if (data.status === 200) {
          queryClient
            .refetchQueries(["allProjects"])
            .then(() => {
              setIsUpdating(false);
            })
            .catch((error) => {
              setIsUpdating(false);
              setErrorMessage(error.message || 'An error occurred');
              setDialogOpen(true);
            });
        }
      },
      onError: (error) => {
        setIsUpdating(false);
        setErrorMessage(error.message || 'An error occurred');
        setDialogOpen(true);
      },
    }
  );

  const handleChange = (event) => {
    // VALUE IS AN ARRAY OF ALL CURRENTLY SELECTED NAMES
    const {
      target: { value },
    } = event;

    // COLLECTOR IS AN ARRAY OF THE FULL CATALOG OBJECTS THAT ARE CURRENTLY SELECTED
    const collector = value.map((name) => {
      return catalogsQuery.data.data.find((catalogObj) => {
        return catalogObj.name === name;
      });
    });

    //IF THE NEW ARRAY IS LONGER WE NEED TO ADD A catalog
    if (collector.length > selectedCatalogs.length) {
      //FIND THE ADDED catalog
      const catalogToAdd = collector.filter(
        (catalog) => !selectedCatalogs.includes(catalog)
      );

      // RUN THE MUTATION
      setIsUpdating(true);
      const payload = {};
      payload.projectId = project.id;
      payload.catalogId = catalogToAdd[0].id;
      addCatalogToProject(payload);

      // UPDATE LOCAL STATE
      setSelectedCatalogs(collector);
    }

    //IF THE NEW ARRAY IS SHORTER WE NEED TO REMOVE A catalog
    if (value.length < selectedCatalogs.length) {
      //FIND THE REMOVED SERVICE
      const catalogToAdd = selectedCatalogs.filter(
        (catalog) => !collector.includes(catalog)
      );

      // RUN THE MUTATION
      setIsUpdating(true);
      const payload = {};
      payload.projectId = project.id;
      payload.catalogId = catalogToAdd[0].id;
      removeCatalogFromProject(payload);

      // UPDATE LOCAL STATE
      setSelectedCatalogs(collector);
    }
  };

  const Box1_ = styled(Box)`
    margin-right: auto;
  `;

  return (
    <>
        <Dialog open={props.projectCatalogDialogOpen}>
          <Box>
            {/* DIALOG HEADER */}
            <Box sx={styles.box[1]}>
              <Typography variant="h6" color="#fff">
                Add Catalog(s)
              </Typography>
            </Box>
            {/* DIALOG BODY */}
            <Box sx={styles.box[2]}></Box>
            <div>
              <FormControl sx={styles.formControl}>
                <InputLabel id="multiple-checkbox-label">
                  Assign Catalogs
                </InputLabel>
                <Select
                  onOpen={() => {
                    setTimeout(() => {
                      const menuElement = document.querySelector('.MuiMenu-paper');
                      if (menuElement) {
                        menuElement.scrollTop = 0;
                      }
                    }, 0);
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 45 * 4.5,
                        width: 250,
                      },
                    },
                    getContentAnchorEl: null,
                  }}
                  disabled={isUpdating}
                  labelId="multiple-checkbox-label"
                  id="multiple-checkbox"
                  multiple
                  value={selectedCatalogs?.map((catalog) => catalog?.name)}
                  onChange={handleChange}
                  input={<OutlinedInput label="Assign Catalogs" />}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {allCatalogs.map((catalog) => (
                    <MenuItem_ key={catalog.id} value={catalog.name}>
                      <Box1_ sx={styles.row}>
                      <Checkbox
                        checked={selectedCatalogs?.indexOf(catalog) > -1}
                      />
                      <ListItemText primary={catalog.name} />
                      </Box1_>
                    </MenuItem_>
                  ))}
                </Select>
              </FormControl>
            </div>
            <Box
              sx={styles.box[3]}
            >
              <Outlined_btn
                onClick={(e) => props.setProjectCatalogDialogOpen(false)}
              >
                Close
              </Outlined_btn>
              {isUpdating ? (
                <Box sx={styles.row}>
                  <Typography
                    sx={styles.marginLeft}
                    variant="button"
                    color="secondary"
                  >
                    Updating...
                  </Typography>
                  <CircularProgress size={18} />
                </Box>
              ) : null}
            </Box>
          </Box>
        </Dialog>
        <ErrorDialog open={dialogOpen} handleClose={handleCloseDialog} errorMessage={errorMessage} />

    </>
  );
};

const styles = {
  box: {
    1: { backgroundColor: "#F15A29", padding: "1rem" },
    2: { display: "flex", flexDirection: "row" },
    3: { display: "flex", flexDirection: "row", padding: "1rem" }
  },
  formControl: { m: 1, width: 300 },
  color: {
    flame: {color: "#F15A29"}
  },
  column: {display: "flex", flexDirection: "column"},
  row: {display: "flex", flexDirection: "row" },
  marginLeft: { marginLeft: "1rem" }
};

const MenuItem_ = styled(MenuItem)`
  display: flex !important;
  flex-direction: column !important;
`;
