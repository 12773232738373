import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import { getServices } from "../../../data/queries/queryAPI";
import { useQuery } from "react-query";

export const CollapseServices = (props) => {
  const [servicesList, setServicesList] = useState(props.services);

  return (
    <Box sx={styles.box[1]}>
      <Collapse in={props.servicesOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {servicesList.filter(service => service).length > 0 ? (
            servicesList.filter(service => service).map((service) => {
              return <ListItemText key={service?.id} primary={service?.name} />;
            })
          ) : (
            <ListItemText>No services found</ListItemText>
          )}
        </List>
      </Collapse>
    </Box>
  );
};

const styles = {
  box: {
    1: { marginLeft: "4rem" }
  }
};
