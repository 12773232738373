import {
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import {
    DataGridPro,
    GridToolbar,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
    LicenseInfo,
    useGridApiRef
} from "@mui/x-data-grid-pro";

import Delete from "@mui/icons-material/Delete";
import DeleteForever from "@mui/icons-material/DeleteForever"
import DeleteOutline from "@mui/icons-material/DeleteOutline"
import { useContext } from "react";

function CustomToolbar() {
    return (
        <GridToolbarContainer>
          <GridToolbarColumnsButton/>
          <GridToolbarFilterButton/>
          <GridToolbarDensitySelector />         
        </GridToolbarContainer>
    );
  }

  const styles = {
    cardSwitch: { marginTop: "2rem" },
    typography: {fontSize: '13px', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', fontWeight: 400, lineHeight: 1.5, letterSpacing: "0.02857em"}
  }

  export default CustomToolbar;
  
