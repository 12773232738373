import React, { useEffect, useState } from "react";
import {
  deleteCompanyStatus,
  moveCompanyStatuses,
} from "../../../data/queries/queryAPI";
import {
  useAPICallback,
  useAPIData,
  useAPIEffect,
} from "./../../../data/hooks/customHooks.js";
import { useMutation, useQueryClient } from "react-query";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import CircularProgress from "@mui/material/CircularProgress";
import { Outlined_btn } from "components/Theme/Styled";

export const PopoverButtons = (props) => {
  // const [isDuplicating, setIsDuplicating] = useState(false);
  const [isConverting, setIsConverting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isMovingUp, setIsMovingUp] = useState(false);
  const [isMovingDown, setIsMovingDown] = useState(false);
  const queryClient = useQueryClient();


  const handleDelete = (e) => {
    e.stopPropagation();
    setIsDeleting(true);
    let payload = {companyId: props.companyId, data: {name: props.selectedStatus}};
    //deleteItem(props.serviceId);
    deleteStatus(payload)
  };

  const {mutate: deleteStatus } = useMutation(deleteCompanyStatus, {
    onSettled: (data) => {
        queryClient.refetchQueries(['companyStatuses']).then(() => {
            setIsDeleting(false);
        })
    },
    onError: (error) => {
        console.error("Error deleting company status", error);
        alert("An error occurred while deleting a company status: ", error);
    }
  })

  const {mutate: moveStatus} = useMutation(moveCompanyStatuses, {
    onSettled: (data) => {
        queryClient.refetchQueries(['companyStatuses']).then(() => {
            setIsMovingUp(false);
            setIsMovingDown(false);
        })
    },
    onError: (error) => {
        console.error("Error deleting company status", error);
        alert("An error occurred while deleting a company status: ", error);
    }
  })

  const handleConfigure = (e) => {
    e.stopPropagation();
    props.setIsConfiguring(true);
  }
  const handleMoveUp = (e) => {
    e.stopPropagation();
    setIsMovingUp(true);
    let payload = {companyId: props.companyId, data: {direction: 1, status: props.selectedStatus}}
    moveStatus(payload)
  }
  const handleMoveDown = (e) => {
    e.stopPropagation();
    setIsMovingDown(true)
    let payload = {companyId: props.companyId, data: {direction: 0, status: props.selectedStatus}}
    moveStatus(payload)
  }

  return (
      <ButtonGroup
        orientation="vertical"
        aria-label="vertical outlined button group"
      >
        <Outlined_btn
          //color="secondary"
          onClick={(e) => handleConfigure(e)}
        >
          Configure
        </Outlined_btn>
        <Outlined_btn
          //color="secondary"
          //variant="outlined"
          onClick={(e) => handleMoveUp(e)}
        >
          Move Up
          {isMovingUp ? (
            <CircularProgress sx={styles.marginLeft} color="secondary" size={12} />
          ) : null}
        </Outlined_btn>
        <Outlined_btn
          //color="secondary"
          //variant="outlined"
          onClick={(e) => handleMoveDown(e)}
        >
          Move Down
          {isMovingDown ? (
            <CircularProgress sx={styles.marginLeft} color="secondary" size={12} />
          ) : null}
        </Outlined_btn>
        <Outlined_btn
          //color="secondary"
          onClick={(e) => handleDelete(e)}
        >
          Delete
          {isDeleting ? (
            <CircularProgress sx={styles.marginLeft} color="secondary" size={12} />
          ) : null}
        </Outlined_btn>
      </ButtonGroup>
  );
};

const styles = {
  marginLeft: { marginLeft: "1rem" },
};
