import { CircularProgress, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getCompany,
  getUsersWithCognitoData,
} from "../../../data/queries/queryAPI";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Backdrop } from "@mui/material";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import { DataGridPro } from "@mui/x-data-grid-pro";
import Divider from "@mui/material/Divider";
import NewCustomDataGrid from "./CustomDataGrid";
import Typography from "@material-ui/core/Typography";
import { openCreateUserDrawer } from "../../../Context/DrawerStates";
import { useQuery } from "react-query";

export const UsersList = (props) => {
  const [dataForDisplay, setDataForDisplay] = useState([]);
  const [licenseCount, setLicenseCount] = useState(0);
  const [activeUsersCount, setActiveUsersCount] = useState(0);

  const usersQuery = useQuery(["allDetailedUsers"], getUsersWithCognitoData, {
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const companyId = props.companyId;
  const companyQuery = useQuery(
    ["getOneCompany"],
    () => getCompany(companyId),
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (companyQuery.data) {
      setLicenseCount(companyQuery.data.data.license?.licenseCount);
    }
  }, [companyQuery.data]);

  const handleRowClick = (userId) => {
    props.openDrawer(userId);
  };
  const USER_LICENSE_EXCLUDE_DOMAINS = [
    "gogtp.com",
    "steeltoeconsulting.com",
    "whitethorndigital.com",
    "whitethorngames.com"
  ]

  useEffect(() => {
    const buildDataForDisplay = () => {
      if (usersQuery.data?.length > 0) {
        // ALPHABETIZE THE USERS
        const alphabetized = usersQuery.data.slice().sort((a, b) => {
          if (a.name.toUpperCase() === b.name.toUpperCase()) {
            return 0;
          }
          return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1;
        }).filter(e => e);
        setDataForDisplay(alphabetized);
        const activeUsers = alphabetized.filter((user) => user.enabled && !USER_LICENSE_EXCLUDE_DOMAINS?.includes(user?.email?.substring(user?.email?.indexOf('@') + 1)));
        setActiveUsersCount(activeUsers.length);
      }
    };
    buildDataForDisplay();
  }, [usersQuery.data]);

  const convertActiveToString = (active) =>{
    if(active) return "Active";
    else return "Inactive";
  }
  const convertAccessesToString = (accesses) => {
    const accessesArray = [];
    if (accesses.canEditUsersDivisions) {
      accessesArray.push("Admin");
    }

    if (accesses.canEditProjects) {
      accessesArray.push("Projects");
    }

    if (accesses.canEditServices) {
      accessesArray.push("Services");
    }

    return accessesArray.join(", ");
  };

  if (dataForDisplay.length > 0) {
    return (
        <>
          <Box
            sx={styles.box[1]}
          >
            <Typography variant="h5">
              {" "}
              {`Users (${activeUsersCount}/${licenseCount})`}{" "}
            </Typography>
            <Button
              onClick={openCreateUserDrawer}
            >
              <AddCircleIcon
                sx={styles.addCircleIcon}
                color="secondary"
              />
            </Button>
          </Box>
          <Divider sx={styles.divider} />
          <Box sx={{paddingLeft: "1.5rem"}}>
          <NewCustomDataGrid
            rows={dataForDisplay}
            autoHeight={true}
            columns={[
              { field: "name", headerName: "Name", width: 250 },
              { field: "username", headerName: "ID", width: 175 },
              { field: "email", headerName: "Email", width: 225 },
              {
                field: "accesses",
                headerName: "Group",
                width: 225,
                renderCell: (params) => {
                  return <>{convertAccessesToString(params.value)}</>;
                },
              },
              {
                field: "enabled",
                headerName: "Status",
                width: 225,
                renderCell: (params) => {
                  return <>{convertActiveToString(params.value)}</>;
                },
              },
            ]}
            onRowClick={(params) => {
              handleRowClick(params?.id);
            }}
            hideFooter={true}
          />
          </Box>
        </>
    );
  } else
    return (
      <>
        <Box
          sx={styles.box[1]}
        >
          <Typography variant="h5">
            {" "}
            {`Users (${activeUsersCount}/${licenseCount})`}{" "}
          </Typography>
          <Button
            onClick={openCreateUserDrawer}
          >
            <AddCircleIcon
              sx={styles.addCircleIcon}
              color="secondary"
            />
          </Button>
        </Box>
        <Divider sx={styles.divider} />
        <Box sx={{...styles.box[2], color: 'gray'}}>
          <Typography variant="h3">Building Users Tree...</Typography>
          <CircularProgress color="inherit" />
        </Box>
      </>
    );
};

const styles = {
  box: {
    1: {
      paddingTop: "1rem",
      paddingLeft: "2rem",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    2: { display: "flex", flexDirection: "col" },
  },
  addCircleIcon: { marginLeft: ".5rem" },
  divider: { marginTop: "1rem", marginBottom: "1rem" },
  color: {
    flame: {color: "#F15A29"}
  }

};
