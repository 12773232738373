import { Button, ButtonGroup, CircularProgress, } from "@mui/material";
import React, { useState } from "react";
import { assignCatalogProject, assignServiceProject, assignUserProject, assignViewOnlyUser, createProject } from "data/queries/queryAPI";
import { deleteProject, postDeleteProject, restoreProject } from "data/queries/queryAPI";
import { openProjectHardDeleteDialog, openProjectRenameDialog, openProjectSoftDeleteDialog } from "Context/DrawerStates";
import { useMutation, useQueryClient } from "react-query";

import ErrorDialog from "components/Utilities/ErrorDialog";
import { Outlined_btn } from "components/Theme/Styled";
import { SoftDeleteDialog } from "./SoftDeleteDialog";

export const PopoverButtons = (props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isRestoring, setIsRestoring] = useState(false);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const queryClient = useQueryClient();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleCloseDialog = () => {
      setDialogOpen(false);
  };

  props.setProjectForDialog(props.project);
  const { mutate: restoreItem } = useMutation(restoreProject, {
    onSettled: (data) => {
      queryClient
        .refetchQueries(["allProjects"])
        .then(() => {
          setIsRestoring(false);
        })
        .then(() => {
          props.handleClose();
        })
        .catch((error) => {
          setIsRestoring(false);
          setErrorMessage(error.message || 'An error occurred');
          setDialogOpen(true);
        });
    },
    onError: (error) => {
      setIsRestoring(false);
      setErrorMessage(error.message || 'An error occurred');
      setDialogOpen(true);
    },
  });


  const handleEdit = (e) => {
    e.stopPropagation();
    props.openDrawer(props.projectId);
    props.handleClose();
  };

  const handleRestore = (e) => {
    e.stopPropagation();
    setIsRestoring(true);
    restoreItem(props.projectId);
    //TODO::: RESTORE LOGIC HERE
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    setIsDeleting(true);
    openProjectHardDeleteDialog();
    props.handleClose();

  };
  const handleDuplicate = (e) => {
    setIsDuplicating(true);
    duplicateProject();
  }

  const duplicateProject = async () => {
    const payload = {};
    payload.name = props.project.name;
    payload.divisionId = props.project.divisionId;

    try{
      // Extract projects from props
      const allProjects = props.projects;
      
      // Take the full name of the project being duplicated
      const fullName = props.project.name;
      
      // Find projects with names that start with the full name followed by " ("
      const similarProjects = allProjects.filter(project => 
        project.name === fullName || project.name.startsWith(`${fullName} (`)
      );
      
      // Determine the unique identifier to append to the new project's name
      let uniqueIdentifier = "";
      if (similarProjects.length > 0) {
        const counts = similarProjects.map(project => {
          const match = project.name.slice(fullName.length).match(/\((\d+)\)$/);
          return match ? parseInt(match[1], 10) : 0;
        });
        const maxCount = Math.max(...counts);
        uniqueIdentifier = `(${maxCount + 1})`;
      }
    
      const payload = {
        name: `${fullName} ${uniqueIdentifier}`.trim(),
        divisionId: props.project.divisionId,
      };
    
      // Create the new project
      const newProject = await createProject(payload);

      //Assign services from old project to new copy
      const servicePromises = props.project.services.map((serviceId) => {
        const servicePayload = {
          projectId: newProject.id,
          serviceId,
        };
        return assignServiceProject(servicePayload);
      });
      
      //Assign catalogs from old project to new copy
      const catalogPromises = props.project.catalogs.map((catalogId) => {
        const catalogPayload = {
          projectId: newProject.id,
          catalogId,
        };
        return assignCatalogProject(catalogPayload);
      });
  
      //Assign users from old project to new copy
      const userPromises = props.project.users.map((userId) => {
        const userPayload = {
          projectId: newProject.id,
          userId,
        };
        return assignUserProject(userPayload);
      });
  
      await Promise.all([...servicePromises, ...catalogPromises, ...userPromises]);
      queryClient.refetchQueries(['allProjects']).then(() => {
        setIsDuplicating(false);
        props.handleClose();
      })
    }catch(error){
      setIsDuplicating(false);
      setErrorMessage(error.message || 'An error occurred');
      setDialogOpen(true);
    }
    
  }

  return (
    <>
      <ButtonGroup
        orientation="vertical"
        aria-label="vertical outlined button group"
      >
        <Outlined_btn
          onClick={(e) => handleEdit(e)}
          key="edit">
          Edit
        </Outlined_btn>
        {
          (props.project?.divisionId !== "stratus0-0000-0000-0000-000000000000") ? (
            <Outlined_btn
              key="rename"
              onClick={(e) => {
                e.stopPropagation();
                openProjectRenameDialog();
                props.handleClose();
              }}
            >
              Rename
            </Outlined_btn>
            
          ) : null
        }
        {
          (props.project?.divisionId !== "stratus0-0000-0000-0000-000000000000") ? (
            <Outlined_btn
              key="duplicate"
              onClick={(e) => {
                handleDuplicate(e);
              }}
            >
              Duplicate
              {
                isDuplicating ? (
                  <CircularProgress sx={styles.marginLeft} size={12} />
                ) : null
              } 
            </Outlined_btn>
            
          ) : null
        }
        <Outlined_btn
          onClick={(e) => handleDelete(e)}
          key="delete">
          Delete
        </Outlined_btn>
      </ButtonGroup>
      <ErrorDialog open={dialogOpen} handleClose={handleCloseDialog} errorMessage={errorMessage} />

      </>
  );
};

const styles = {
  marginLeft: { marginLeft: "1rem" },
};
