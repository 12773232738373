import {
    Box,
    Button,
    Card,
    CardMedia,
    Divider,
    FormControl,
    Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'

import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";

export const ServiceConfigurationPartCard = (props) => {
    const [selectedPart, setSelectedPart] = useState(props.selectedPart);
    const [partIcon, setPartIcon] = useState(props.selectedPart?.materialSprite);
    const handleIconDelete = () => {
        setPartIcon("");
        selectedPart.materialSprite = ""
        props.setPropertiesChanged(true);
        props.setSelectedPart(selectedPart)
    };
    useEffect(() => {
        setSelectedPart(props.selectedPart)
    },[props.selectedPart])

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });
    };

    const handleIconUpdate = async (file) => {
        const base64 = await getBase64(file);
        setPartIcon(base64);
        selectedPart.materialSprite = base64
        props.setPropertiesChanged(true);
        props.setSelectedPart(selectedPart)
        
    };
    return (
        <>
              < Card id="TitleCard"
                sx={{ display: 'flex', border: "1px solid #F15A29 !important", maxHeight: 64, width: 310 }}
                variant="outlined"
              >
                { partIcon && partIcon.length > 55 ?
                    < CardMedia
                      component="img"
                      sx={{ width: 64, height: 64 }}
                      // image={selectedItem.materialSprite}
                      image={partIcon}
                      alt={selectedPart?.description}
                      key={selectedPart?.partNumber}
                    />
                    :
                      <PhotoCameraIcon sx={styles.tenrem} />

                }
                <Divider orientation="vertical" />

                <Box sx={{flex: 1, textAlign: "center !important" }}>
                      <Typography variant="body1">
                        { ( selectedPart?.description?.toString()?.includes('?') ) ?
                            selectedPart?.description?.toString().replace("?", "\u00B0")
                            :
                            selectedPart?.description
                        }
                      </Typography>
                      <Divider fullwidth/>

                      <Box sx={{flexDirection: "row"}}>
                        <Button
                          size="large"
                          onClick={handleIconDelete}
                          sx={{marginLeft: ".5rem !important", marginRight: ".5rem !important"}}
                        >
                          Delete
                        </Button>

                        <FormControl id='sv-csv-form'>
                          <input
                          type="file"
                          accept=".png"
                          id="newIconImg"
                          sx={styles.txt}
                          onChange={(e) => {
                              handleIconUpdate(e.target.files[0])

                          }}
                          hidden
                          />

                          <label htmlFor="newIconImg">
                            <Button
                            variant="raised"
                            component="span"
                            size="large"
                            sx={{marginLeft: ".5rem !important",
                            marginRight: ".5rem !important",
                            marginTop: ".10rem !important",
                            color: "#F15A29 !important"}}

                            >
                              New
                            </Button>
                          </label>

                        </FormControl>

                      </Box>
                </Box>
              </Card>
              </>
    )
}

const styles = {
    box: { paddingTop: "1rem" },
    drawer: {
        drawerHeader: {
          backgroundColor: "#000",
          color: "#fff",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }
      },
    iconButton: { color: "#fff", textAnchor: "right" },
    btn: { paddingLeft: "2rem", paddingRight: "2rem" },
    cancelIcon: { color: "#fff", textAlign: "right" },
    row: {display: "flex", flexDirection: "row"},
    tenrem: { width: "64px", height: "64px"},
};
