import React, {useEffect, useRef, useState} from 'react';

import {Box} from "@mui/material";
import Card from '@mui/material/Card';
import CheckBox from "@material-ui/core/CheckBox";
import Loading from "./Loading";
import {styled} from "@mui/material/styles";

const SelectedPartContainer = (props) =>{
    const[isPlaceholder, setPlaceHolder] = useState(false);
    const[selectedItem, setSelectedItem] = useState([]);
    useRef(React.createRef());

    useEffect(() => {
        setSelectedItem(props.item);
    }, [props.item])

    const handleChange = (event) => {
        setPlaceHolder(event.target.checked);
        props.setPlaceholder(event.target.checked);
    }

    return(
        <div>      
            {
                (selectedItem.materialSprite !== undefined) ?
                <Card variant="outlined" sx={styles.card}>

                    <div 
                        style={styles.div[2]}
                    >
                        <IMG_ 
                            src={selectedItem.materialSprite} 
                            //style={styles.img}
                            alt="Failed"
                        />
                        <br/>
                        <Label_>Import Mesh?</Label_>
                        <CheckBox 
                            sx={styles.checkBox} 
                            onChange={handleChange} 
                            checked={isPlaceholder}
                        />              
                    </div>
                </Card>
                : 
                (
                    <Card variant="outlined" sx={styles.card}>
                        <Loading message="Waiting for Part Selection..."></Loading>
                    </Card>
                )
            }
        </div>
        
    );
};

const styles = {
    div: {
        1: {
            display: 'block', 
            width: 160,
            height: 170, 
            overflowX: 'hidden', 
            overflowY: 'hidden', 
            border: '4px solid #003E70', 
            justifyContent: 'center'
        },
        2: {
            display: 'block', 
            justifyContent: 'center'
        },

    },
    img: {
        paddingLeft: '43px', 
        paddingTop: '25px'
    },
    label: {paddingLeft: '30px'},
    checkBox: {paddingLeft: '60px !important'},
    card: {
        display: 'block', 
        width: 160,
        height: 170, 
        overflowX: 'hidden', 
        overflowY: 'hidden', 
        border: '1px solid #F15A29 !important', 
        justifyContent: 'center'
    }
};

const IMG_ = styled('img')`
    paddin-left: 43px !important;
    padding-top: 25px !important;
`;

const Label_ = styled('label')`
    padding-left: 30px !important;
`;

export default SelectedPartContainer;