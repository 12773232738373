import {
    Card,
    Grid,
} from '@mui/material'
import React, {useEffect, useState} from 'react';

import {ServiceConfigurationPartDropdownOption} from './ServiceConfigurationPartDropdownOption'
import {ServiceConfigurationPartValueOption} from './ServiceConfigurationPartValueOption'

export const ServiceConfigurationPartOptions = (props) => {
    const [options, setOptions] = useState(props.itemOptions)
    const [selectedPart, setSelectedPart] = useState(props.selectedPart);

    useEffect(() => {
        setOptions(props.itemOptions)
    }, [props.itemOptions])

    useEffect(() => {
        setSelectedPart(props.selectedPart);
    }, [props.selectedPart])

    const changeCheckedState = (index, value) => {
        selectedPart.Options[index].IsLocked = value;
        props.setSelectedPart(selectedPart)
        props.setPropertiesChanged(true)

    }

    const handleOptionChange = (index, option) => {
        selectedPart.Options[index] = option
        props.setSelectedPart(selectedPart)
        props.setPropertiesChanged(true)
    }

    return (
        <Card variant="outlined" sx={{ alignItems: 'center', marginTop: "1rem", width: 310, border: " 1px solid #F15A29 !important", paddingTop: "1rem", paddingBottom: "1.5rem", marginBottom: "1.5rem" }}>
            <Grid
            container
            direction="column"
            sx={{
                width: 400,
                marginLeft: '1px'
            }}
            >
                {
                    options !== undefined && Array.isArray(options) && options?.length > 0 ? (
                            options.map((option, index) => {
                                if(option.PossibleValues.length > 0){
                                    return (
                                        <ServiceConfigurationPartDropdownOption
                                        key={index} 
                                        optionIndex={index} 
                                        option={option} 
                                        handleOptionChange={handleOptionChange} 
                                        checkOption={changeCheckedState}
                                        />
                                    )
                                }else{
                                    return (
                                        <ServiceConfigurationPartValueOption
                                        key={index} 
                                        optionIndex={index} 
                                        option={option}
                                        handleOptionChange={handleOptionChange}
                                        checkOption={changeCheckedState} 
                                        />
                                    )
                                }
                            }
                        )
                    ) : null
                }
            </Grid>
        </Card>
    )
}