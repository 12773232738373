import {
  Box,
  Button,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  assignServiceProject,
  getServices,
  unassignServiceProject,
} from "../../../data/queries/queryAPI";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { CircularProgress } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import ErrorDialog from "components/Utilities/ErrorDialog";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Outlined_btn } from "components/Theme/Styled";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import {styled} from "@mui/material/styles";

export const ProjectServicesDialog = (props) => {
  const [allServices, setAllServices] = useState([]);
  //   const [eligibleServices, setEligibleServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const queryClient = useQueryClient();
  const project = props.project;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleCloseDialog = () => {
      setDialogOpen(false);
  };

  const servicesQuery = useQuery(["allServices"], () => getServices(), {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (servicesQuery?.data?.data) {
      const alphabetized = servicesQuery.data.data
        .filter(service => service?.name) // Filter out any objects where the name property is null or undefined
        .sort((a, b) => {
          if (a.name.toUpperCase() === b.name.toUpperCase()) {
            return 0;
          }
          return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1;
        });
      setAllServices(alphabetized);
    }
  }, [servicesQuery?.data?.data]);

  useEffect(() => {
    const collector = props.project?.services?.map((service) => {
      // eslint-disable-next-line array-callback-return
      return allServices.find((serviceObj) => {
        if (serviceObj.id === service) {
          return serviceObj;
        }
      });
    });
    setSelectedServices(collector);
  }, [allServices, props.project?.services]);

  const { mutate: addServiceToProject } = useMutation(assignServiceProject, {
    onSettled: (data) => {
      if (data.status === 200) {
        queryClient
          .refetchQueries(["allProjects"])
          .then(() => {
            setIsUpdating(false);
          })
          .catch((error) => {
            setIsUpdating(false);
            setErrorMessage(error.message || 'An error occurred');
            setDialogOpen(true);
          });
      }
    },
    onError: (error) => {
      setIsUpdating(false);
      setErrorMessage(error.message || 'An error occurred');
      setDialogOpen(true);
    },
  });

  const { mutate: removeServiceFromProject } = useMutation(
    unassignServiceProject,
    {
      onSettled: (data) => {
        if (data.status === 200) {
          queryClient
            .refetchQueries(["allProjects"])
            .then(() => {
              setIsUpdating(false);
            })
            .catch((error) => {
              setIsUpdating(false);
              setErrorMessage(error.message || 'An error occurred');
              setDialogOpen(true);
            });
        }
      },
      onError: (error) => {
        setIsUpdating(false);
        setErrorMessage(error.message || 'An error occurred');
        setDialogOpen(true);
      },
    }
  );

  const handleChange = (event) => {
    // VALUE IS AN ARRAY OF ALL CURRENTLY SELECTED NAMES
    const {
      target: { value },
    } = event;

    // COLLECTOR IS AN ARRAY OF THE FULL SERVICE OBJECTS THAT ARE CURRENTLY SELECTED
    
    const collector = value.map((name) => {
      let serviceProfile = "";
      let serviceName = "";
      if(name.includes("__")){
        serviceName = name.split("__")[0];
        serviceProfile = name.split("__")[1];
        if(serviceProfile === "Global"){
          serviceProfile = "";
        }
      }
      return servicesQuery.data.data.find((serviceObj) => {
        if(serviceName !== ""){
          if(serviceObj.serviceProfile === "Global" || serviceObj.serviceProfile === ""){
            return serviceObj.name === serviceName && "" === serviceProfile
          }else{
            return serviceObj.name === serviceName && serviceObj.serviceProfile === serviceProfile;
          }
        }else{
          return serviceObj.name === name;
        }
      });
    });

    //IF THE NEW ARRAY IS LONGER WE NEED TO ADD A SERVICE
    if (collector.length > selectedServices.length) {
      //FIND THE ADDED SERVICE
      const serviceToAdd = collector.filter(
        (service) => {
          return !selectedServices.includes(service)
        }
      );

      // RUN THE MUTATION
      setIsUpdating(true);
      const payload = {};
      payload.projectId = project.id;
      payload.serviceId = serviceToAdd[0].id;
      addServiceToProject(payload);

      // UPDATE LOCAL STATE
      setSelectedServices(collector.filter((service) => service !== undefined));
    }

    //IF THE NEW ARRAY IS SHORTER WE NEED TO REMOVE A SERVICE
    if (collector.length < selectedServices.length) {
      //FIND THE REMOVED SERVICE
      const serviceToRemove = selectedServices.filter(
        (service) => !collector.includes(service)
      );

      // RUN THE MUTATION
      setIsUpdating(true);
      const payload = {};
      payload.projectId = project.id;
      payload.serviceId = serviceToRemove[0].id;
      removeServiceFromProject(payload);

      // UPDATE LOCAL STATE
      setSelectedServices(collector.filter((service) => service !== undefined));
    }
  };

  useEffect(()=> {

  }, [selectedServices])

  const Box1_ = styled(Box)`
    margin-right: auto;
  `;
  return (
    <>
        <Dialog open={props.projectServiceDialogOpen}>
          <Box>
            {/* DIALOG HEADER */}
            <Box sx={styles.box[1]}>
              <Typography variant="h6" color="#fff">
                Add Service(s)
              </Typography>
            </Box>
            {/* DIALOG BODY */}
            <Box sx={styles.box[2]}></Box>
            <div>
              <FormControl sx={styles.formControl}>
                <InputLabel id="multiple-checkbox-label">
                  Assign Services
                </InputLabel>
                <Select
                  onOpen={() => {
                    setTimeout(() => {
                      const menuElement = document.querySelector('.MuiMenu-paper');
                      if (menuElement) {
                        menuElement.scrollTop = 0;
                      }
                    }, 0);
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 45 * 4.5,
                        width: 250,
                      },
                    },
                    getContentAnchorEl: null,
                  }}
                  disabled={isUpdating}
                  labelId="multiple-checkbox-label"
                  id="multiple-checkbox"
                  multiple
                  value={selectedServices?.map((service) => (service?.serviceProfile === "") ? service?.name +  "__Global" : service?.name +  "__" + service?.serviceProfile)}
                  onChange={handleChange}
                  input={<OutlinedInput label="Assign Services" />}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {allServices?.map((service) => (
                    <MenuItem_ key={service.id} value={(service?.serviceProfile === "") ? service?.name +  "__Global" : service?.name +  "__" + service?.serviceProfile}>
                      <Box1_ sx={styles.row}>
                      {/* This is the box where the listitemtext comes from */}
                        <Checkbox
                          checked={selectedServices?.indexOf(service) > -1}
                        />
                        <ListItemText primary={(service?.serviceProfile === "") ? service?.name +  "__Global" : service?.name +  "__" + service?.serviceProfile} />
                      </Box1_>
                    </MenuItem_>
                  ))}
                </Select>
              </FormControl>
            </div>
            <Box
              sx={styles.box[3]}
            >
              <Outlined_btn
                onClick={(e) => props.setProjectServiceDialogOpen(false)}
              >
                Close
              </Outlined_btn>
              {isUpdating ? (
                <Box sx={styles.row}>
                  <Typography
                    sx={styles.marginLeft}
                    variant="button"
                    color="secondary"
                  >
                    Updating...
                  </Typography>
                  <CircularProgress size={18} />
                </Box>
              ) : null}
            </Box>
          </Box>
        </Dialog>
        <ErrorDialog open={dialogOpen} handleClose={handleCloseDialog} errorMessage={errorMessage} />

    </>
  );
};

const styles = {
  box: {
    1: { backgroundColor: "#F15A29", padding: "1rem" },
    2: { display: "flex", flexDirection: "row" },
    3: { display: "flex", flexDirection: "row", padding: "1rem" }
  },
  formControl: { m: 1, width: 300 },
  color: {
    flame: {color: "#F15A29"}
  },
  column: {display: "flex", flexDirection: "column"},
  row: {display: "flex", flexDirection: "row" },
  marginLeft: { marginLeft: "1rem" }
};

const MenuItem_ = styled(MenuItem)`
  display: flex !important;
  flex-direction: column !important;
  left: 0px;
`;
