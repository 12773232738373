import {
  Box,
  CircularProgress,
  Drawer,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  Contained_btn,
  Outlined_btn_v4,
  _Switch
} from "components/Theme/Styled";
import React, { useEffect, useRef, useState } from "react";
import { assignUserDivision, createMultiUser, createUser, getDivisions } from "../../../data/queries/queryAPI";
import {
  closeCreateUserDrawer,
  createUserDrawerOpen,
  openFailedUserDialog,
  openUserNoFileSelectedDialog
} from "Context/DrawerStates";
import { useMutation, useQueryClient } from "react-query";

import Button from "@material-ui/core/Button";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorDialog from "components/Utilities/ErrorDialog";
import MenuItem from "@material-ui/core/MenuItem";
import { NoFileSelected } from "../components/NoFileSelected";
import Switch from "@material-ui/core/Switch";
import { currentUser } from "../../../Context/currentUser";
import { styled } from "@mui/material/styles";

export const CreateUserDrawer = (props) => {
  const [propertiesChanged, setPropertiesChanged] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [multiUpdating, setMultiUpdating] = useState(false);
  const [status, setStatus] = useState(false);
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [username, setUsername] = useState("");
  const [userFile, setUserFile] = useState(null);
  const [userFileName, setUserFileName] = useState("");
  const [csvArray, setCsvArray] = useState([]);
  const [canEditProjects, setCanEditProjects] = useState(false);
  const [canEditServices, setCanEditServices] = useState(false);
  const [canEditUsersDivisions, setCanEditUsersDivisions] = useState(false);
  const [failedUserName, setFailedUserName] = useState(false);
  const queryClient = useQueryClient();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleCloseDialog = () => {
      setDialogOpen(false);
  };
  const companyId = currentUser.use().attributes["custom:companyId"];
  const divisions = queryClient.getQueryData(['allDivisions'])?.data;
  const fileInputRef = useRef();
  const drawerWidth = 400;
  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  }));

  const Div_ = styled("div")(({theme}) => ({
    display: "flex",
    marginTop: "2rem",
    marginLeft: "2rem"
  }))

  const SubDiv_ = styled("div")(({theme}) => ({
    display: "flex",
    justifyContent: "center",
    marginTop: "2rem",
    marginLeft: "2rem"
  }))

  const handleFileButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileInputChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setUserFile(event.target.files[0]);
      setUserFileName(event.target.files[0].name);
      setPropertiesChanged(true);
    }
  };

  const { mutate: addUserToDivision } = useMutation(assignUserDivision, {
    onSettled: (data) => {
      if (data.status === 200) {
        queryClient
          .refetchQueries(["allDivisions"])
          .catch((error) => {
            setErrorMessage(error.message || 'An error occurred');
            setDialogOpen(true);
          });
      } else {
        setErrorMessage('An error occurred');
        setDialogOpen(true);
      }
    },
    onError: (error) => {
      setErrorMessage(error.message || 'An error occurred');
      setDialogOpen(true);
    },
  });

  const { mutate: addUser } = useMutation(createUser, {
    onSettled: async (data) => {
      if (data.id) {        
        await divisions.forEach((division) => {
          let payload = {}
          payload['userId'] = data.id;
          payload['divisionId'] = division.id;
          addUserToDivision(payload);
        });
        queryClient.refetchQueries(["allDetailedUsers"]).then(()=> {
          queryClient.refetchQueries(["allProjects"]).then(() => {
            setFullName("");
            setUsername("");
            setUserFile(null);
            setCsvArray([]);
            setEmail("");
            setStatus(false);
            setCanEditProjects(false);
            setCanEditServices(false);
            setCanEditUsersDivisions(false);
            setUpdating(false);
            setPropertiesChanged(false);
            closeCreateUserDrawer();
          })
        });

      }

      if (data.error === "Failed to create user: User with username already exists"){
        setFailedUserName(true);
        setUpdating(false)
      }

      if(data.error){
        alert(data.error);
        setPropertiesChanged(false);
        setUpdating(false);
        closeCreateUserDrawer();
        queryClient.refetchQueries(["allDetailedUsers"]);
        setFullName("");
        setUsername("");
        setUserFile(null);
        setCsvArray([]);
        setEmail("");
        setStatus(false);
        setCanEditProjects(false);
        setCanEditServices(false);
        setCanEditUsersDivisions(false);

      }
    },
    onError: (error) => {
      setUpdating(false);
      setErrorMessage(error.message || 'An error occurred');
      setDialogOpen(true);
    },
  });

  const { mutate: addMultiUser } = useMutation(createMultiUser, {

      onSettled: async (data) => {
        await data.forEach((user) => {
          divisions.forEach((division) => {
            let payload = {}
            payload['userId'] = user.id;
            payload['divisionId'] = division.id;
            addUserToDivision(payload);
          });
        })
        queryClient.refetchQueries(["allDetailedUsers"]).then(()=> {
          setPropertiesChanged(false);
          setUpdating(false);
          closeCreateUserDrawer();
        });

    },
    onError: (error) => {
      setUpdating(false);
      setErrorMessage(error.message || 'An error occurred');
      setDialogOpen(true);
    },
  });

  const handleNameChange = (value) => {
    setFullName(value);
    setPropertiesChanged(true);
  };
  const handleUsernameChange = (value) => {
    setUsername(value);
    setPropertiesChanged(true);
  };
  const handleEmailChange = (value) => {
    setEmail(value);
    setPropertiesChanged(true);
  };

  const handleAdminPermissionChange = (value) => {
    setCanEditUsersDivisions((canEditUsersDivisions) => !canEditUsersDivisions);
    setPropertiesChanged(true);
  };
  const handleProjectsPermissionChange = () => {
    setCanEditProjects((canEditProjects) => !canEditProjects);
    setPropertiesChanged(true);
  };
  const handleServicesPermissionChange = (value) => {
    setCanEditServices((canEditServices) => !canEditServices);
    setPropertiesChanged(true);
  };

  const handleStatusChange = (value) => {
    setStatus(value);
    setPropertiesChanged(true);
  };

  const handleCancel = () => {
    closeCreateUserDrawer();
    setFullName("")
    setUsername("")
    setEmail("")
    setMultiUpdating(false)
    setUpdating(false)
    setUserFile(null)
    setCsvArray([])
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    if(userFile){
      submitUserFileRead()
      setMultiUpdating(true)
    }else{
      setUpdating(true);
      const newUser = {};
      newUser.name = fullName;
      newUser.companyId = companyId;
      newUser.username = username;
      newUser.email = email;
      newUser.enabled = status;
      newUser.accesses = {};
      newUser.accesses.canEditProjects = canEditProjects;
      newUser.accesses.canEditServices = canEditServices;
      newUser.accesses.canEditUsersDivisions = canEditUsersDivisions;
      addUser(newUser);
    }
  };

  const submitUserFileRead = () => {
      const file = userFile;

      const reader = new FileReader();
      reader.onload = function(e) {
        const text = e.target.result;
        parseCSV(text);
      }
      reader.readAsText(file);
  }

  const parseCSV = (str, delim=",") => {
    const headers = str.slice(0,str.indexOf('\r\n')).split(delim);
    const rows = str.slice(str.indexOf('\n')+1).split('\r\n');
    const newArray = rows.map( row => {
      const values = row.split(delim);
      const eachObject = headers.reduce((obj, header, i) => {
          obj[header] = values[i];
          return obj;
      }, {})
      return eachObject;
    })
    makeMultiUser(newArray)
  }


  const makeMultiUser = (newArray) => {
    if(newArray.length > 0){
      let payload = {}
      payload.companyId = companyId;
      payload.data = newArray
      addMultiUser(payload)

    }

  }

  const handleUserSubmit = (e) => {
    e.preventDefault()
    if(userFile){
      submitUserFileRead()
      setMultiUpdating(true)
    }else{
      openUserNoFileSelectedDialog()

    }
  }

  useEffect(() => {
    if(failedUserName === true){
      openFailedUserDialog()
    }
  }, [failedUserName])


  return (
    <>
    <Drawer
      sx={{
        backgroundColor: "#000",
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="persistent"
      anchor="right"
      open={createUserDrawerOpen.use()}
    >
      <DrawerHeader
        sx={styles.drawerHeader}
      >
        <Typography variant="h6" gutterBottom component="div">
          CREATE USER
        </Typography>
        <IconButton
          sx={styles.iconButton}
          onClick={() => {
            handleCancel();
            setUserFile(null)
          }}
        >
          <CancelIcon sx={styles.cancelIcon} />
        </IconButton>
      </DrawerHeader>
        <Box
          component="form"
          sx={styles.box[1]}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="name"
            label="Name"
            value={fullName}
            onChange={(e) => handleNameChange(e.target.value)}
          />
          <TextField
            id="username"
            label="Username"
            value={username}
            onChange={(e) => handleUsernameChange(e.target.value)}
          />
          <TextField
            id="email"
            label="Email"
            value={email}
            onChange={(e) => handleEmailChange(e.target.value)}
          />
          <InputLabel sx={styles.marginLeft} id="status-label">
            Group:
          </InputLabel>
          <Box
            sx={styles.box[2]}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <_Switch
                    checked={canEditUsersDivisions || false}
                    onChange={handleAdminPermissionChange}
                  />
                }
                label="Admin"
                labelPlacement="start"
              />
              <FormControlLabel
                control={
                  <_Switch
                    checked={canEditProjects || false}
                    onChange={handleProjectsPermissionChange}
                  />
                }
                label="Projects"
                labelPlacement="start"
              />
              <FormControlLabel
                control={
                  <_Switch
                    checked={canEditServices || false}
                    onChange={handleServicesPermissionChange}
                  />
                }
                label="Services"
                labelPlacement="start"
              />
            </FormGroup>
          </Box>

            <InputLabel sx={styles.marginLeft} id="status-label">
              Status:
            </InputLabel>
            <Select
              labelId="status-label"
              id="status"
              defaultValue=""
              value={status}
              label="Status"
              onChange={(e) => handleStatusChange(e.target.value)}
              sx={styles.select}
            >
              <MenuItem key="Active" value={true}>
                Active
              </MenuItem>
              <MenuItem key="Inactive" value={false}>
                Inactive
              </MenuItem>
            </Select>
            <InputLabel sx={{marginLeft: '20px', marginTop: '1rem'}} id="file-label">
              File:
            </InputLabel>
            <input
              type="file"
              accept=".csv"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileInputChange}
            />
            <Box
              component="div"
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '1rem',
                marginBottom: '1rem',
                marginLeft: '1.25rem', 
                marginRight: '1.25rem',
                padding: '15px 12px',
                border: '1px solid #F15A19',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
              onClick={handleFileButtonClick}
            >
              <Typography variant="body1">{userFileName || 'Select File...'}</Typography>
            </Box>
            <Box
              sx={styles.box[3]}
            >
              <Box sx={{display: 'flex', flexDirection: 'column'}}>
              <Outlined_btn_v4
                disabled={true}
                hidden={true}
                onClick={handleCancel}
              >
                Cancel
              </Outlined_btn_v4>
              
              <Contained_btn
                disabled={propertiesChanged === false}
                onClick={handleSubmit}
              >
                {updating ? <CircularProgress color="white"/> : "Create User"}
              </Contained_btn>
              </Box>
            </Box>
            
        </Box>
    </Drawer>
    <ErrorDialog open={dialogOpen} handleClose={handleCloseDialog} errorMessage={errorMessage} />

    
    </>
    // </ClickAwayListener>
  );
};

const styles= {
  drawerHeader: {
    backgroundColor: "#000",
    color: "#fff",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  iconButton: { color: "#fff", textAnchor: "right" },
  cancelIcon: {color:"#fff", textAlign: "right" },
  select: { width: "90%", marginLeft: "20px" },
  box: {
    1: {"& .MuiTextField-root": { margin: 2, width: "90%" }},
    2: {
      display: "flex",
      flexDirection: "col",
      paddingLeft: "2rem",
      paddingRight: "6rem",
      justifyContent: "center",
    },
    3: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      marginTop: "2rem",
      paddingLeft: "1rem",
      paddingRight: "1rem",
    }
  },
  createUserbtn: { backgroundColor: "#F15A29", color:"white"},
  marginLeft: { marginLeft: "20px" },
  btn: {
    outlined: {borderColor: "#F15A29", color: "#F15A29"}
  }

};
