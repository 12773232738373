import { useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    DialogActions
} from "@mui/material";

import {styled} from "@mui/material/styles";
import { Text_btn } from "components/Theme/Styled";

import{
    closeUserNoFileSelectedDialog,
    userNoFileSelectedOpen
} from "../../../Context/DrawerStates";

export const NoFileSelected = (props) => {

    const [open, setOpen] = useState(false);

    const handleClose = (e) => {
        closeUserNoFileSelectedDialog();
        setOpen(false);
    }

    return (
        <>
            <Dialog open={userNoFileSelectedOpen.use()}>
                <DialogTitle color="secondary">NO FILE SELECTED</DialogTitle>
                <DialogContent>
                    <Typography_>No File Selected. Please select a file.</Typography_>
                </DialogContent>
                <DialogActions>
                    <Text_btn onClick={(e) => handleClose(e)}>OK</Text_btn>
                </DialogActions>
            </Dialog>
        </>
    )
}

const Typography_ = styled(Typography)`
  //width: 75% !important;
  flex-shrink: 0 !important;
`;
