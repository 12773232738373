import {
    DataGridPro,
    GridToolbar,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
    LicenseInfo,
    gridDateComparator,
    useGridApiRef
} from "@mui/x-data-grid-pro";
import { useEffect, useReducer, useState } from 'react';

import { Box } from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import DataGrid from "../components/DataGrid";
import { getPilotQueue } from "data/queries/queryAPI";
import { useMutation } from "react-query";
import { useQuery } from "react-query";

const PilotRequests = props => {



    const [rows, setRows] = useState( [] );
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedObjects, setSelectedObjects] = useState([]);
    const dataGridRef = useGridApiRef();

    useEffect(() => {
        props.setSelectionModel(selectionModel)
    }, [selectionModel])

    useEffect(() => {
        props.setSelectedObjects(selectedObjects)
    }, [selectedObjects])

    const dbFormat = value => {

        const arr = String(value).split("'");
        const str = arr.join('"');
        const obj = JSON.parse(str);
        const res = obj.Items;
        return res;
    }

    const pilotQuery = useQuery(
        ['pilotApplicationQueue'],
        async () => {
            await getPilotQueue().then( data => {
                const items = data.data;
                const format = dbFormat(items);
                const res = formatForRows(format);
                setRows(res);
                return items;
            })
        },
        {
            staleTime: Infinity,
            refetchOnMount: 'always',
            refetchOnWindowFocus: false
        }
    );
    

    const dynamoHelper = value => {

        // EXTEND WITH WHATEVER DATATYPES NEEDED

        if( value.N ) {
            return Number(value.N);
        }
        else if(value.S) {
            return String(value.S)
        }
    };

    const formatForRows = value => {

        const batch = [];

        value.forEach( app => {

            const item = {};
            const dh = dynamoHelper;

            item.id = dh( app.id ); //String( app.id.S );
            item.name = dh( app.name );  //String( app.name.S );
            item.phoneNumber = dh( app.phoneNumber ); //String( app.phoneNumber.S );
            item.date = dh( app.date ); //Number( app.date.N );
            item.employeeCount = dh( app.employeeCount ); //Number( app.employeeCount.N );
            item.licenseCount = dh( app.licenseCount ); //Number( app.licenseCount.N );
            item.address = dh( app.address ); //String( app.address.S );
            item.email = dh( app.email );
            item.referral = dh( app.referral );
            item.status = dh( app.status ); //String( app.status)

            batch.push(item);
        });   
        
        return batch;
    };

    useEffect(() => {
    }, [pilotQuery])

    const columns = [
        {
            field: 'status',
            headerName: 'Status',
            sortable: true,
            width: 80,
            renderCell: params => {

                if(params.row.status === 'Approved') {

                    return <CircleIcon sx={styles.color.green}/>

                }
                else if(params.row.status === 'Pending') {

                    return <CircleIcon sx={styles.color.yellow}/>

                }
                else if(params.row.status === 'Declined') {

                    return <CircleIcon sx={styles.color.red}/>

                }               
            },
        },
        {
            field: "date",
            headerName: "Date",
            sortable: true,
            width: 100,
            disableClickEventBubbling: true,
            delete: true,
            // valueGetter: params => {
    
            //     // CONVERTS TO UNIX EPOCH SO THE MUI NUMBER COMPARATOR CAN HANDLE SORTING
            //     // var nums = params.value.split('/');
            //     // var dateParsed = new Date( Number(nums[2]), Number(nums[0]) - 1, Number(nums[1]) ).getTime();
            //     // return dateParsed;
            // },
            // valueFormatter: params => {
    
            //     var str = new Date( params.row.date ).toLocaleDateString();
            //     return str;
            // }  ,
            renderCell: params => {
                var str = new Date( params.row.date ).toLocaleDateString();
                return str;
            }          
        },
        { field: "name", headerName: "Name", width: 200 },
        { field: "employeeCount", headerName: "Employees", width: 80},
        { field: "licenseCount", headerName: "Licenses", width: 100 },
        { field: "email", headerName: "Email", width: 250 },
        { field: "phoneNumber", headerName: "Phone", width: 125 },
        { field: "referral", headerName: "Referral", width: 150 },

    ];    

    return(
        <Box sx={ props.sx } >
            <DataGrid
                apiRef={dataGridRef}
                editMode="row"
                columns={columns}
                rows={rows}
                checkboxSelection={true}
                pageSize={10}
                pagination
                onSelectionModelChange={ (ids) => {
                    const selectedIDs = new Set(ids);
                    const selectedRowData = rows.filter((row) => selectedIDs.has(row.id.toString()))
                    setSelectionModel(ids)
                    setSelectedObjects(selectedRowData)
                }}
                selectionModel={selectionModel}
                autoHeight={true}            
            />
            {/* <CircleIcon sx={styles.color.green}/>
            <CircleIcon sx={styles.color.yellow}/>
            <CircleIcon sx={styles.color.red}/> */}

        </Box>
    );
};

const styles = {

    color: {
        green: { color: '#3DED97'},
        red: { color: '#E3242B'},
        yellow: { color: '#FDE992'}
    }
};

export default PilotRequests;