import {
    DataGridPro,
    GridToolbar,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
    LicenseInfo,
    useGridApiRef
} from "@mui/x-data-grid-pro";

function CustomToolbar() {

    return (
        <GridToolbarContainer>
          <GridToolbarColumnsButton/>
          <GridToolbarFilterButton/>
          <GridToolbarDensitySelector/>
          {/* <GridToolbarExport/> */}
        </GridToolbarContainer>
    );
  }

  export default CustomToolbar;
