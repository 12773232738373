import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { Contained_btn, Outlined_btn } from "components/Theme/Styled";
import React, { useEffect, useState } from "react";
import {
  closeDivisionRenameDialog,
  divisionRenameDialogOpen,
} from "../../../Context/DrawerStates";

import clone from "just-clone";

export const RenameDivisionForm = (props) => {
  const [open, setOpen] = React.useState(false);



  const handleClickOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = (e) => {
    e.stopPropagation(e);
    props.setNewDivisionName("");
    closeDivisionRenameDialog();
    setOpen(false);
  };

  const handleRename = () => {
    const divisionClone = clone(props.division);
    divisionClone.name = props.newDivisionName;
    props.renameItem({
      divisionId: props.division.id,
      data: {
        ...divisionClone,
      },
    });
    props.setIsRenaming(true);
    setOpen(false);

  };

  return (
    <>
      <Dialog open={divisionRenameDialogOpen.use()}>
        <Box sx={{backgroundColor: '#F15A19', color: '#fff', display: 'flex', justifyContent: 'left', alignItems: 'center', height: '60px'}}>
          <Box sx={{marginLeft: '2rem'}}>
            <Typography variant="h5" >Rename Division</Typography>
          </Box>
        </Box>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              sx={{width: '250px'}}
              label=" New Division Name"
              type="text"
              fullWidth
              variant="outlined"
              value={props.newDivisionName}
              onChange={(e) => props.setNewDivisionName(e.target.value)}
            />
          </DialogContent>
          <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '1rem'}}>
            <Box sx={{marginRight: '1rem'}}>
              <Outlined_btn
                onClick={
                  (e) => {
                    handleClose(e)
                    props.setNewDivisionName(" ")
                  }}
              >
                Cancel
              </Outlined_btn>
            </Box>
            <Box sx={{marginLeft: '1rem'}}>
              <Contained_btn
                onClick={handleRename}>
                Rename
                {props.isRenaming ? <CircularProgress size={12} color='white'/> : null}
              </Contained_btn>
            </Box>
          </Box>
        </Dialog>
    </>
  );
};
