import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import { Typography } from "@mui/material";
import { getCatalogs } from "../../../data/queries/queryAPI";
import { useQuery } from "react-query";

export const CollapseCatalogs = (props) => {
  const [catalogList, setCatalogList] = useState([]);
  const catalogsQuery = useQuery(["allCatalogs"], () => getCatalogs(), {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });


  useEffect(() => {
    const collector = props.catalogs.map((catalogId) => {
      return catalogsQuery.data?.data.find((catalogObj) => {
        return catalogObj.id === catalogId;
      });
    });

    const alphabetized = collector.sort((a, b) => {
      if (a.name.toUpperCase() === b.name.toUpperCase()) {
        return 0;
      }
      return a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1;
    });

    setCatalogList(alphabetized);
  }, [catalogsQuery.data, props.catalogs]);

  return (
    <Box sx={styles.box[1]}>
      <Collapse in={props.catalogsOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {catalogList.filter(catalog => catalog).length > 0 ? (
            catalogList.filter(catalog => catalog).map((catalog) => {
              return <ListItemText key={catalog.id} primary={catalog.name} />;
            })
          ) : (
            <Typography variant="body">No catalogs found</Typography>
          )}
        </List>
      </Collapse>
    </Box>
  );
};

const styles = {
  box: {
    1: { marginLeft: "4rem" }
  }
}
