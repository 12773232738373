import React, {useEffect, useRef, useState} from 'react';
import Card from '@mui/material/Card';
import Grid from "@material-ui/core/Grid";
import Loading from "./Loading";
import {Box} from "@mui/material";
import {styled} from "@mui/material/styles";

const ModifiedParts = (props) =>{
    const {modifiedParts, setSelectedItem} = props;
    const [selected, setSelected] = useState('none');
    
    const onSelect = (event) =>{
        localStorage.setItem("LAST_SELECTED_DUCT_ITEM", event);
        setSelected(event);
        setSelectedItem(event);
    }
    return(

        <Card sx={styles.card} variant="outlined">
            <Grid_ 
                container 
                spacing={1} 
            >
            {modifiedParts && modifiedParts.connectors && Array.isArray(modifiedParts.connectors) && modifiedParts.connectors.length > 0 ? (modifiedParts.connectors.map((e, key) =>{
                    return<Box key={key} sx={styles.div[2]} className="item" onClick={() =>{onSelect(e)}}>
                            <IMG_ 
                                src={e.materialSprite}
                                alt="sketch"
                            />
                            <Box sx={styles.div[3]} className="multiline">{e.description}</Box>
                          </Box>
                })) : (<Loading message="Waiting for item to be saved to configuration..."></Loading>)}
            </Grid_>
        </Card>

    );
};

const styles = {
    div: {
        1: {width: 400, height: 512, border: '4px solid #003E70' , backgroundColor: '#003E70'},
        2: {height:168,width: 79.6, backgroundColor: 'darkgray'},
        3: { objectFit: "contain", overflow:'hidden', height: 60}
    },
    grid: {width: 400, height: 325, marginLeft: '1px', marginTop: '1px'},
    img: { height:64, objectFit: "contain", border:'4px solid lightgray' },
    card: {width: 400, height: 512, border: '2px solid #F15A29 !important' , backgroundColor: '#white'}
};

const IMG_ = styled('img')`
    height: 64px; 
    objectFit: contain; 
    border: 4px solid lightgray;
`;
const Grid_ = styled(Grid)`
    width: 400px; 
    height: 325px;
    marginLeft: 1px !important; 
    marginTop: 1px !important;  
`;

export default ModifiedParts;