export const statusPalette = {
  D: { main: "#ff00ff", contrastText: "#fff" },
  DESIGN: { main: "#ff00ff", contrastText: "#000" },
  "FABRICATION IN PROGRESS": { main: "#003fff", contrastText: "#000" },
  "FABRICATION COMPLETE": { main: "#ff00bf", contrastText: "#000" },
  F: { main: "#000000", contrastText: "#fff" },
  FC: { main: "#ff00bf", contrastText: "#000" },
  FI: { main: "#ffdf80", contrastText: "#000" },
  "FIELD INSTALLED": { main: "#ffdf80", contrastText: "#000" },
  "FIELD RECEIVED": { main: "#ff9f80", contrastText: "#000" },
  FIP: { main: "#003fff", contrastText: "#000" },
  FR: { main: "#ff9f80", contrastText: "#000" },
  FLUSHED: { main: "#000000", contrastText: "#FFF" },
  I: { main: "#00ff3f", contrastText: "#000" },
  IN: { main: "#000000", contrastText: "#fff" },
  INSPECTED: { main: "#000000", contrastText: "#fff" },
  "ISSUED FOR FABRICATION": { main: "#00ff3f", contrastText: "#000" },
  JC: { main: "#bf00ff", contrastText: "#000" },
  "JOINT COMPLETE": { main: "#bf00ff", contrastText: "#000" },
  M: { main: "#ff8080", contrastText: "#000" },
  MC: { main: "#3f00ff", contrastText: "#000" },
  "MATERIAL CUT": { main: "#3f00ff", contrastText: "#FFF" },
  "MATERIAL ORDERED": { main: "#00ffbf", contrastText: "#000" },
  "MATERIAL RECEIVED": { main: "#00bfff", contrastText: "#000" },
  MO: { main: "#00ffbf", contrastText: "#000" },
  MODELED: { main: "#ff8080", contrastText: "#000" },
  MR: { main: "#00bfff", contrastText: "#000" },
  OH: { main: "#000000", contrastText: "#000" },
  "ON HOLD": { main: "#000000", contrastText: "#fff" },
  P: { main: "#bfff00", contrastText: "#000" },
  PACKAGED: { main: "#bfff00", contrastText: "#000" },
  PH: { main: "#000000", contrastText: "#fff" },
  "PLACE HOLDER GEOMETRY": { main: "#000000", contrastText: "#fff" },
  "RELEASE TRIMBLE POINTS": { main: "#000000", contrastText: "#fff" },
  RTP: { main: "#000000", contrastText: "#fff" },
  S: { main: "#3fff00", contrastText: "#000" },
  SH: { main: "#ff003f", contrastText: "#000" },
  SHIPPED: { main: "#ff003f", contrastText: "#FFF" },
  SPOOLED: { main: "#3fff00", contrastText: "#000" },
  TEST: { main: "#000000", contrastText: "#fff" },
  TESTED: { main: "#000000", contrastText: "#fff" },
};
